/**
 * AmplifyClient is to Configure the amplify. 
 */
import { Amplify } from "@aws-amplify/core"

export default class AmplifyClient {

    static Configure(apiUrl: string) {

        // Added the below line for lower env test. 
        const apiKey = localStorage.getItem("api_key") ? localStorage.getItem("api_key") : process.env.API_KEY

        //setup auth for lower environment with api key
        if (process.env.ENVIRONMENT === "sbx" || process.env.ENVIRONMENT === "dev") {
            Amplify.configure({
                API: {
                    GraphQL: {
                        endpoint: apiUrl,
                        region: process.env.AWS_REGION,
                        defaultAuthMode: 'apiKey',
                        apiKey: apiKey
                    }
                }
            });
        }
        //setup auth for higher environment with token 
        else {
            Amplify.configure({
                API: {
                    GraphQL: {
                        endpoint: apiUrl,
                        region: process.env.AWS_REGION,
                        defaultAuthMode: 'lambda',
                        apiKey: apiKey
                    }
                }
            });
        }
    }
}