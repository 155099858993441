import React, { useCallback, useEffect, useRef, useState } from "react";
import { User } from "../utils/user";
import { ISendMessageStateProps } from "./SendMessage";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { ApolloError, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import PreviewPanelUserUpdates from "./PreviewPanelUserUpdates";
import { V2UserConversation, IMessageDetails } from "../types/userConversation";

//style
import commonStyle from "../styles/Common.module.scss";
import { GET_CONVERSATION_INFO, GET_LOCAL_CONVERSATION_AND_MESSAGE, GET_LOCAL_V2MESSAGES } from "../graphql/queries";
import { UPDATE_MESSAGE_READ_STATUS_V2 } from "../graphql/mutations";

export interface IPreviewPanelMessagesLoadingProps {
  memoizedSetSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  refConversationId: string;
  selectedUserERN: string;
  queryConversation: any;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}

//#region Graphql schema
export const initialLoadCount = 100;

const PreviewPanelMessagesLoading: React.FC<IPreviewPanelMessagesLoadingProps> = (
  props: IPreviewPanelMessagesLoadingProps
) => {
  const loadedMessages = useRef<IMessageDetails[]>([]);
  const updateCount = useRef(0);
  const hasLoaded = useRef(false);
  // const [readTriggerCount, setReadTriggerCount] = useState(0);
  const [loadedCount, setLoadedCount] = useState(0);
  const client = useApolloClient();
  const [updateMessageReadStatus] = useMutation(UPDATE_MESSAGE_READ_STATUS_V2);

  const handleError = (_err: ApolloError) => {
    console.error(`Error  : ${JSON.stringify(_err.message)}`);
    props.setIsError(true);
    Logger.error(_err.message);
    return false;
  };

  const appContext: IAppContextInterface = React.useContext(AppContext);
  let user = new User(appContext.userToken);

  // Fetch messages in the first conversation
  const {
    data: messages,
    fetchMore,
    updateQuery: updateMessageCache
  } = useQuery(GET_CONVERSATION_INFO, {
    variables: {
      ConversationIds: [props.queryConversation[0].ConversationId]
    },
    onError: handleError,
    fetchPolicy: "cache-first"
  });

  const { data: allMessages } = useQuery(GET_LOCAL_V2MESSAGES, {
    onError: handleError,
    variables: {
      Id: "all"
    },
    fetchPolicy: "cache-only"
  });

  useEffect(() => {
    if (allMessages?.getLocalMessages?.length > 0) {
      loadedMessages.current = allMessages.getLocalMessages;

      //Think and try to stop this
      setLoadedCount((prev) => {
        return prev + 1;
      });
    }
  }, [allMessages]);

  /**
   * Append message into cache
   */
  const writeMessageCache = (messages: IMessageDetails[]) => {
    let prevMessages: IMessageDetails[] = [];
    //read cached messages
    const result = client.cache.readQuery({
      query: GET_LOCAL_V2MESSAGES,
      variables: {
        Id: "all"
      }
    });

    if (result?.["getLocalMessages"]) {
      prevMessages = result["getLocalMessages"] as IMessageDetails[];
    }
    //write cached messaes
    client.cache.writeQuery({
      query: GET_LOCAL_V2MESSAGES,
      variables: {
        Id: "all"
      },
      data: {
        getLocalMessages: [...prevMessages, ...messages]
      }
    });
  };

  const fetchMoreMessages = async (conv: V2UserConversation[]) => {
    let convIds: string[] = [];
    //get list of conversationIds in string array
    for (const element of conv) {
      convIds.push(element.ConversationId);
    }

    const updateTotal = sliceIntoChunks(props.queryConversation.slice(1), 25).length;
    await fetchMore({
      variables: {
        ConversationIds: convIds
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        writeMessageCache(fetchMoreResult["getConversationByIds"]);

        updateCount.current++;
        if (updateCount.current == updateTotal) {
          setLoadedCount((prev) => {
            return prev + 1;
          });
        }
      }
    }).catch((error) => {
      console.log(error);
      props.setIsError(true);
    });
  };

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  useEffect(() => {
    if (messages?.getConversationByIds && hasLoaded.current === false) {
      hasLoaded.current = true;
      loadedMessages.current = [...loadedMessages.current, ...messages["getConversationByIds"]];
      writeMessageCache(messages["getConversationByIds"]);
      if (props.queryConversation.length > 1) {
        sliceIntoChunks(props.queryConversation.slice(1), 25).forEach(async (conv: V2UserConversation[]) => {
          await fetchMoreMessages(conv);
        });
      } else {
        // Trigger render when less than 2 conversations
        setLoadedCount((prev) => {
          return prev + 1;
        });
      }
    }
  }, [messages]);
  // Get read status updates from message previews navigation
  const handleReadMessageUpdate = useCallback((conversationId) => {
    if (conversationId != "") {
      let localMessages;
      const messages = client.cache.readQuery({
        query: GET_LOCAL_CONVERSATION_AND_MESSAGE,
        variables: {
          Id: "all"
        }
      });
      if (messages?.["getLocalConversationAndMessage"]) {
        let filterList = [...messages["getLocalConversationAndMessage"]];
        localMessages = filterList?.filter((x) => {
          if (x.conversationId === conversationId) {
            return { ...x };
          }
        });
      }
      if (localMessages?.length > 0) {
        updateMessageReadStatus({
          variables: {
            ConversationId: conversationId,
            AuthorERN: user.userERN,
            AuthorOrgERN: user.organisationId,
            ParticipantList: localMessages[0]?.participantList
          },
          onError: handleError,
          update() {
            updateMessageCache((prev) => {
              let itemList: IMessageDetails[];

              if (loadedMessages.current) {
                itemList = loadedMessages.current;
              }
              if (itemList) {
                let items = JSON.parse(JSON.stringify(itemList));

                items = sortItems(items, conversationId);
                itemList = items;
              }
              loadedMessages.current = itemList;
            });
            // setReadTriggerCount((prev) => {
            //   return prev + 1;
            // });
          }
        }).catch((e) => console.error("Update messge read status failed." + e.message));
      }
    }
  }, []);

  function sortItems(items: any, conversationId: any) {
    items
      .filter(
        (item: IMessageDetails) =>
          item.IsRead === false && item.ConversationId === conversationId && item.LastMessageAuthorId !== user.userERN
      )
      .forEach((item) => {
        item.IsRead = true;
      });

    return items;
  }
  //#endregion
  if (loadedCount > 0 && loadedMessages.current.length > 0) {
    return (
      <PreviewPanelUserUpdates
        key={"PreviewPanelUserUpdates"}
        queryConversation={props.queryConversation}
        memoizedSetSendMessageState={props.memoizedSetSendMessageState}
        messageReadCallback={handleReadMessageUpdate}
        setIsError={props.setIsError}
        refConversationId={props.refConversationId}
        selectedUserERN={props.selectedUserERN}
        loadedMessageList={loadedMessages.current}
        isGroup={props.isGroup}
        setIsRemoved={props.setIsRemoved}
      />
    );
  } else {
    return (
      <div className={commonStyle.leftPanel}>
        <div className={commonStyle.spinner}>
          <ProgressSpinner text="Loading messages..." />
        </div>
      </div>
    );
  }
};

export default PreviewPanelMessagesLoading;
