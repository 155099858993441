import style from "../styles/MessageListItemComponent.module.scss";
import { ListItem } from "@emisgroup/ui-list";
import { Badge } from "@emisgroup/ui-badge";
import { getLocalTimeString, getMessageDate } from "../utils";
import { useContext, memo } from "react";
import { Participant } from "./ListItemComponent";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import Avatar from "../custom_components/UserAvatar/Avatar";
import Reply from "~icons/ic/baseline-reply";
import { ISendMessageStateProps } from "./SendMessage";

import { UserConversation } from "../types/userConversation";
import { useApolloClient } from "@apollo/client";
import { getLocalConversationGroupV2 } from "../utils/MessageComponent";
import UserGroup from "~icons/ic/outline-groups";
import { UserAvatar } from "@emisgroup/ui-avatar";

export interface IMessageListItemComponentProps {
  index: number;
  preview: Partial<messageDetails>;
  setSendMessageState?: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  messageReadCallback: Function;
  selectedConversationId: string;
  selectedUserERN: string;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}

export type messageDetails = {
  name: string;
  authorId: string;
  message: string;
  time: string;
  isRead: boolean;
  Las: string;
  conversationId: string;
  isReply: boolean;
  isUrgent: boolean;
  participants: Array<Participant>;
  userStatus: boolean;
  organisationId: string;
  isGroup: boolean;
};

const checkReceivedMessage = (userName: string, author: string) => {
  return userName !== author;
};

const checkListLabel = (listLabel: string, time?: string) => {
  return listLabel === "Today" ? getLocalTimeString(time) : listLabel;
};
const MessageListItemComponent = (props: IMessageListItemComponentProps) => {
  const { preview, index } = props;
  let isGroupInProgress: boolean = false;
  //Apollo Client
  const apolloClient = useApolloClient();

  const appContext: IAppContextInterface = useContext(AppContext);
  let user = appContext.user;

  let localGroupConversation: UserConversation;
  localGroupConversation = getLocalConversationGroupV2(apolloClient.cache);

  const splitDisplayName = (displayName) => {
    const splitName = displayName.split(",");
    const familyName: string = splitName[0].trim();
    const givenName: string = splitName.length > 1 ? splitName[1].trim() : "";
    return [familyName, givenName];
  };

  function clearlocalCache() {
    if (localGroupConversation) {
      const normalizedId = apolloClient.cache.identify(localGroupConversation);
      return apolloClient.cache.evict({ id: normalizedId });
    }
  }

  const findRecipient = (messageDetails) => {
    if (messageDetails.participantList) {
      return messageDetails.participantList.length > 1 &&
        user.userERN === messageDetails.participantList[0].split("_")[1]
        ? messageDetails.participantList[1].split("_")[1]
        : messageDetails.participantList[0].split("_")[1];
    } else {
      return "";
    }
  };

  const findSelectedUser = (messageDetails) => {
    if (
      messageDetails.participantList &&
      messageDetails.participantList.length <= 2 &&
      !props.selectedConversationId &&
      !messageDetails.isGroup
    ) {
      if (user.userERN === props.selectedUserERN && messageDetails.participantList.length === 1) {
        return messageDetails.participantList[0].split("_")[1];
      } else {
        let filterName = messageDetails.participantList.find(
          (participant) =>
            participant.split("_")[1] == props.selectedUserERN && participant.split("_")[1] != user.userERN
        );
        if (filterName) return filterName.UserERN;
      }
    }
    return undefined;
  };

  const toDispalyMessages = (messageDetails) => {
    clearlocalCache();
    const recipient = findRecipient(messageDetails);
    const avatarColor = setAvatarColor(messageDetails);
    props.setSendMessageState((prev) => {
      if (prev.conversationId != messageDetails.conversationId) {
        if (prev.conversationId) props.messageReadCallback(prev.conversationId);
        props.setIsRemoved(() => messageDetails.isRemoved);
        appContext.selectedConversationId.current = messageDetails.conversationId;
        return {
          title: messageDetails.name,
          conversationId: messageDetails.conversationId,
          recipients: [recipient],
          message: messageDetails.message,
          time: messageDetails.time,
          createdAtMessageId: messageDetails.createdAtMessageId,
          authorId: user.userERN,
          avatarColor: avatarColor,
          isGroup: preview.isGroup,
          conversationName: "",
          isRemoved: messageDetails.isRemoved,
          removedOn: messageDetails.removedOn,
          removedBy: messageDetails.removedBy,
          hasBeenNamed: messageDetails.hasBeenNamed
        };
      } else {
        return prev;
      }
    });
  };

  let isRecievedMessage = checkReceivedMessage(user.userERN, preview.authorId);
  if (!preview.name) {
    preview.name = "";
  }

  const [lastName, firstName] = splitDisplayName(preview.name);

  let listLabel = getListLabel(preview);

  const isSelected = () => {
    if (
      props.selectedConversationId === preview.conversationId ||
      (!props.selectedConversationId && props.selectedUserERN === findSelectedUser(preview))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setListMessageNameStyle = (isRecMessage) => {
    return !preview.isRead && isRecMessage ? style.listUnReadMessageNameV2 : style.listMessageNameV2;
  };
  const setReplyMessageStyle = (isRecMessage) => {
    return isRecMessage ? style.replyMessageV2 : style.replyMessageWithIconV2;
  };
  const setListMessageTextStyle = (isRecMessage) => {
    if (isRecMessage && !preview.isRead) {
      return style.listUnReadMessageTextV2; //Unread
    } else if (!isRecMessage && !isSelected()) {
      return style.listMessageTextWithIconV2; //Replied and not selected
    } else if (!isRecMessage && isSelected()) {
      return style.listMessageTextWithIconSelectedV2; //Replied and selected
    } else if (preview.isRead && isRecMessage && isSelected()) {
      return style.listMessageTextSelectedV2; //Read and selected
    } else {
      return style.listMessageTextV2; //Read
    }
  };
  const setReplyPositionStyle = () => {
    return isSelected() ? style.replyPositionSelectedV2 : style.replyPositionV2;
  };
  const setListTimestyle = (isRecMessage) => {
    if (preview.isRead || !isRecMessage) {
      return style.readListTimeV2;
    } else if (preview.isUrgent && !preview.isRead) {
      return style.unReadBadgeListTimeV2;
    } else {
      return style.unReadListTimeV2;
    }
  };
  const setAvatarColor = (messagePreview) => {
    return messagePreview.participantList ? findRecipient(messagePreview) : "";
  };

  const ConversationIcon = preview.isGroup ? (
    <UserAvatar color="featured" size="medium" tooltip="">
      <UserGroup color="mono" size="medium" title="" />
    </UserAvatar>
  ) : (
    <Avatar
      firstName={firstName}
      fullName={preview.name}
      lastName={lastName}
      isAvailable={preview.userStatus}
      color={setAvatarColor(preview)}
      isGroup={false}
      size={"medium"}
    />
  );

  return (
    <div key={"" + index} role={"listItem_" + index} className={getStyleDetails(props, preview, findSelectedUser)}>
      <ListItem
        divider={true}
        className={style.listItemWidthV2}
        data-testid={"listItem" + (isRecievedMessage && !preview.isRead ? "_unread" : "_read")}
        key={"" + index}
        action={{
          callback: () => toDispalyMessages(preview),
          disableToggle: true
        }}
      >
        <div className={style.avatarV2}>{ConversationIcon}</div>
        <div className={style.leftElementV2}>
          <span className={setListMessageNameStyle(isRecievedMessage)}>{preview.name}</span>
          {!isRecievedMessage && !isGroupInProgress ? (
            <div className={setReplyPositionStyle()}>
              <Reply title="Replied" size="small" />
            </div>
          ) : null}
          {!isGroupInProgress && (
            <div className={setReplyMessageStyle(isRecievedMessage)}>
              <span
                className={setListMessageTextStyle(isRecievedMessage)}
                dangerouslySetInnerHTML={{ __html: preview.message }}
              ></span>
            </div>
          )}
        </div>
        <div className={style.rightElementsV2}>
          {isRecievedMessage && preview.isUrgent && !preview.isRead ? (
            <div className={style.badgeDisplayV2}>
              {" "}
              <Badge children="Urgent" variant="danger" className={style.badgeV2} />{" "}
            </div>
          ) : null}
          {!isGroupInProgress && (
            <span className={setListTimestyle(isRecievedMessage)}>{checkListLabel(listLabel, preview.time)}</span>
          )}
        </div>
      </ListItem>
    </div>
  );
};

export default memo(MessageListItemComponent);

function getStyleDetails(
  props: IMessageListItemComponentProps,
  preview: Partial<messageDetails>,
  findSelectedUser: (messageDetails: any) => any
): string {
  return props.selectedConversationId === preview.conversationId ||
    (!props.selectedConversationId && props.selectedUserERN === findSelectedUser(preview))
    ? style.listItemSelected
    : style.listItem;
}

function getListLabel(preview: Partial<messageDetails>) {
  let listLabel: string = "";
  const date = getMessageDate(preview.time).toDateString();
  listLabel = new Date(date)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric"
    })
    .split(" ")
    .join("-");

  if (date == new Date().toDateString()) {
    listLabel = "Today";
  } else if (date == new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()) {
    listLabel = "Yesterday";
  }
  return listLabel;
}
