/**
 * ! THIS FILE IS MANAGED BY THE MENDELEEV TEAM.
 * ! IF UPDATES ARE REQUIRED PLEASE SPEAK TO THE TEAM FIRST (https://emishealth.slack.com/archives/C0105EZHBCL)
 * ! OR RAISE AN ISSUE IN THE CREATE-EMISX-APP REPO (https://github.com/emisgroup/create-emisx-app/issues)
 */

import { useEffect } from "react";
import { SessionContext } from "@emisgroup/application-session-management";
import { SessionContextProvider } from "@emisgroup/application-session-react";
import { IntlProvider } from "./IntlProvider";
import App from "./App";
import { ILifeCycleProps } from "./Types";
import { BrowserRouter } from 'react-router-dom';

const sessionContext = new SessionContext();

/**
 * Entry point for the application.
 *
 * @param props Lifecycle props. These can be used to get access to the base path for routing and the environment of the host e.g. dev, int, prd etc.
 * @returns Main application element
 */
const Root = (props: ILifeCycleProps): JSX.Element => {
  /*
    This section can be used to setup the application and clear it down when it is mounted into the frame
  */

  const onApplicationMount = (): void => {
    // Setup code here

    // Set the language.
    // This is to bypass the need for a hard coded HtmlWebpackPlugin
    // template.
    // It also means we can derive this from elsewhere in the future.
    document.documentElement.lang = "en";
  };

  const onApplicationUnMount = (): void => {
    // Teardown code here
  };

  useEffect(() => {
    onApplicationMount();

    return (): void => {
      onApplicationUnMount();
    };
  });

  return (
    <IntlProvider>
      <SessionContextProvider value={sessionContext}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SessionContextProvider>
    </IntlProvider>
  );
};

export default Root;
