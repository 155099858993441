import { gql } from "@apollo/client";
export const CONVERSATION_FRAGMENT =
  gql`
          fragment conversation on GroupConversationV2 {
            ConversationId
            LastUpdatedOn
            ConversationName
            HasBeenNamed
            IsDeleted
            DeletedOn
            OrganisationId
            UserName
            UnreadCount
            HasUrgent
            RemovedOn
            Participants {
              OrganisationId
              UserName
            }
            ParticipantList
            IsLocal @client,
            IsGroupConversation
            AddedToConversationOn
            IsConversationHistoryShared
          }
        `;

export const GET_LOCAL_CONVERSATION = gql`
  query GetLocalConversation($ConverastionId: String!) {
    getLocalConversation(ConversationId: $ConverastionId) {
      ConversationId
      ConversationName
      DeletedOn
      IsDeleted
      LastUpdatedOn
      OrganisationId
      RemovedOn
      UnreadCount
      HasUrgent
      HasBeenNamed
      IsGroupConversation
      IsRemoved
      Participants {
        OrganisationId
        UserName
      }
      ParticipantList
      IsLocal @client
      UserName
      AddedToConversationOn
      IsConversationHistoryShared
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation CreateConversations(
    $ConversationId: ID
    $OrganisationId: ID!
    $Participants: [Participant!]!
    $ConversationName: String
    $UserName: String
    $IsGroupConversation: Boolean
    $IsConversationHistoryShared: Boolean
    $HasBeenNamed: Boolean
  ) {
    createConversationsV4(
      ConversationId: $ConversationId
      OrganisationId: $OrganisationId
      Participants: $Participants
      ConversationName: $ConversationName
      IsGroupConversation: $IsGroupConversation
      IsConversationHistoryShared: $IsConversationHistoryShared
      HasBeenNamed: $HasBeenNamed
      UserName: $UserName
    ) {
      ConversationId
      OrganisationId
      Participants {
        OrganisationId
        UserName
      }
      ParticipantList
      ParticipantHash
      IsDeleted
      DeletedOn
      HasBeenNamed
      ConversationName
      LastUpdatedOn
      IsGroupConversation
      IsConversationHistoryShared
    }
  }
`;


