import React, { useState } from "react";
import { ContextUtils } from "./utils/contextUtils";
import { ApplicationContext } from "./utils/ApplicationContext";
import { LoggerComponent } from "@emisgroup/logging-sdk-typescript";
import { AppContextUtil } from "./utils/AppContextUtils";

import BaseComponent from "./components/BaseComponent";
import V2BaseComponent from "./V2components/BaseComponent";
import AddUpdateUser from "./components/AddUpdateUser";
import { ApolloProvider } from "@apollo/client";
import { useFlags } from "@emisgroup/acp-utility-feature-flags";

const AppContext = ApplicationContext.getApplicationContext();
let appContextUtil = new AppContextUtil();

const Layout = (): React.JSX.Element => {
  const [ready, setReady] = useState<boolean>(false);
  const { staffMessagingUiEnablenewapi, staffMessagingUiEnablenewschema } = useFlags();

  //setup amplify
  ContextUtils.getAccessTokenFromContext()
    .then((token) => {
      const apiUrl = staffMessagingUiEnablenewapi ? process.env.API_URL_NEW : process.env.API_URL;
      appContextUtil.config(token, apiUrl);
      setReady(true);
    })
    .catch((e) => console.error(`error while get token${e}`));

  const getBaseComponent = () => {
    if (staffMessagingUiEnablenewschema) {
      return <V2BaseComponent user={appContextUtil.appUtil.appContext.user} />;
    } else {
      return <BaseComponent user={appContextUtil.appUtil.appContext.user} />;
    }
  };

  return (
    <>
      {ready && (
        <AppContext.Provider value={appContextUtil.appUtil.appContext}>
          <ApolloProvider client={appContextUtil.appUtil.client}>
            <LoggerComponent {...appContextUtil.appUtil.loggerConfig}>
              <AddUpdateUser data-testid="updateUser" existingUser={appContextUtil.appUtil.appContext.user} />
              {getBaseComponent()}
            </LoggerComponent>
          </ApolloProvider>
        </AppContext.Provider>
      )}
    </>
  );
};

export default Layout;
