import { getUserContext } from "@emisgroup/acp-utility-user-context";

export class ContextUtils {
  static async getAccessTokenFromContext(): Promise<any> {
    let token;
    if (process.env.AUTH == "MOCKED" || (process.env.AUTH == "MIXED" && localStorage.getItem("session_eidp_access_token"))) {
      token = localStorage.getItem("session_eidp_access_token") || "";
    } else {
      const userContext = getUserContext();
      token  = await userContext.getAccessToken();
    }
    return token.trim();
  }
}

