import React from "react";
import Forum from "~icons/ic/twotone-forum";
import { FeaturedIcon } from "@emisgroup/ui-icon";
import Edit from "~icons/ic/edit";
import { Button } from "@emisgroup/ui-button";
import commonStyle from "../styles/Common.module.scss";
import { Stack } from "@emisgroup/ui-layouts";
import EmptyStatePanel from "./EmptyStatePanel";
import { useNavigate } from "react-router-dom";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { User } from "../utils/user";

const NoConversation: React.FC<{}> = () => {
    const appContext: IAppContextInterface = React.useContext(AppContext);
    const nav = useNavigate();
    let user = new User(appContext.userToken);
    return (
        <div className={commonStyle.centerContainer}>
          <Stack className={commonStyle.stackTemplate}>
            <FeaturedIcon>
              <Forum color="var(--primary-dark)" size="x-large" title="Conversation" />
            </FeaturedIcon>
            <EmptyStatePanel header="No messages" content="New messages will appear here." subContent="Compose a new message to send to a colleague." />
            <div>
              <Button  data-testid={"navButton"} variant="filled" onClick={() => {
                nav("/staff/contact-list", {
                  state: {
                    organisationId: user.organisationId,
                    userName: user.userName
                  }
                })
              }
              }>
                <Edit title="Edit" />
                Compose
              </Button>
            </div>
          </Stack>
        </div>
      );
};

export default React.memo(NoConversation);
