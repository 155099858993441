import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../utils/ApplicationContext";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { ApolloError, useQuery } from "@apollo/client";
import { GET_LOCAL_CONVERSATION_AND_MESSAGE } from "../graphql/queries";
import { messageDetails } from "./ListItemComponent";

export interface IApproachPage { }

const ApproachPageDetails: React.FC<IApproachPage> = (props: IApproachPage) => {
  let { conversationId } = useParams();
  const [isError, setIsError] = useState(false);
  const startedQueryRequest = useRef(false);

  // Set the conversation id passed in into the context and redirect (clears url ready for next notification click etc...)
  const appContext: IAppContextInterface = React.useContext(AppContext);
  const nav = useNavigate();

  const handleError = (err: ApolloError) => {
    console.error(`Error from approach page(getConversationByIds) : ${JSON.stringify(err.message)}`);
    Logger.error(err.message);
    setIsError(true);
  };

  const { data: allMessages } = useQuery(GET_LOCAL_CONVERSATION_AND_MESSAGE, {
    variables: {
      Id: "all"
    },
    onError: handleError,
    fetchPolicy: "cache-only"
  });


  useEffect(() => {
    if (conversationId && allMessages?.getLocalConversationAndMessage?.length > 0) {
      const oldConversation = allMessages?.getLocalConversationAndMessage.find((conversation: any) => conversation.conversationId === conversationId);
      setConversationDetails(oldConversation);

    } else {
      nav("/staff/landing-page/"); // Redirect to Landing Page as conversationId not set.
    }
  }, [allMessages]);


  function setConversationDetails(oldConversation: messageDetails) {
    appContext.approachConversationName = oldConversation.name;
    appContext.approachConversationId = conversationId;
    appContext.approachIsGroup = oldConversation.isGroup;
    appContext.approachRemovedOn = oldConversation.removedOn;
    appContext.approachIsRemoved = oldConversation.isRemoved;
    appContext.approachRemovedBy = oldConversation.removedBy;
    appContext.hasBeenNamed = oldConversation.hasBeenNamed;
    if (appContext.selectedConversationId) appContext.selectedConversationId.current = conversationId;
    nav("/staff/landing-page/");
  }

  if ((startedQueryRequest.current && (allMessages == undefined)) || isError) {
    // Failed to identify conversation. Nav anyway in non-selected state.
    nav("/staff/landing-page/");
  }
  return <div />;
};

export default ApproachPageDetails;
