import { User as UserClass } from "../../../utils/user";
import { V2User } from "../../../types/user";
import { generateClient } from 'aws-amplify/data';
import * as subscriptions from "../../../graphql/subscriptions";
import { CacheUtil } from "../utils/Cache";
import { IAppContextInterface } from "../../../models/IAppContextInterface";
import { AppContext } from "../../../utils/ApplicationContext";
import React from "react";
import { NotificationTypeV2 } from "../../../types/notification";


export class User {
    user: UserClass;
    cacheUtil: CacheUtil;
    constructor() {
        this.cacheUtil = new CacheUtil();
    }
    appContext: IAppContextInterface = React.useContext(AppContext);
    client = generateClient();
    subscribe(token: string): any {
        this.user = new UserClass(token);
        const filter = `${this.user.organisationId}`;
        const notificationReq = { Filter: filter };
        return this.client.graphql({
            query: subscriptions.subscribeToNotificationV5,
            variables: notificationReq,
            authToken: token.trim()
        }).subscribe({
            next: (value) => {
                if (!value?.data.subscribeToNotificationV5) return;
                //Handle system message subscription. 
                if (value.data["subscribeToNotificationV5"].Type == NotificationTypeV2.USER_STATUS_UPDATE) {
                    const userDetails: V2User = JSON.parse(value.data["subscribeToNotificationV5"].Data) as V2User;

                    //Update or add user on the cache
                    const userData = this.cacheUtil.readUser(this.user.organisationId);
                    if (userData.some(x => x.UserERN == userDetails.UserERN)) {
                        let updateData: V2User[] = [];
                        for (const item of userData) {
                            //Update only user status
                            if (item.UserERN == userDetails.UserERN) {
                                updateData.push({ ...item, UserStatus: userDetails.UserStatus });
                            } else {
                                updateData.push(item);
                            }
                        }
                        this.cacheUtil.writeUser(this.user.organisationId, updateData);
                    }
                }},
                error: (error) => {
                    this.appContext.setRefreshSubscriptions({});
                    console.error("User status subscription: " + error)
                }
            });
    }
}