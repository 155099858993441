import { FC, useCallback, useEffect, useRef, useState } from "react";
import { IListGroupProps, IListItemProps } from "./Props";
import styles from "./ListGroup.module.scss";
import { ListGroupView } from "./ListGroup.view";
import { ListItem } from "../ListItem";

/**
 * The logic layer of the ListGroup component
 *
 * @param props The props passed into this component
 * @returns A complete list group
 */
export const ListGroup: FC<IListGroupProps> = (props: IListGroupProps): JSX.Element => {
  const { className, "data-testid": dataTestId, id, items, title } = props;

  const thisListGroupHeaderTextRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [showTitle, setShowTitle] = useState(false);

  /**
   * Make ourselves a ref we can use to trigger the overflow arrows, as well
   * as a nice resize watcher so we can adjust to window size changes
   *
   * Tweaked from SO answer: https://stackoverflow.com/a/63927992
   *
   * @param onResize The resize function
   */
  const useResizeEffect = (onResize: () => void) => {
    useEffect(() => {
      onResize();
      window.addEventListener("resize", onResize);

      return () => window.removeEventListener("resize", onResize);
    }, [onResize]);
  };

  /**
   * A custom callback that will change the title status if the width of the header text
   * gets larger than the header can contain
   */
  const checkOverflowTrigger = useCallback(() => {
    if (title) {
      const listGroupHeaderTextScrollWidth = thisListGroupHeaderTextRef.current.scrollWidth;
      const listGroupHeaderTextClientWidth = thisListGroupHeaderTextRef.current.clientWidth
      if (listGroupHeaderTextScrollWidth > listGroupHeaderTextClientWidth) {
        setShowTitle(true);
      } else {
        setShowTitle(false);
      }
    }
  }, [thisListGroupHeaderTextRef]);

  useResizeEffect(checkOverflowTrigger);

  const getListItemsFromData = () => {
    return items.map(
      (item: IListItemProps, index: number): JSX.Element => (
        <li className={styles.listGroupListItem} key={"listItem_" + index.toString()}>
          <ListItem
            bottomBarContent={item.bottomBarContent}
            className={item.className}
            clickable={item.clickable}
            data-testid={dataTestId && `${dataTestId}-listitem-${index}`}
            disableItemSelection={item.disableItemSelection}
            hasDivider={item.hasDivider}
            id={item.id}
            leadingContent={item.leadingContent}
            onClick={item.onClick}
            onToggle={item.onToggle}
            selected={item.selected}
            stickyLeadingContent={item.stickyLeadingContent}
            trailingContent={item.trailingContent}
          >
            {item.children}
          </ListItem>
        </li>
      )
    );
  };

  return (
    <ListGroupView
      className={className}
      data-testid={dataTestId}
      headerTextRef={thisListGroupHeaderTextRef}
      id={id}
      listItems={getListItemsFromData()}
      showTitle={showTitle}
      title={title}
    />
  );
};

export default ListGroup;
