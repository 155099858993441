import { FC } from "react";
import classNames from "classnames";
import styles from "./ListItem.module.scss";
import { IListItemViewProps } from "./Props";

/**
 * Presentation layer of ListItem component
 *
 * @param props the props sent to the view
 * @returns a section of shortcuts
 */
export const ListItemView: FC<IListItemViewProps> = (props: IListItemViewProps): JSX.Element => {
  const {
    bottomBarContent,
    children,
    className,
    "data-testid": dataTestId,
    disableItemSelection,
    handleKeyEvent,
    hasDivider,
    id,
    itemSelected,
    leadingContent,
    reference,
    stickyOnLeft,
    toggleSelectedState,
    trailingContent,
    trailingContentSection,
  } = props;

  const listItemClasses: string = classNames(
    styles.euiListItem,
    disableItemSelection && styles.disabledSelection,
    hasDivider && styles.bordered,
    itemSelected && styles.selected,
    className
  );

  const leadingContentClasses: string = classNames(
    styles.leftContent,
    stickyOnLeft && styles.stickyLeft
  );

  const trailingContentContentClasses: string = classNames(
    trailingContent && trailingContent.alignTop && styles.trailingContentTop
  );

  const bottomRowClasses: string = classNames(styles.bottomContent);

  // If disableItemSelection is set to true, disable tab index
  const currentTabIndex: number = disableItemSelection ? -1 : 0;

  return (
    <div
      aria-label="List item"
      className={listItemClasses}
      data-testid={dataTestId}
      id={id}
      onClick={toggleSelectedState}
      onKeyDown={handleKeyEvent}
      ref={reference}
      role="button"
      tabIndex={currentTabIndex}
    >
      <div className={styles.container}>
        {leadingContent && <div className={leadingContentClasses}>{leadingContent}</div>}
        <div className={styles.centralContent}>{children}</div>
        {trailingContent && (
          <div
            className={trailingContentContentClasses}
            data-testid={`${dataTestId}-trailingContent`}
          >
            {trailingContentSection}
          </div>
        )}
      </div>
      {bottomBarContent && <div className={bottomRowClasses}>{bottomBarContent}</div>}
    </div>
  );
};

export default ListItemView;
