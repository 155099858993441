import React, { useCallback, useEffect, useRef, useState } from "react";
import { User } from "../utils/user";
import { useLocation, useNavigate } from "react-router-dom";
import { ISendMessageStateProps } from "./SendMessage";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { UserConversation } from "../types/userConversation";
import Topbar from "./LandingPageTitlebar";
import { Stack } from "@emisgroup/ui-layouts";
//style
import commonStyle from "../styles/Common.module.scss";

import { getLocalGroupConversation } from "../utils/MessageComponent";
import { useApolloClient } from "@apollo/client";
import RightPanelContent from "./RightPanelContent";
import { Alert } from "@emisgroup/ui-alert";
import { Button } from "@emisgroup/ui-button";
import DialogPanel from "./DialogPanel";
import EmptyStatePanel from "./EmptyStatePanel";
import { FeaturedIcon } from "@emisgroup/ui-icon";

import Refresh from "~icons/ic/refresh";
import SystemProblem from "~icons/ic/twotone-sync-problem";
import NoConversation from "./NoConversation";

export interface ILandingPage { }

export enum EScreenSize {
  small = "small",
  medium = "medium",
  large = "large",
  xlarge = "xlarge"
}

const LandingPage: React.FC<ILandingPage> = (props: ILandingPage) => {
  const [leaveError, setLeaveError] = useState(false);
  const [sendMessageState, setSendMessageState] = useState({} as ISendMessageStateProps);
  const memoizedSetSendMessageState = useCallback(
    (state) => {
      setSendMessageState(state);
    },
    [sendMessageState]
  );
  const appContext: IAppContextInterface = React.useContext(AppContext);

  console.clear();
  // create context for LeaveGroup error and warnings
  const [leaveGroupError, setLeaveGroupError] = useState<boolean>(false);
  const [leaveGroupWarning, setLeaveGroupWarning] = useState<boolean>(false);
  const [leaveGroup, setLeaveGroup] = useState<boolean>(false);

  appContext.setLeaveGroupHasError = setLeaveGroupError;
  appContext.setLeaveGroup = setLeaveGroup;
  appContext.setLeaveGroupShowWarning = setLeaveGroupWarning;
  appContext.leaveGroupHasError = leaveGroupError;
  appContext.leaveGroup = leaveGroup;
  appContext.leaveGroupShowWarning = leaveGroupWarning;

  const { state } = useLocation();
  const stateData = JSON.stringify(state);
  const messageInfo = stateData ? JSON.parse(stateData) : null;
  const [noMessages, setNoMessages] = useState(false);
  const [rerenderCount, setRerenderCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const client = useApolloClient();
  const refDisplayTitle = useRef("");
  const refRemovedBy = useRef("");
  const refIsGroup = useRef(false);
  const refHasBeenNamed = useRef(false);
  const refIsNoMessages = useRef(false);
  const refOldConversationId = useRef("");
  const [isRemoved, setIsRemoved] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  const nav = useNavigate();


  useEffect(() => {
    if (sendMessageState?.conversationId) {
      isConversationSelected.current = true;
      //reset conversation scroll position.
      refConversationId.current = sendMessageState.conversationId;

      setRerenderCount(rerenderCount + 1);
    }
  }, [sendMessageState]);

  let user = new User(appContext.userToken);
  let localConversation: UserConversation;
  localConversation = getLocalGroupConversation(client.cache);

  const leaveErrorTitle = "Something went wrong"
  const leaveErrorMessage = "We couldn't remove you from the group.";


  const checkSelectedConv = () => {
    if (appContext.approachConversationId || messageInfo || (localConversation?.ConversationId))
      return true;
    return false;
  }

  //to know all the conversation message has been loaded

  appContext.selectedConversationId = useRef(appContext.approachConversationId);
  let isConversationSelected = useRef(checkSelectedConv())
  let refConversationId = useRef(appContext.approachConversationId ? appContext.approachConversationId : undefined);



  function clearlocalCache() {
    const normalizedId = client.cache.identify(localConversation);
    return client.cache.evict({ id: normalizedId });
  }

  let selectedUserName: string = "";
  function setDisplayTitle() {
    if (sendMessageState?.title) {
      refHasBeenNamed.current = sendMessageState.hasBeenNamed;
      refDisplayTitle.current = sendMessageState.title;
      refIsGroup.current = sendMessageState.isGroup;
      appContext.approachRemovedBy = "";
      refRemovedBy.current = "";
      if (sendMessageState.removedBy) {
        refRemovedBy.current = sendMessageState.removedBy;
      }
    } else if (messageInfo?.title) {
      refDisplayTitle.current = messageInfo.title;
      selectedUserName = messageInfo.recipients[0];
    } else if (appContext.approachConversationId && appContext.approachConversationName) {
      refConversationId.current = appContext.approachConversationId;
      refDisplayTitle.current = appContext.approachConversationName;
      refRemovedBy.current = appContext.approachRemovedBy;
      clearlocalCache();
      // Resets approach conversation ready for next time
      appContext.approachConversationId = "";
      appContext.approachConversationName = "";
      appContext.approachRemovedBy = "";
      refHasBeenNamed.current = appContext.hasBeenNamed;
      refIsGroup.current = appContext.approachIsGroup;
      setIsRemoved(appContext.approachIsRemoved);
    } else if (localConversation?.ConversationName) {
      refDisplayTitle.current = localConversation.ConversationName;
      if (!isRemoved && localConversation.IsRemoved)
        setIsRemoved(localConversation.IsRemoved);
      refIsGroup.current = true;
      refHasBeenNamed.current = localConversation.HasBeenNamed;
    }
  }

  setDisplayTitle();

  function PreviewStyleChoice() {
    return isConversationSelected.current ? commonStyle.hideSM : "";
  }

  function PanelStyleChoice() {
    return !isConversationSelected.current ? commonStyle.hideSM : "";
  }

  function NoMessagesPreviewStyleChoice(styleFunction: Function) {
    return noMessages && !isConversationSelected.current ? commonStyle.noMessageHide : styleFunction();
  }

  //#region Error screen
  const errorContent = "Sorry, but we can't show your messages at the moment. Try again later.";



  if (isError)
    return (
      <div className={commonStyle.centerContainer}>
        <Stack className={commonStyle.stackTemplate}>
          <FeaturedIcon>
            <SystemProblem color="primary" size="x-large" title="SystemProblem" />
          </FeaturedIcon>
          <EmptyStatePanel header="Something went wrong"
            content={errorContent} />
          <div>
            <Button variant="filled" onClick={() => {
              nav("/staff/");
            }
            }>
              <Refresh title="Edit" />
              Try again
            </Button>
          </div>
        </Stack>
      </div>
    );

  function onTopbarBackButtonClick() {
    isConversationSelected.current = false;
    refDisplayTitle.current = "";
    setSendMessageState({} as ISendMessageStateProps);
    refConversationId.current = "";
    appContext.selectedConversationId.current = "";
    refIsNoMessages.current = noMessages;
  }

  // To render home page with no mmessages screen when there is no messages. 
  if (refIsNoMessages.current)
    return (
      <React.Fragment>
        <Topbar
          data-testid="topbar"
          isConversationSelected={isConversationSelected.current}
          isGroup={refIsGroup.current}
          conversationId={refConversationId.current}
          displayTitle={refDisplayTitle.current}
          onBackButtonClick={onTopbarBackButtonClick}
          isRemoved={isRemoved || sendMessageState.isRemoved}
          refOldConversationId={refOldConversationId}
          organisationId={user.organisationId}
          userName={user.userName}
          setLeaveError={setLeaveError}
          setDialogOpen={setDialogOpen}
        />
        <NoConversation />
      </React.Fragment>
    )

  // Error message button
  const buttons = (
    <Button onClick={() => setLeaveError(false)} variant="danger">
      Close
    </Button>
  );


  function CheckPathArgs() {
    const oldConversationId = refOldConversationId ? refOldConversationId.current : "";
    const pathArgs = refConversationId.current ? refConversationId.current : oldConversationId;
    return pathArgs;
  }

  return (
    <div className={commonStyle.app} >
      <Topbar
        data-testid="topbar"
        isConversationSelected={isConversationSelected.current}
        isGroup={refIsGroup.current}
        conversationId={refConversationId.current}
        displayTitle={refDisplayTitle.current}
        onBackButtonClick={onTopbarBackButtonClick}
        isRemoved={isRemoved || sendMessageState.isRemoved}
        refOldConversationId={refOldConversationId}
        organisationId={user.organisationId}
        userName={user.userName}
        setLeaveError={setLeaveError}
        setDialogOpen={setDialogOpen}
      />
      {leaveError && <Alert
        buttons={buttons}
        onOpenChange={() => setLeaveError(false)}
        open={leaveError}
        title={leaveErrorTitle}
        type="error"
      >
        {leaveErrorMessage}
      </Alert>
      }
      <DialogPanel
        setDialogOpen={setDialogOpen}
        dialogOpen={dialogOpen}
        PathArgs={CheckPathArgs()} />

      {noMessages && !refDisplayTitle.current ? <NoConversation /> : ""}
      <RightPanelContent
        previewStyleChoice={NoMessagesPreviewStyleChoice(PreviewStyleChoice)}
        panelStyleChoice={NoMessagesPreviewStyleChoice(PanelStyleChoice)}
        isConversationSelected={isConversationSelected.current}
        refDisplayTitle={refDisplayTitle.current}
        refConversationId={refConversationId.current}
        memoizedSetSendMessageState={memoizedSetSendMessageState}
        setNoMessages={setNoMessages}
        setIsError={setIsError}
        sendMessageState={sendMessageState}
        user={user}
        messageInfo={messageInfo}
        localConversation={localConversation && localConversation.Participants.length > 1 ? localConversation : null}
        selectedUserName={selectedUserName}
        refIsGroup={refIsGroup.current}
        setIsRemoved={setIsRemoved}
        isRemoved={isRemoved || sendMessageState.isRemoved}
        refOldConversationId={refOldConversationId}
        setLeaveError={setLeaveError}
        setDialogOpen={setDialogOpen}
        removedBy={refRemovedBy.current ? refRemovedBy.current : appContext.approachRemovedBy}
      />
    </div>
  );
};

export default LandingPage;
