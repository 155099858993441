import React, { useState } from "react";
import { ITopbar } from "./hoc/withTopbar";
import style from "../styles/SendMessage.module.scss";
import MessageSendingComponent from "./MessageSendingComponent";
import { IUser } from "../utils/user";

import { Form } from "@emisgroup/ui-form";
import MessageDisplayComponent from "./MessageDisplayComponent";
import { GET_CONVERSATION_INFO, GET_LOCAL_CONVERSATION_AND_MESSAGE } from "../graphql/queries";
import { ApolloError, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { UPDATE_MESSAGE_READ_STATUS_V2 } from "../graphql/mutations";
import commonStyle from "../styles/Common.module.scss";
import TitlePanel from "./TitlePanel";
import { IConversationByUser } from "../types/userConversation";
import { GET_USERCONVERSATION_FRAGMENT } from "./MultipleContact/schema";

export interface ISendMessageProps extends ISendMessageStateProps, ITopbar, IUser {
  refOldConversationId?: React.MutableRefObject<string>;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
  setLeaveError: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isConversationSelected: boolean;
  isGroupConv: boolean;
}

export interface ISendMessageStateProps {
  title: string;
  recipients: string[];
  message: string;
  time: string;
  conversationId: string;
  createdAtMessageId: string;
  authorId: string;
  avatarColor: string;
  isGroup: boolean;
  isRemoved: boolean;
  conversationName: string;
  hasBeenNamed?: boolean;
  removedOn: string;
  removedBy: string;
}
export const initialLoadCount = 100;

const SendMessage: React.FC<ISendMessageProps> = (props: ISendMessageProps) => {
  const [isSmallGroupCreating, setIsSmallGroupCreating] = useState(false);
  const apolloClient = useApolloClient();
  const [updateMessageReadStatus] = useMutation(UPDATE_MESSAGE_READ_STATUS_V2);
  const [isLoading, setIsLoading] = useState(false);
  const updateMessageCacheDetails = (prev) => {
    const receivedMessageData = prev["getMessage"]["Messages"].filter((item) => item.AuthorERN != props.userERN);
    const messageData = receivedMessageData.map((item) => ({ ...item, IsRead: "true" }));
    return messageData;
  };
  const handleError = (_err: ApolloError) => {
    console.error(`Error: ${JSON.stringify(_err.message)}`); 
    return false;
  };
  const { data: conversationData } = useQuery(GET_CONVERSATION_INFO, {
    variables: { ConversationIds: props.conversationId },
    fetchPolicy: "cache-first",
    onError: handleError
  });

  function sentMessageDetails(prev: any) {
    return prev["getMessage"]["Messages"].filter((item) => item.AuthorERN == props.userERN);
  }

  const handleReadMessageUpdate = (conversationId, authorERN, organisationERN, updateMessageCache: Function) => {
    if (conversationId != "") {
      let localMessages;
      const messages = apolloClient.cache.readQuery({
        query: GET_LOCAL_CONVERSATION_AND_MESSAGE,
        variables: {
          Id: "all"
        }
      });
      if (messages?.["getLocalConversationAndMessage"]) {
        let filterList = [...messages["getLocalConversationAndMessage"]];
        localMessages = filterList?.filter((x) => {
          if (x.conversationId === conversationId) {
            return { ...x };
          }
        });
      }
      // Only update read statuses if we have any unread statuses
      if (localMessages?.length > 0)
        updateMessageReadStatus({
          variables: {
            ConversationId: conversationId,
            AuthorERN: authorERN,
            AuthorOrgERN: organisationERN,
            ParticipantList: localMessages[0]?.participantList
          },
          update() {
            updateMessageCache((prev) => {
              const sentMessageData = sentMessageDetails(prev);
              const messageData = updateMessageCacheDetails(prev);
              if (prev?.["getMessage"]) {
                return {
                  getMessage: {
                    __typename: "MessagePages",
                    id: prev["getMessage"].id,
                    Messages: [...messageData, ...sentMessageData],
                    NextToken: prev["getMessage"].NextToken
                  }
                };
              }
            });
            //Note: update all possible getmesage query (with limit and without limit) so we dont't need subscription (subscriptions.ts->MessageReadStatus)
          }
        }).catch((err) => {
          console.error(err);
        });
      // }
    }
  };

  let titlePanelStyle = props.isRemoved ? commonStyle.rightPanelTopBarDeactivated : commonStyle.rightPanelTopBar;
  function getDisplayName(){
    const result : IConversationByUser = apolloClient.cache.readFragment({
      id: `ChatUserConversation:${props.conversationId}_${props.userERN}`,
      fragment: GET_USERCONVERSATION_FRAGMENT
    }); 
    let isRemoved = !!result?.RemovedFromConversationOn;
    if(props.isGroupConv && !isRemoved)
    {
      if ((conversationData?.["getConversationByIds"][0].ConversationName) != null)
        return (conversationData?.["getConversationByIds"][0].ConversationName) as string;
      else
        return props.conversationName;
    }
    else{
      return props.conversationName;
    }
  }
  return (
    <>
      {props.isConversationSelected ? (
        <div className={titlePanelStyle}>
          <TitlePanel
            displayTitle= {getDisplayName()} 
            isGroupChat={props.isGroupConv}
            refOldConversationId={props.refOldConversationId}
            conversationId={props.conversationId}
            organisationId={props.organisationId}
            setDialogOpen={props.setDialogOpen}
            setIsRemoved={props.setIsRemoved}
            setLeaveError={props.setLeaveError}
            readMessageCallBack={handleReadMessageUpdate}
          />
        </div>
      ) : null}
      <div className={commonStyle.rightPanel}>
        <div className={style.sendMessage} key={props.conversationId}>
          <Form>
            <MessageDisplayComponent
              conversationId={props.conversationId ? props.conversationId : ""}
              authorId={props.authorId ? props.authorId : ""}
              isGroup={props.isGroupConv}
              isRemoved={props.isRemoved}
              refOldConversationId={props.refOldConversationId}
              removedOn={props.removedOn}
              isSmallGroupCreating={isSmallGroupCreating}
              readMessageCallBack={handleReadMessageUpdate}
              setLoading={setIsLoading}
              {...props}
            />
            <MessageSendingComponent
              conversationId={props.conversationId ? props.conversationId : ""}
              authorId={props.authorId ? props.authorId : ""}
              isGroupConv={props.isGroupConv}
              isRemoved={props.isRemoved}
              hasBeenNamed={props.hasBeenNamed}
              setIsSmallGroupCreating={setIsSmallGroupCreating}
              readMessageCallBack={handleReadMessageUpdate}
              isLoading={isLoading}
              {...props}
            />
          </Form>
        </div>
      </div>
    </>
  );
};

export default React.memo(SendMessage);
