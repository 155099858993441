import { Form, FormSection, FormElement, FormButtons } from "@emisgroup/ui-form";
import { Input } from "@emisgroup/ui-input";
import { Button } from "@emisgroup/ui-button";
import contactStyle from "../styles/Contacts.module.scss";
import { withTopbar } from "./hoc/withTopbar";

const GroupMessage = () => {
  return (
    <>
      <div className={contactStyle.memberSection}>
      {/* Render member list here... */}
      </div>
      <div className={contactStyle.memberFooter}>
        <Form>
          <FormSection>
            <FormElement>
              <h4 className={contactStyle.memberText} data-testid="membercount">Select at least 2 members.</h4>
            </FormElement>
          </FormSection>
          <FormSection horizontal={true} >
            <FormElement labelText="Name (optional)" fieldId="groupname">
              <Input name="groupname" data-testid="groupname" />
            </FormElement>
            <FormButtons>
              <Button type="submit" variant="filled" data-testid="creategroup">
                Create group
              </Button>
            </FormButtons>
          </FormSection>
        </Form>
      </div>
    </>
  );
};

export default withTopbar(GroupMessage, false, "Select group members");
