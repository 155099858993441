// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UvGZyfxnsWf3IbJfOERv{font-size:var(--font-size);font-weight:var(--font-weight);color:var(--text-light);width:calc(100% - 0px);text-overflow:ellipsis;overflow:hidden;white-space:nowrap;line-height:var(--line-height);padding:var(--spacing)}`, "",{"version":3,"sources":["webpack://./src/styles/NoContacts.module.scss"],"names":[],"mappings":"AACA,sBACE,0BAAA,CACA,8BAAA,CACA,uBAAA,CACA,sBAAA,CACA,sBAAA,CACA,eAAA,CACA,kBAAA,CACA,8BAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noContactFormat": `UvGZyfxnsWf3IbJfOERv`
};
export default ___CSS_LOADER_EXPORT___;
