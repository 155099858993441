import { Participant } from "../types/messages";
import * as hash from 'object-hash';

//Function to return complex object as participants.
//Required Input Types:  Participant[], string[]
//Returns : Participant[]

//Note: WHEN NEW API SCHEMA AVAILABLE
// CHANGES: USE OrgnisationERN, UserERN in ParticipantList.


export function getParticipants(participants: Participant[], participantList: string[]): Participant[] {
    let groupParticipants: Participant[] = [];

    if (participantList && participantList.length > 0) {
        participantList.forEach(item => {
            let [orgId, ...userName] = item.split('_');
            groupParticipants.push({ OrganisationId: orgId, UserName: userName.join('_') });
        })
    }
    else {
        groupParticipants = participants;
    }

    return groupParticipants;
}


//Note: WHEN NEW API SCHEMA AVAILABLE 
// CHANGES: USE OrgnisationERN, UserERN in ParticipantList.
export function getParticipantList(participants: Participant[]): string[] {
    return participants.map(x => `${x.OrganisationId}_${x.UserName}`);
}

/* 
 To return hash code.
 Input parameters: string[]
 Return: string 
 */
export function getHashCode(participantList: string[]): string {
    const sortedList =  participantList.slice().sort((a, b) => (a > b) ? 1 : -1);
    return hash.sha1(sortedList);
}

