// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lWY4c99aCupuhemvLqvU{display:flex;flex-direction:column;text-align:center}.uwhxoDtpMbRzNuPpgfPJ{margin:0;padding-bottom:var(--spacing);font-family:var(--font-family);text-align:center}.XRViLumEH1jl9Z5ANPPK{font-size:var(--font-size);font-family:var(--font-family)}.TfKPxb5ImFujpmuGVVrs{font-size:var(--font-size);font-weight:var(--font-weight);margin-bottom:0;font-family:var(--font-family)}.MIE7ndchcPFbcOGlUIoG{display:inline-flex;padding-right:var(--spacing)}.SJz8IKAwUO9avmZS7glK{flex:1}`, "",{"version":3,"sources":["webpack://./src/styles/EmptyState.module.scss"],"names":[],"mappings":"AAGA,sBACI,YAAA,CACA,qBAAA,CACA,iBAAA,CAEF,sBACE,QAAA,CACA,6BAAA,CACA,8BAAA,CACA,iBAAA,CAGF,sBACE,0BAAA,CACA,8BAAA,CAGF,sBACE,0BAAA,CACA,8BAAA,CACA,eAAA,CACA,8BAAA,CAGF,sBACE,mBAAA,CACA,4BAAA,CAGF,sBACE,MAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyStateParent": `lWY4c99aCupuhemvLqvU`,
	"emptyStateHeader": `uwhxoDtpMbRzNuPpgfPJ`,
	"emptyStateChildHeader": `XRViLumEH1jl9Z5ANPPK`,
	"emptyStateChildContent": `TfKPxb5ImFujpmuGVVrs`,
	"icon": `MIE7ndchcPFbcOGlUIoG`,
	"stateFlex": `SJz8IKAwUO9avmZS7glK`
};
export default ___CSS_LOADER_EXPORT___;
