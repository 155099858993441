import { useLazyQuery, useQuery, useApolloClient, ApolloError } from "@apollo/client";
import * as React from "react";
import { ListItem } from "@emisgroup/ui-list";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import moduleStyle from "../styles/Common.module.scss";
import { useState, useRef, useEffect } from "react";
import Avatar from "../custom_components/UserAvatar/Avatar";
import { Checkbox } from "@emisgroup/ui-checkbox";
import { CONVERSATION_FRAGMENT_V2, GET_LOCAL_CONVERSATION_V2 } from "./MultipleContact/schema";
import { v4 as uuidv4 } from "uuid";
import EmptyStatePanel from "./EmptyStatePanel";
import { Stack } from "@emisgroup/ui-layouts";
import { Button } from "@emisgroup/ui-button";
import Refresh from "~icons/ic/refresh";
import { FeaturedIcon } from "@emisgroup/ui-icon";
import SystemProblem from "~icons/ic/twotone-sync-problem";
import { getUserDisplayName } from "../utils";
import { Input } from "@emisgroup/ui-input";
import Search from "~icons/ic/outline-search";
import { useDebounce } from "use-debounce";
import noContactStyle from "../styles/NoContacts.module.scss";
import { V2IContactsProps } from "../models/IContactsProps";
import style from "../styles/Contacts.module.scss";
import { GET_NEW_USERS } from "../graphql/queries";

let _value: any;

export function get_latestData(): any {
  return _value;
}
export function set_contactData(v: any) {
  _value = v;
}

export enum SelectStatus {
  SELECT_ALL,
  DESELECT_ALL,
  INDETERMINATE
}

const GroupContacts: React.FC<V2IContactsProps> = (props: V2IContactsProps) => {
  //#endregion Read and update cache for select group member
  //get user conversation from cache
  const [getConversation, { data: queryConversation }] = useLazyQuery(GET_LOCAL_CONVERSATION_V2, {
    variables: {
      ConversationId: "local-conversation"
    },
    onError: handleGroupContactError,
    fetchPolicy: "cache-only"
  });

  const { loading, data, previousData, refetch } = useQuery(GET_NEW_USERS, {
    variables: { OrganisationERN: checkOrgId() },
    onError: handleGroupContactError,
    onCompleted: () => setErrorState(false),
    fetchPolicy: "cache-first"
  });

  const participantMaxLimit = 80;
  const contactListRef = useRef<HTMLUListElement>(null);
  const [errorState, setErrorState] = useState(false);
  const [text, setText] = useState("");
  const [value] = useDebounce(text, 1000);
  const contactListScrollTopRef = useRef<number>(0);
  const contactLength = useRef(0);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const previousGroupContactRef = useRef([]);
  const divRef = useRef([]);
  const [participantList, setParticipantList] = React.useState<string[]>([]);
  //Apollo Client
  const apolloClient = useApolloClient();
  const onContactListScroll = () => {
    contactListScrollTopRef.current = contactListRef.current.scrollTop;
  };

  React.useLayoutEffect(
    () => {
      if (contactListScrollTopRef.current)
        contactListRef.current?.scrollTo(
          {
            top: contactListScrollTopRef.current
          }
        );
    }
  );

  const filterFunction = (userInput: string) => {
    return get_latestData()?.filter((x) => {
      if (!checked) {
        return x.UserERN != props.userERN && x.DisplayName.toLowerCase().includes(userInput);
      }
      if (userInput)
        return (
          x.UserStatus == "online" && x.UserERN != props.userERN && x.DisplayName.toLowerCase().includes(userInput)
        );
      return x.UserStatus == "online";
    });
  };

  useEffect(() => {
    if (contactListRef.current && previousGroupContactRef.current?.length == 0) {
      contactListRef.current.scrollTop = 0;
    }
    previousGroupContactRef.current = [];
  }, [filteredContacts]);

  function handleGroupContactError(err: ApolloError) {
    console.error(`Error: ${JSON.stringify(err.message)}`);
    setErrorState(true);
  }

  function checkOrgId() {
    return props.organisationId ?? "";
  }

  useEffect(() => {
    previousGroupContactRef.current = data?.getUser;
  }, [previousData]);

  useEffect(() => {
    setFilteredContacts(filterFunction(value.toLowerCase()));
  }, [data, value, checked]);

  React.useEffect(() => {
    if (queryConversation?.["getLocalConversation"]) {
      const participantList = queryConversation["getLocalConversation"].ParticipantList as string[];
      const participants = participantList;
      setParticipantList(participants);
    }
  }, [queryConversation]);

  React.useEffect(() => {
    if (props.conversationId) {
      getConversation().catch((err) => { console.error(err); });
    }
  }, [props.conversationId]);

  const checkCurrentUser = (conversationId: string, participant: string) => {
    if (participant && participant.split("_")[1] == props.userERN) return false;
    if (!conversationId && !queryConversation && queryConversation && !queryConversation["getLocalConversation"])
      return false;
  };

  // Add or remove participants from list
  function addRemoveMembers(remove: boolean, partcipantDetails: string[], participant: string) {
    let members = [];
    if (remove) {
      //remove given Participant
      members = partcipantDetails.filter((item: string) => {
        if (item.split("_")[1] != participant.split("_")[1]) {
          return item;
        }
      });
    } else {
      //add given Participant

      members = [
        ...partcipantDetails,
        participant
      ];
    }
    return members;
  }

  function clearSearch() {
    setText("");
    inputRef.current.focus();
  }

  //Update covnersation cache.
  function updateParticipants(conversationId: string, selectAll: SelectStatus, participant: string) {
    checkCurrentUser(conversationId, participant);

    //Add or remove user
    if (selectAll == SelectStatus.INDETERMINATE) {
      const data = apolloClient.cache.readFragment({
        id: `GroupConversation:${props.conversationId}_${props.userERN}`,
        fragment: CONVERSATION_FRAGMENT_V2
      });

      const participants = data?.["ParticipantList"];
      let remove: boolean = participants?.some((item) => item.split("_")[1] == participant.split("_")[1]);

      let members = [];
      let participantList = [];
      if (participants) {
        members = addRemoveMembers(remove, participants, participant);
        participantList = members;
      }

      //Update with
      apolloClient.cache.updateFragment(
        {
          id: `GroupConversation:${props.conversationId}_${props.userERN}`,
          fragment: CONVERSATION_FRAGMENT_V2
        },
        (data) => {
          let result = { ...data, ParticipantList: participantList };
          return result;
        }
      );
      if (value) clearSearch();
    }
  }
  if (data) {
    const userData = data?.getUser;
    set_contactData(userData?.filter((x) => x.DisplayName));
  }

  if (loading) {
    return (
      <div className={style.spinner}>
        <ProgressSpinner text="Loading contacts..." />
      </div>
    );
  }

  const handleListItem = (e, data: any) => {
    e.preventDefault();
    if (data) {
      let participant = `${data.OrganisationERN}_${data.UserERN}`;
      updateParticipants(props.conversationId, SelectStatus.INDETERMINATE, participant);
    }
    if (e.defaultPrevented) return;
  };

  const memberSelected = (userErn: string) => {
    if (participantList != null) {
      return participantList.some((item) => item.split("_")[1] == userErn);
    }
  };

  function getDisplayName(userDisplayName: string) {
    return userDisplayName && userDisplayName.split(",").length > 1 ? userDisplayName.split(",") : "";
  }

  function checkStatus(status: string) {
    return status ? status === "online" : false;
  }

  const createContactList = (contactDetails) => {
    contactLength.current = contactDetails.length;
    return contactDetails.map((value, index) => {
      const displayName = getUserDisplayName(value);
      const userFirstName = getDisplayName(displayName)[1]?.trim();
      const userLastName = getDisplayName(displayName)[0]?.trim();
      const userStatus = checkStatus(value.UserStatus);
      const userKey = value.UserERN;
      //Note: orgId may required.
      return props.userERN == userKey ? null : (
        <div
          key={uuidv4()}
          ref={(element) => {
            divRef.current[index] = element;
          }}
          id={userKey}
          data-testid={userKey}
          className={memberSelected(userKey) ? style.backgroundColorSelected : style.backgroundColorWhite}
          onClickCapture={(e) => {
            handleListItem(e, value);
          }}
        >
          <ListItem
            id={userKey}
            data-testid="listItem"
            className={style.listItem}
            action={{
              disableToggle: true
            }}
            key={userKey}
          >
            <span className={style.contactSpan}>
              {props.isGroupMessage ? (
                <div className={style.contactCheckbox}>
                  <Checkbox
                    id={index}
                    data-testid={`contact-checkbox-${index}`}
                    className={style.checkboxPadding}
                    checked={memberSelected(value.UserERN)}
                  />
                </div>
              ) : null}
              <div className={style.avatarColumn}>
                <Avatar
                  firstName={userFirstName}
                  fullName={getUserDisplayName(value)}
                  lastName={userLastName}
                  isAvailable={userStatus}
                  color={value.UserERN}
                  isGroup={false}
                  size={"medium"}
                />
              </div>
              <div>
                <div className={style.contactDisplayName}>{getUserDisplayName(value)}</div>
              </div>
            </span>
          </ListItem>
        </div>
      );
    });
  };

  const sortContacts = (data: any) => {
    if (data) {
      return data.slice().sort((a, b) => getUserDisplayName(a)?.localeCompare(getUserDisplayName(b)));
    }
  };

  const contactDetails = (contactDetails) => {
    if (errorState === false) {
      if (contactDetails != null && contactDetails.length > 0) {
        const contacts = sortContacts(contactDetails);
        return createContactList(contacts);
      } else if (contactDetails != null && contactDetails.length == 0) {
        return (
          <div key={uuidv4()} className={style.container}>
            <EmptyStatePanel header="No contacts" content="You do not have any contacts to send messages yet." />
          </div>
        );
      }
    }
  };

  const checkListStyle = () => {
    return searchMessageBackgroundStyle();
  };

  const searchMessageBackgroundStyle = () => {
    if (props.isNewMemberAdded) {
      if (participantList.length > participantMaxLimit) {
        return style.groupMessageBackgroundWithHistoryError;
      }
      return style.groupMessageBackgroundWithHistory;
    } else if (participantList.length > participantMaxLimit) {
      return style.groupMessageBackgroundError;
    }
    return style.groupMessageBackground;
  };

  const filterOnlineUsers = (e) => {
    setChecked(e);
  };

  if (errorState) {
    return (
      <div className={moduleStyle.centerContainer}>
        <Stack className={moduleStyle.stackTemplate}>
          <FeaturedIcon>
            <SystemProblem color="primary" size="x-large" title="SystemProblem" />
          </FeaturedIcon>
          <EmptyStatePanel
            header="Something went wrong"
            content="Sorry, but we can't show your contacts at the moment. Try again later."
          />
          <div>
            <Button
              variant="filled"
              data-testid="retry-button"
              onClick={() => {
                refetch().catch((err) => {
                  console.error(err);
                });
              }}
            >
              <Refresh title="Edit" />
              Try again
            </Button>
          </div>
        </Stack>
      </div>
    );
  } else {
    return (
      <>
        <div className={style.background}>
          <div className={style.groupInputMargin}>
            <Input
              ref={inputRef}
              data-testid="text-input"
              clearable={true}
              aria-label="search-contacts"
              onClear={() => {
                clearSearch();
              }}
              onChange={(e) => {
                setText(e.target.value);
              }}
              value={text}
              defaultValue=""
              autoFocus
              placeholder="Search for a contact by name"
            >
              <Search title="" />
            </Input>
            <div className={style.onlineFormat}>
              <Checkbox className={style.checkBoxFormat} onChange={filterOnlineUsers} id="online-checkbox">
                <div>Online only</div>
              </Checkbox>
            </div>
          </div>
        </div>
        <div key={uuidv4()}>
          {filteredContacts && value && filteredContacts.length == 0 ? (
            <ul key={uuidv4()} className={checkListStyle()}>
              <div className={noContactStyle.noContactFormat}>No contacts found.</div>
            </ul>
          ) : (
            <ul
              key={uuidv4()}
              ref={contactListRef}
              data-testid={"scrollId"}
              onScroll={onContactListScroll}
              className={checkListStyle()}
            >
              {contactDetails(filteredContacts && filteredContacts.length > 0 ? filteredContacts : get_latestData())}
            </ul>
          )}
        </div>
      </>
    );
  }
};

export default GroupContacts;
