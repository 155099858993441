import style from "../styles/MessageDisplayComponent.module.scss";
import { FormElement, FormSection } from "@emisgroup/ui-form";
import commonStyle from "../styles/Common.module.scss";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { ApolloError, useLazyQuery, useQuery, useApolloClient } from "@apollo/client";
import React, { useState, useEffect, useRef } from "react";
import { getLocalTimeString, getMessageDate } from "../utils";
import CardComponent from "./CardComponent";
import ListGroup from "../custom_components/ListGroup";
import { MessageV2 } from "../types/messages";
import { IUser } from "../utils/user";
import { ITopbar } from "./hoc/withTopbar";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import { checkLabel, FormatSystemMessageV2, V2updateParticipants } from "../utils/MessageComponent";
import SystemMessage from "./SystemMessage";
import EmptyStatePanel from "./EmptyStatePanel";
import { Stack } from "@emisgroup/ui-layouts";
import Refresh from "~icons/ic/refresh";
import { Button } from "@emisgroup/ui-button";
import {
  GET_NEW_USERS,
  GET_MESSAGES_BY_CONVERSATIONID,
  GET_OLDCONVERSATION_V2,
  GET_CONVERSATION_INFO,
  GET_CONVERSATION_BY_USER_AND_CONVERSATIONID
} from "../graphql/queries";
import { FeaturedIcon } from "@emisgroup/ui-icon";
import SystemProblem from "~icons/ic/twotone-sync-problem";
import Forum from "~icons/ic/twotone-forum";
import ArrowDown from "~icons/ic/arrow-downward";
import { Badge } from "@emisgroup/ui-badge";
import { useInView } from "react-intersection-observer";
import { V2User } from "../types/user";
import { IConversationByUser } from "../types/userConversation";
import { GET_USERCONVERSATION_FRAGMENT } from "./MultipleContact/schema";
export interface IMessageDisplayComponentProps extends IUser, ITopbar {
  title: string;
  authorId: string;
  conversationId: string;
  recipients: string[];
  isGroupConv: boolean;
  refOldConversationId?: React.MutableRefObject<string>;
  isRemoved: boolean;
  isSmallGroupCreating: boolean;
  readMessageCallBack: Function;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IStateProps extends IUser, ITopbar {
  title: string;
  recipientId: string[];
}

export interface IParticipants {
  OrganisationERN: string;
  UserERN: string;
}

export const initialLoadCount = 100;
export const loadMoreCount = 100;

export const MessageDisplayComponent: React.FC<IMessageDisplayComponentProps> = (
  props: IMessageDisplayComponentProps
) => {
  const client = useApolloClient();

  //get the recipient details
  //get the recipient details
  const { data: userData } = useQuery(GET_NEW_USERS, {
    variables: { OrganisationERN: props.organisationId },
    fetchPolicy: "cache-first",
    onError: handleError
  });

  //get OldConversation for the user
  const [getOldConversation, { data: OldConversationData, loading: loadingOldConversation }] = useLazyQuery(
    GET_OLDCONVERSATION_V2,
    {
      fetchPolicy: "network-only",
      onError: handleError
    }
  );

  const [getConversationByIds, { loading: loadingConversation }] = useLazyQuery(GET_CONVERSATION_INFO, {
    fetchPolicy: "cache-first",
    onError: handleError
  });

  //get list of messages for existing conversation
  const [getMessage, { data: getMessages, updateQuery: updateMessageCache, loading: messagesLoading, fetchMore }] =
    useLazyQuery(GET_MESSAGES_BY_CONVERSATIONID, {
      onCompleted() {
        //hide load from top of the chat history to indicate loded more message.
        setMoreMessageLoading(false);
      },
      fetchPolicy: "cache-first",
      onError: handleError
    });
    
  const { ref, inView } = useInView();

  function handleError(err: ApolloError) {
    console.error(`Error: ${JSON.stringify(err.message)}`);
    setErrorState(err.message);
    setMoreMessageLoading(false);
    Logger.error(err.message);
  }

  const refHasNextScroll = useRef<boolean>(false);
  const refHasUnredMessage = useRef<boolean>(false);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const hasPositioned = useRef<boolean>(false);
  const [nextScrollPosition, setNextScrollPosition] = useState<number | null>(null);
  const [newMessageBanner, setNewMessageBanner] = useState(false);
  const [refDetails, setRefDetails] = useState("");
  const [lastRead, setLastRead] = useState("");
  const [scrollPosition, setScrollPosition] = useState(Number);
  const [isLoading, setIsLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const recipientDisplayName = useRef("");
  const [moreMessageLoading, setMoreMessageLoading] = useState(false);
  const [error, setError] = useState(false);
  const [nextToken, setNextToken] = useState("");
  const oldConversationRef = useRef<string>(props.conversationId);
  const lastMessageIsReceived = useRef<boolean>(true);
  const setPositionForConversation = useRef<boolean>(false);
  const refHasScrollatBottom = useRef<boolean>(false);
  const refHasScrollMovedAtBottom = useRef<boolean>(false);
  const isBadgeAdded = useRef<boolean>(false);
  const lastSentMessageIdRef = useRef<string>("");
  const userList = useRef([]);

  let refs;
  let recipients: string[];

  setRecipients();

  function setRecipients() {
    if (props.recipients) {
      recipients = props.recipients;
    }
  }

  const orgId: string = props.organisationId;
  const userERN = props.userERN;

  //Note: WHEN NEW API SCHEMA AVAILABLE
  // CHANGES: USE OrgnisationERN, UserERN in ParticipantList.
  let participants: string[] = V2updateParticipants(
    props.organisationId,
    userERN,
    recipients,
    props.authorId,
    props.isRemoved
  );

  const initialChatContent = props.isGroupConv
    ? "You're starting a new group conversation"
    : "You're starting a new conversation";

  // useEffect(() => {
  //   if (conversation != undefined && conversation["getUserConversationById"].length > 0) {
  //     const lastUpdatedOn = conversation["getUserConversationById"][0]?.LastUpdatedOn;
  //     // setLastUpdatedOn(lastUpdatedOn);
  //   }
  // }, [conversation]);

  useEffect(() => {
    if (userData != undefined && userData["getUser"].length > 0) {
      userList.current = userData["getUser"] as V2User[];
    }
  }, [userData]);

  const participantList = participants;

  let oldConversationId: string | undefined;

  useEffect(() => {
    setPositionForConversation.current = false;
  }, [oldConversationRef.current]);

  useEffect(() => {
    if (
      hasPositioned.current &&
      !refHasScrollMovedAtBottom.current &&
      !refHasScrollatBottom.current &&
      !inView &&
      refHasUnredMessage.current
    )
      setNewMessageBanner(true);
    else setNewMessageBanner(false);
  }, [inView, isBadgeAdded.current]);

  const fetchMoreMessages = async (conversationId: string) => {
    await fetchMore({
      variables: {
        ConversationId: conversationId,
        Limit: loadMoreCount,
        NextToken: nextToken
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (prev?.getMessage.Messages) {
          return {
            getMessage: {
              __typename: "MessagePages",
              id: fetchMoreResult.getMessage.ConversationId,
              Messages: [...prev.getMessage.Messages, ...fetchMoreResult.getMessage.Messages],
              NextToken: fetchMoreResult.getMessage.NextToken
            }
          };
        }
      }
    });
  };

  const queryVariables = {
    OrganisationERN: orgId.valueOf(),
    UserERN: userERN.valueOf(),
    ParticipantList: participantList,
    ConversationName: props.isGroupConv ? props.title : ""
  };

  useEffect(() => {
    if (oldConversationRef.current) { 
      getMessage({
        variables: {
          ConversationId: oldConversationRef.current,
          Limit: initialLoadCount
        }
      }).catch((err) => {
        console.error(err);
      });
    } else {
      getOldConversation({
        variables: queryVariables
      }).catch(() => {
        console.log(scrollPosition);
      });
    }
  }, []);

  useEffect(() => {
    if (refDetails?.[lastRead]?.current && !setPositionForConversation.current) {
      refDetails[lastRead].current.scrollIntoView({
        behavior: "auto",
        block: "center"
      });

      hasPositioned.current = true;
      // set scroll based on the last message is received or send
      // added LastMesageIsReceived and hasUnreadMessage=false
      if (!lastMessageIsReceived.current || (lastMessageIsReceived.current && !refHasUnredMessage.current)) {
        listInnerRef.current.scrollTo({
          top: listInnerRef.current.scrollHeight - listInnerRef.current.clientHeight,
          behavior: "auto"
        });
      }
      setScrollPosition(listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop);
      setPositionForConversation.current = true;
    }
  }); // No array so, triggers after all renders

  const groupList = (previewList: MessageV2[]) =>
    previewList.reduce((groups, d) => {
      const date = getMessageDate(d.MessageCreatedAt).toDateString();

      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(d);
      return groups;
    }, {});

  let listLabel = "";
  const groupArrays = (previewList: MessageV2[]) => {
    let filteredMessageList = groupList(previewList);
    return Object.keys(filteredMessageList).map((date) => {
      return {
        date,
        messages: filteredMessageList[date]
      };
    });
  };

  let keyId;
  let unreadId: string = "";

  function checkUnreadMessage(element: MessageV2) {
    //get OrgUser's data to calculate lastUpdatedOn
    const result : IConversationByUser = client.cache.readFragment({
      id: `ChatUserConversation:${element.ConversationId}_${userERN}`,
      fragment: GET_USERCONVERSATION_FRAGMENT
    });  
    const lastUpdatedOn = result?.LastUpdatedOn;
    if (
      !element.IsRead &&
      new Date(parseInt(element.MessageCreatedAt)) >= new Date(parseInt(lastUpdatedOn)) &&
      unreadId === "" &&
      userERN != element.AuthorERN &&
      !element.IsReplied
    ) {
      unreadId = element.MessageId;
    }
    return unreadId;
  }

  function checkReceivedMessage(element: MessageV2) {
    if (userERN === element.AuthorERN) {
      return false;
    }
    return true;
  }

  function setLoading(index, messageLength, listIndex, sortedListLength) {
    if (index == messageLength - 1 && listIndex == sortedListLength - 1) {
      setIsLoading(false);
    }
  }

  function calculateIsUnread(unreadId: string): boolean {
    return unreadId != "";
  }

  function generateNewArray(item) {
    return Array.from(new Set(item.messages.map((a) => a.MessageId))).map((id) => {
      return item.messages.find((a) => a.MessageId === id);
    });
  }

  function prepareMessagesToRender(messages: MessageV2[]) { 
    isBadgeAdded.current = false;

    //find last sent message from messages
    if (messages.length > 0) {
      const sentMessageList = messages.filter((item) => {
        if (!checkReceivedMessage(item) && !item.IsSystemMessage) {
          return true;
        }
      });
      lastSentMessageIdRef.current = sentMessageList[sentMessageList.length - 1]?.MessageId;
    }
    let sortedList = groupArrays(messages);
    let listGroup = [];
    const objRefs = messages.reduce((acc, value) => {
      acc[value.MessageId] = React.createRef();
      return acc;
    }, {});

    refs = objRefs;
    setRefDetails(refs);
    let isRecievedMessage: boolean;
    let items = [];
    let content = "";
    lastMessageIsReceived.current = checkReceivedMessage(
      sortedList[sortedList.length - 1].messages[sortedList[sortedList.length - 1].messages.length - 1]
    );
    let hasNewMessage = true;
    sortedList.forEach((item, listIndex) => {
      items = [];

      const newArray = generateNewArray(item);

      newArray.forEach((element, index) => {
        unreadId = checkUnreadMessage(element);

        ({ hasNewMessage } = createNewBadge(unreadId, hasNewMessage, items, element, refs));
        isRecievedMessage = checkReceivedMessage(element);
        if (element.IsSystemMessage) {
          content = createSystemMessageCard(element, content, userList.current, items);
        } else {
          items.push({
            children: createCardElement({
              element: element,
              refs: refs,
              isRecievedMessage: isRecievedMessage,
              calculateIsUnread: calculateIsUnread,
              unreadId: unreadId,
              messageDisplayProps: props
            })
          });
        }
        setLoading(index, item.messages.length, listIndex, sortedList);
        keyId = element.MessageId;
      });

      const date = item.date;

      listLabel = checkLabel(date);

      listGroup.push(
        <div key={listLabel}>
          <ListGroup items={items} title={listLabel} id={"card_content"} />
          {listIndex != sortedList.length - 1 ? <div className={style.headerspace} /> : null}
        </div>
      );
    });

    //next scroll position will be message from top of the scroll.
    setLastRead(unreadId);
    refHasUnredMessage.current = true;
    assignLastread(unreadId, refHasUnredMessage, setLastRead, keyId);
    setMessageList([...listGroup]);
  }

  let listItems = [];

  Array.prototype.push.apply(listItems, messageList);

  //set scroll position based on cache and unreadMessage
  useEffect(() => {
    // Filter conversation by Id and get scroll
    if (listInnerRef?.current) {
      //if nextScrollPosition is exist then load that position
      if (nextScrollPosition != undefined) {
        //Set new scrollpostion from the top = (newHight from  - oldScrollPostion) + current scroll position.
        const currentScrollHeight = listInnerRef.current.scrollHeight;
        const currentScrollTop = listInnerRef.current.scrollTop; // to set current scroll position
        setNextScrollPosition(currentScrollHeight);
        if (refHasNextScroll.current) {
          listInnerRef.current.scrollTo({
            top: currentScrollHeight - nextScrollPosition + currentScrollTop,
            behavior: "auto"
          });
        } else if (!refHasUnredMessage.current || refHasScrollatBottom.current) {
          listInnerRef.current.scrollTo({
            top: listInnerRef.current.scrollHeight - listInnerRef.current.clientHeight,
            behavior: "auto"
          });
        }
      } else if (
        !lastMessageIsReceived.current ||
        (lastMessageIsReceived.current && !refHasUnredMessage.current) ||
        refHasScrollatBottom.current
      ) {
        // set scroll based on the last message is received or send
        // added LastMesageIsReceived and hasUnreadMessage=false
        listInnerRef.current.scrollTo({
          top: listInnerRef.current.scrollHeight - listInnerRef.current.clientHeight,
          behavior: "auto"
        });
      }
    }
  }, [messageList]);

  useEffect(() => {
    const messages = client.cache.readQuery({
      query: GET_MESSAGES_BY_CONVERSATIONID,
      variables: {
        ConversationId: oldConversationRef.current,
        Limit: initialLoadCount
      }
    });
    if (messages?.["getMessage"]) {
      if (messages["getMessage"]["Messages"].length > 0) {
        let listOfMessagescpy: MessageV2[] = [...(messages["getMessage"]["Messages"] as MessageV2[])];
        listOfMessagescpy.sort(function (a, b) {
          let dateA = a.MessageCreatedAt;
          let dateB = b.MessageCreatedAt;
          if (dateA > dateB) {
            return 1;
          }
          return dateA < dateB ? -1 : 0;
        });
        prepareMessagesToRender(listOfMessagescpy);
      }
      setNextToken(getMessages?.["getMessage"]["NextToken"] ? getMessages["getMessage"]["NextToken"] : "");
    }
  }, [getMessages]);

  const groupWithName = props.isGroupConv && props.title;
  const conversationName = props.title ? props.title : "";
  function processOldConvData() {
    oldConversationId = OldConversationData["getOldConversation"][0].ConversationId;
    oldConversationRef.current = oldConversationId;
    recipientDisplayName.current = OldConversationData["getOldConversation"][0].ConversationName;

    if (
      (groupWithName && OldConversationData["getOldConversation"][0].ConversationName !== conversationName) ||
      props.isGroupConv !== OldConversationData["getOldConversation"][0].IsGroupConversation
    ) {
      recipientDisplayName.current = conversationName;
      oldConversationRef.current = "";
    }
    if (oldConversationRef.current != "") { 
      getConversationByIds({
        variables: {
          ConversationIds: [oldConversationRef.current]
        }
      }).catch((err) => {
        console.error(err);
      });

      getMessage({
        variables: {
          ConversationId: oldConversationRef.current,
          Limit: initialLoadCount
        }
      }).catch((err) => {
        console.error(err);
      }); 

      if (props.refOldConversationId) {
        props.refOldConversationId.current = OldConversationData["getOldConversation"][0].ConversationId;
      }
    }
  }

  useEffect(() => {
    if (OldConversationData?.["getOldConversation"] && OldConversationData["getOldConversation"].length > 0) {
      if (OldConversationData["getOldConversation"][0].ConversationId) {
        processOldConvData();
      } else {
        setIsLoading(false);
      }
    }
  }, [OldConversationData]);

  const setErrorState = (errorMessage: string) => {
    setError(true);
  };

  const formElement = props.isRemoved ? style.formelementDeactivated : style.formelement;

  if (error) {
    const errorContent = "Sorry, but we can't show your messages at the moment. Try again later.";

    return (
      <FormSection className={style.formsection}>
        <FormElement className={formElement}>
          <div className={style.errorCenterContainer}>
            <Stack className={commonStyle.stackTemplate}>
              <FeaturedIcon>
                <SystemProblem color="primary" size="x-large" title="SystemProblem" />
              </FeaturedIcon>
              <EmptyStatePanel header="Something went wrong" content={errorContent} />
              <div>
                <Button
                  variant="filled"
                  onClick={() => {
                    setError(false);
                    getConversationByIds({
                      variables: {
                        ConversationIds: [oldConversationRef.current]
                      }
                    }).catch((err) => {
                      console.error(err);
                    });

                    getMessage({
                      variables: {
                        ConversationId: oldConversationRef.current,
                        Limit: initialLoadCount
                      }
                    }).catch((err) => {
                      console.error(err);
                    });  
                  }}
                >
                  <Refresh title="Edit" />
                  Try again
                </Button>
              </div>
            </Stack>
          </div>
        </FormElement>
      </FormSection>
    );
  }
  const checkFormelementStyle = props.isRemoved ? style.formsectionRemoved : style.formsection;
  if (checkLoadingDetails([isLoading, messagesLoading, loadingOldConversation, loadingConversation])) {
    props.setLoading(true);
    return (
      <FormSection className={checkFormelementStyle}>
        <FormElement className={formElement}>
          <div className={style.spinner}>
            <ProgressSpinner text="Loading messages..." />
          </div>
        </FormElement>
      </FormSection>
    );
  } else if (props.isSmallGroupCreating) {
    props.setLoading(true);
    const text = "Creating group...\n\nYour message will be sent in a moment.";
    return (
      <FormSection className={checkFormelementStyle}>
        <FormElement className={formElement}>
          <div className={style.conversationSpinner}>
            {/* <ProgressSpinner text="Group setup is in progress. It may take a few moments to send your message." /> */}
            <ProgressSpinner  text={"Creating group...\n\nYour message will be sent in a moment."} />
          </div>
        </FormElement>
      </FormSection>
    );
  } else {
    props.setLoading(false);
  }

  // fetch more message when reaching top of the scroll
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight } = listInnerRef.current;
      refHasScrollatBottom.current = false;
      setScrollPosition((prev) => {
        refHasScrollMovedAtBottom.current = false;
        if (prev && prev > listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop)
          refHasScrollMovedAtBottom.current = true;
        return listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop;
      });

      if (scrollTop === 0 && nextToken.length) {
        //show loading on top of the chat history to indicat loading more messages.
        setMoreMessageLoading(true);
        fetchMoreMessages(oldConversationRef.current).catch((err) => {
          console.error(err);
        });
        //setting the scroll height to get next scroll position
        setNextScrollPosition(scrollHeight);
        refHasNextScroll.current = true;
      } else {
        refHasNextScroll.current = false;
      }
      if (listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop === listInnerRef.current.clientHeight) {
        refHasScrollatBottom.current = true;
      }
      if (
        listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop === listInnerRef.current.clientHeight &&
        refHasScrollMovedAtBottom.current
      )
        props.readMessageCallBack(oldConversationRef.current, userERN, orgId, updateMessageCache);

      // this is temporary fix to not to freez scroll at top.
      SetScrollAtTop(scrollTop, nextToken, listInnerRef);
    }
  };

  const newFragment =
    oldConversationRef?.current !== "" ? (
      <div />
    ) : (
      <Stack className={commonStyle.stackTemplate}>
        <FeaturedIcon>
          <Forum color="var(--primary-dark)" size="x-large" title="Conversation" />
        </FeaturedIcon>
        <EmptyStatePanel header={initialChatContent} content={"Type your first message below."} />
        <div></div>
      </Stack>
    );

  const listGroup = props.isRemoved ? style.listgroupDeactivated : style.listgroup;

  const newMessageButtonClick = () => {
    if (refDetails?.[lastRead]?.current) {
      refDetails[lastRead].current.scrollIntoView({
        behavior: "instant",
        block: "center"
      });
      setNewMessageBanner(false);
    }
  };

  function createNewBadge(unreadId: string, hasNewMessage: boolean, items: any[], element: any, refs: any) {
    if (unreadId != "" && hasNewMessage && !isBadgeAdded.current) {
      isBadgeAdded.current = true;
      items.push({
        children: (
          <div className={style.newBadgeContainer} key={element.MessageId} ref={refs[element.MessageId]}>
            <div className={style.newMessageLine}></div>
            <div id="newBadge" ref={ref} className={style.badgeAlign}>
              <Badge children=" New " />
            </div>
          </div>
        )
      });
      hasNewMessage = false;
    }
    return { hasNewMessage };
  }

  function SetScrollAtTop(scrollTop: number, nextToken: string, listInnerRef: React.MutableRefObject<HTMLDivElement>) {
    if (scrollTop === 0 && (!nextToken || nextToken.length === 0)) {
      listInnerRef.current.scrollTo({
        top: 1,
        behavior: "auto"
      });
    }
  }

  function assignLastread(
    unreadId: string,
    refHasUnredMessage: React.MutableRefObject<boolean>,
    setLastRead: React.Dispatch<React.SetStateAction<string>>,
    keyId: any
  ) {
    if (unreadId === "") {
      refHasUnredMessage.current = false;
      setLastRead(keyId);
    }
  }

  interface ICardElementProps {
    element: any;
    refs: any;
    isRecievedMessage: boolean;
    calculateIsUnread: (unreadId: string) => boolean;
    unreadId: string;
    messageDisplayProps: IMessageDisplayComponentProps;
  }

  function createCardElement(props: ICardElementProps) {
    function cardComponentUtil() {
      return (
        <CardComponent
          text={props.element.Content}
          time={getLocalTimeString(props.element.MessageCreatedAt)}
          isReceived={props.isRecievedMessage}
          isUrgent={props.element.IsUrgent}
          isUnread={calculateIsUnread(unreadId)}
          isGroup={props.messageDisplayProps.isGroupConv}
          authorERN={props.element.AuthorERN}
          organisationId={props.messageDisplayProps.organisationId}
          isLastMessage={lastSentMessageIdRef.current == props.element.MessageId}
          isRead={props.element.IsRead}
        ></CardComponent>
      );
    }

    return !isBadgeAdded.current ? (
      <div key={props.element.MessageId} ref={refs[props.element.MessageId]}>
        {cardComponentUtil()}
      </div>
    ) : (
      <div>{cardComponentUtil()}</div>
    );
  }

  function createSystemMessageCard(element: any, content: string, userList: V2User[], items: any[]) {
    if (element.Content != "") {
       content = FormatSystemMessageV2(element.IsSystemMessage, element.Content, userList);
      items.push({
        children: <SystemMessage title={content} time={getLocalTimeString(element.MessageCreatedAt)} />
      });
    }
    return content;
  }

  return (
    <div className={style.containerDiv}>
      <div className={checkFormelementStyle} onScroll={onScroll} ref={listInnerRef} data-testid="message-scroll">
        <FormSection>
          {messageList.length > 0 ? (
            <FormElement className={formElement}>
              <div id="scrollId" data-testid="scrollId" className={listGroup}>
                {moreMessageLoading && (
                  <div className={style.loadMoreProgress}>
                    <ProgressSpinner small={true} text="Loading more messages..." />
                  </div>
                )}
                {messageList}
              </div>
            </FormElement>
          ) : (
            <FormElement className={style.newMessageFormElement}>
              <div className={style.newMessageContainer}>{newFragment}</div>
            </FormElement>
          )}
        </FormSection>
      </div>
      {newMessageBanner && (
        <div className={style.containerBanner}>
          <div className={style.newMessageBanner}>
            <Button data-testid="message-banner" size="small" variant="filled" onClick={newMessageButtonClick}>
              <ArrowDown title="" /> New messages
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(MessageDisplayComponent);

function checkLoadingDetails(isLoading: boolean[]) {
  return isLoading.some((x) => x);
}
