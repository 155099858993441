import { ApolloCache } from "@apollo/client/cache";
import { GET_LOCAL_CONVERSATION } from "../components/MultipleContact/schema";
import { GET_LOCAL_CONVERSATION_V2 } from "../V2components/MultipleContact/schema";
import { UserConversation, V2UserConversation } from "../types/userConversation";
import { User, V2User } from "../types/user";
import { Participant } from "../types/messages";
import { getUserDisplayName } from "../utils";

//#region V1 methods remove this once we migrated to V2
export interface IParticipants {
  OrganisationId: string;
  UserName: string;
}

export const updateParticipants = (
  organisationId: string,
  userName: string,
  recipients: string[],
  authorId: string,
  isRemoved?: boolean
) => {
  let Updatedparticipants: IParticipants[] = [];
  if (!isRemoved) {
    Updatedparticipants.push({
      OrganisationId: organisationId.valueOf(),
      UserName: userName.valueOf()
    });
  }
  if (recipients && recipients.length > 1) {
    recipients.forEach((item) => {
      if (userName.toLowerCase() !== item.toLowerCase()) {
        Updatedparticipants.push({
          OrganisationId: organisationId.valueOf(),
          UserName: item.valueOf()
        });
      }
    });
  }

  if (recipients && recipients.length == 1) {
    if (userName.toLowerCase() !== recipients[0].toLowerCase()) {
      Updatedparticipants.push({
        OrganisationId: organisationId.valueOf(),
        UserName: recipients[0].valueOf()
      });
    }

    if (
      authorId &&
      authorId.toLowerCase() !== recipients[0].toLowerCase() &&
      authorId.toLowerCase() !== userName.toLowerCase()
    ) {
      Updatedparticipants.push({
        OrganisationId: organisationId.valueOf(),
        UserName: authorId.valueOf()
      });
    }
  }

  return Updatedparticipants;
};

export function getLocalGroupConversation(cache: ApolloCache<object>): UserConversation {
  let localConversation: UserConversation;
  const localGroupConveration = cache.readQuery({
    query: GET_LOCAL_CONVERSATION,
    variables: {
      ConversationId: "local-conversation"
    }
  });

  if (localGroupConveration?.["getLocalConversation"]) {
    localConversation = localGroupConveration["getLocalConversation"] as UserConversation;
  }
  return localConversation;
}

export function updateCache(cache: ApolloCache<object>, isRemoved: boolean) {
  let newConv;
  let localConversation = getLocalGroupConversation(cache);
  if (localConversation) {
    newConv = { ...newConv, ...localConversation };
    newConv.IsRemoved = isRemoved;
    // Write to the cache if there is no conersation Id
    cache.writeQuery({
      query: GET_LOCAL_CONVERSATION,
      data: {
        getLocalConversation: newConv
      },
      variables: {
        ConversationId: "local-conversation"
      }
    });
  }
}

export const FormatSystemMessage = (isSystemMessage: boolean, content: string, users: User[]) => {
  if (isSystemMessage) {
    //content = <OrgId_UserName> removed
    let actualContent = content.substring(0, content.indexOf(" ")); // <OrgId_UserName>
    if (actualContent.indexOf("<") > -1) {
      let [orgId, ...userName] = actualContent.substring(1, actualContent.length - 1).split("_");

      if (orgId && userName) {
        let filteredUser = users.find((u) => u.UserName == userName.join("_"));
        if (filteredUser) {
          return content.replace(actualContent, getUserDisplayName(filteredUser));
        } else {
          return content.replace(actualContent, userName.join("_"));
        }
      }
    }
    return content;
  } else return content;
};

export function getConversationName(
  participantsList: string[],
  author: string,
  conversationName: string,
  users: any
): string {
  let groupName = conversationName || "";
  let groupNameLength = 7;
  if (groupName == "" && users && users.length > 0) {
    let groupList: Participant[] = [];
    const getParticipants = (participants: string[]): Participant[] => {
      return participants.map((item) => {
        const [organisationId, ...userName] = item.split("_");
        return <Participant>{
          OrganisationId: organisationId,
          UserName: userName.join("_")
        };
      });
    };
    // Need to use own name for own self to self conversation,
    // but filter it out for group conversations
    if (participantsList.length > 1) {
      const participants = getParticipants(participantsList);
      groupList = participants.filter((item) => item.UserName != author);
    } else {
      const participants = getParticipants(participantsList);
      groupList = participants;
    }

    let displayNameList: string[] = [];
    groupList.forEach((element) => {
      const user = users.find((u) => u.UserName == element.UserName);
      const displayName = getUserDisplayName(user);
      if (displayName != "") {
        let firstName = getUserNames(displayName)[1]?.trim();
        displayNameList.push(removeTitle(firstName) || displayName);
      }
    });
    displayNameList.sort((a, b) => (a > b ? 1 : -1));
    let groupMembers = displayNameList.slice(0, groupNameLength).join(", ");
    if (displayNameList.length - 1 >= groupNameLength)
      groupMembers = groupMembers + " +" + (displayNameList.length - groupNameLength);
    return groupMembers;
  }
  return groupName;
}
//#endregion

//#region V2 methods
// remove title from the firstName
export function removeTitle(userDisplayName: string): string {
  if (userDisplayName) {
    const start = userDisplayName.lastIndexOf("("); // find the index of the last opening bracket
    const end = userDisplayName.lastIndexOf(")") + 1; // find the index of the last closing bracket and add 1
    const result = userDisplayName.slice(start, end); // slice the string from start to end
    let formattedText = userDisplayName.replace(result, "");
    return formattedText.trim();
  }
  return userDisplayName;
}

export function getNonGroupConversation(participantsList: string[], currentUser: string, users: any): string {
  let conversationName = "";
  let recipientDetails = participantsList[0];
  if (participantsList.length > 1) {
    recipientDetails = participantsList.find((item) => item.split("_")[1] != currentUser);
  }
  conversationName = getUserDisplayName(users.find((u) => u.UserERN == recipientDetails.split("_")[1]));
  return conversationName;
}

export function getV2ConversationName(
  participantsList: string[],
  author: string,
  conversationName: string,
  users: any
): string {
  let groupName = conversationName || "";
  let groupNameLength = 7;
  if (groupName == "" && users && users.length > 0) {
    let groupList: string[] = [];

    // Need to use own name for own self to self conversation,
    // but filter it out for group conversations
    if (participantsList.length > 1) {
      groupList = participantsList.filter((item) => item.split("_")[1] != author);
    } else {
      const participants = participantsList;
      groupList = participants;
    }

    let displayNameList: string[] = [];
    groupList.forEach((element) => {
      const user = users.find((u) => u.UserERN == element.split("_")[1]);
      const displayName = getUserDisplayName(user);
      if (displayName != "") {
        let firstName = getUserNames(displayName)[1]?.trim();
        displayNameList.push(removeTitle(firstName) || displayName);
      }
    });
    displayNameList.sort((a, b) => (a > b ? 1 : -1));
    let groupMembers = displayNameList.slice(0, groupNameLength).join(", ");
    if (displayNameList.length - 1 >= groupNameLength)
      groupMembers = groupMembers + " +" + (displayNameList.length - groupNameLength);
    return groupMembers;
  }
  return groupName;
}

export const V2updateParticipants = (
  organisationERN: string,
  userERN: string,
  recipients: string[],
  authorId: string,
  isRemoved?: boolean
) => {
  let Updatedparticipants: string[] = [];
  if (!isRemoved) {
    Updatedparticipants.push(organisationERN.valueOf() + "_" + userERN.valueOf());
  }
  if (recipients && recipients.length > 1) {
    recipients.forEach((item) => {
      if (userERN !== item) {
        Updatedparticipants.push(organisationERN.valueOf() + "_" + item.valueOf());
      }
    });
  }

  if (recipients && recipients.length == 1) {
    if (userERN !== recipients[0]) {
      Updatedparticipants.push(organisationERN.valueOf() + "_" + recipients[0].valueOf());
    }

    if (authorId && authorId !== recipients[0] && authorId !== userERN) {
      Updatedparticipants.push(organisationERN.valueOf() + "_" + authorId.valueOf());
    }
  }
  return Updatedparticipants;
};

export const FormatSystemMessageV2 = (isSystemMessage: boolean, content: string, users: V2User[]) => {
  if (isSystemMessage && !content.includes("removed") && !content.includes("added") && !content.includes("left")) return content;
  let actualContent = content.substring(0, content.indexOf(" ")); // <OrgId_UserName>
  if (actualContent.indexOf("<") > -1) {
    let [orgId, ...userERN] = actualContent.substring(1, actualContent.length - 1).split("_");

    if (orgId && userERN) {
      let filteredUser = users.find((u) => u.UserERN == userERN.join("_"));
      if (filteredUser) {
        return content.replace(actualContent, getUserDisplayName(filteredUser));
      } else {
        return content.replace(actualContent, userERN.join("_"));
      }
    }
  }
  return content;
};

export function updateCacheV2(cache: ApolloCache<object>, isRemoved: boolean) {
  let newConv;
  let localConversation = getLocalConversationGroupV2(cache);
  if (localConversation) {
    newConv = { ...newConv, ...localConversation };
    newConv.IsRemoved = isRemoved;
    // Write to the cache if there is no conersation Id
    cache.writeQuery({
      query: GET_LOCAL_CONVERSATION_V2,
      data: {
        getLocalConversation: newConv
      },
      variables: {
        ConversationId: "local-conversation"
      }
    });
  }
}

export function checkLabel(date: string) {
  let listLabel = new Date(date)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric"
    })
    .split(" ")
    .join("-");
  if (date == new Date().toDateString()) {
    listLabel = "Today";
  }
  if (date == new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()) {
    listLabel = "Yesterday";
  }
  return listLabel;
}

export function getLocalConversationGroupV2(cache: ApolloCache<object>): V2UserConversation {
  let localConversation: V2UserConversation;
  const localGroupConveration = cache.readQuery({
    query: GET_LOCAL_CONVERSATION_V2,
    variables: {
      ConversationId: "local-conversation"
    }
  });

  if (localGroupConveration?.["getLocalConversation"]) {
    localConversation = localGroupConveration["getLocalConversation"] as V2UserConversation;
  }
  return localConversation;
}

export function getUserNames(displayName: string): string[] {
  if (displayName && displayName.split(",").length > 1) return displayName.split(",");
  else return [""];
}
//#endregion
