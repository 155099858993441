/**
 * ! THIS FILE IS MANAGED BY THE MENDELEEV TEAM.
 * ! IF UPDATES ARE REQUIRED PLEASE SPEAK TO THE TEAM FIRST (https://emishealth.slack.com/archives/C0105EZHBCL)
 * ! OR RAISE AN ISSUE IN THE CREATE-EMISX-APP REPO (https://github.com/emisgroup/create-emisx-app/issues)
 */

import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import { ILifeCycleProps } from "./Types";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err: Error, info: React.ErrorInfo, props: any) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  }
});

/**
 * Mount the application
 *
 * @param props Props passed from the host
 * @returns Promise to mount the application
 */
export function mount(props: ILifeCycleProps): Promise<unknown> {
  return lifecycles.mount(props);
}

/**
 * Unmount the application
 *
 * @param props Props passed from the host
 * @returns Promise to unmount the application
 */
export function unmount(props: ILifeCycleProps): Promise<unknown> {
  return lifecycles.unmount(props);
}
