import { ApolloError, useLazyQuery, useQuery } from "@apollo/client";
import * as React from "react";
import style from "../../styles/MultipleContact.module.scss";
import { GET_LOCAL_CONVERSATION } from "./schema";
import { UserConversation } from "../../types/userConversation";
import { User } from "../../types/user";
import Pills from "./Pills";
import { GET_USERS } from "../../graphql/queries";

interface IMemberSectionProps {
  conversationId: string;
  organisationId: string;
  userName: string;
  isNewMemberSelected: boolean;
}

const MemberSection: React.FunctionComponent<IMemberSectionProps> = (props) => {
  const participantMaxLimit = 80;
  const [conversation, setConversation] = React.useState<UserConversation>(null);
  const usersRef = React.useRef<User[]>();
  const participantCountRef = React.useRef<number>(0);

  const handleError = (err: ApolloError) => {
    console.error(`Error  : ${JSON.stringify(err.message)}`);
  }

  const { data: user } = useQuery(GET_USERS, {
    variables: { OrganisationId: props.organisationId },
    onError: handleError,
    fetchPolicy: "cache-first"
  });

  //get user conversation from cache 
  const [getConversation,
    { data: queryConversation }
  ] = useLazyQuery(GET_LOCAL_CONVERSATION, {
    variables: {
      ConversationId: "local-conversation"
    },
    onError: handleError,
    fetchPolicy: "cache-only"
  });

  React.useEffect(() => {
    if (props.conversationId && user && user['getUserV2'].length > 0) {
      getConversation().catch(err => { console.error(err) });
    }
  }, [props.conversationId, user]);

  // This will run whenever then cache is updated.
  React.useEffect(() => {
    if (queryConversation?.['getLocalConversation']) {
      usersRef.current = user['getUserV2'] as User[];
      let localConversation = queryConversation['getLocalConversation'] as UserConversation;
      setConversation(localConversation);
      participantCountRef.current = localConversation.ParticipantList.length;
    }
  }, [queryConversation]);

  const checkMemberSectionStyle = () => {
    if (props.isNewMemberSelected) {
      if (participantCountRef.current > participantMaxLimit) {
        return style.memberInfoSharedHistoryError;
      }
      return style.memberInfoSharedHistory;
    } else if (participantCountRef.current > participantMaxLimit) {
      return style.memberInfoColumnError
    }
    return style.memberInfoColumn;
  }
  return (
    <div className={style.contactInfoColumn}>
      <h4 className={style.memberName}>{"Members"}</h4>
      <div className={checkMemberSectionStyle()}>
        <Pills conversation={conversation} users={usersRef.current} conversationId={props.conversationId} userName={props.userName} />
      </div>
    </div>
  );
};

export default MemberSection;