/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const NewMessage = /* GraphQL */ `subscription OnCreateMessage($Filter: String!) {
  onCreateMessage(Filter: $Filter) {
    id
    ConversationId
    AuthorId
    AuthorOrg
    Content
    CreatedAt
    CreatedAt_MessageId
    IsDeleted_IsRead_IsUrgent
    IsEdited
    IsRead
    IsReplied
    IsUrgent
    IsDeleted
    IsSystemMessage
    IsReadBy {
      OrganisationId
      UserName
      __typename
    }
    DisplayName
    ParticipantList
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMessageSubscriptionVariables,
  APITypes.OnCreateMessageSubscription
>;

export const UserStatus = /* GraphQL */ `subscription SubscribeToUser($OrganisationId: String!) {
  subscribeToUser(OrganisationId: $OrganisationId) {
    OrganisationId
    UserName
    JobTypeId
    JobType
    OrganisationName
    DisplayName
    IsApplicationActive
    UserStatus
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToUserSubscriptionVariables,
  APITypes.SubscribeToUserSubscription
>;

export const NotificationV2Mutation = /* GraphQL */ `subscription SubscribeToNotificationV2($Filter: String!) {
  subscribeToNotificationV2(Filter: $Filter) {
    ParticipantList
    Type
    Data
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToNotificationV2SubscriptionVariables,
  APITypes.SubscribeToNotificationV2Subscription
>;

//#region  V2 subscriptions
export const onCreateNewMessage = /* GraphQL */ `subscription OnCreateNewMessage($Filter: String!) {
  onCreateNewMessage(Filter: $Filter) {
    ConversationId
    AuthorERN
    AuthorOrgERN
    Content
    DisplayName
    MessageId
    IsEdited
    IsRead
    IsReplied
    IsUrgent
    IsDeleted
    IsSystemMessage
    MessageCreatedAt
    Type_Id
    ParticipantList
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNewMessageSubscriptionVariables,
  APITypes.OnCreateNewMessageSubscription
>;

export const subscribeToNotificationV5 = /* GraphQL */ `subscription SubscribeToNotificationV5($Filter: String!) {
  subscribeToNotificationV5(Filter: $Filter) {
    FilterList
    Type
    Data
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToNotificationV5SubscriptionVariables,
  APITypes.SubscribeToNotificationV5Subscription
>;
//#endregion