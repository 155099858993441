import { Button } from "@emisgroup/ui-button";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ListItem } from "@emisgroup/ui-list";
import Avatar from "../custom_components/UserAvatar/Avatar";
import dialogStyle from "../styles/DialogPanel.module.scss";
import { useNavigate } from "react-router-dom";
import UserGroup from "~icons/ic/outline-groups";
import { Dialog } from "@emisgroup/ui-dialog";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { User as UserList } from "../types/user";
import { getUserDisplayName } from "../utils";

export interface IDialogPanelTopbar {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogOpen: boolean;
  PathArgs: string;
}

const DialogPanel: React.FC<IDialogPanelTopbar> = (props: IDialogPanelTopbar) => {
  const nav = useNavigate();
  const appContext: IAppContextInterface = React.useContext(AppContext);

  function getUserNames(displayName: string) {
    return displayName && displayName.split(",").length > 1 ? displayName.split(",") : "";
  }

  function groupnavPath() {
    let pathArgs = props.PathArgs;
    nav("/staff/group-contact-list/" + pathArgs);
  }

  let filteredUserList: UserList[] = [];
  const viewParticipants = () => {
    appContext.currentParticipants.forEach((participant) => {
      const userDetails = appContext.userList.find((u) => u.UserName == participant.UserName);
      if (userDetails)
        filteredUserList.push(userDetails);
    });
    filteredUserList.sort((a, b) => getUserDisplayName(a)?.localeCompare(getUserDisplayName(b)));

    return filteredUserList.map((userInfo, index) => {
      const displayName = getUserDisplayName(userInfo);
      const firstName = getUserNames(displayName)[1]?.trim();
      const lastName = getUserNames(displayName)[0]?.trim();
      const userStauts = userInfo.UserStatus == "online";
      return (
        <div key={uuidv4()} id={userInfo.UserName} data-testid={"listItem_" + index}>
          <ListItem
            id={userInfo.UserName}
            data-testid="listItem"
            className={dialogStyle.listItem}
            key={userInfo.UserName}
          >
            <div className={dialogStyle.listIn}>
              <div className={dialogStyle.avatarColumn}>
                <Avatar
                  firstName={firstName}
                  fullName={getUserDisplayName(userInfo)}
                  lastName={lastName}
                  isAvailable={userStauts}
                  color={userInfo.UserName.toLowerCase()}
                  isGroup={false}
                  size={"medium"}
                />
              </div>
              <div></div>
              <div>
                <div className={dialogStyle.contactDisplayName}>{getUserDisplayName(userInfo)}</div>
              </div>
            </div>
          </ListItem>
        </div>
      );
    });
  };

  return (
    <>
      {props.dialogOpen && (
        <Dialog open={true} onOpenChange={() => props.setDialogOpen(() => false)}>
          <Dialog.Inner>
            <Dialog.Header>
              <Dialog.Title>
                <div className={dialogStyle.dialogHeader} >{"Group members"}</div>
              </Dialog.Title>
            </Dialog.Header>
            <Dialog.Content>
              <Dialog.Title/>
              <div className={dialogStyle.listGroup}>
                <ul key={uuidv4()} className={dialogStyle.marginList}>
                  {viewParticipants()}
                </ul>
              </div>
            </Dialog.Content>
            <Dialog.Footer>
              <div className={dialogStyle.dialogFooter}>
                <Button borderless={false} onClick={() => groupnavPath()} role={"dialog-edit-group"}>
                  <UserGroup title="" color="primary" />
                  Edit group
                </Button>
              </div>
            </Dialog.Footer>
          </Dialog.Inner>
        </Dialog>
      )}
    </>
  );
};

export default DialogPanel;
