import { UserAvatar } from "@emisgroup/ui-avatar";
import style from "../../styles/UserStatus.module.scss";
import Offline from "~icons/emis/offline";  
import Online from "~icons/emis/online";

export interface IUserStatusProps {
    isAvailable?: boolean,
    color: string,
    firstName: string,
    fullName: string,
    lastName: string,
    isGroup: boolean,
    size: string
}
declare type AvatarSize = "x-small" | "small" | "medium";
const Avatar = (props: IUserStatusProps) => {
    let getStatusTag = () => {
        if(!props.isGroup)
        {
            return props.isAvailable ?
            <Online className={style.userStatus} title="Online" /> :
            <Offline className={style.userStatus} title="Offline" />
        }
        return null;
    }
    
    return (<div className={style.divStyle}>
        <UserAvatar
        color={props.color}
        firstName={props.firstName}
        fullName={props.fullName}
        lastName={props.lastName}
        size={props.size as AvatarSize}
        />
        {
            getStatusTag()
        }
        </div>
        )
    }
    
    export default Avatar;