import style from "../styles/MessageSendingComponent.module.scss";
import { FormElement, FormSection } from "@emisgroup/ui-form";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "@emisgroup/ui-button";
import { Checkbox } from "@emisgroup/ui-checkbox";
import { v4 as uuidv4 } from "uuid";
import { IUser } from "../utils/user";
import { ITopbar } from "./hoc/withTopbar";
import { Banner } from "@emisgroup/ui-banner";
import InfoNotification from "~icons/ic/baseline-info";
import ErrorCircleOutline from "~icons/ic/outline-error-outline";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import { RichTextEditor, useRichText } from "@emisgroup/ui-rich-text-editor";
import { updateParticipants } from "../utils/MessageComponent";
import { AppContext } from "../utils/ApplicationContext";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { CONVERSATION_STORE, GET_MESSAGES_BY_CONVERSATION, GET_USERS, GET_OLDCONVERSATION } from "../graphql/queries";
import { getHashCode, getParticipantList } from "../utils/GetParticipants";
import { state } from "../types/GroupState";
import "@emisgroup/ui-styles/dist/base.css";
import { getUserDisplayName } from "../utils";
import Send from "~icons/ic/outline-send";
import { datadogRum } from "@emisgroup/acp-utility-log";

const clinicalDisclaimer = "For general communication only. Do not use for clinical emergencies or patient care.";
const leftConversation = "You have left this conversation. You will not see any future messages.";
const removedFromConversationText = "You have been removed from this conversation. You will not see any future messages."
const createLargeGroupErrorMessage = "Couldn't send message";

export interface IMessageSendingComponentProps extends IUser, ITopbar {
  authorId: string;
  conversationId: string;
  recipients: string[];
  setIsSmallGroupCreating: React.Dispatch<React.SetStateAction<boolean>>;
  btnNotDisabled?: boolean;
  isGroupConv: boolean;
  hasBeenNamed: boolean;
  conversationName: string;
  isRemoved: boolean;
  removedBy: string;
  readMessageCallBack: Function;
  isLoading: boolean;
}

export interface IStateProps extends IUser, ITopbar {
  title: string;
  recipientId: string[];
}

export interface IParticipants {
  OrganisationId: string;
  UserName: string;
}

export const CREATE_CONVERSATION = gql`
  mutation CreateConversations(
    $ConversationId: ID
    $OrganisationId: ID!
    $Participants: [Participant!]!
    $ConversationName: String
    $UserName: String
    $IsGroupConversation: Boolean
    $IsConversationHistoryShared: Boolean
    $HasBeenNamed: Boolean
  ) {
    createConversationsV4(
      ConversationId: $ConversationId
      OrganisationId: $OrganisationId
      Participants: $Participants
      ConversationName: $ConversationName
      IsGroupConversation: $IsGroupConversation
      IsConversationHistoryShared: $IsConversationHistoryShared
      HasBeenNamed: $HasBeenNamed
      UserName: $UserName
    ) {
      ConversationId
      OrganisationId
      Participants {
        OrganisationId
        UserName
      }
      IsDeleted
      DeletedOn
      HasBeenNamed
      ConversationName
      LastUpdatedOn
      IsGroupConversation
      IsConversationHistoryShared
      IsRemoved
      RemovedOn
      RemovedBy
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $AuthorId: String!
    $AuthorOrg: ID!
    $Content: String!
    $ConversationId: ID!
    $IsUrgent: Boolean!
    $DisplayName: String!
  ) {
    createMessageV3(
      AuthorId: $AuthorId
      AuthorOrg: $AuthorOrg
      Content: $Content
      IsUrgent: $IsUrgent
      ConversationId: $ConversationId
      DisplayName: $DisplayName
    ) {
      id
      AuthorId
      AuthorOrg
      CreatedAt_MessageId
      Content
      ConversationId
      IsRead
      IsReplied
      IsUrgent
      CreatedAt
      IsDeleted
      IsEdited
      IsSystemMessage
      IsDeleted_IsRead_IsUrgent
      ParticipantList
    }
  }
`;

let recipientStatus: string;
export const initialLoadCount = 100;
export const loadMoreCount = 100;

export const MessageSendingComponent: React.FC<IMessageSendingComponentProps> = (
  props: IMessageSendingComponentProps
) => {
  const [sendMessage, { loading }] = useMutation(SEND_MESSAGE);
  const [createConversation, { loading: creationLoading }] = useMutation(CREATE_CONVERSATION);

  //get the recipient details..!HERE NEED TO CHANGE THE QUERY FOR GROUP CONVERSATION
  const { data: userData } = useQuery(GET_USERS, {
    variables: {
      OrganisationId: props.organisationId
    },
    fetchPolicy: "cache-first"
  });

  //Get user details from cache  
  const { data: conversationStoreDetails } = useQuery(CONVERSATION_STORE, {
    variables: { ConversationId: props.conversationId },
    fetchPolicy: "cache-only"
  });

  const oldConversationRef = useRef(props.conversationId);


  const [sentState, setSentState] = useState(false);
  const [disabled, setDisabled] = useState(!props.btnNotDisabled);
  const [showElement, setShowElement] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const recipientDisplayName = useRef("");
  const refMessage = useRef("");
  const [isReplied] = useState(false);

  const [isExceeded, setIsExceeded] = useState(false);
  const [charLimit, setCharLimit] = useState(0);
  const { clearEditor } = useRichText();
  const isFirstConv = useRef(false);
  const refFirstFocus = useRef(true);
  const [isEditable, setIsEditable] = useState(true);
  const [createLargeGroupFailedError, setCreateLargeGroupFailedError] = React.useState(false);

  const getHelperForOS = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("Mac")) {
      return (<span>
        <span className={style.cntlEnterFormat}>⌘+Enter</span>
        <span className={style.textFormat}> to send</span>
      </span>);
    } else if (userAgent.includes("Windows") || (userAgent.includes("Linux") && !userAgent.includes("Android"))) {
      return (<span>
        <span className={style.cntlEnterFormat}>Ctrl+Enter</span>
        <span className={style.textFormat}> to send</span>
      </span>);
    } else {
      return (<span></span>)
    }
  };

  const appContext: IAppContextInterface = React.useContext(AppContext);

  if (props.conversationId != "") {
    oldConversationRef.current = props.conversationId;
  }

  let recipientId;
  let recipients: string[];

  setRecipients();

  function setRecipients() {
    if (props.recipients) {
      recipients = props.recipients;
    }
  }

  recipientId = recipients && recipients.length > 0 ? recipients[0] : "";

  const orgId = props.organisationId ? props.organisationId : "";
  const userName = props.userName ? props.userName.toLowerCase() : "";
  const recipientName = recipientId ? recipientId.toLowerCase() : "";

  let participants: IParticipants[] = updateParticipants(props.organisationId, userName, recipients, props.authorId);



  const handleError = (err: ApolloError) => {
    console.error(`Error : ${JSON.stringify(err.message)}`);
    setSentState(false);
    setIsLoading(false);
    setShowElement(true);
    if (refMessage.current != "") {
      setDisabled(false);
    }
    Logger.error(err.message);
  };

  useEffect(() => {
    if (userData != undefined && userData["getUserV2"].length > 0) {
      setIsEnabled(false);
      recipientStatus = userData["getUserV2"][0].UserStatus;
      recipientDisplayName.current = getUserDisplayName(userData["getUserV2"][0]);
    }
  }, [userData]);

  let participantsDetails = sortParticipants(participants);
  const participantList = getParticipantList(participantsDetails);

  useEffect(() => {
    if (conversationStoreDetails?.['conversationDetails']) {
      switch (conversationStoreDetails?.['conversationDetails'].Action) {
        case state.INSERT: {
          setIsEditable(false);
          break;
        }
        case state.MODIFY: {
          setIsEditable(false);
          break;
        }
        case state.COMPLETED: {
          setIsEditable(true);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [conversationStoreDetails]);

  const queryVariables = {
    OrganisationId: orgId.valueOf(),
    UserName: userName.valueOf(),
    ParticipantHash: getHashCode(participantList),
    ConversationName: props.hasBeenNamed ? props.conversationName : "",
    IsGroupConversation: props.isGroupConv
  };

  //get OldConversation for the user
  const [getOldConversation, { data: OldConversationData, loading: oldConvLoading }] =
    useLazyQuery(
      GET_OLDCONVERSATION,
      {
        variables: queryVariables,
        fetchPolicy: "network-only",
        onError: handleError
      }
    );


  let oldConversationId: string | undefined;

  //get list of messages for existing conversation
  const [getMessage, { updateQuery: updateSendMessageCache }] = useLazyQuery(GET_MESSAGES_BY_CONVERSATION, {
    variables: {
      ConversationId: oldConversationRef.current,
      Limit: initialLoadCount
    },
    fetchPolicy: "cache-first"
  });


  useEffect(() => {
    if (oldConversationRef.current) {
      getMessage().catch(err => { console.error(err) });
    } else {
      getOldConversation().catch(err => { console.error(err) });
    }
    return () => {
      // Updates the message cache for the both with limit and without limit (for chatpanel)
      props.readMessageCallBack(oldConversationRef.current, userName.toLowerCase(), orgId, updateSendMessageCache);

    };
  }, []);

  useEffect(() => {
    if (OldConversationData?.["getOldConversationV3"]) {
      if (OldConversationData["getOldConversationV3"].ConversationId != "") {
        oldConversationId = OldConversationData["getOldConversationV3"].ConversationId;
        oldConversationRef.current = oldConversationId;
        recipientDisplayName.current = props.conversationName;
        if (
          (props.isGroupConv &&
            props.hasBeenNamed &&
            props.conversationName &&
            OldConversationData["getOldConversationV3"].ConversationName !== props.conversationName) ||
          props.isGroupConv !== OldConversationData["getOldConversationV3"].IsGroupConversation
        ) {
          oldConversationRef.current = "";
          recipientDisplayName.current = "";
        }
      } else {
        setIsLoading(false);
      }
    }
  }, [OldConversationData]);


  if (isLoading && oldConversationRef.current !== "") {
    return (
      <div className={style.spinner}>
        <ProgressSpinner text="Loading messages..." />
      </div>
    );
  }

  const handleCreateConv = (
    _ConversationId,
    _IsGroupConversation,
    _IsConversationHistoryShared,
    _ConversationName,
    _newMessage,
    _DisplayName
  ) => {

    appContext.creatingConversation = true;
    if (_IsGroupConversation) props.setIsSmallGroupCreating(true);

    createConversation({
      variables: {
        ConversationId: _ConversationId.valueOf(),
        OrganisationId: orgId.valueOf(),
        UserName: userName.valueOf(),
        ConversationName: _ConversationName,
        Participants: participants,
        IsGroupConversation: _IsGroupConversation,
        IsConversationHistoryShared: _IsConversationHistoryShared,
        HasBeenNamed: props.hasBeenNamed
      },
      onCompleted: (result) => {
        oldConversationRef.current = _ConversationId.valueOf();
        appContext.selectedConversationId.current = _ConversationId.valueOf();
        sendMessagetoUser(_newMessage, recipientName, isEnabled, _ConversationId, _DisplayName);
      },
      onError: (err) => {
        props.setIsSmallGroupCreating(false);
        handleError(err);
      },
    }).catch(err => { console.error(err) });
  };

  function sendMessagetoUser(newMessage: string, recipientName: any, isEnabled: boolean, conversationId: string, DisplayName: string) {
    sendMessage({
      variables: {
        AuthorId: userName,
        AuthorOrg: orgId,
        Content: newMessage,
        Recipient: recipientName,
        IsUrgent: isEnabled,
        ConversationId: conversationId,
        DisplayName: DisplayName
      },
      onError: (err) => {
        handleError(err);
        // To set error when large group conversation failed to create due to networkError or any graphQLErrors.
        if (participants.length > 100)
          setCreateLargeGroupFailedError(true);
      },
      onCompleted: (result) => {
        refMessage.current = "";
        setSentState(true);
        if (!isFirstConv.current)
          props.readMessageCallBack(conversationId, userName.toLowerCase(), orgId, updateSendMessageCache); 
        setDisabled(true);
        setIsEnabled(false);
        handleEditorClear();
      }
    }).catch(err => { console.error(err); });
  }


  const sendNewMessage = (newMessage: string) => {
    let conversationId = uuidv4();

    if (oldConversationRef.current == "") {
      isFirstConv.current = true;
    } else {
      isFirstConv.current = false;
      conversationId = oldConversationRef.current;
    }

    let DisplayName = getUserDisplayName(userData["getUserV2"].find(u => u.UserName == userName));
    const conversationName = props.hasBeenNamed ? props.conversationName : ""

    if (oldConversationRef.current == "") {
      handleCreateConv(conversationId, props.isGroupConv, true, conversationName, newMessage, DisplayName);
    }
    else {
      sendMessagetoUser(newMessage, recipientName, isEnabled, conversationId, DisplayName);
    }
    dataDogSendMessageCustomEvent(props.userERN, props.cdb, orgId, props.isGroupConv);
  };

  const dataDogSendMessageCustomEvent = (senderErn: string, cdb: string, OrgERN: string, IsGroupConversation: boolean) => {

    datadogRum.addAction('SendMessage', {
      senderERN: senderErn,
      cdb: cdb,
      organisationERN: OrgERN,
      isGroupConversation: IsGroupConversation
    });
  }


  const checkboxChangeAction = (e) => {
    props.readMessageCallBack(oldConversationRef.current, userName.toLowerCase(), orgId, updateSendMessageCache);
    setIsEnabled(e);
  };

  const handleEditorClear = () => {
    clearEditor("message");
  };

  const onSubmitAction = () => {
    if (!props.isLoading && !loading && !creationLoading && !disabled && !oldConvLoading && !isExceeded && checkEmptyTextContent(refMessage.current)) {
      sendNewMessage(refMessage.current);
      setShowElement(false);
      setDisabled(true);
    }
  };


  const isCountExceeded = (html: string) => {
    if (html.trim().length > 7000) {
      setCharLimit(html.trim().length - 7000);
      return setIsExceeded(true);
    }
    return setIsExceeded(false);
  };

  refFirstFocus.current = true;

  // Define a function that takes an HTML string as a parameter and returns a boolean value
  const checkEmptyTextContent = (html: string): boolean => {
    let tParser = document.createElement("div");
    tParser.innerHTML = html;
    return tParser.textContent.trim().length > 0
  };


  // remove if any styles applied in rich text editor
  const removeStylesFromString = (html: string): string => {

    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    // Remove the style attribute from the <span> tag
    const spanElements = tempElement.querySelectorAll('span, strong, em, u, a, div, p, blockquote, li, ul, ol');
    spanElements.forEach((span) => {
      span.removeAttribute('style');
    });

    // Get the cleaned HTML string
    const cleanedHtmlString = tempElement.innerHTML;
    return cleanedHtmlString;
  }

  const textchange = (html: string) => {

    if (isEditable) {
      // Temporary fix to make sure links don't try to open within the electron app.
      if (html.includes("<a href=")) {
        html = html.replace("<a href=", '<a target="_blank" href=');
      }
      html = removeStylesFromString(html);
      refMessage.current = html;
      isCountExceeded(html);
      if (checkEmptyTextContent(html)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }

      // temporary fix to check empty html content
      if (html === '')
        console.error("html is empty. ");

      if (refFirstFocus.current && !isFirstConv.current) {
        props.readMessageCallBack(oldConversationRef.current, userName.toLowerCase(), orgId, updateSendMessageCache);
      }
      refFirstFocus.current = false;
    }
  };

  //check which form element  need to dispaly
  const checkFormElement = () => {
    // check failed api call and display the form with red context
    if (((sentState === false || isReplied === false) && showElement && createLargeGroupFailedError === false) || isExceeded) {
      return (
        <>
          <FormElement className={style.errormessageElement} fieldId="message" mandatory={false}>
            <RichTextEditor
              invalid={true}
              height="100px"
              id="message"
              initialValue={refMessage.current}
              placeholderText="Type a new message"
              onTextUpdate={textchange}
              onCtrlEnterPress={onSubmitAction}
            />
          </FormElement>
          <p className={style.errorpara} role="alert">
            {" "}
            {isExceeded ? "Character limit exceeded by " + charLimit : "Failed to send. Try again."}
          </p>
        </>
      );
    } else {
      return (
        <FormElement className={style.messageElement} fieldId="message" mandatory={false}>
          {createLargeGroupFailedError &&
            <Banner color="error">
              <ErrorCircleOutline title="Error" />
              <div>
                <div>
                  <h6>
                    {createLargeGroupErrorMessage}
                  </h6>
                  <p className={style.errorBannerAlignment}>
                    Try sending your message again.
                  </p>
                </div>
              </div>
            </Banner>}
          <RichTextEditor
            id="message"
            height="100px"
            editable={isEditable}
            placeholderText="Type a new message"
            initialValue={refMessage.current}
            onTextUpdate={textchange}
            onCtrlEnterPress={onSubmitAction}
          />
        </FormElement >
      );
    }
  };

  const renderDisclaimerText = () => {
    if (props.isRemoved) {
      if (props.removedBy && props.removedBy != props.userName) {
        return <div>{removedFromConversationText}</div>
      }
      else {
        return <div>{leftConversation}</div>
      }
    } else {
      return <div>{clinicalDisclaimer}</div>
    }
  }

  return (
    <div className={style.messageContainer}>
      <div>
        <Banner>
          <div>
            <InfoNotification title=""></InfoNotification>
          </div>
          {renderDisclaimerText()}
        </Banner>
      </div>
      {!props.isRemoved ? (
        <>
          <FormSection>
            {checkFormElement()}
          </FormSection>
          <div className={style.urgentSendButton}>
            <Checkbox
              id="urgent"
              className={style.checkboxPadding}
              checked={isEnabled}
              disabled={!isEditable}
              onChange={checkboxChangeAction}
            >{"Urgent"}</Checkbox>
            <div className={style.sendButton}>
              {!disabled && getHelperForOS()}
              <Button disabled={disabled} type="submit" variant="filled" data-testid="send-button" onClick={onSubmitAction}>
                Send
                <Send title="" />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div />
      )}
    </div>
  );
};

export default React.memo(MessageSendingComponent);


function sortParticipants(participants: IParticipants[]) {
  return participants.sort((a, b) => (a.UserName > b.UserName ? 1 : -1));
}