import React, { useEffect, useRef, useState } from "react";
import { User } from "../utils/user";
import { ISendMessageStateProps } from "./SendMessage";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { ApolloError, useQuery, useApolloClient, useLazyQuery } from "@apollo/client";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import PreviewPanelMessagesLoading from "./PreviewPanelMessagesLoading";
import { findSelectedConversation } from "../utils/PreviewPanelConversationLoading";
import * as subscriptions from "../graphql/subscriptions";
import { generateClient } from "aws-amplify/api";
import AmplifyClient from "../utils/AmplifyClient";
import { ContextUtils } from "../utils/contextUtils";
import { authTokenWithBearer, HubListner } from "../utils";
import { updateCacheV2 } from "../utils/MessageComponent";
import { ConversationNotification, NotificationTypeV2 } from "../types/notification";
import {
  GET_MESSAGES_BY_CONVERSATIONID,
  GET_CONVERSATION_INFO,
  GET_CONVERSATION_BY_USER,
  GET_NEW_USERS,
  GET_CONVERSATION_BY_USER_AND_CONVERSATIONID
} from "../graphql/queries";
import { IConversationByUser, IMessageDetails, UserConversation } from "../types/userConversation";
//style
import commonStyle from "../styles/Common.module.scss";

const client = generateClient();

export interface IPreviewPanelConversationsLoadingProps {
  memoizedSetSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  setNoMessages: React.Dispatch<React.SetStateAction<boolean>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  refConversationId: string;
  selectedUserERN: string;
  localConversation: UserConversation;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
  isRemoved: boolean;
  refOldConversationId?: React.MutableRefObject<string>;
}

//#region Graphql schema

export const initialLoadCount = 100;

const PreviewPanelConversationsLoading: React.FC<IPreviewPanelConversationsLoadingProps> = (
  props: IPreviewPanelConversationsLoadingProps
) => {
  const newConversation = useRef(false);
  const loadedConversations = useRef<any[]>([]);
  const [loadedCount, setLoadedCount] = useState(0);
  const apolloClient = useApolloClient();
  const conversationSubRef = useRef(null);
  const priorConnectionRef = useRef<string>("");
  const tokenRef = useRef();
  const usersList = useRef([]);
  const currentConversationId = useRef("");
  const currentDisabledStatus = useRef(false);

  currentConversationId.current = props.refConversationId
    ? props.refConversationId
    : props.refOldConversationId.current;
  currentDisabledStatus.current = props.isRemoved;

  const handleError = (_err: ApolloError) => {
    console.error(`Error: ${JSON.stringify(_err.message)}`);
    props.setIsError(true);
    Logger.error("Error rendering reviewPanelConversationsLoading");
    Logger.error(_err.message);
    return false;
  };

  const appContext: IAppContextInterface = React.useContext(AppContext);
  let user = new User(appContext.userToken);

  // Get conversation information on load
  const { loading, data: queryConversation } = useQuery(GET_CONVERSATION_BY_USER, {
    variables: {
      UserERN: user.userERN,
      OrganisationERN: user.organisationId
    },
    onError: handleError,
    fetchPolicy: "cache-first"
  });

  const [getConversation] = useLazyQuery(GET_CONVERSATION_INFO, {
    fetchPolicy: "cache-first",
    onError: handleError
  });

  const [getUserConversationById] = useLazyQuery(GET_CONVERSATION_BY_USER_AND_CONVERSATIONID, {
    fetchPolicy: "network-only",
    onError: handleError
  });

  const userType = "getUser";

  //Get user details from cache
  const { data: users } = useQuery(GET_NEW_USERS, {
    variables: { OrganisationERN: user.organisationId },
    fetchPolicy: "cache-first"
  });
  //get list of messages for existing conversation
  const [getMessage] = useLazyQuery(GET_MESSAGES_BY_CONVERSATIONID, {
    fetchPolicy: "network-only",
    onError: handleError
  });

  useEffect(() => {
    if (users?.[userType] && users[userType].length > 0) {
      usersList.current = users;
    }
  }, [users]);

  // Process GET_USER_CONVERSATIONS response
  useEffect(() => {
    if (queryConversation?.["getConversationByUser"]) {
      if (queryConversation["getConversationByUser"].length === 0) {
        props.setNoMessages(true);
      } else {
        props.setNoMessages((prev) => {
          if (prev) return false;
          return prev;
        });
      }
      loadedConversations.current = JSON.parse(JSON.stringify(queryConversation["getConversationByUser"]));
      setLoadedCount((prev) => {
        return prev + 1;
      });
    }
  }, [queryConversation]);

  const readMessage = (conversationId: string) => {
    getMessage({
      variables: {
        ConversationId: conversationId,
        Limit: initialLoadCount
      }
    }).catch((err) => {
      console.error(err);
    });
  };
  const handleConversation = (conversation: IMessageDetails, userConv: IConversationByUser, prev: any) => {
    let isRemoved = false;
    if (userConv.RemovedFromConversationOn) isRemoved = true;
    if (conversation) {
      if (!loadedConversations.current.some((conv) => conv.ConversationId === conversation.ConversationId)) {
        loadedConversations.current = [...loadedConversations.current, conversation];
      }
      if (prev.some((conv) => conv.ConversationId === conversation.ConversationId && !isRemoved)) {
        //refresh messages if history is shared
        readMessage(conversation.ConversationId);

        if (currentConversationId.current === conversation.ConversationId) {
          updateCacheV2(apolloClient.cache, false);
          props.setIsRemoved(() => {
            return false;
          });
        }
        console.warn("WARNING:- Conversation already found inside the current list.");
        return prev;
      } else {
        newConversation.current = true;
        // This is needed to render the right panel when a new conversation is created
        // Only render right panel if message sent by author though (creatingConversation set in MessageSendingComponent).
        if (appContext.creatingConversation) {
          appContext.creatingConversation = false;
          props.memoizedSetSendMessageState((prev) => {
            return {
              title: prev.title,
              conversationId: conversation.ConversationId,
              recipients: prev.recipients,
              message: prev.message,
              time: prev.time,
              createdAtMessageId: prev.createdAtMessageId,
              authorId: prev.authorId,
              avatarColor: prev.avatarColor,
              isGroup: conversation.IsGroupConversation,
              conversationName: conversation.ConversationName,
              isRemoved: isRemoved,
              removedOn: userConv.RemovedFromConversationOn,
              removedBy: userConv.RemovedBy
            };
          });
        }

        if (prev["getConversationByUser"]) {
          return {
            getConversationByUser: [userConv, ...prev["getConversationByUser"]]
          };
        } else {
          return {
            getConversationByUser: [userConv]
          };
        }
      }
    }
  };

  function readUserConversationCache(apolloClient, variables: { UserERN: string; OrganisationERN: string }) {
    return apolloClient.cache.readQuery({
      query: GET_CONVERSATION_BY_USER,
      variables: variables
    });
  }

  async function subscribeToNewConverstion() {
    const filter = `${user.organisationId}_${user.userERN}`;
    const notificationReq = { Filter: filter };

    tokenRef.current = (await ContextUtils.getAccessTokenFromContext()) || "";
    conversationSubRef.current = client
      .graphql({
        query: subscriptions.subscribeToNotificationV5,
        variables: notificationReq,
        authToken: authTokenWithBearer(tokenRef.current)
      })
      .subscribe({
        next: (value) => {
          if (!value?.data.subscribeToNotificationV5) return;
          //Add next conversation into the cache.
          const notification = value.data.subscribeToNotificationV5;
          if (notification.Type == NotificationTypeV2.CREATE_CONVERSATION) {
            subscriptionHandler(notification);
          } else if (notification.Type == NotificationTypeV2.UPDATE_CONVERSATION) {
            subscriptionHandlerUpdateCovnersation(notification);
          }
        },
        error: (error) => {
          console.error("Conversation Subscription: " + error);
        }
      });
  }

  function subscriptionHandler(notification: any) {
    // if conversation update
    // get user conversation
    // set to conversationNotification

    const conversationNotification = JSON.parse(notification.Data) as IConversationByUser;
    const variables = {
      UserERN: user.userERN,
      OrganisationERN: user.organisationId
    };
    const conversationCache = readUserConversationCache(apolloClient, variables);
    let data = conversationCache?.["getConversationByUser"] ? conversationCache["getConversationByUser"] : [];

    //get conversation By id
    getConversation({
      variables: {
        ConversationIds: [conversationNotification.ConversationId]
      }
    }).then((conversations) => {
      if (!conversations) return;

      const updatedConversation = conversations.data["getConversationByIds"][0] as IMessageDetails;
      handleConversation(updatedConversation, conversationNotification, data);
      writeUserConversationCache(apolloClient, variables, conversationNotification, data);
      setLoadedCount((prev) => {
        return prev + 1;
      });
    });
  }

  function subscriptionHandlerUpdateCovnersation(notification: any) {
    // if conversation update
    // get user conversation
    // set to conversationNotification

    const conversationNotification = JSON.parse(notification.Data) as ConversationNotification;
    const variables = {
      UserERN: user.userERN,
      OrganisationERN: user.organisationId
    };
    const conversationCache = readUserConversationCache(apolloClient, variables);
    let data = conversationCache?.["getConversationByUser"] ? conversationCache["getConversationByUser"] : [];

    //TODO: Find sub conversaiton from cache.
    const isExisting = data.find((item) => item.ConversationId == conversationNotification.ConversationId);
    let userConversation: IConversationByUser | null = null;
    if (!isExisting) {
      //TODO: If not found then get UserConversation by conversation id from network
      getUserConversationById({
        variables: {
          ConversationId: conversationNotification.ConversationId,
          UserERN: user.userERN,
          OrganisationERN: user.organisationId
        }
      }).then((result) => {
        let userConversation: IConversationByUser = result.data["getUserConversationById"][0];
        //get conversation By id
        getConversation({
          variables: {
            ConversationIds: [conversationNotification.ConversationId]
          }
        }).then((conversations) => {
          if (!conversations) return;
          const conversationCache = readUserConversationCache(apolloClient, variables);
          let data = conversationCache?.["getConversationByUser"] ? conversationCache["getConversationByUser"] : [];
          const updatedConversation = conversations.data["getConversationByIds"][0] as IMessageDetails;
          handleConversation(updatedConversation, userConversation, data);
          writeUserConversationCache(apolloClient, variables, userConversation, data);
          setLoadedCount((prev) => {
            return prev + 1;
          });
        });
      });
    }
  }



  async function subscriptionRecreate() {
    const apiUrl = process.env.API_URL_NEW ?? "";
    AmplifyClient.Configure(apiUrl);
    tokenRef.current = (await ContextUtils.getAccessTokenFromContext()) || "";
    //close existing connection.
    if (conversationSubRef.current) {
      conversationSubRef.current.unsubscribe();
    }

    //re-try
    await subscribeToNewConverstion().catch((err) => {
      console.error(err);
    });
  }

  //#region Conversation .
  useEffect(() => {
    subscribeToNewConverstion().catch((err) => {
      console.error(err);
    });
    //listen the connection status to re-run the subscription.
    const cancelListener = HubListner(subscriptionRecreate, priorConnectionRef);
    return () => {
      //stop listening the connection status.
      cancelListener();
      if (conversationSubRef.current) conversationSubRef.current.unsubscribe();
    };
  }, []);

  let selectedConversationId = props.refConversationId;
  // Where we've been passed a "localConversation" use it to set he selected conversation (highlights the conversation in the preview)
  if (props.localConversation && loadedConversations.current?.length > 0) {
    selectedConversationId = findSelectedConversation(
      props.localConversation,
      props.isGroup,
      loadedConversations.current
    );
  }

  //#endregion
  //#region Panels and views
  if (!loading && loadedCount > 0 && loadedConversations.current && loadedConversations.current.length > 0) {
    return (
      <PreviewPanelMessagesLoading
        key={loadedCount}
        queryConversation={loadedConversations.current}
        memoizedSetSendMessageState={props.memoizedSetSendMessageState}
        setIsError={props.setIsError}
        refConversationId={selectedConversationId}
        selectedUserERN={props.selectedUserERN}
        isGroup={props.isGroup}
        setIsRemoved={props.setIsRemoved}
      />
    );
  } else if (!loading && loadedCount > 0 && loadedConversations.current) {
    return <div className={commonStyle.leftPanel}></div>;
  } else {
    return (
      <div className={commonStyle.leftPanel}>
        <div className={commonStyle.spinner}>
          <ProgressSpinner text="Loading messages..." />
        </div>
      </div>
    );
  }
};
export default PreviewPanelConversationsLoading;

function writeUserConversationCache(
  apolloClient,
  variables: { UserERN: string; OrganisationERN: string },
  conversationData: IConversationByUser,
  conversations: IConversationByUser[]
) {
  const conversation = JSON.parse(JSON.stringify(conversationData));
  if (conversation) {
    //filter if the conversation is already in cache and add as new conversation. 
    let updatedData = {
      ...conversationData,
      __typename: "ChatUserConversation",

    }; 
    const newCacheData = [updatedData, ...conversations];
    apolloClient.cache.writeQuery({
      query: GET_CONVERSATION_BY_USER,
      variables: variables,
      data: {
        getConversationByUser: newCacheData
      }
    });
  }
}
