import { ILoggerConfig, LogLevel } from "@emisgroup/logging-sdk-typescript";

export function LoggerConfig(token: any): ILoggerConfig {
    return {
      module: "staff-messaging",
      component: "staff-messaging-ui",
      apiUrl: process.env.TRACKING_URL || "",
      getApiToken: token,
      consoleLogLevel: LogLevel[process.env.TRACKING_CONSOLE_LOG_LEVEL || "DEBUG"],
      minimumLogLevel: LogLevel[process.env.TRACKING_MINIMUM_LOG_LEVEL || "ERROR"],
      batchSize: 50,
      outputToApi: process.env.TRACKING_LOG_TO_API === "true",
      outputToConsole: process.env.TRACKING_LOG_TO_CONSOLE === "true"
    };
  }