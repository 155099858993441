import React, { useEffect, useRef, useState } from "react";
import { User } from "../utils/user";
import { ISendMessageStateProps } from "./SendMessage";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { ApolloError, useQuery, useApolloClient, useLazyQuery } from "@apollo/client";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import PreviewPanelMessagesLoading from "./PreviewPanelMessagesLoading";
import { findSelectedConversation } from "../utils/PreviewPanelConversationLoading";
import * as subscriptions from "../graphql/subscriptions";
import { generateClient } from 'aws-amplify/api';
import AmplifyClient from "../utils/AmplifyClient";
import { ContextUtils } from "../utils/contextUtils";
import { authTokenWithBearer, HubListner } from "../utils";
import { getConversationName, updateCache } from "../utils/MessageComponent";
import { GET_USERS, GET_MESSAGES_BY_CONVERSATION, GET_CONVERSATION_BY_ID, GET_USER_CONVERSATIONS } from '../graphql/queries';
import { UserConversation } from "../types/userConversation";
import { ConversationNotification } from "../types/notification";
import { useFlags } from "@emisgroup/acp-utility-feature-flags";
//style
import commonStyle from "../styles/Common.module.scss";
import * as fragments from "../graphql/fragments";
import { getParticipants } from "../utils/GetParticipants";

const client = generateClient();

export enum NotificationType {
  CREATE_CONVERSATION,
  CREATE_USER,
  CREATE_MESSAGE,
  UPDATE_CONVERSATION,
  MESSAGE_READ_STATUS
}
export interface IPreviewPanelConversationsLoadingProps {
  memoizedSetSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  setNoMessages: React.Dispatch<React.SetStateAction<boolean>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  refConversationId: string;
  selectedUserName: string;
  localConversation: UserConversation;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
  isRemoved: boolean;
  refOldConversationId?: React.MutableRefObject<string>;
}

//#region Graphql schema

export const initialLoadCount = 100;

const PreviewPanelConversationsLoading: React.FC<IPreviewPanelConversationsLoadingProps> = (
  props: IPreviewPanelConversationsLoadingProps
) => {
  const newConversation = useRef(false);
  const loadedConversations = useRef<any[]>([]);
  const [loadedCount, setLoadedCount] = useState(0);
  const apolloClient = useApolloClient();
  const conversationSubRef = useRef(null);
  const priorConnectionRef = useRef<string>("");
  const tokenRef = useRef();
  const usersList = useRef([]);
  const currentConversationId = useRef("");
  const currentDisabledStatus = useRef(false);
  console.clear();
  
  currentConversationId.current = props.refConversationId
    ? props.refConversationId
    : props.refOldConversationId.current;
  currentDisabledStatus.current = props.isRemoved;

  const handleError = (_err: ApolloError) => {
    console.error(`Error: ${JSON.stringify(_err.message)}`);
    props.setIsError(true);
    Logger.error("Error rendering reviewPanelConversationsLoading");
    Logger.error(_err.message);
    return false;
  };



  Logger.debug("Creating PreviewPanelConversationsLoading component");

  const appContext: IAppContextInterface = React.useContext(AppContext);
  let user = new User(appContext.userToken);

  const { staffMessagingUiEnablenewapi } = useFlags();
  // Get conversation information on load
  const { loading, data: queryConversation } = useQuery(GET_USER_CONVERSATIONS, {
    variables: {
      UserName: user.userName ? user.userName.toLowerCase() : "",
      OrganisationId: user.organisationId
    },
    onError: handleError,
    fetchPolicy: "cache-first"
  });

  const [getConversation] = useLazyQuery(GET_CONVERSATION_BY_ID, {
    fetchPolicy: "network-only",
    onError: handleError
  })

  //Get user details from cache
  const { data: users } = useQuery(GET_USERS, {
    variables: { OrganisationId: user.organisationId },
    fetchPolicy: "cache-first"
  });

  //get list of messages for existing conversation
  const [getMessage] = useLazyQuery(GET_MESSAGES_BY_CONVERSATION, {
    fetchPolicy: "network-only",
    onError: handleError
  });

  useEffect(() => {
    if (users?.["getUserV2"] && users["getUserV2"].length > 0) {
      usersList.current = users;
    }
  }, [users]);

  // Process GET_USER_CONVERSATIONS response
  useEffect(() => {
    if (queryConversation?.["getConversationByUserV3"]) {
      if (queryConversation["getConversationByUserV3"].length === 0) {
        props.setNoMessages(true);
      } else {
        props.setNoMessages((prev) => {
          if (prev) return false;
          return prev;
        });
      }

      let loadedConversation = JSON.parse(JSON.stringify(queryConversation["getConversationByUserV3"]));

      loadedConversations.current = loadedConversation.map((item: UserConversation) => {
        item.Participants = getParticipants([], item.ParticipantList);
        return item;
      });

      setLoadedCount((prev) => {
        return prev + 1;
      });
    }
  }, [queryConversation]);


  const handleGroupName = (conversation: UserConversation, prev: any, currentUser: string) => {
    //Reset userName. since we reveice without userName.for removed an added or updated users.

    const filterList: UserConversation[] = loadedConversations.current.filter((item: UserConversation) => !(
      item.ConversationId == conversation.ConversationId &&
      item.UserName == conversation.UserName));

    if (conversation) {
      loadedConversations.current = [...filterList, conversation];

      newConversation.current = false;
      appContext.creatingConversation = false;
      if (appContext.selectedConversationId.current == conversation.ConversationId) {
        props.setIsRemoved(conversation.IsRemoved);
        props.memoizedSetSendMessageState((prev) => {
          return {
            title: getConversationName(
              conversation.ParticipantList,
              currentUser,
              conversation.ConversationName,
              usersList.current["getUserV2"]
            ),
            conversationId: conversation.ConversationId,
            recipients: prev.recipients,
            message: prev.message,
            time: prev.time,
            createdAtMessageId: prev.createdAtMessageId,
            authorId: prev.authorId,
            avatarColor: prev.avatarColor,
            isGroup: conversation.IsGroupConversation,
            isRemoved: conversation.IsRemoved,
            removedOn: conversation.RemovedOn,
            removedBy: conversation.RemovedBy,
            conversationName: conversation.ConversationName
          };
        });
      }
    }
  };

  const readMessage = (conversationId: string) => {
    getMessage({
      variables: {
        ConversationId: conversationId,
        Limit: initialLoadCount
      }
    }).catch(err => { console.error(err) });
  }
  const handleConversation = (conversation: UserConversation, prev: any) => {

    if (conversation) {
      loadedConversations.current = [...loadedConversations.current, conversation];
      if (prev.some(
        (conv) =>
          conv.ConversationId === conversation.ConversationId &&
          !conversation.IsRemoved
      )) {

        //refresh messages if history is shared
        readMessage(conversation.ConversationId);

        if (currentConversationId.current === conversation.ConversationId) {
          updateCache(apolloClient.cache, false);
          props.setIsRemoved(() => { return false });
        }
        console.warn("WARNING:- Conversation already found inside the current list.");
        return prev;
      } else {
        newConversation.current = true;

        // This is needed to render the right panel when a new conversation is created
        // Only render right panel if message sent by author though (creatingConversation set in MessageSendingComponent).
        if (appContext.creatingConversation) {
          props.setIsRemoved(conversation.IsRemoved);
          appContext.creatingConversation = false;
          props.memoizedSetSendMessageState((prev) => {
            return {
              title: prev.title,
              conversationId: conversation.ConversationId,
              recipients: prev.recipients,
              message: prev.message,
              time: prev.time,
              createdAtMessageId: prev.createdAtMessageId,
              authorId: prev.authorId,
              avatarColor: prev.avatarColor,
              isGroup: conversation.IsGroupConversation,
              conversationName: conversation.ConversationName,
              isRemoved: conversation.IsRemoved,
              removedOn: conversation.RemovedOn,
              removedBy: conversation.RemovedBy
            };
          });
        }

        if (prev["getConversationByUserV3"]) {
          return {
            getConversationByUserV3: [conversation, ...prev["getConversationByUserV3"]]
          };
        } else {
          return {
            getConversationByUserV3: [conversation]
          };
        }
      }
    }
  };

  function readUserConversationCache(apolloClient, variables: { UserName: string; OrganisationId: string }) {
    return apolloClient.cache.readQuery({
      query: GET_USER_CONVERSATIONS,
      variables: variables
    });
  }

  async function subscribeToNewConverstion() {

    const filter = `${user.organisationId}_${user.userName}`;
    const notificationReq = { Filter: filter };

    tokenRef.current = (await ContextUtils.getAccessTokenFromContext()) || "";
    conversationSubRef.current = client.graphql({
      query: subscriptions.NotificationV2Mutation,
      variables: notificationReq,
      authToken: authTokenWithBearer(tokenRef.current)
    }).subscribe({
      next: (value) => {
        if (!value?.data.subscribeToNotificationV2) return;
        //Add next conversation into the cache.
        const notification = value.data.subscribeToNotificationV2;
        if (Number(notification.Type) == NotificationType.CREATE_CONVERSATION || Number(notification.Type) === NotificationType.UPDATE_CONVERSATION) {
          subscriptionHandler(notification);
        }
      },
      error: (error) => {
        console.error("Conversation Subscription: " + error)
      }
    });
  }

  function subscriptionHandler(notification: any) {
    const conversationNotification = JSON.parse(notification.Data) as ConversationNotification;
    const variables = {
      UserName: user.userName ? user.userName.toLowerCase() : "",
      OrganisationId: user.organisationId ? user.organisationId : ""
    };
    const conversationCache = readUserConversationCache(apolloClient, variables);
    let data = conversationCache?.["getConversationByUserV3"] ? conversationCache["getConversationByUserV3"] : [];

    //get conversation By id
    getConversation({
      variables: {
        ConversationId: conversationNotification.ConversationId
      }
    }).then((conversations) => {
      if (!conversations) return;

      const updatedConversation = conversations.data['getConversationByIdV2'][0] as UserConversation;

      handleConversation(updatedConversation, data);
      handleGroupName(updatedConversation, data, user.userName);
      writeUserConversationCache(apolloClient, user.userName, variables, updatedConversation, data, usersList.current);
      setLoadedCount((prev) => {
        return prev + 1;
      });
    })

  }

  async function subscriptionRecreate() {
    const apiUrl = staffMessagingUiEnablenewapi ? process.env.API_URL_NEW : process.env.API_URL;
    AmplifyClient.Configure(apiUrl);
    tokenRef.current = (await ContextUtils.getAccessTokenFromContext()) || "";
    //close existing connection.
    if (conversationSubRef.current) {
      conversationSubRef.current.unsubscribe();
    }

    //re-try
    await subscribeToNewConverstion().catch(err => { console.error(err) });
  }

  //#region Conversation .
  useEffect(() => {
    subscribeToNewConverstion().catch(err => { console.error(err) });
    //listen the connection status to re-run the subscription.
    const cancelListener = HubListner(subscriptionRecreate, priorConnectionRef);
    return () => {
      //stop listening the connection status.
      cancelListener();
      if (conversationSubRef.current) conversationSubRef.current.unsubscribe();
    };
  }, []);

  let selectedConversationId = props.refConversationId;
  // Where we've been passed a "localConversation" use it to set he selected conversation (highlights the conversation in the preview)
  if (props.localConversation && loadedConversations.current?.length > 0) {
    selectedConversationId = findSelectedConversation(
      props.localConversation,
      props.isGroup,
      loadedConversations.current
    );
  }

  //#endregion
  //#region Panels and views
  if (!loading && loadedCount > 0 && loadedConversations.current && loadedConversations.current.length > 0) {
    return (
      <PreviewPanelMessagesLoading
        key={loadedCount}
        queryConversation={loadedConversations.current}
        memoizedSetSendMessageState={props.memoizedSetSendMessageState}
        setIsError={props.setIsError}
        refConversationId={selectedConversationId}
        selectedUserName={props.selectedUserName}
        isGroup={props.isGroup}
        setIsRemoved={props.setIsRemoved}
      />
    );
  } else if (!loading && loadedCount > 0 && loadedConversations.current) {
    return <div className={commonStyle.leftPanel}></div>;
  } else {
    return (
      <div className={commonStyle.leftPanel}>
        <div className={commonStyle.spinner}>
          <ProgressSpinner text="Loading messages..." />
        </div>
      </div>
    );
  }
};
export default PreviewPanelConversationsLoading;

function writeUserConversationCache(
  apolloClient,
  currentUser: string,
  variables: { UserName: string; OrganisationId: string },
  conversationData: UserConversation,
  conversations: UserConversation[],
  users: any
) {
  const conversation = JSON.parse(JSON.stringify(conversationData)) as UserConversation;
  if (conversation) {
    //update ConverastionName for groupConversation
    if (conversation.IsGroupConversation)
      conversation.ConversationName = getConversationName(
        conversation.ParticipantList,
        currentUser,
        conversation.ConversationName,
        users["getUserV2"]
      );

    //Update local cache for completed large group
    apolloClient.cache.updateFragment({
      id: `ConversationStore:${conversation.ConversationId}`,
      fragment: fragments.getConversationStore,
    }, (data) => {
      return { ...data, Action: "COMPLETED" };
    });

    //filter if the conversation is already in cache and add as new conversation.
    const filterResult = conversations.filter(item => !(
      item.ConversationId == conversation.ConversationId &&
      item.UserName == conversation.UserName));

    const newCacheData = [conversation, ...filterResult];
    apolloClient.cache.writeQuery({
      query: GET_USER_CONVERSATIONS,
      variables: variables,
      data: {
        getConversationByUserV3: newCacheData
      }
    });

  }
}