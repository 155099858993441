import React from "react";
import { Stack } from "@emisgroup/ui-layouts";
import style from "../styles/EmptyState.module.scss";

export interface IEmptyStatePanel {
    header: string;
    content: string;
    subContent?: string;

}

const EmptyStatePanel: React.FC<IEmptyStatePanel> = (props: IEmptyStatePanel) => {

    return (
        <Stack>
            <div >
                <div className={style.emptyStateHeader}>
                    <h3>{props.header}</h3>
                    <p className={style.emptyStateChildContent}> {props.content}</p>
                    <p className={style.emptyStateChildContent}> {props.subContent}</p>
                </div>
            </div>
        </Stack>
    );
};

export default EmptyStatePanel;
