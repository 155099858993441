import React, { useEffect } from "react";
import style from "../styles/ListItemComponent.module.scss";
import MessageListItem from "./MessageListItem";
import { ISendMessageStateProps } from "./SendMessage";
import { useQuery } from "@apollo/client";
import { GET_LOCAL_CONVERSATION_AND_MESSAGE } from "../graphql/queries";

export interface IListItemComponentProps {
  setSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  messageReadCallback: Function;
  refConversationId: string;
  selectedUserERN: string;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}

export type messageDetails = {
  name: string;
  authorId: string;
  message: string;
  time: string;
  isRead?: boolean;
  lastMessageTime: string;
  conversationId: string;
  isReply: boolean;
  isUrgent: boolean;
  participantList: string[];
  userStatus: boolean;
  organisationId: string;
  isGroup: boolean;
  isRemoved: boolean;
  removedOn: string;
  removedBy: string;
  hasBeenNamed: boolean;
  type_Id: string;
  lastUpdatedOn: string;
};

export type Participant = {
  OrganisationId: string;
  UserName: string;
};

const ListItemComponent = (props: IListItemComponentProps) => {
  const [messageList, setMessageList] = React.useState<Partial<messageDetails[]>>([]);
  let selectedConversationId = "";
  props.setSendMessageState((prev) => {
    selectedConversationId = prev.conversationId;
    return prev;
  });
  if (!selectedConversationId) selectedConversationId = props.refConversationId;

  const { data: allMessages } = useQuery(GET_LOCAL_CONVERSATION_AND_MESSAGE, {
    variables: {
      Id: "all"
    },
    fetchPolicy: "cache-only"
  });
  
  useEffect(() => {
    if (allMessages?.getLocalConversationAndMessage?.length > 0) {
      setMessageList(allMessages.getLocalConversationAndMessage);     
    }
  }, [allMessages]);


  return (
    <div className={style.listDiv}>
      {messageList.map((element, index, { length }) => {
        return (
          <div key={"ListItem-" + index} id={"listItem_content"}>
            <MessageListItem
              index={index}
              preview={element}
              setSendMessageState={props.setSendMessageState}
              messageReadCallback={props.messageReadCallback}
              selectedConversationId={selectedConversationId}
              selectedUserERN={props.selectedUserERN}
              isGroup={props.isGroup}
              setIsRemoved={props.setIsRemoved}
            />
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(ListItemComponent);
