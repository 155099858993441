import { useQuery, ApolloError, useApolloClient } from "@apollo/client";
import * as React from "react";
import { ListItem } from "@emisgroup/ui-list";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import commonStyle from "../styles/Common.module.scss";
import { useState, useRef, useEffect } from "react";
import Avatar from "../custom_components/UserAvatar/Avatar";
import { v4 as uuidv4 } from "uuid";
import EmptyStatePanel from "./EmptyStatePanel";
import { Stack } from "@emisgroup/ui-layouts";
import { Button } from "@emisgroup/ui-button";
import Refresh from "~icons/ic/refresh";
export type ActionElementType = "button" | "spinner";
import { FeaturedIcon } from "@emisgroup/ui-icon";
import SystemProblem from "~icons/ic/twotone-sync-problem";
import { getUserDisplayName } from "../utils";
import { getLocalConversationGroupV2 } from "../utils/MessageComponent";
import { V2UserConversation } from "../types/userConversation";
import { Input } from "@emisgroup/ui-input";
import Search from "~icons/ic/outline-search";
import { useDebounce } from "use-debounce";
import noContactStyle from "../styles/NoContacts.module.scss";
import { V2IContactsProps } from "../models/IContactsProps";
import contactStyle from "../styles/Contacts.module.scss";
import { Checkbox } from "@emisgroup/ui-checkbox";
import { GET_NEW_USERS } from "../graphql/queries";

let _value: any;
export function get_latestData(): any {
  return _value;
}
export function set_latestData(v: any) {
  _value = v;
}

const Contacts: React.FC<V2IContactsProps> = (props: V2IContactsProps) => {
  const { loading, data, previousData, refetch } = useQuery(GET_NEW_USERS, {
    variables: { OrganisationERN: props.organisationId },
    onError: handleError,
    onCompleted: () => setErrorState(false),
    fetchPolicy: "cache-first"
  });

  const [errorState, setErrorState] = useState(false);
  //Apollo Client
  const apolloClient = useApolloClient();
  const contactListScrollTopRef = useRef<number>(0);
  const contactListRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [checked, setChecked] = useState(false);

  const contactLength = useRef(0);
  const divRef = useRef([]);
  const previousUserStausRef = useRef([]);
  const [text, setText] = useState("");
  const [value] = useDebounce(text, 1000);

  const onContactListScroll = () => {
    contactListScrollTopRef.current = contactListRef.current.scrollTop;
  };

  const filterFunction = (userInput: string) => {
    return get_latestData()?.filter((x) => {
      if (!checked) {
        return x.DisplayName.toLowerCase().includes(userInput);
      }
      if (userInput) return x.UserStatus == "online" && x.DisplayName.toLowerCase().includes(userInput);
      return x.UserStatus == "online";
    });
  };

  useEffect(() => {
    if (contactListRef.current && previousUserStausRef.current?.length == 0) {
      contactListRef.current.scrollTop = 0;
    }
    previousUserStausRef.current = [];
  }, [filteredResults]);

  React.useLayoutEffect(() => {
    if (contactListScrollTopRef.current)
      contactListRef.current?.scrollTo({
        top: contactListScrollTopRef.current
      });
  });

  function handleError(err: ApolloError) {
    console.error(`Error: ${JSON.stringify(err.message)}`);
    setErrorState(true);
  }

  useEffect(() => {
    previousUserStausRef.current = data?.getUser;
  }, [previousData]);

  useEffect(() => {
    setFilteredResults(filterFunction(value.toLowerCase()));
  }, [data, value, checked]);

  let localGroupConversation: V2UserConversation;
  localGroupConversation = getLocalConversationGroupV2(apolloClient.cache);

  if (data) {
    const userData = data?.getUser;
    set_latestData(userData?.filter((x) => x.DisplayName));
  }

  if (loading) {
    return (
      <div className={contactStyle.spinner}>
        <ProgressSpinner text="Loading contacts..." />
      </div>
    );
  }

  const listItemClickHandler = (data: any) => {
    if (data) {
      const recipient = data.UserERN;
      props.setUserName(getUserDisplayName(data));
      props.setRecipientId(recipient);
    }
    clearlocalCache();
  };

  function clearlocalCache() {
    const normalizedId = apolloClient.cache.identify(localGroupConversation);
    return apolloClient.cache.evict({ id: normalizedId });
  }

  function getUserNames(displayName: string) {
    return displayName && displayName.split(",").length > 1 ? displayName.split(",") : "";
  }

  function checkUserStatus(userStatus: string) {
    return userStatus === "online";
  }

  const createContactList = (contactDetailsData) => {
    contactLength.current = contactDetailsData.length;
    return contactDetailsData.map((value, index) => {
      const displayName = getUserDisplayName(value);
      const firstName = getUserNames(displayName)[1]?.trim();
      const lastName = getUserNames(displayName)[0]?.trim();
      const available = checkUserStatus(value.UserStatus);
      const userKey = value.UserERN;
      //Note: orgId may required.
      return (
        <div
          key={uuidv4()}
          ref={(element) => {
            divRef.current[index] = element;
          }}
          id={userKey}
          data-testid={userKey}
          className={contactStyle.backgroundColorWhite}
          onClickCapture={(e) => {
            listItemClickHandler(value);
          }}
        >
          <ListItem
            id={userKey}
            data-testid="listItem"
            className={contactStyle.listItem}
            action={{
              disableToggle: true
            }}
            key={userKey}
          >
            <span className={contactStyle.contactSpan}>
              <div className={contactStyle.avatarColumn}>
                <Avatar
                  firstName={firstName}
                  fullName={getUserDisplayName(value)}
                  lastName={lastName}
                  isAvailable={available}
                  color={value.UserERN}
                  isGroup={false}
                  size={"medium"}
                />
              </div>
              <div>
                <div className={contactStyle.contactDisplayName}>{getUserDisplayName(value)}</div>
              </div>
            </span>
          </ListItem>
        </div>
      );
    });
  };

  const filterOnlineContact = (e) => {
    setChecked(e);
  };

  const checkContacts = (): boolean => {
    return filteredResults && value && filteredResults.length == 0;
  };
  const sortContacts = (data: any) => {
    if (data) {
      return data.slice().sort((a, b) => getUserDisplayName(a)?.localeCompare(getUserDisplayName(b)));
    }
  };

  const contactDetails = (contactDetailsData) => {
    if (errorState === false) {
      if (contactDetailsData != null && contactDetailsData.length > 0) {
        const contacts = sortContacts(contactDetailsData);
        return createContactList(contacts);
      } else if (contactDetailsData != null && contactDetailsData.length == 0) {
        return (
          <div key={uuidv4()} className={contactStyle.container}>
            <EmptyStatePanel header="No contacts" content="You do not have any contacts to send messages yet." />
          </div>
        );
      }
    }
  };

  if (errorState) {
    return (
      <div className={commonStyle.centerContainer}>
        <Stack className={commonStyle.stackTemplate}>
          <FeaturedIcon>
            <SystemProblem color="primary" size="x-large" title="Error" />
          </FeaturedIcon>
          <EmptyStatePanel
            header="Something went wrong"
            content="Sorry, but we can't show your contacts at the moment. Try again later."
          />
          <div>
            <Button
              variant="filled"
              data-testid="retry-button"
              onClick={() => {
                refetch().catch((err) => {
                  console.error(err);
                });
              }}
            >
              <Refresh title="Try again" />
              Try again
            </Button>
          </div>
        </Stack>
      </div>
    );
  } else {
    return (
      <div className={contactStyle.app}>
        <div className={contactStyle.background}>
          <div className={contactStyle.inputMargin}>
            <Input
              ref={inputRef}
              data-testid="text-input"
              aria-label="search-contacts"
              clearable={true}
              onClear={() => {
                setText("");
                inputRef.current.focus();
              }}
              onChange={(e) => {
                setText(e.target.value);
              }}
              value={text}
              defaultValue=""
              autoFocus
              placeholder="Search for a contact by name"
            >
              <Search title="" />
            </Input>
            <div className={contactStyle.onlineFormat}>
              <Checkbox className={contactStyle.checkBoxFormat} onChange={filterOnlineContact} id="online-checkbox">
                <div>Online only</div>
              </Checkbox>
            </div>
          </div>
        </div>
        <div key={uuidv4()} className={contactStyle.background}>
          {checkContacts() ? (
            <ul key={uuidv4()} className={contactStyle.marginList}>
              <div className={noContactStyle.noContactFormat}>No contacts found.</div>
            </ul>
          ) : (
            <ul
              key={uuidv4()}
              ref={contactListRef}
              data-testid={"scrollId"}
              onScroll={onContactListScroll}
              className={contactStyle.marginList}
            >
              {contactDetails(filteredResults && filteredResults.length > 0 ? filteredResults : get_latestData())}
            </ul>
          )}
        </div>
      </div>
    );
  }
};

export default Contacts;
