import { UserConversation } from "../types/userConversation";
import { getHashCode } from "./GetParticipants";

export function findSelectedConversation(selectedConversation: UserConversation, isGroup: boolean, loadedConversation: UserConversation[]): string {
    const conversationList = [...loadedConversation].reverse();
    const conversation = conversationList.find(
        conv => 
            conv.IsRemoved === false && 
            conv.ConversationName === selectedConversation.ConversationName && 
            isGroup === conv.IsGroupConversation && (getHashCode([...conv.ParticipantList]) == getHashCode([...selectedConversation.ParticipantList])))

    return conversation ? conversation.ConversationId : "" // "a225d061-3d17-4410-a363-1b4189b4d524"; //conversation.ConversationId ? conversation.ConversationId : ""; //"a225d061-3d17-4410-a363-1b4189b4d524";

}