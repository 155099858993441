import { TitleArea, TitleBar } from "@emisgroup/ui-title-bar";
import MoreVert from "~icons/ic/outline-more-vert";
import UserGroup from "~icons/ic/outline-groups";
import { useNavigate } from "react-router-dom";
import { Button } from "@emisgroup/ui-button";
import React, { useState, useEffect, useRef } from "react";
import commonStyle from "../styles/Common.module.scss";
import { DropdownMenu } from "@emisgroup/ui-dropdown-menu";
import { leaveConversationFilterV2 } from "../utils/ConversationUtils";
import { ApolloError, useMutation, useQuery, useApolloClient, ApolloClient } from "@apollo/client";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { UserAvatar } from "@emisgroup/ui-avatar";
import { GET_NEW_USERS, GET_LOCAL_CONVERSATION_AND_MESSAGE } from "../graphql/queries";
import { LEAVE_CONVERSATION } from "../graphql/mutations";
import { updateCacheV2 } from "../utils/MessageComponent";
import EditIcon from "~icons/ic/outline-edit";
import LogoutIcon from "~icons/ic/outline-logout";
export interface ITitlePanel {
  displayTitle: string;
  isGroupChat: boolean;
  conversationId: string;
  refOldConversationId: React.MutableRefObject<string>;
  organisationId: string;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
  setLeaveError: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readMessageCallBack: Function;
}

function useOutsideAlerter(ref, setDropdownMenuOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownMenuOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function CheckPathArgs(refOldConversationId: string, conversationId: string) {
  const oldConversationId = refOldConversationId || "";
  const pathArgs = conversationId || oldConversationId;
  return pathArgs;
}

function checkUserDetails(user, userType: string) {
  return user?.[userType] && user[userType].length > 0;
}

function checkLeaveStatus(leaveRequested: boolean, userConversationData: any) {
  return (
    leaveRequested &&
    userConversationData?.["getLocalConversationAndMessage"] &&
    userConversationData["getLocalConversationAndMessage"].length > 0
  );
}

function checkConversationStore(conversationStore: any) {
  return !conversationStore || conversationStore["conversationDetails"].Action == "COMPLETED";
}

const TitlePanel: React.FC<ITitlePanel> = (props: ITitlePanel) => {
  const { displayTitle, isGroupChat } = props;
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);

  const leaveRequested = useRef(false);
  const dialogPanelRequested = useRef(false);
  const nav = useNavigate();
  const appContext: IAppContextInterface = React.useContext(AppContext);
  const handleLeaveError = (err: ApolloError) => {
    console.error(`Error  : ${JSON.stringify(err.message)}`);
    appContext.setLeaveGroup(false);
    props.setLeaveError(true);
  };
  const userType = "getUser";

  const { data: user } = useQuery(GET_NEW_USERS, {
    variables: { OrganisationERN: props.organisationId },
    fetchPolicy: "cache-first"
  });

  useEffect(() => {
    readUsers(user, appContext, userType);
  }, [user]);

  const [leaveConversations] = useMutation(LEAVE_CONVERSATION);
  const client = useApolloClient();


  const { data: allMessages } = useQuery(GET_LOCAL_CONVERSATION_AND_MESSAGE, {
    variables: {
      Id: "all"
    },
    fetchPolicy: "cache-only"
  });

  useEffect(() => {
    if (allMessages?.getLocalConversationAndMessage?.length > 0) {
      let conversationId = CheckPathArgs(props.refOldConversationId.current, props.conversationId);
      appContext.currentParticipants = allMessages?.getLocalConversationAndMessage.filter((conversation) => conversation.conversationId === conversationId)[0]?.participantList;
    }
  }, [allMessages]);


  const ref = useRef();

  useOutsideAlerter(ref, setDropdownMenuOpen);

  function groupnavPath() {
    let pathArgs = CheckPathArgs(props.refOldConversationId.current, props.conversationId);
    nav("/staff/group-contact-list/" + pathArgs);
  }

  function handleDialog() {
    dialogPanelRequested.current = true;
    updateDialogPanelRequested(dialogPanelRequested, props.setDialogOpen)
  }

  useEffect(() => {
    if (appContext.leaveGroup) {
      leaveConversation();
      appContext.setLeaveGroup(false);
      appContext.setLeaveGroupShowWarning(false);
    }
  }, [appContext.leaveGroup]);

  function leaveConversation() {
    // Get current conversation data and trigger effect to leave conversation
    if (CheckPathArgs(props.refOldConversationId.current, props.conversationId) != "") {
      leaveRequested.current = true;
    }
  }

  useEffect(() => {
    // Get current conversation data and trigger effect to leave conversation
    if (checkLeaveStatus(leaveRequested.current, allMessages)) {
      const updatedParticipants = leaveConversationFilterV2(appContext.currentParticipants, appContext.user.userERN);
      leaveRequested.current = false;
      if (updatedParticipants.length > 0) {
        let conversationId = CheckPathArgs(props.refOldConversationId.current, props.conversationId);
        props.readMessageCallBack(conversationId, appContext.user.userERN, props.organisationId);
        updateConversation(
          props,
          appContext,
          leaveConversations,
          handleLeaveError,
          client
        );
      } else {
        props.setLeaveError(() => true);
      }
      // Close Menu
      setDropdownMenuOpen(false);
    }
  }, [leaveRequested.current]);

  function checkIsNewGroup() {
    if (CheckPathArgs(props.refOldConversationId.current, props.conversationId)) handleDialog();
  }

  function CheckMenuClick() {
    if (
      CheckPathArgs(props.refOldConversationId.current, props.conversationId)
    ) {
      setDropdownMenuOpen(!dropdownMenuOpen);
    }
  }

  return isGroupChat ? (
    <div ref={ref}>
      <TitleBar className={commonStyle.titleBarAlign}>
        <TitleArea backButton={false} data-testid={"title_without_button"} id={"header_button_title"}>
          <Button
            className={commonStyle.titleBarButton}
            borderless={true}
            variant="mono"
            role={"group-icon"}
            onClick={() => checkIsNewGroup()}
          >
            <UserAvatar color="featured" size="x-small" tooltip="">
              <UserGroup color="mono" size="small" title="" />
            </UserAvatar>
          </Button>
          <div
            role="title-button"
            className={commonStyle.titleOverflow}
            data-testId={"title-button-large"}
            onClick={() => checkIsNewGroup()}
          >
            <div className={commonStyle.title}> {displayTitle}</div>
          </div>
        </TitleArea>
        <Button role="button-large" borderless={true} variant="mono" onClick={() => CheckMenuClick()}>
          <MoreVert color="mono" size="medium" data-testid="system" title={""} />
        </Button>
      </TitleBar>
      {dropdownMenuOpen && (
        <div className={commonStyle.dropDownMenu}>
          <DropdownMenu
            data={[
              {
                action: () => groupnavPath(),
                children: (
                  <>
                    <EditIcon size="small" className={commonStyle.editIcon} title="" />
                    <span className={commonStyle.menu}>Edit group</span>
                  </>
                )
              },
              {
                action: () => {
                  appContext.setLeaveGroupShowWarning(true);
                },
                children: (
                  <>
                    <LogoutIcon size="small" className={commonStyle.editIcon} title="" />
                    <span className={commonStyle.menu}>Leave group</span>
                  </>
                )
              }
            ]}
          />
        </div>
      )}
    </div>
  ) : (
    <TitleBar className={commonStyle.titleBarAlign}>
      <TitleArea backButton={false} data-testid={"title_without_button"} id={"header_button_title"}>
        <div className={commonStyle.titleAlign}>
          <div className={commonStyle.title}> {displayTitle}</div>
        </div>
      </TitleArea>
    </TitleBar>
  );
};

function readUsers(user: any, appContext: IAppContextInterface, userType: string) {
  if (checkUserDetails(user, userType)) {
    appContext.userList = user[userType];
  }
}

function updateConversation(
  props: ITitlePanel,
  appContext: IAppContextInterface,
  leaveConversations,
  handleLeaveError: (err: ApolloError) => void,
  client: ApolloClient<object>
) {
  let conversationId = CheckPathArgs(props.refOldConversationId.current, props.conversationId);
  for (const sub of appContext.subscriptions) {
    if (sub.ConversationId === conversationId)
      Promise.resolve(sub.Subscription.unsubscribe()).catch((err) => {
        console.error(err);
      });
  }
  leaveConversations({
    variables: {
      ConversationId: conversationId,
      OrganisationERN: props.organisationId,
      UserERN: appContext.user.userERN,
    },
    onError: handleLeaveError,
    onCompleted: () => {
      updateCacheV2(client.cache, true);
      props.setIsRemoved(() => {
        return true;
      });
    }
  });
}

function updateDialogPanelRequested(
  dialogPanelRequested: React.MutableRefObject<boolean>,
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (dialogPanelRequested.current) {
    dialogPanelRequested.current = false;
    setDialogOpen(() => true);
  }
}

export default TitlePanel;
