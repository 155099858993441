import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { ILoggerConfig } from "@emisgroup/logging-sdk-typescript";
import { IAppContextInterface } from "../models/IAppContextInterface";
import AmplifyClient from "./AmplifyClient";
import { ApolloProviderClient } from "./ApolloProviderClient";
import { LoggerConfig } from "./LoggerConfigUtils";
import { User } from "./user";

export type AppUtil = {
  client: ApolloClient<NormalizedCacheObject>;
  loggerConfig: ILoggerConfig;
  appContext: IAppContextInterface;
};

export class AppContextUtil {
  appUtil: AppUtil;
  constructor() {
    this.appUtil = {
      client: undefined,
      loggerConfig: undefined,
      appContext: {
        user: undefined,
        userToken: "",
        approachConversationId: "",
        approachConversationName: "",
        approachIsGroup: false,
        approachIsRemoved: false,
        creatingConversation: false,
        approachRemovedOn: "",
        approachRemovedBy: "",
        userList: [],
        currentParticipants: [],
        subscriptions: [],
        hasBeenNamed: false,
        leaveGroupHasError: false,
        leaveGroup: false,
        leaveGroupShowWarning: false,
        setLeaveGroupHasError: null,
        setLeaveGroup: null,
        setLeaveGroupShowWarning: null,
      }
    };
  }

  config(token: string, apiUrl: string) {
    AmplifyClient.Configure(apiUrl);
    this.appUtil.appContext = {
      userToken: token,
      user: new User(token),
      approachConversationId: "",
      approachConversationName: "",
      approachIsGroup: false,
      approachIsRemoved: false,
      creatingConversation: false,
      approachRemovedOn: "",
      approachRemovedBy: "",
      userList: [],
      currentParticipants: [],
      subscriptions: [],
      hasBeenNamed: false,
      leaveGroupHasError: false,
      leaveGroup: false,
      leaveGroupShowWarning: false,
      setLeaveGroupHasError: null,
      setLeaveGroup: null,
      setLeaveGroupShowWarning: null,
    };
    this.appUtil.client = new ApolloProviderClient(token, apiUrl).getClient();
    this.appUtil.loggerConfig = LoggerConfig(token);
  }
}
