
import * as React from "react";
import { UserConversation } from "../types/userConversation";
import { Message } from "../types/messages";
import ListItemComponent, { messageDetails } from "./ListItemComponent";
import { useState } from "react";
import style from "../styles/Common.module.scss";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { AppContext } from "../utils/ApplicationContext";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { ISendMessageStateProps } from "./SendMessage";

import { FormatSystemMessage } from "../utils/MessageComponent";
import { User } from "../types/user";
import { getUserDisplayName } from "../utils";
import { useApolloClient } from "@apollo/client";
import { MESSAGE_UNREAD_COUNT } from "../graphql/queries";

export interface IMessagePreviewProps {
  userConversations: UserConversation[];
  messageThread: Message[];
  users: React.MutableRefObject<User[]>;
  rerenderCount: number;
  setSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  messageReadCallback: Function;
  refConversationId: string;
  selectedUserName: string;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}

let completeLoad = false;
let localHasMessages = false;

const MessagePreview: React.FunctionComponent<IMessagePreviewProps> = (props: IMessagePreviewProps) => {
  const [isMessageLoaded, setIsMessageLoaded] = useState(false);
  const [messageList, setMessageList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [reOrder, setReOrder] = useState(false);
  const client = useApolloClient();


  let messageRenderList: messageDetails[] = [];

  //get user context
  const appContext: IAppContextInterface = React.useContext(AppContext);
  let user = appContext.user;


  function compare(a, b) {
    return b.time - a.time;
  }

  function compareCreatedAt(a, b) {
    if (b.CreatedAt === a.CreatedAt) {
      return -1;
    }
    return b.CreatedAt > a.CreatedAt ? 1 : -1;
  }

  function getUserName(userName) {
    if (props.users.current && props.users.current.length > 0) {
      return props.users.current.find((item) => item.UserName == userName);
    }
    return null;
  }

  const updateMessagelist = () => {
    if (messageRenderList === undefined && messageList != undefined && (messageList as messageDetails[]).length > 0) {
      messageRenderList = messageList as messageDetails[];
    } else if (messageRenderList === undefined) {
      messageRenderList = [];
    }
  };

  const getConvDisplayName = (item) => {
    return user.userName.toLowerCase() !== item.Participants[0].UserName.toLowerCase() || item.Participants.length === 1
      ? getUserName(item.Participants[0].UserName.toLowerCase())
      : getUserName(item.Participants[1].UserName.toLowerCase());
  };



  const processUnrepliedMessages = (item) => {
    const checkExistingConversation = (obj) => obj.conversationId === item.ConversationId;
    let cachedCountStore = client.cache.readQuery({
      query: MESSAGE_UNREAD_COUNT,
      variables: {
        ConversationId:  item.ConversationId
      }
    });

    const name = !item.Participants ? getUserName(item.AuthorId) : getConvDisplayName(item);
    let displayName = "",
      userStatus = "",
      organisationId = "";
    if (name != null && name != undefined) {
      displayName = getUserDisplayName(name);
      userStatus = name.UserStatus;
      organisationId = name.OrganisationId;
    }

    if (!messageRenderList.some(checkExistingConversation)) {
      const messageDetail: messageDetails = {
        message: FormatSystemMessage(item.IsSystemMessage, item.Content, props.users.current),
        name: item.IsGroupConversation ? item.ConversationName : displayName,
        authorId: item.AuthorId,
        time: item.CreatedAt,
        isRead: item.IsRead.toString().toLowerCase() === "true",
        conversationId: item.ConversationId,
        createdAtMessageId: item.CreatedAt_MessageId,
        isReply: item.IsReplied,
        isUrgent:  cachedCountStore?.['conversationDetails'].HasUrgent ?? item.HasUrgent,
        participants: item.Participants,
        unreadCount: cachedCountStore?.['conversationDetails'].UnreadCount ?? item.UnreadCount,
        userStatus: userStatus.toLowerCase() === "online",
        organisationId: organisationId,
        isGroup: item.IsGroupConversation,
        isRemoved: item.IsRemoved,
        removedOn: item.RemovedOn,
        removedBy: item.RemovedBy,
        hasBeenNamed: item.HasBeenNamed
      };
      messageRenderList.push(messageDetail);
    }
  };

  function sortMessageListForRender(messageListForRender: messageDetails[]) {
    let arrayForSort = [...messageListForRender];
    arrayForSort.sort(compare);
    messageListForRender = arrayForSort;

    const NonUrgentMessageList = messageListForRender.filter(
      (lists) =>
        lists.isRead ||
        user.userName.toLowerCase() === lists.authorId.toLowerCase() ||
        (!lists.isUrgent && !lists.isRead)
    );
    const urgentMessageList = messageListForRender.filter(
      (element) => element.isUrgent && user.userName.toLowerCase() !== element.authorId.toLowerCase() && !element.isRead
    );
    Array.prototype.push.apply(urgentMessageList, NonUrgentMessageList);
    messageListForRender = urgentMessageList;

    return messageListForRender;
  }

  function getCurrentSystemMessage(messages: Message[]): Message {
    return messages && messages.length > 0 && messages.reduce((a, b) => (a.CreatedAt > b.CreatedAt ? a : b), messages[0]);
  }

  // To filter system messages for the removed users (when has same time) to show releavant messages to the user.
  function handleSysMessagesToRemovedUsers(messages: Message[]): Message[] {
    let filteredMessages: Message[] = [];
    const removedSysMessages = messages.filter(item => item.IsSystemMessage === true && item.Content.includes("removed"));
    const addedSysMessages = messages.filter(item => item.IsSystemMessage === true && item.Content.includes("added") && item.Content.includes(user.userName));
    const currentUserSysMsgs = removedSysMessages.filter(item => item.Content.includes(user.userName));
    const latestRemoved = getCurrentSystemMessage(currentUserSysMsgs);
    const latestAdded = getCurrentSystemMessage(addedSysMessages);
    if (latestRemoved) {
      // Filter the removed system messages by CreatedAt and Content.
      // If removed user again added he should get all the messages.
      if (!latestAdded || latestAdded.CreatedAt < latestRemoved.CreatedAt)
        filteredMessages = messages.filter(item => item.CreatedAt == latestRemoved.CreatedAt && item.Content.includes("removed") && !item.Content.includes(user.userName));
    }
    const messagesToDeleteSet = new Set(filteredMessages);
    if (filteredMessages.length > 0) {
      messages = messages.filter((message) => {
        // return those elements not in the messagesToDeleteSet
        return !messagesToDeleteSet.has(message);
      });
    }

    return messages;
  }

  function pushToMessageRenderList(messages: Message[]) {
    messages = handleSysMessagesToRemovedUsers(messages);
    updateMessagelist();

    // To sort messages by CreatedAt
    let sortMessages = [...messages];
    sortMessages.sort(compareCreatedAt);

    // To remove duplicate messages
    messages = sortMessages.filter((v, i, a) => a.findIndex(v2 => (v2.CreatedAt_MessageId === v.CreatedAt_MessageId)) === i);

    props.userConversations.forEach((item) => {
      let filteredMessages = messages.find((i2) => i2.ConversationId === item.ConversationId);
      if (filteredMessages)
        processUnrepliedMessages({ ...item, ...filteredMessages });
    });

    setMessageList(sortMessageListForRender(messageRenderList));

    if (messageRenderList.length > 0) {
      setIsMessageLoaded(true);
    } else {
      setIsMessageLoaded(false);
    }
  }

  React.useEffect(() => {
    pushToMessageRenderList(props.messageThread);
  }, [props.messageThread]);

  React.useEffect(() => {
    localHasMessages = false;
    completeLoad = true;
    setIsLoading(false);
  }, [props.userConversations]);

  React.useEffect(() => {
    localHasMessages = isMessageLoaded;
  }, [isMessageLoaded]);

  React.useEffect(() => {
    if (reOrder === true) {
      let newList = messageList as messageDetails[];
      setMessageList(sortMessageListForRender(newList));
      setReOrder(false);
    }
  }, [reOrder]);

  return (
    <>      
      {isLoading ? (
        <div className={style.spinner}>
          <ProgressSpinner text="Loading messages..." />
        </div>
      ) : (
        <div />
      )}
      {isMessageLoaded ? (
        <ListItemComponent
          key={props.rerenderCount}
          previewList={messageList as messageDetails[]}
          setSendMessageState={props.setSendMessageState}
          messageReadCallback={props.messageReadCallback}
          refConversationId={props.refConversationId}
          selectedUserName={props.selectedUserName}
          isGroup={props.isGroup}
          setIsRemoved={props.setIsRemoved}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default React.memo(MessagePreview);