import React from "react";
import style from "../styles/SystemMessage.module.scss";


export interface ISystemMessageProps {
    title: string;
    time: string;
}

const SystemMessage: React.FC<ISystemMessageProps> = (props: ISystemMessageProps) => {


    return (
        <div className={style.container}>
            <div className={style.systemTitle}>{props.title}</div>
            <div className={style.leftContnet}>
                <div className={style.circlePadding}>
                    <div className={style.circle} />
                </div>
                <div className={style.systemTime}>{props.time}</div>
            </div>
        </div>
    );
};

export default React.memo(SystemMessage);
