import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import React, { useEffect, useState } from "react";
import { useFFClient, withFFProvider } from "@emisgroup/acp-utility-feature-flags";

/**
 * Wrapper component to provider LaunchDarkly feature flags in the application.
 *
 * @param props the child components.
 * @returns React.ReactElement
 */
const LaunchDarklyProvider: React.FC<any> = (props: any): React.ReactElement => {
  const { children } = props;
  const [ready, setReady] = useState(false);

  const ldClient = useFFClient();

  useEffect(() => {
    const initializeLDClient = async () => {
      if (ldClient) {
        setReady(true);
      }
    };
    initializeLDClient().catch((err: any) => {
      console.error(err);
    });
  }, [ldClient]);

  if (!ready) {
    return (
      <div data-testid="app-loading-progress-spinner">
        <ProgressSpinner />
      </div>
    );
  }

  return <>{children}</>;
};

export default withFFProvider({})(LaunchDarklyProvider) as React.FC<any>;