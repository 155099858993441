/**
 * This class is to handle the CREATE_MESSAGE which we are receiving from notification subscription
 * and we receive only system messages from this subscription. 
 */
import { User } from "../../../utils/user";
import { useApolloClient } from "@apollo/client";
import { generateClient } from 'aws-amplify/api';
import * as subscriptions from "../../../graphql/subscriptions";
import { Message } from "../../../types/messages";
import { CacheUtil } from "../utils/Cache";
import { NotificationType } from "../../../types/notification";
import * as fragments from "../../../graphql/fragments";
import { IAppContextInterface } from "../../../models/IAppContextInterface";
import { AppContext } from "../../../utils/ApplicationContext";
import React from "react";
import { MessageReadStatusNotification } from "../../../types/messageReadStatus";

export class Notification {

    user: User;

    apolloClient = useApolloClient();
    cacheUtil: CacheUtil;

    constructor() {
        this.cacheUtil = new CacheUtil();
    }
    appContext: IAppContextInterface = React.useContext(AppContext);
    client = generateClient();
    subscribe(token: string, getConversation: any): any {

        this.user = new User(token);
        const filter = `${this.user.organisationId}_${this.user.userName}`;
        const notificationReq = { Filter: filter };
        return this.client.graphql({
            query: subscriptions.NotificationV2Mutation,
            variables: notificationReq,
            authToken: token.trim()
        }).subscribe({
            next: (value) => {
                if (!value?.data.subscribeToNotificationV2) return;

                //Handle system message subscription. 
                if (Number(value.data["subscribeToNotificationV2"].Type) == NotificationType.CREATE_MESSAGE) {
                    const message: Message[] = JSON.parse(value.data["subscribeToNotificationV2"].Data) as Message[];
                    message.forEach(message => this.cacheUtil.appendMessageInCache(message, getConversation, token.trim(), this.user));
                    this.apolloClient.cache.updateFragment({
                        id: `ConversationStore:${message[0].ConversationId}`,
                        fragment: fragments.getConversationStore,
                    }, (data) => {
                        if (data)
                            return { ...data, Action: "COMPLETED" };
                    });
                }
                if (Number(value.data["subscribeToNotificationV2"].Type) ===  (NotificationType.MESSAGE_READ_STATUS)) {
                    //MessageReadStatusNotification from the subscription
                    const readStatus: MessageReadStatusNotification[] = JSON.parse(value.data["subscribeToNotificationV2"].Data) as MessageReadStatusNotification[];
                    readStatus.forEach(message => this.cacheUtil.updateMessageStatusInCache(message));
                }

            },
            error: (error) => {
                this.appContext.setRefreshSubscriptions({});
                console.error("Notification subscription: " + error)
            }
        });
    }

}