import { User as UserClass } from "../../../utils/user";
import { User as UserType } from "../../../types/user";
import { generateClient } from 'aws-amplify/data';
import * as subscriptions from "../../../graphql/subscriptions";
import { CacheUtil } from "../utils/Cache";
import { IAppContextInterface } from "../../../models/IAppContextInterface";
import { AppContext } from "../../../utils/ApplicationContext";
import React from "react";


export class User {
    user: UserClass;
    cacheUtil: CacheUtil;
    constructor() {
        this.cacheUtil = new CacheUtil();
    }
    appContext: IAppContextInterface = React.useContext(AppContext);
    client = generateClient();
    subscribe(token: string): any {
        this.user = new UserClass(token);
        return this.client.graphql({
            query: subscriptions.UserStatus,
            variables: { OrganisationId: this.user.organisationId },
            authToken: token.trim()}).subscribe({
                next: ({ data }) => {
                    if (!data?.subscribeToUser) return;
                    const updatedUserInfo = data.subscribeToUser as UserType;

                    //Update or add user on the cache
                    const userData = this.cacheUtil.readUser(this.user.organisationId);
                    if (userData.some(x => x.UserName == updatedUserInfo.UserName)) {
                        let updateData: UserType[] = [];
                        for (const item of userData) {
                            //Update only user status
                            if (item.UserName == updatedUserInfo.UserName) {
                                updateData.push({ ...item, UserStatus: updatedUserInfo.UserStatus });
                            } else {
                                updateData.push(item);
                            }
                        }
                        this.cacheUtil.writeUser(this.user.organisationId, updateData);
                    }
                },
                error: (error) => {
                    this.appContext.setRefreshSubscriptions({});
                    console.error("User status subscription: " + error)
                }
            });
    }
}