import React, { useEffect, useRef, useState } from "react";
import { ISendMessageStateProps } from "./SendMessage";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { useLazyQuery } from "@apollo/client";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { Logger } from "@emisgroup/logging-sdk-typescript";

//style
import commonStyle from "../styles/Common.module.scss";
import { GET_USERS } from "../graphql/queries";
import { User as Users } from "../types/user";
import { User } from "../utils/user";
import MessagePreview from "./MessagePreview";
import { getUserDisplayName } from "../utils";

export interface IPreviewPanelUserUpdatesProps {
  memoizedSetSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  refConversationId: string;
  selectedUserName: string;
  queryConversation: any;
  loadedMessageList: any[];
  messageReadCallback: Function;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}

//#endregion

const PreviewPanelUserUpdates: React.FC<IPreviewPanelUserUpdatesProps> = (props: IPreviewPanelUserUpdatesProps) => {

  const [getUsers, { data: userList }] = useLazyQuery(GET_USERS, {
    fetchPolicy: "cache-first"
  });
  const refUsers = useRef<Partial<Users[]>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedCount, setLoadedCount] = useState(0);
  Logger.debug("Creating PreviewPanelUserUpdates component");

  const appContext: IAppContextInterface = React.useContext(AppContext);
  let user = new User(appContext.userToken);



  useEffect(() => {
    //only at first time render call get users
    getUsers({ variables: { OrganisationId: user.organisationId } }).catch(err => { console.error(err) });
  }, []);

  useEffect(() => {
    if (userList?.["getUserV2"]) {
      if (userList["getUserV2"].length > 0) {
        let listItem: Users[] = [];
        userList["getUserV2"].forEach((element) => {
          const newItem: Users = {
            UserName: element.UserName,
            DisplayName: getUserDisplayName(element),
            OrganisationId: element.OrganisationId,
            UserStatus: element.UserStatus,
            IsEmailVerified: true
          };
          listItem.push(newItem);
        });

        refUsers.current = [...listItem];
        setIsLoading(false);
        setLoadedCount((prev) => {
          return prev + 1;
        });
      } else {
        props.setIsError(true);
      }
    }
  }, [userList]);


  //#endregion
  //#region Panels and views
  if (!isLoading && refUsers.current.length > 0) {
    return (
      <div key={"MessagePreview"} className={commonStyle.leftPanel}>
        <MessagePreview
          key={loadedCount}
          rerenderCount={0}
          messageReadCallback={props.messageReadCallback}
          userConversations={props.queryConversation}
          messageThread={props.loadedMessageList}
          users={refUsers}
          setSendMessageState={props.memoizedSetSendMessageState}
          refConversationId={props.refConversationId}
          selectedUserName={props.selectedUserName}
          isGroup={props.isGroup}
          setIsRemoved={props.setIsRemoved}
        />
      </div>
    );
  } else {
    return (
      <div className={commonStyle.leftPanel}>
        <div className={commonStyle.spinner}>
          <ProgressSpinner text="Loading messages..." />
        </div>
      </div>
    );
  }
};

export default PreviewPanelUserUpdates;