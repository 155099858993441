/**
 * This class is the manage the new message subscription. 
 */
import { User } from "../../../utils/user";
import { generateClient } from 'aws-amplify/api';
import * as subscriptions from "../../../graphql/subscriptions";
import { Message as MessageType } from "../../../types/messages";
import { CacheUtil } from "../utils/Cache";
import { IAppContextInterface } from "../../../models/IAppContextInterface";
import { AppContext } from "../../../utils/ApplicationContext";
import React from "react";
const client = generateClient();
export class Message {
    user: User;
    cacheUtil: CacheUtil;
    constructor() {
        this.cacheUtil = new CacheUtil();
    }
    appContext: IAppContextInterface = React.useContext(AppContext);

    subscribe(token: string, getConversation: any): any {
        this.user = new User(token);
        const filter = `${this.user.organisationId}_${this.user.userName}`;
        const newMessageSubVariables = { Filter: filter };
        return client.graphql({
            query: subscriptions.NewMessage,
            variables: newMessageSubVariables,
            authToken: token.trim()
        }).subscribe({
            next: (value) => {
                if (value?.data["onCreateMessage"]) {
                    const newMessageThread = value.data["onCreateMessage"];
                    const item = newMessageThread as MessageType;
                    this.cacheUtil.appendMessageInCache(item, getConversation, token.trim(), this.user);
                }
            },
            error: (error) => {
                this.appContext.setRefreshSubscriptions({});
                console.error("New message subscription: " + error)
            }
        });
    }
}   