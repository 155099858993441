import style from "../../styles/MultipleContact.module.scss";
import { withTopbar } from '../hoc/withTopbar';
import { IUser } from "../../utils/user";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import MemberSection from "./MemberSection";
import MemberFooter from "./MemberFooter";
import { GET_LOCAL_CONVERSATION } from "./schema";
import { useQuery, useApolloClient } from "@apollo/client";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";

import { Participant } from "../../types/messages";
import { GET_CONVERSATION_BY_ID } from "../../graphql/queries";
import { getParticipants } from "../../utils/GetParticipants";
import LightBoxComponent from "../LightboxComponent";
import GroupContacts from "../GroupContacts";

export interface ISelectMultipleContactProps extends IUser {
}




export enum SelectType {
    ADD,
    EDIT
}


export const LocalConversationId = "local-converation";
const EditMultipleContact: React.FC<ISelectMultipleContactProps> = (props: ISelectMultipleContactProps) => {
    const { userName, organisationId } = props;
    const { conversationId } = useParams();
    const [isGroupSaving, setIsGroupSaving] = useState(false);
    const refConversationId = useRef(conversationId || "");
    const [displayName, setDisplayName] = useState("");
    const [recipientId, setRecipientId] = useState("");
    const [isGroupMessage] = useState(true);
    const [selectedCount, setSelectedCount] = useState(0);
    const apolloClient = useApolloClient();
    const refIsCreated = useRef<boolean>(false);
    const [groupName, setGroupName] = useState("");
    const [oldParticipant, setOldParticipant] = useState<Participant[]>([]);
    const [isnewMemberSelected, setIsnewMemberSelected] = useState(false);

    const { data: getConversation, loading } = useQuery(
        GET_CONVERSATION_BY_ID,
        {
            variables: {
                ConversationId: conversationId,
                UserName: userName
            },
            fetchPolicy: "network-only"
        }
    );
    let oldConversation = [];
    let newConversation;

    // If ConversationId not exists 
    useEffect(() => {

        if (getConversation != undefined) {
            oldConversation = getConversation["getConversationByIdV2"];
             //Note: WHEN NEW API SCHEMA AVAILABLE/NEW RESOLVER getConversationByUser
            // CHANGES: USE OrgnisationERN, UserERN in ParticipantList.
            const participants = getParticipants(oldConversation[0].Participants, oldConversation[0].ParticipantList);
            setOldParticipant(participants);
            setGroupName(oldConversation[0].HasBeenNamed ? oldConversation[0].ConversationName : "");

            newConversation = {
                __typename: "GroupConversationV2",
                ConversationId: refConversationId.current,
                LastUpdatedOn: "",
                ConversationName: oldConversation[0].ConversationName,
                IsDeleted: "",
                DeletedOn: "",
                OrganisationId: organisationId,
                UserName: userName,
                Participants: participants,
                ParticipantList: oldConversation[0].ParticipantList,
                IsLocal: true,
                IsRemoved: false,
                RemovedOn:"",
                HasUrgent: false,
                UnreadCount: 0,
                IsGroupConversation: true,
                HasBeenNamed: oldConversation[0].HasBeenNamed,
                AddedToConversationOn: new Date().getTime().toString(),
                IsConversationHistoryShared: true
            }

            // Write to the cache if there is no conersation Id
            apolloClient.cache.writeQuery({
                query: GET_LOCAL_CONVERSATION,
                data: {
                    getLocalConversation: newConversation
                },
                variables: {
                    ConversationId: refConversationId.current
                }
            });

            //remove orphan data
            apolloClient.cache.gc();
        }

        return () => {
            if (!refIsCreated.current)
                clearlocalCache()

        }
    }, [getConversation]);

    function clearlocalCache() {
        const normalizedId = apolloClient.cache.identify(newConversation);
        return apolloClient.cache.evict({ id: normalizedId });
    }

    if (loading) {
        return (
            <div>
                <ProgressSpinner text="Loading contacts..." />
            </div>
        );
    }


    //pass ConversationId, UserName, OrgId
    return (
        <div className={style.app}>
            <LightBoxComponent text="Saving group changes" isGroupUpdated={isGroupSaving} />
            <div className={style.container}>
                <div className={style.contactListItem}>
                    <GroupContacts
                        debug={false}
                        organisationId={organisationId}
                        displayName={displayName}
                        setUserName={setDisplayName}
                        recipientId={recipientId}
                        setRecipientId={setRecipientId}
                        userName={userName}
                        isGroupMessage={isGroupMessage}
                        setSelectedCount={setSelectedCount}
                        selectedCount={selectedCount}
                        conversationId={refConversationId.current}
                        isNewMemberAdded={isnewMemberSelected}
                    />
                </div >
                <div className={style.memberRightSide}>
                    <div className={style.memberSection}>
                        <MemberSection organisationId={organisationId} userName={userName} conversationId={refConversationId.current} isNewMemberSelected={isnewMemberSelected} />
                    </div>
                    <div className={style.memberFooter}>
                        <MemberFooter organisationId={organisationId} userName={userName} conversationId={refConversationId.current} refIsCreated={refIsCreated} type={SelectType.EDIT} groupName={groupName} oldParticipants={oldParticipant} setIsnewMemberSelected={setIsnewMemberSelected} setIsgroupSaving={setIsGroupSaving} />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default withTopbar(EditMultipleContact, false, "Edit group");
