// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r8XJKlMHX2nhXnvix0i9{position:relative;justify-content:center;align-items:center;background-color:var(--background);width:100%}`, "",{"version":3,"sources":["webpack://./src/styles/ContactList.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,sBAAA,CACA,kBAAA,CACA,kCAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts": `r8XJKlMHX2nhXnvix0i9`
};
export default ___CSS_LOADER_EXPORT___;
