import { TitleArea, TitleBar } from "@emisgroup/ui-title-bar";
import MoreVert from "~icons/ic/outline-more-vert";
import UserGroup from "~icons/ic/outline-groups";
import { useNavigate } from "react-router-dom";
import { Button } from "@emisgroup/ui-button";
import React, { useState, useEffect, useRef } from "react";
import commonStyle from "../styles/Common.module.scss";
import { DropdownMenu } from "@emisgroup/ui-dropdown-menu";
import { leaveConversationFilter } from "../utils/ConversationUtils";
import { CREATE_CONVERSATION } from "./MultipleContact/schema";
import { ApolloError, useMutation, useLazyQuery, useQuery, useApolloClient } from "@apollo/client";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { UserAvatar } from "@emisgroup/ui-avatar";
import { GET_CONVERSATION_BY_ID, GET_USERS, CONVERSATION_STORE } from "../graphql/queries";
import { updateCache } from "../utils/MessageComponent";
import { getParticipants } from "../utils/GetParticipants";
import EditIcon from "~icons/ic/outline-edit";
import LogoutIcon from "~icons/ic/outline-logout";
export interface ITitlePanel {
  displayTitle: string;
  isGroupChat: boolean;
  conversationId: string;
  refOldConversationId: React.MutableRefObject<string>;
  organisationId: string;
  userName: string;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
  setLeaveError: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readMessageCallBack: Function;
}

function useOutsideAlerter(ref, setDropdownMenuOpen) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownMenuOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function CheckPathArgs(refOldConversationId: string, conversationId: string) {
  const oldConversationId = refOldConversationId || "";
  const pathArgs = conversationId || oldConversationId;
  return pathArgs;
}

function checkUserDetails(user) {
  return user?.["getUserV2"] && user["getUserV2"].length > 0;
}

function checkLeaveStatus(leaveRequested: boolean, userConversationData: any) {
  return (
    leaveRequested &&
    userConversationData?.["getConversationByIdV2"] &&
    userConversationData["getConversationByIdV2"].length > 0
  );
}

function checkConversationStore(conversationStore: any) {
  return !conversationStore || conversationStore["conversationDetails"].Action == "COMPLETED";
}

const TitlePanel: React.FC<ITitlePanel> = (props: ITitlePanel) => {
  const { displayTitle, isGroupChat } = props;
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);

  const leaveRequested = useRef(false);
  const dialogPanelRequested = useRef(false);

  const nav = useNavigate();
  const appContext: IAppContextInterface = React.useContext(AppContext);
  const handleLeaveError = (err: ApolloError) => {
    console.error(`Error  : ${JSON.stringify(err.message)}`);
    props.setLeaveError(true);
  };

  const { data: user } = useQuery(GET_USERS, {
    variables: { OrganisationId: props.organisationId },
    fetchPolicy: "cache-first"
  });

  useEffect(() => {
    readUsers(user, appContext);
  }, [user]);

  const [createConversation] = useMutation(CREATE_CONVERSATION);
  const client = useApolloClient();

  const [getConversationData, { data: userConversationData, updateQuery: updateConversationCache }] = useLazyQuery(
    GET_CONVERSATION_BY_ID,
    {
      variables: {
        ConversationId: CheckPathArgs(props.refOldConversationId.current, props.conversationId),
        UserName: props.userName
      },
      fetchPolicy: "network-only",
      onError: handleLeaveError,
      onCompleted: () => {
        const participants = getParticipants(
          userConversationData["getConversationByIdV2"][0].Participants,
          userConversationData["getConversationByIdV2"][0].ParticipantList
        );
        appContext.currentParticipants = participants;
        updateDialogPanelRequested(dialogPanelRequested, props.setDialogOpen);
      }
    }
  );

  useEffect(() => {
    if (userConversationData) {
      const participants = getParticipants(
        userConversationData["getConversationByIdV2"][0].Participants,
        userConversationData["getConversationByIdV2"][0].ParticipantList
      );
      appContext.currentParticipants = participants;
      updateDialogPanelRequested(dialogPanelRequested, props.setDialogOpen);
    }
  }, [userConversationData]);

  const ref = useRef();

  useOutsideAlerter(ref, setDropdownMenuOpen);

  function groupnavPath() {
    let pathArgs = CheckPathArgs(props.refOldConversationId.current, props.conversationId);
    nav("/staff/group-contact-list/" + pathArgs);
  }

  function handleDialog() {
    dialogPanelRequested.current = true;
    getConversationData().catch((err) => { console.error(err); });
  }

  useEffect(() => {
    if (appContext.leaveGroup) {
      leaveConversation();
      appContext.setLeaveGroup(false);
      appContext.setLeaveGroupShowWarning(false);
    }
  }, [appContext.leaveGroup]);

  function leaveConversation() {
    // Get current conversation data and trigger effect to leave conversation
    if (CheckPathArgs(props.refOldConversationId.current, props.conversationId) != "") {
      leaveRequested.current = true;
      getConversationData().catch((err) => { console.error(err); });
      updateCache(client.cache, true);
    }
  }

  useEffect(() => {
    // Get current conversation data and trigger effect to leave conversation
    if (checkLeaveStatus(leaveRequested.current, userConversationData)) {
      const userConversation = userConversationData["getConversationByIdV2"][0];
      const updatedParticipants = leaveConversationFilter(userConversation, userConversation.UserName);
      leaveRequested.current = false;
      if (updatedParticipants.length > 0) {
        props.readMessageCallBack(userConversation.ConversationId.valueOf(), props.userName, props.organisationId);
        updateConversation(
          props,
          appContext,
          createConversation,
          userConversation,
          updatedParticipants,
          handleLeaveError,
          updateConversationCache
        );
      } else {
        props.setLeaveError(() => true);
      }
      // Close Menu
      setDropdownMenuOpen(false);
    }
  }, [userConversationData]);

  function checkIsNewGroup() {
    if (CheckPathArgs(props.refOldConversationId.current, props.conversationId)) handleDialog();
  }

  function CheckMenuClick() {
    const conversationStore = client.cache.readQuery({
      query: CONVERSATION_STORE,
      variables: {
        ConversationId: CheckPathArgs(props.refOldConversationId.current, props.conversationId)
      }
    });
    if (
      CheckPathArgs(props.refOldConversationId.current, props.conversationId) &&
      checkConversationStore(conversationStore)
    ) {
      setDropdownMenuOpen(!dropdownMenuOpen);
    }
  }

  return isGroupChat ? (
    <div ref={ref}>
      <TitleBar className={commonStyle.titleBarAlign}>
        <TitleArea backButton={false} data-testid={"title_without_button"} id={"header_button_title"}>
          <Button
            className={commonStyle.titleBarButton}
            borderless={true}
            variant="mono"
            role={"group-icon"}
            onClick={() => checkIsNewGroup()}
          >
            <UserAvatar color="featured" size="x-small" tooltip="">
              <UserGroup color="mono" size="small" title="" />
            </UserAvatar>
          </Button>
          <div
            role="title-button"
            className={commonStyle.titleOverflow}
            data-testId={"title-button-large"}
            onClick={() => checkIsNewGroup()}
          >
            <div className={commonStyle.title}> {displayTitle}</div>
          </div>
        </TitleArea>
        <Button role="button-large" borderless={true} variant="mono" onClick={() => CheckMenuClick()}>
          <MoreVert color="mono" size="medium" data-testid="system" title={""} />
        </Button>
      </TitleBar>
      {dropdownMenuOpen && (
        <div className={commonStyle.dropDownMenu}>
          <DropdownMenu
            data={[
              {
                action: () => groupnavPath(),
                children: <><EditIcon size="small" className={commonStyle.editIcon} title="" /><span className={commonStyle.menu}>Edit group</span></>
              },
              {
                action: () => { appContext.setLeaveGroupShowWarning(true) },
                children: <><LogoutIcon size="small" className={commonStyle.editIcon} title="" /><span className={commonStyle.menu}>Leave group</span></>
              }
            ]}
          />
        </div>
      )}
    </div>
  ) : (
    <TitleBar className={commonStyle.titleBarAlign}>
      <TitleArea backButton={false} data-testid={"title_without_button"} id={"header_button_title"}>
        <div className={commonStyle.titleAlign}>
          <div className={commonStyle.title}> {displayTitle}</div>
        </div>
      </TitleArea>
    </TitleBar>
  );
};

function readUsers(user: any, appContext: IAppContextInterface) {
  if (checkUserDetails(user)) {
    appContext.userList = user["getUserV2"];
  }
}

function updateConversation(
  props: ITitlePanel,
  appContext: IAppContextInterface,
  createConversation,
  userConversation: any,
  updatedParticipants: { UserName: string; OrganisationId: string }[],
  handleLeaveError: (err: ApolloError) => void,
  updateConversationCache
) {
  for (const sub of appContext.subscriptions) {
    if (sub.ConversationId === CheckPathArgs(props.refOldConversationId.current, props.conversationId))
      Promise.resolve(sub.Subscription.unsubscribe()).catch((err) => { console.error(err); });
  }
  createConversation({
    variables: {
      ConversationId: userConversation.ConversationId.valueOf(),
      OrganisationId: props.organisationId,
      UserName: userConversation.UserName,
      ConversationName: userConversation.HasBeenNamed ? userConversation.ConversationName : "",
      Participants: updatedParticipants,
      IsGroupConversation: userConversation.IsGroupConversation,
      IsConversationHistoryShared: userConversation.IsConversationHistoryShared,
      HasBeenNamed: userConversation.HasBeenNamed
    },
    onError: handleLeaveError,
    update() {
      updateConversationCache((prev) => {
        if (prev?.["getConversationByIdV2"]) {
          const participants = getParticipants(
            prev["getConversationByIdV2"][0].Participants,
            prev["getConversationByIdV2"][0].ParticipantList
          );
          return {
            getConversationByIdV2: [
              {
                __typename: "ConversationV2",
                AddedToConversationOn: prev["getConversationByIdV2"][0].AddedToConversationOn,
                ConversationId: prev["getConversationByIdV2"][0].ConversationId,
                ConversationName: prev["getConversationByIdV2"][0].ConversationName,
                HasBeenNamed: prev["getConversationByIdV2"][0].HasBeenNamed,
                DeletedOn: prev["getConversationByIdV2"][0].DeletedOn,
                IsDeleted: prev["getConversationByIdV2"][0].IsDeleted,
                IsRemoved: true,
                LastUpdatedOn: prev["getConversationByIdV2"][0].LastUpdatedOn,
                OrganisationId: prev["getConversationByIdV2"][0].OrganisationId,
                IsGroupConversation: prev["getConversationByIdV2"][0].IsGroupConversation,
                IsConversationHistoryShared: prev["getConversationByIdV2"][0].ConversationId,
                Participants: participants,
                UserName: prev["getConversationByIdV2"][0].UserName,
                ParticipantList: prev["getConversationByIdV2"][0].ParticipantList,
                ParticipantHash: prev["getConversationByIdV2"][0].ParticipantHash,
                RemovedBy: userConversation.UserName
              }
            ]
          };
        }
      });
    },
    OnCompleted: () => {
      props.setIsRemoved(() => { return true; });
    }
  });
}

function updateDialogPanelRequested(
  dialogPanelRequested: React.MutableRefObject<boolean>,
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (dialogPanelRequested.current) {
    dialogPanelRequested.current = false;
    setDialogOpen(() => true);
  }
}

export default TitlePanel;