import { Route, Routes } from "react-router-dom";

import ContactView from "./ContactList";
import LandingPageView from "./LandingPage";
import GroupMessageView from "./GroupMessage";
import CreateGroupMessagePage from "./MultipleContact/SelectMultipleContact";
import EditGroupMessagePage from "./MultipleContact/EditMultipleContact";
import ApproachPageDetails from "./ApproachPage";

import React, { useRef, useState } from "react";
import * as queries from "../graphql/queries";
import { authTokenWithBearer, HubListner } from "../utils";
import { Notification } from "./initialize/subscriptions/notification";
import { Message } from "./initialize/subscriptions/message";
import { User } from "./initialize/subscriptions/user";
import { useLazyQuery } from "@apollo/client";
import { ContextUtils } from "../utils/contextUtils";
import { User as UserClass } from "../utils/user";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";

export interface IBaseComponentProps {
  user: UserClass;
}

export const V2BaseComponent: React.FC<IBaseComponentProps> = (props: IBaseComponentProps) => {
  const notificationSub = new Notification();
  const messageSub = new Message();
  const userSub = new User();
  const subscriptionList = useRef<any[]>([]);
  const hubListner = useRef<any>(undefined);
  const [reloadSubscription, setReloadSubscription] = useState(undefined);

  const refRetryCount = useRef(0);

  const susbcriptionStauts = useRef<string>("");

  //get list of orguser for existing conversation
  const [getConversationByConversationId] = useLazyQuery(queries.GET_CONVERSATION_BY_USER_AND_CONVERSATIONID, {
    fetchPolicy: "network-only"
  });

  //get list of orguser for existing conversation
  const [getConversationDetails] = useLazyQuery(queries.GET_CONVERSATION_INFO, {
    fetchPolicy: "network-only"
  });

  const appContext: IAppContextInterface = React.useContext(AppContext);
  appContext.setRefreshSubscriptions = setReloadSubscription;

  //Amplify dispatch function to reconnect.
  async function subscribeAll() {
    try {
      let token = await ContextUtils.getAccessTokenFromContext();
      const jwt = authTokenWithBearer(token);

      //subscribe to notification.
      const subNotification = notificationSub.subscribe(jwt, getConversationByConversationId, getConversationDetails);
      subscriptionList.current.push(subNotification);

      //subscribe to Messages
      const subMessage = messageSub.subscribe(jwt, getConversationDetails);
      subscriptionList.current.push(subMessage);

      //subscribe to Users
      const subUser = userSub.subscribe(jwt);
      subscriptionList.current.push(subUser);
    } catch (error) {
      console.error(error + props.user.userERN);
    }
  }

  async function subscriptionRecreate() {
    unSubscribeAll();
    //re-try
    await subscribeAll();
  }

  function unSubscribeAll() {
    if (subscriptionList.current.length > 0) {
      for (const subscription of subscriptionList.current) {
        subscription.unsubscribe();
      }
      subscriptionList.current = [];
    }
  }

  function cancelAllHubListner() {
    hubListner.current();
  }

  //initial subcription
  React.useEffect(() => {
    //subscribe all
    subscribeAll();
    hubListner.current = HubListner(subscriptionRecreate, susbcriptionStauts);
    return () => {
      cancelAllHubListner();
      unSubscribeAll();
    };
  }, []);

  //Subscription reload
  React.useEffect(() => {
    if (reloadSubscription && refRetryCount.current <= 5) {
      subscriptionRecreate();
      hubListner.current = HubListner(subscriptionRecreate, susbcriptionStauts);
      refRetryCount.current++;
    }
  }, [reloadSubscription]);

  return (
    <Routes>
      <Route path="/staff">
        <Route index element={<ApproachPageDetails />} />
        <Route path=":conversationId" element={<ApproachPageDetails />} />
        <Route path="landing-page" element={<LandingPageView />} />
        <Route path="contact-list" element={<ContactView />} />
        <Route path="contact-list/:conversationId" element={<ContactView />} />
        <Route path="group-message" element={<GroupMessageView />} />
        <Route path="group-contact-list" element={<CreateGroupMessagePage />} />
        <Route path="group-contact-list/:conversationId" element={<EditGroupMessagePage />} />
      </Route>
    </Routes>
  );
};

export default V2BaseComponent;
