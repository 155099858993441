import { TitleBar, TitleArea } from "@emisgroup/ui-title-bar";
import MoreVert from "~icons/ic/outline-more-vert";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { DropdownMenu } from "@emisgroup/ui-dropdown-menu";
import React, { useState, useRef, useEffect } from "react";
//style
import commonStyle from "../styles/Common.module.scss";
import { useNavigate } from "react-router-dom";
import UserGroup from "~icons/ic/outline-groups";
import { ApolloError, useLazyQuery, useQuery} from "@apollo/client";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { GET_CONVERSATION_INFO, GET_NEW_USERS } from "../graphql/queries";
import { UserAvatar } from "@emisgroup/ui-avatar";
import Edit from "~icons/ic/edit";
import { Alert } from "@emisgroup/ui-alert";
import EditIcon from "~icons/ic/outline-edit";
import LogoutIcon from "~icons/ic/outline-logout";

export interface ILandingPageTopbar {
  isConversationSelected: boolean;
  isGroup: boolean;
  conversationId: string;
  onBackButtonClick: Function;
  displayTitle: string;
  refOldConversationId: React.MutableRefObject<string>;
  organisationId: string;
  userERN: string;
  isRemoved: boolean;
  setLeaveError: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Topbar: React.FC<ILandingPageTopbar> = (props: ILandingPageTopbar) => {
  const nav = useNavigate();
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const ref = useRef();
  const dialogRequested = useRef(false);
  const appContext: IAppContextInterface = React.useContext(AppContext);


  const handleLeaveError = (err: ApolloError) => {
    console.error(`Error  : ${JSON.stringify(err.message)}`);
    props.setLeaveError(() => true);
  };

  //Get user details from cache  
  const { data: userList } = useQuery(GET_NEW_USERS, {
    variables: { OrganisationERN: props.organisationId },
    fetchPolicy: "cache-first"
  });

  useEffect(() => {
    if (userList?.['getUser']?.length > 0) {
      appContext.userList = userList['getUser'];
    }
  }, [userList]);


  function checkConversationId() {
    let convId = CheckPathArgs(props)
    return convId;
  }


  const [getConversationData, { data: conversationData }] = useLazyQuery(GET_CONVERSATION_INFO, {
    fetchPolicy: "network-only",
    onError: handleLeaveError,
    onCompleted: () => {
      checkConversationDataAndTriggerDialog();
    }
  });

  function checkConversationDataAndTriggerDialog() {
    if (conversationData) {
      const participants = conversationData["getConversationByIds"][0].ParticipantList;
      appContext.currentParticipants = participants;
      if (dialogRequested.current) {
        dialogRequested.current = false;
        props.setDialogOpen(() => true);
      }
    }
  }

  useEffect(() => {
    checkConversationDataAndTriggerDialog();
  }, [conversationData]);

  const disabledTitle = props.isRemoved ? commonStyle.disabledSnippet : commonStyle.titleButton;

  function navPath() {
    const pathArgs = props.conversationId ? props.conversationId : "";
    return "/staff/contact-list/" + pathArgs;
  }

  function setNav() {
    nav(navPath());
  }


  function groupnavPath() {
    let pathArgs = CheckPathArgs(props);
    nav("/staff/group-contact-list/" + pathArgs);
  }


  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownMenu(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(ref);


  function handleDialog() {
    const conversationId = props.conversationId ? props.conversationId : props.refOldConversationId.current;
    if (conversationId) {
      dialogRequested.current = true;
      getConversationData(
        {
          variables: {
            ConversationIds: [checkConversationId()]
          }
        }
      );
    }
  }

  function onTopbarBackButtonClick() {
    props.onBackButtonClick()
  }

  function checkDropDownStatus() {
    if (CheckPathArgs(props)) {
      setDropdownMenu(!dropdownMenu)
    }
  }

  return (
    <>
      {/* With back button : small & and conversation selected*/}
      <div ref={ref} className={!props.isConversationSelected ? commonStyle.withBackButton : commonStyle.secondTopbar}>

        {props.isGroup ? (
          <div className={commonStyle.checkOverflow}>
            <TitleBar data-testid="message" className={commonStyle.titleBarAlign}>
              <TitleArea
                backButton={true}
                onClick={onTopbarBackButtonClick}
                data-testid={"title_with_button"}
                id={"header_title"}
                className={commonStyle.titleBarAlign}
              >
                <Button className={disabledTitle} borderless={true} variant="mono" role={"group-icon"} onClick={() => handleDialog()}>
                  <UserAvatar color="featured" size="x-small" tooltip="">
                    <UserGroup color="mono" size="small" title="" />
                  </UserAvatar>
                </Button>
                <div className={disabledTitle} data-testid={"title-button-narrow"} onClick={() => handleDialog()}>
                  <div className={commonStyle.title}> {props.displayTitle}</div>
                </div>
              </TitleArea>
              <Button
                className={disabledTitle}
                role="button-narrow"
                borderless={true}
                variant="mono"
                onClick={() => checkDropDownStatus()}
              >
                <MoreVert color="mono" size="medium" data-testid="system" title="" />
              </Button>
            </TitleBar>
            {dropdownMenu && (
              <div className={commonStyle.dropDownMenu}>
                <DropdownMenu
                  data={[
                    {
                      action: () => groupnavPath(),
                      children: <><EditIcon size="small" className={commonStyle.editIcon} title="" /><span className={commonStyle.menu}>Edit group</span></>
                    },
                    {
                      action: () => { appContext.setLeaveGroupShowWarning(true) },
                      children: <><LogoutIcon size="small" className={commonStyle.editIcon} title="" /><span className={commonStyle.menu}>Leave group</span></>
                    }
                  ]}
                />
              </div>
            )}
          </div>
        ) : (
          <TitleBar data-testid="message" className={commonStyle.titleBarAlign}>
            <TitleArea
              backButton={true}
              onClick={onTopbarBackButtonClick}
              data-testid={"title_with_button"}
              id={"header_title"}
              className={commonStyle.titleBarAlign}
            >
              <div className={commonStyle.title}> {props.displayTitle}</div>
            </TitleArea>
          </TitleBar>
        )}
      </div>

      {/* Without back button */}
      <div className={props.isConversationSelected ? commonStyle.withoutBackButton : ""}>
        <TitleBar data-testid="message" className={commonStyle.titleBarAlign}>
          <TitleArea backButton={false}>
            <div className={commonStyle.titlePadding}>
              <div className={commonStyle.title}>{"Messages"}</div>
            </div>
          </TitleArea>
          <Button borderless={false} onClick={setNav}>
            <Edit title="" />
            Compose
          </Button>
        </TitleBar>
      </div>

      {appContext.leaveGroupShowWarning && <Alert
        type="warning"
        title="Leave group?"
        buttons={<ButtonGroup>
          <Button borderless={true} onClick={() => appContext.setLeaveGroupShowWarning(false)} >Cancel</Button>
          <Button variant="warning" onClick={() => appContext.setLeaveGroup(true)} >Leave</Button>
        </ButtonGroup>}
        onOpenChange={(e: boolean) => { appContext.setLeaveGroupShowWarning(e) }}
        open={appContext.leaveGroupShowWarning}

      >
        <p>You'll still have access to the conversation history, but you won't receive any new messages from this group.</p>
        <br />
        <p>If you change your mind, ask a group member to add you back.</p>
      </Alert>
      }
    </>
  );
};

export default Topbar;

function CheckPathArgs(props: ILandingPageTopbar) {
  const oldConversationId = props.refOldConversationId ? props.refOldConversationId.current : "";
  const pathArgs = props.conversationId ? props.conversationId : oldConversationId;
  return pathArgs;
}
