import { Logger } from "@emisgroup/logging-sdk-typescript";

import React from "react";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { User } from "../utils/user";

export interface IAddUpdateUser {
  existingUser?: User;
}

let lastCreatedUser: User = null;

export const AddUpdateUser: React.FC<IAddUpdateUser> = (props: IAddUpdateUser) => {
  const { existingUser } = props;

  const appContext: IAppContextInterface = React.useContext(AppContext);

  let user: User;

  if (process.env.ONBOARDING == "false") {
    try {
      if (existingUser) {
        user = existingUser;
      } else {
        user = new User(appContext.userToken);
      }

      if (JSON.stringify(user) !== JSON.stringify(lastCreatedUser)) {
        user.createUpdateUser();
        lastCreatedUser = user;
      }
    } catch (error) {
      Logger.error(error.message);
    }
  }
  return <div />;
};

export default React.memo(AddUpdateUser);
