import style from "../../styles/MultipleContact.module.scss";
import { withTopbar } from '../hoc/withTopbar';
import { IUser } from "../../utils/user";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import MemberSection from "./MemberSection";
import MemberFooter from "./MemberFooter";
import { GET_LOCAL_CONVERSATION } from "./schema";
import { useApolloClient } from "@apollo/client";

import { getParticipantList } from "../../utils/GetParticipants";
import GroupContacts from "../GroupContacts";
export interface ISelectMultipleContactProps extends IUser {
}

export enum SelectType {
  ADD,
  EDIT
}


export const LocalConversationId = "local-converation";
const SelectMultipleContact: React.FC<ISelectMultipleContactProps> = (props: ISelectMultipleContactProps) => {
  const { userName, organisationId } = props;
  const { conversationId } = useParams();
  const conversationIdRef = useRef(conversationId || "");
  const [displayName, setDisplayName] = useState("");
  const [recipientId, setRecipientId] = useState("");
  const [isGroupMessage] = useState(true);
  const [selectedCount, setSelectedCount] = useState(0);
  const apolloClient = useApolloClient();
  const refIsCreated = useRef<boolean>(false);

  let newConversation;



  // If ConversationId not exists 
  useEffect(() => {

    if (!conversationIdRef.current) {
      const currentMember = {
        OrganisationId: organisationId,
        UserName: userName,
      };
      conversationIdRef.current = LocalConversationId;
      newConversation = {
        __typename: "GroupConversationV2",
        ConversationId: conversationIdRef.current,
        LastUpdatedOn: "",
        ConversationName: "",
        IsDeleted: "",
        DeletedOn: "",
        OrganisationId: organisationId,
        UserName: userName,
        IsRemoved: false,
        RemovedOn: "",
        Participants: [currentMember],
        HasUrgent: false,        
        UnreadCount: 0,
        ParticipantList: getParticipantList([currentMember]),
        IsLocal: true,
        IsGroupConversation: true,
        HasBeenNamed: false,
        AddedToConversationOn: "",
        IsConversationHistoryShared: true
      };

    }
    // Write to the cache if there is no conersation Id
    apolloClient.cache.writeQuery({
      query: GET_LOCAL_CONVERSATION,
      data: {
        getLocalConversation: newConversation
      },
      variables: {
        ConversationId: conversationIdRef.current
      }
    });

    //remove orphan data
    apolloClient.cache.gc();

    return () => {
      if (!refIsCreated.current)
        clearlocalCache()

    }
  }, []);

  function clearlocalCache() {
    const normalizedId = apolloClient.cache.identify(newConversation);
    return apolloClient.cache.evict({ id: normalizedId });
  }

  //pass ConversationId, UserName, OrgId
  return (
    <div className={style.app}>
      <div className={style.container}>
        <div className={style.contactListItem}>
          <GroupContacts
            debug={false}
            organisationId={organisationId}
            displayName={displayName}
            setUserName={setDisplayName}
            recipientId={recipientId}
            setRecipientId={setRecipientId}
            userName={userName}
            isGroupMessage={isGroupMessage}
            setSelectedCount={setSelectedCount}
            selectedCount={selectedCount}
            conversationId={conversationIdRef.current}
          />
        </div>
        <div className={style.memberRightSide}>
          <div className={style.memberSection}>
            <MemberSection organisationId={organisationId} userName={userName} conversationId={conversationIdRef.current} isNewMemberSelected={false} />
          </div>
          <div className={style.memberFooter}>
            <MemberFooter organisationId={organisationId} userName={userName} conversationId={conversationIdRef.current} refIsCreated={refIsCreated} type={SelectType.ADD} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTopbar(SelectMultipleContact, false, "Select group members");
