import React, { useEffect, useState } from "react";
import { TitleBar, TitleArea } from "@emisgroup/ui-title-bar";
import { useLocation, useNavigate } from "react-router-dom";
import commonStyle from "../../styles/Common.module.scss";
import { IAppContextInterface } from "../../models/IAppContextInterface";
import { AppContext } from "../../utils/ApplicationContext";
import { Button } from "@emisgroup/ui-button";

import UserGroup from "~icons/ic/outline-groups";
import Edit from "~icons/ic/edit";

export interface IwithTopbar {
  title: string;
}

export interface ITopbar {
  setDisplayIcon?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayTitle?: React.Dispatch<React.SetStateAction<string>>;
}

export const withTopbar = (Component: React.FunctionComponent, isHomePage: boolean, title: string) => {
  return (props: any) => {
    const { state } = useLocation();
    const [displayTitle, setDisplayTitle] = useState("");
    const [displayIcon, setDisplayIcon] = useState(false);

    const appContext: IAppContextInterface = React.useContext(AppContext);
    let user = appContext.user;

    useEffect(() => {
      if (state && (state as IwithTopbar).title) {
        setDisplayTitle((state as IwithTopbar).title);
      } else if (title === "UserName") {
        setDisplayTitle("");
      } else {
        setDisplayTitle(title);
      }
    }, [state]);
    return (
      <>
        <div className={commonStyle.topbar}>
          <Topbar displayComposeIcon={displayIcon} isHomePage={isHomePage} title={displayTitle} />
        </div>
        <Component setDisplayIcon={setDisplayIcon} setDisplayTitle={setDisplayTitle} {...user} {...props} />
      </>
    );
  };
};

const Topbar = (props) => {
  const nav = useNavigate();

  let path = window.location.pathname.split("/");
  let convId;
  if (path.length > 2) {
    convId = path[3];
  }

  function navPath() {
    const pathArgs = convId;
    return "/staff/" + pathArgs;
  }

  const navBack = () => {
    if (convId && window.history.state && window.history.state.usr == undefined) {
      nav(navPath());
    } else {
      nav(-1);
    }
  };
  if (props.isHomePage) {
    return (
      <>
        {props.displayComposeIcon ? (
          <TitleBar data-testid="message" className={commonStyle.titleBarAlign}>
            <TitleArea backButton={false}>
              <div className={commonStyle.titlePadding}>
                <div className={commonStyle.title}>{"Messages"}</div>
              </div>
            </TitleArea>
            <Button borderless={false} onClick={() => nav("/staff/contact-list")}>
              <Edit title="" />
              Compose
            </Button>
          </TitleBar>
        ) : (
          <TitleBar data-testid="message" className={commonStyle.titleBarAlign}>
            <TitleArea backButton={false}>
              <div className={commonStyle.titlePadding}>
                <div className={commonStyle.title}>{"Messages"} </div>
              </div>
            </TitleArea>
          </TitleBar>
        )}
      </>
    );
  } else {
    return (
      <>
        {props.displayComposeIcon ? (
          <TitleBar data-testid="goback" className={commonStyle.titleBarAlign}>
            <TitleArea backButton={true} onClick={navBack}>
              <div className={commonStyle.title}> {props.title}</div>
            </TitleArea>
            <Button borderless={false} onClick={() => nav("/staff/contact-list")}>
              <Edit title="" />
              Compose
            </Button>
          </TitleBar>
        ) : (
          <TitleBar data-testid="goback" className={commonStyle.titleBarAlign}>
            <TitleArea backButton={true} onClick={navBack}>
              <div className={commonStyle.title}> {props.title}</div>
            </TitleArea>
            {props.title == "Select a contact" && process.env.GROUP == "true" ? (
              <Button borderless={false} onClick={() => nav("/staff/group-contact-list")}>
                <UserGroup title="" color="primary" />
                Group message
              </Button>
            ) : (
              <></>
            )}
          </TitleBar>
        )}
      </>
    );
  }
};
