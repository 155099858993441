import * as React from "react";
import style from "../../styles/MultipleContact.module.scss";
import { Input } from "@emisgroup/ui-input";
import { Button } from "@emisgroup/ui-button";
import { Form, FormSection, FormElement } from "@emisgroup/ui-form";
import { ApolloError, useLazyQuery, useMutation, useApolloClient, useQuery } from "@apollo/client";
import { CONVERSATION_FRAGMENT_V2, GET_LOCAL_CONVERSATION_V2 } from "./schema";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { V2User } from "../../types/user";
import { Banner } from "@emisgroup/ui-banner";
import Warning from "~icons/ic/baseline-warning-amber";
import { GET_NEW_USERS } from "../../graphql/queries";
import { getUserDisplayName } from "../../utils";
import { removeTitle } from "../../utils/MessageComponent";
import { UPDATE_CONVERSATION } from "../../graphql/mutations";

enum limit {
  BELOW_LIMIT,
  ABOVE_LIMIT
}

export enum SelectType {
  ADD,
  EDIT
}

interface IMemberFooterProps {
  conversationId: string;
  organisationId: string;
  userERN: string;
  refIsCreated?: React.MutableRefObject<boolean>;
  type: SelectType;
  groupName?: string;
  oldParticipants?: string[];
  setIsnewMemberSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsgroupSaving?: React.Dispatch<React.SetStateAction<boolean>>;
}


const MemberFooter: React.FunctionComponent<IMemberFooterProps> = (props) => {
  const [groupName, setGroupName] = useState(props.groupName);
  const newParticipants = useRef([]);
  const apolloClient = useApolloClient();
  const [participantCount, setParticipantCount] = React.useState(0);
  const [updateConversations] = useMutation(UPDATE_CONVERSATION);
  const [disabled, setDisabled] = useState(false);
  const [errorState, setErrorState] = React.useState(false);
  const handleError = (err: ApolloError) => {
    console.error(`Error: ${JSON.stringify(err.message)}`);
  };
  useEffect(() => {
    if (props.groupName) setGroupName(props.groupName);
  }, [props.groupName]);
  const nav = useNavigate();
  const localConversation = useRef(null);
  const usersRef = React.useRef<V2User[]>();
  const participantMaxLimit = 80;
  const userType = "getUser";

  //Get user details from cache

  const { data: users } = useQuery(GET_NEW_USERS, {
    variables: { OrganisationERN: props.organisationId },
    onError: handleError,
    fetchPolicy: "cache-first"
  });

  //get user conversation from cache
  const [getConversation, { data: queryConversation }] = useLazyQuery(GET_LOCAL_CONVERSATION_V2, {
    variables: {
      ConversationId: "local-conversation"
    },
    onError: handleError,
    fetchPolicy: "cache-only"
  });

  React.useEffect(() => {
    if (props.conversationId && users && users[userType].length > 0) {
      getConversation().catch((err) => { console.error(err); });
    }
  }, [props.conversationId, users]);

  let conversation: any;
  React.useEffect(() => {
    if (queryConversation?.["getLocalConversation"]) {
      conversation = queryConversation["getLocalConversation"];
      localConversation.current = conversation;
      newParticipants.current = conversation.ParticipantList;
      usersRef.current = users[userType] as V2User[];
      setParticipantCount(conversation.ParticipantList.length - 1); // not including the current user
    }
  }, [queryConversation]);

  const handleCreateConv = async (localConversation: any) => {
    let localConv = localConversation;
    const participants = localConv.ParticipantList;
    await updateConversations({
      variables: {
        ConversationId: props.conversationId.valueOf(),
        ConversationName: localConv.HasBeenNamed ? localConv.ConversationName : "",
        IsGroupConversation: localConv.IsGroupConversation,
        HasBeenNamed: localConv.HasBeenNamed,
        OrganisationERN: localConv.OrganisationERN,
        ParticipantList: participants,
        Type_Id: localConv.Type_Id,
        LastUpdatedOn: localConv.LastUpdatedOn,
      },
      onError: handleError
    });
  };

  const IsValid = () => {
    if (participantCount > 0 && participantCount < participantMaxLimit) {
      return true;
    } else if (participantCount >= participantMaxLimit) {
      setErrorState(true);
    }

    return false;
  };

  const getParticipantCountErrorMessage = (count: number): limit => {
    if (count == 0) return limit.BELOW_LIMIT;
    return limit.ABOVE_LIMIT;
  };

  let groupMembers = "";
  let hasBeenNamed = false;

  function getUserNames(displayName: string) {
    return displayName && displayName.split(",").length > 1 ? displayName.split(",") : "";
  }

  const SortParticipants = (members) => {
    let memberList: string[] = [];
    let groupNameLength = 7;
    members.forEach((participant) => {
      const user = usersRef.current.find(
        (user) => user.UserERN == participant.split('_')[1]
      );
      const displayName = getUserDisplayName(user);
      if (displayName != "") {
        let firstName = getUserNames(user.DisplayName)[1]?.trim();
        memberList.push(removeTitle(firstName) || displayName);
      }
    });
    memberList.sort((a, b) => (a > b ? 1 : -1));

    groupMembers = memberList.slice(0, groupNameLength).join(", ");

    if (memberList.length - 1 >= groupNameLength)
      groupMembers = groupMembers + " +" + (memberList.length - groupNameLength);
    return groupMembers;
  };

  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.split('_')[1] === object2.split('_')[1];
      });
    });
  }

  function isGroupNameChanged(oldGroupName, newGroupName) {
    return oldGroupName != newGroupName;
  }

  function setUnnamedGroup() {
    if (!groupName) {
      hasBeenNamed = false;
      if (localConversation.current) {
        let members = [...localConversation.current.ParticipantList];
        groupMembers = SortParticipants(members);
      }
    } else {
      hasBeenNamed = true;
    }
  }

  const CreateGroupClickHandler = async () => {
    if (!IsValid()) {
      setErrorState(true);
      return;
    }

    setUnnamedGroup();

    //Update local cache with groupname
    apolloClient.cache.updateFragment(
      {
        id: `GroupConversation:${props.conversationId}_${props.userERN}`,
        fragment: CONVERSATION_FRAGMENT_V2
      },
      (data) => {
        let convName = groupName || groupMembers;
        let result = {
          ...data,
          HasBeenNamed: hasBeenNamed,
          IsRemoved: false,
          ConversationName: convName,
          RemovedBy: ""
        };

        localConversation.current = result;
        return result;
      }
    );

    await updateConversation(newParticipants, localConversation);
    //TO navigate to main page
  };

  async function updateConversation(
    newParticipants: React.MutableRefObject<any[]>,
    localConversation: React.MutableRefObject<any>
  ) {
    if (props.type === SelectType.ADD) {
      if (props.refIsCreated) props.refIsCreated.current = true;
    } else {
      let updateParticipant = [
        ...getDifference(props.oldParticipants, newParticipants.current),
        ...getDifference(newParticipants.current, props.oldParticipants)
      ];

      if (
        (updateParticipant && updateParticipant.length > 0) ||
        isGroupNameChanged(
          props.groupName,
          !localConversation.current.HasBeenNamed ? "" : localConversation.current.ConversationName
        )
      ) {
        // To set true to show spinner on saving group.
        props.setIsgroupSaving(true);
        setDisabled(true);
        await handleCreateConv(localConversation.current);
        // To restore initial state value.
        props.setIsgroupSaving(false);
      }
    }
    if (props.type == SelectType.ADD) nav("/staff/");
    else nav("/staff/" + localConversation.current.ConversationId);
  }

  const memberTextProp = {
    participantCount: participantCount,
    errorState: errorState,
    isValid: IsValid,
    getParticipantCountErrorMessage: getParticipantCountErrorMessage,
    participantMaxLimit: participantMaxLimit
  };

  function checkIsNewMemberExist(): boolean {
    let OldMembers = props.oldParticipants.map((i) => i.split("_")[1]);
    let newMembers = newParticipants.current.map((i) => i.split("_")[1]);

    if (newMembers.length > 0) {
      let isExist = newMembers.every((element) => OldMembers.includes(element));
      props.setIsnewMemberSelected(!isExist);
      return isExist;
    }
    return true;
  }

  return (
    <React.Fragment>
      {props.type == SelectType.EDIT && !checkIsNewMemberExist() && (
        <Banner color="warning">
          <Warning title="Warning" />
          <div>
            <div>
              <strong>History will be shared</strong>
              <br />
              Conversation history will be shared with newly <br />
              added users.
            </div>
          </div>
        </Banner>
      )}
      <Form>
        <MemberText {...memberTextProp} />
        <FormSection>
          <FormElement fieldId="groupname">
            <div className={style.groupNameInput}>
              {"Group name "}
              <div className={style.groupNameOptionaInput}>{"(optional)"}</div>
            </div>
            <Input
              className={style.groupNameInputField}
              name="groupname"
              value={groupName}
              type="text"
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
              data-testid="groupname"
              maxLength={250}
            />
            <div className={style.formButtons}>
              <Button
                type="submit"
                disabled={disabled}
                variant="filled"
                onClick={() => {
                  CreateGroupClickHandler().catch((err) => { console.error(err); });
                }}
                data-testid="creategroup"
              >
                {props.type == SelectType.ADD ? "Create group" : "Save"}
              </Button>
            </div>
          </FormElement>
        </FormSection>
      </Form>
    </React.Fragment>
  );
};

const MemberText = (props) => {
  return props.isValid() ? (
    <FormSection>
      <FormElement>
        <h4 className={style.memberText} data-testid="membercount">
          {props.participantCount == 1 ? "1 member selected." : props.participantCount + " members selected."}
        </h4>
      </FormElement>
    </FormSection>
  ) : (
    <FormSection>
      <FormElement>
        <h4 className={props.errorState ? style.memberTextError : style.memberText} data-testid="membercount">
          {props.getParticipantCountErrorMessage(props.participantCount) == limit.BELOW_LIMIT ? (
            <span>Select at least 1 member.</span>
          ) : (
            <>
              <span>Group conversations have a limit of {props.participantMaxLimit} members.</span>
              <br />
              <span>Remove some members.</span>
            </>
          )}
        </h4>
      </FormElement>
    </FormSection>
  );
};

export default MemberFooter;
