import * as React from "react";
import { IAppContextInterface } from "../models/IAppContextInterface";

export const AppContext = React.createContext<IAppContextInterface | null>(null);

export class ApplicationContext {

    static getApplicationContext(): any {
        return AppContext;
    }
}
