import style from "../../styles/MultipleContact.module.scss";
import { withTopbar } from '../hoc/withTopbar';
import { IUser } from "../../utils/user";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import MemberSection from "./MemberSection";
import MemberFooter from "./MemberFooter";
import { GET_LOCAL_CONVERSATION_V2 } from "./schema";
import { useApolloClient } from "@apollo/client";
import GroupContacts from "../GroupContacts";
export interface ISelectMultipleContactProps extends IUser {
}

export enum SelectType {
  ADD,
  EDIT
}


export const LocalConversationId = "local-converation";
const CreateGroupMessagePage: React.FC<ISelectMultipleContactProps> = (props: ISelectMultipleContactProps) => {
  const { userERN, organisationId } = props;
  const { conversationId } = useParams();
  const conversationIdRef = useRef(conversationId || "");
  const [displayName, setDisplayName] = useState("");
  const [recipientId, setRecipientId] = useState("");
  const [isGroupMessage] = useState(true);
  const [selectedCount, setSelectedCount] = useState(0);
  const apolloClient = useApolloClient();
  const refIsCreated = useRef<boolean>(false);

  let newConversation;



  // If ConversationId not exists 
  useEffect(() => {
    if (!conversationIdRef.current) {
      const currentMember = [organisationId + '_' + userERN];
      conversationIdRef.current = LocalConversationId;
      newConversation = {
        __typename: "GroupConversation",
        ConversationId: conversationIdRef.current,
        ConversationName: "",
        OrganisationERN: organisationId,
        UserERN: userERN,
        IsRemoved: false,
        ParticipantList: currentMember,
        IsLocal: true,
        IsGroupConversation: true,
        LastUpdatedOn: new Date().getTime().toString(),
        Type_Id: "Conv_" + conversationIdRef.current,
        HasBeenNamed: false,
      };

    }
    // Write to the cache if there is no conersation Id
    apolloClient.cache.writeQuery({
      query: GET_LOCAL_CONVERSATION_V2,
      data: {
        getLocalConversation: newConversation
      },
      variables: {
        ConversationId: conversationIdRef.current
      }
    });

    //remove orphan data
    apolloClient.cache.gc();

    return () => {
      if (!refIsCreated.current)
        clearlocalCache()

    }
  }, []);

  function clearlocalCache() {
    const normalizedId = apolloClient.cache.identify(newConversation);
    return apolloClient.cache.evict({ id: normalizedId });
  }

  //pass ConversationId, UserName, OrgId
  return (
    <div className={style.app}>
      <div className={style.container}>
        <div className={style.contactListItem}>
          <GroupContacts
            debug={false}
            organisationId={organisationId}
            displayName={displayName}
            setUserName={setDisplayName}
            recipientId={recipientId}
            setRecipientId={setRecipientId}
            userERN={userERN}
            isGroupMessage={isGroupMessage}
            setSelectedCount={setSelectedCount}
            selectedCount={selectedCount}
            conversationId={conversationIdRef.current}
          />
        </div>
        <div className={style.memberRightSide}>
          <div className={style.memberSection}>
            <MemberSection organisationId={organisationId} userERN={userERN} conversationId={conversationIdRef.current} isNewMemberSelected={false} />
          </div>
          <div className={style.memberFooter}>
            <MemberFooter organisationId={organisationId} userERN={userERN} conversationId={conversationIdRef.current} refIsCreated={refIsCreated} type={SelectType.ADD} />
          </div>userERN
        </div>
      </div>
    </div>
  );
}

export default withTopbar(CreateGroupMessagePage, false, "Select group members");
