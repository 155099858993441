// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@container (width < 768px){.HLWUzrKv_LI10c78wpdj{padding:0px;margin:0px;height:calc(100vh - (1*var(--acp-navapp-height, 48px) + var(--acp-navapp-height, 0px) + var(--footer-height, 0px)));overflow-y:auto;background-color:var(--background-light)}}@container (min-width: 768px){.HLWUzrKv_LI10c78wpdj{max-width:800px;padding:0px;margin:0 auto;background-color:var(--background-light);height:calc(100vh - (1*var(--acp-navapp-height, 48px) + var(--acp-navapp-height, 0px) + var(--footer-height, 0px)));overflow-y:auto}}`, "",{"version":3,"sources":["webpack://./src/styles/NoSearchContacts.module.scss"],"names":[],"mappings":"AAEA,2BACE,sBACE,WAAA,CACA,UAAA,CACA,mHAAA,CACA,eAAA,CACA,wCAAA,CAAA,CAIJ,8BACE,sBACE,eAAA,CACA,WAAA,CACA,aAAA,CACA,wCAAA,CACA,mHAAA,CACA,eAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginList": `HLWUzrKv_LI10c78wpdj`
};
export default ___CSS_LOADER_EXPORT___;
