/**
 * Utils class to manage MasterPage cache operations. 
 */
import { GET_MESSAGES_BY_CONVERSATION, GET_USER_CONVERSATIONS } from '../../../graphql/queries';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { Message } from "../../../types/messages";

import * as queries from "../../../graphql/queries";
import * as fragment from "../../../graphql/fragments";

import { User } from "../../../types/user";
import { MessageReadStatusNotification } from '../../../types/messageReadStatus';
import { User as user } from "../../../utils/user";


export class CacheUtil {
  initialLoadCount: number;
  apolloClient: ApolloClient<Object>;

  constructor() {
    this.initialLoadCount = parseInt(process.env.INITIAL_LOAD_COUNT);
    this.apolloClient = useApolloClient();
  }


  //#region system message append
  // check will fit for normal message also.
  // Append message into the cache. (Only for system messages.) 
  appendMessageInCache(message: Message, getConversation: any, token: string, user: user) {
    // update conversation cache if conversation is expired (more than 3 months)
    this.updateConversationCache(message, getConversation, user);

    // update unread count in cache
    this.updateUnreadCountInCache(message, user);

    let cachedMessages = this.apolloClient.cache.readQuery({
      query: GET_MESSAGES_BY_CONVERSATION,
      variables: {
        ConversationId: message.ConversationId,
        Limit: this.initialLoadCount
      }
    });
    if (cachedMessages?.['getMessageV2']) {
      let isExist = cachedMessages["getMessageV2"].Messages.find(x => x.CreatedAt_MessageId === message.CreatedAt_MessageId);
      if (!isExist) {
        this.apolloClient.writeQuery({
          query: GET_MESSAGES_BY_CONVERSATION,
          variables: {
            ConversationId: message.ConversationId,
            Limit: this.initialLoadCount
          },
          data: {
            getMessageV2: {
              __typename: "MessageV2Pages",
              id: cachedMessages["getMessageV2"].id,
              Messages: [...cachedMessages["getMessageV2"].Messages, { __typename: "MessageV2", ...message }],
              NextToken: cachedMessages["getMessageV2"].NextToken
            }
          }
        });
      }
    }

    cachedMessages = this.apolloClient.cache.readQuery({
      query: queries.GET_LOCAL_MESSAGES,
      variables: {
        Id: "all"
      }
    });
    if (cachedMessages?.['getLocalMessages']) {
      let isExist = cachedMessages["getLocalMessages"].find(x => x.CreatedAt_MessageId === message.CreatedAt_MessageId);
      if (!isExist) {
        this.apolloClient.writeQuery({
          query: queries.GET_LOCAL_MESSAGES,
          variables: {
            Id: "all"
          },
          data: {
            getLocalMessages: [...cachedMessages["getLocalMessages"], { __typename: "MessageV2", ...message }],
          }
        });
      }
    }
  }

  private updateUnreadCountInCache(message: Message, user: user) {
    let unreadCount = 0;
    let HasUrgent = false;
    if (!message.IsSystemMessage && message.AuthorId != user.userName) {
      let cachedCountStore = this.apolloClient.cache.readQuery({
        query: queries.MESSAGE_UNREAD_COUNT,
        variables: {
          ConversationId: message.ConversationId
        }
      });

      // check if local cache exist
      if (cachedCountStore?.['conversationDetails']) {
        unreadCount = cachedCountStore['conversationDetails'].UnreadCount + 1;
        HasUrgent = (cachedCountStore['conversationDetails'].HasUrgent ?? false) || message.IsUrgent;
      } else {
        let cachedConversations = this.apolloClient.cache.readQuery({
          query: GET_USER_CONVERSATIONS,
          variables: {
            OrganisationId: user.organisationId,
            UserName: user.userName
          }
        });
        let currentConversation = cachedConversations?.['getConversationByUserV3'].find(x => x.ConversationId === message.ConversationId);
        unreadCount = currentConversation?.UnreadCount ? currentConversation.UnreadCount + 1 : 1;
        HasUrgent = (currentConversation?.HasUrgent ?? false) || message.IsUrgent;
      }

      // write unreacount in cache
      this.apolloClient.cache.writeQuery({
        query: queries.MESSAGE_UNREAD_COUNT,
        variables: {
          ConversationId: message.ConversationId
        },
        data: {
          conversationDetails: {
            __typename: "CountStore",
            id: `CountStore:${message.ConversationId}`,
            ConversationId: message.ConversationId,
            UnreadCount: unreadCount,
            HasUrgent: HasUrgent
          }
        }
      });
    }
  }

  //update conversation cache
  private updateConversationCache(message: Message, getConversation: any, user: user) {
    let cachedConversations = this.apolloClient.cache.readQuery({
      query: GET_USER_CONVERSATIONS,
      variables: {
        OrganisationId: user.organisationId,
        UserName: user.userName
      }
    });

    let convExist = cachedConversations?.['getConversationByUserV3'].find(x => x.ConversationId === message.ConversationId);
    if (!convExist) {
      let newConversations = this.apolloClient.cache.readQuery({
        query: queries.GET_CONVERSATION_BY_ID,
        variables: {
          ConversationId: message.ConversationId,
          UserName: user.userName
        }
      });
      let conversationList = cachedConversations?.['getConversationByUserV3'] ? cachedConversations['getConversationByUserV3'] : [];
      this.appendConversations(newConversations, conversationList, user, getConversation);
    }
  }


  private appendConversations(newConversations: unknown, conversationList: any, user: user, getConversation: any) {
    if (newConversations?.['getConversationByIdV2']?.length > 0) {
      let newData = [...conversationList, newConversations?.['getConversationByIdV2'][0]];
      if (newData.length > 0) {
        this.apolloClient.writeQuery({
          query: GET_USER_CONVERSATIONS,
          variables: {
            OrganisationId: user.organisationId,
            UserName: user.userName
          },
          data: {
            getConversationByUserV3: newData
          }
        });
      }
    } else {
      getConversation();
    }
  }

  //#endregion

  //#region User
  //read user 
  readUser(organisationId: string): User[] {
    const result = this.apolloClient.readQuery({
      query: queries.GET_USERS,
      variables: {
        OrganisationId: organisationId
      }
    });
    if (result['getUserV2']?.length > 0) return result['getUserV2'] as User[];
    return [];
  }

  //write user
  writeUser(organisationId: string, users: User[]) {
    this.apolloClient.writeQuery({
      query: queries.GET_USERS,
      variables: {
        OrganisationId: organisationId
      },
      data: {
        getUserV2: [...users]
      }
    });
  }
  //#endregion

  //#region Update message status using MessageReadStatusNotification
  updateMessageStatusInCache(readStatus: MessageReadStatusNotification) {
    //Skip unwanted update
    if (!readStatus.IsRead) return;
    //update message cache with updateFragment
    this.apolloClient.cache.writeFragment({
      id: `MessageV2:${readStatus.MessageId}`,
      fragment: fragment.messageFragment,
      data: {
        __typename: "MessageV2",
        IsRead: readStatus.IsRead,
      }
    })
  }
  //#endregion
}
