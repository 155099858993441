import style from "../styles/MessageDisplayComponent.module.scss";
import { FormElement, FormSection } from "@emisgroup/ui-form";
import commonStyle from "../styles/Common.module.scss";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { ApolloError, useLazyQuery, useQuery, useApolloClient } from "@apollo/client";
import React, { useState, useEffect, useRef } from "react";
import { getLocalTimeString, getMessageDate } from "../utils";
import CardComponent from "./CardComponent";
import ListGroup from "../custom_components/ListGroup";
import { Message } from "../types/messages";
import { IUser } from "../utils/user";
import { ITopbar } from "./hoc/withTopbar";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import { checkLabel, updateParticipants, FormatSystemMessage } from "../utils/MessageComponent";
import SystemMessage from "./SystemMessage";
import EmptyStatePanel from "./EmptyStatePanel";
import { Stack } from "@emisgroup/ui-layouts";
import Refresh from "~icons/ic/refresh";
import { Button } from "@emisgroup/ui-button";
import { CONVERSATION_STORE, GET_GROUP_CONVERSATION, GET_MESSAGES_BY_CONVERSATION, GET_CONVERSATION_BY_ID, GET_OLDCONVERSATION, GET_USERS } from "../graphql/queries";
import { FeaturedIcon } from "@emisgroup/ui-icon";
import SystemProblem from "~icons/ic/twotone-sync-problem";
import Forum from "~icons/ic/twotone-forum";
import { getParticipantList, getHashCode } from "../utils/GetParticipants";
import { state } from "../types/GroupState";
import * as fragments from "../graphql/fragments";
import ArrowDown from "~icons/ic/arrow-downward";
import { Badge } from "@emisgroup/ui-badge";
import { useInView } from 'react-intersection-observer';
export interface IMessageDisplayComponentProps extends IUser, ITopbar {
  title: string;
  authorId: string;
  conversationId: string;
  recipients: string[];
  isGroupConv: boolean;
  refOldConversationId?: React.MutableRefObject<string>;
  hasBeenNamed: boolean;
  isRemoved: boolean;
  isSmallGroupCreating: boolean;
  readMessageCallBack: Function;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IStateProps extends IUser, ITopbar {
  title: string;
  recipientId: string[];
}

export interface IParticipants {
  OrganisationId: string;
  UserName: string;
}

export const initialLoadCount = 100;
export const loadMoreCount = 100;

export const MessageDisplayComponent: React.FC<IMessageDisplayComponentProps> = (
  props: IMessageDisplayComponentProps
) => {
  const client = useApolloClient();

  //Get local cache about conversation store  
  const [conversationStore, { data: conversationStoreDetails }] = useLazyQuery(CONVERSATION_STORE, {
    fetchPolicy: "cache-only"
  });

  const [getgroupConversation, { data: GroupConversation }] = useLazyQuery(GET_GROUP_CONVERSATION,
    {
      fetchPolicy: "network-only",
      onError: handleError
    }
  );

  //get the recipient details
  const { data: userData } = useQuery(GET_USERS, {
    variables: {
      OrganisationId: props.organisationId
    },
    fetchPolicy: "cache-first",
    onError: handleError
  });

  //get OldConversation for the user
  const [getOldConversation, { data: OldConversationData, loading: loadingOldConversation }] = useLazyQuery(
    GET_OLDCONVERSATION,
    {
      fetchPolicy: "network-only",
      onError: handleError
    }
  );


  const [getConversationByIdV2, { data: GetConversation, loading: loadingConversation }] = useLazyQuery(
    GET_CONVERSATION_BY_ID,
    {
      fetchPolicy: "cache-first",
      onError: handleError
    }
  );


  //get list of messages for existing conversation
  const [getMessage, { data: getMessages, updateQuery: updateMessageCache, loading: messagesLoading, fetchMore }] = useLazyQuery(
    GET_MESSAGES_BY_CONVERSATION,
    {
      onCompleted() {
        //hide load from top of the chat history to indicate loded more message.
        setMoreMessageLoading(false);
      },
      fetchPolicy: "cache-first",
      onError: handleError
    }
  );

  const { ref, inView } = useInView();

  function handleError(err: ApolloError) {
    console.error(`Error: ${JSON.stringify(err.message)}`);
    setErrorState(err.message);
    setMoreMessageLoading(false);
    Logger.error(err.message);
  };

  const refHasNextScroll = useRef<boolean>(false);
  const refHasUnredMessage = useRef<boolean>(false);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const hasPositioned = useRef<boolean>(false);
  const [nextScrollPosition, setNextScrollPosition] = useState<number | null>(null);
  const [newMessageBanner, setNewMessageBanner] = useState(false);
  const [refDetails, setRefDetails] = useState("");
  const [lastRead, setLastRead] = useState("");
  const [scrollPosition, setScrollPosition] = useState(Number);
  const [isLoading, setIsLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const recipientDisplayName = useRef("");
  const [moreMessageLoading, setMoreMessageLoading] = useState(false);
  const [illegalAccess, setIllegalAccess] = useState(false);
  const [error, setError] = useState(false);
  const [showGroupSpinner, setShowGroupSpinner] = useState<string | null>(null);
  const [nextToken, setNextToken] = useState("");
  const oldConversationRef = useRef<string>(props.conversationId);
  const lastMessageIsReceived = useRef<boolean>(true);
  const setPositionForConversation = useRef<boolean>(false);
  const refHasScrollatBottom = useRef<boolean>(false);
  const refHasScrollMovedAtBottom = useRef<boolean>(false);
  const isBadgeAdded = useRef<boolean>(false);

  const lastSentMessageIdRef = useRef<string>("");

  const userList = useRef([]);


  let refs;
  let recipients: string[];

  setRecipients();

  function setRecipients() {
    if (props.recipients) {
      recipients = props.recipients;
    }
  }

  const orgId: string = props.organisationId;
  const userName: string = props.userName;

  let participants: IParticipants[] = updateParticipants(props.organisationId, userName, recipients, props.authorId, props.isRemoved);

  const initialChatContent = props.isGroupConv
    ? "You're starting a new group conversation"
    : "You're starting a new conversation";

  useEffect(() => {
    if (userData != undefined && userData["getUserV2"].length > 0) {
      userList.current = userData["getUserV2"];
    }
  }, [userData]);
  let participantsDetails = sortParticipants();
  const participantList = getParticipantList(participantsDetails);

  let oldConversationId: string | undefined;

  useEffect(() => {
    if (oldConversationRef.current) {
      //get group conversation 
      getgroupConversation({
        variables: {
          ConversationId: oldConversationRef.current
        }
      });

      getConversationByIdV2({
        variables: {
          ConversationId: oldConversationRef.current,
          UserName: props.userName
        }
      });
      conversationStore({
        variables: {
          ConversationId: oldConversationRef.current
        }
      });
    }
    setPositionForConversation.current = false;
  }, [oldConversationRef.current]);


  useEffect(() => {
    if (hasPositioned.current && !refHasScrollMovedAtBottom.current && !refHasScrollatBottom.current && !inView && refHasUnredMessage.current)
      setNewMessageBanner(true);
    else
      setNewMessageBanner(false);
  }, [inView, isBadgeAdded.current]);



  useEffect(() => {
    if (GetConversation != undefined) {
      let length = GetConversation["getConversationByIdV2"].length;

      if (length > 0) {
        if ((GetConversation["getConversationByIdV2"][0].UserName !== userName)) {
          setIllegalAccess(true);
        }
      }
    }
  }, [GetConversation]);


  useEffect(() => {
    if (GroupConversation?.["getGroupConversation"].length > 0) {
      let groupConversation = GroupConversation['getGroupConversation'].filter(u => u.Author.split('_').slice(1).join('_') == props.userName);

      client.cache.writeQuery({
        query: CONVERSATION_STORE,
        variables: {
          ConversationId: groupConversation[0].ConversationId
        },
        data: {
          conversationDetails: {
            __typename: "ConversationStore",
            id: `ConversationStore:${groupConversation[0].ConversationId}`,
            ConversationId: groupConversation[0].ConversationId,
            Action: groupConversation[0].Action,
            Author: groupConversation[0].Author.split('_').slice(1).join('_')
          }
        }
      });
    } else if (GroupConversation?.["getGroupConversation"].length == 0) {
      client.cache.updateFragment({
        id: `ConversationStore:${oldConversationRef.current}`,
        fragment: fragments.getConversationStore,
      }, (data) => {
        return { ...data, Action: "COMPLETED" };
      });
    }
  }, [GroupConversation]);


  const fetchMoreMessages = async (conversationId: string) => {
    await fetchMore({
      variables: {
        ConversationId: conversationId,
        Limit: loadMoreCount,
        NextToken: nextToken
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (prev?.getMessageV2.Messages) {
          return {
            getMessageV2: {
              __typename: "MessageV2Pages",
              id: fetchMoreResult.getMessageV2.id,
              Messages: [...prev.getMessageV2.Messages, ...fetchMoreResult.getMessageV2.Messages],
              NextToken: fetchMoreResult.getMessageV2.NextToken
            }
          };
        }
      }
    });
  };


  const queryVariables = {
    OrganisationId: orgId.valueOf(),
    UserName: userName.valueOf(),
    ParticipantHash: getHashCode([...participantList]),
    ConversationName: props.hasBeenNamed ? props.title : "",
    IsGroupConversation: props.isGroupConv
  };

  useEffect(() => {
    if (oldConversationRef.current) {
      if (!illegalAccess) {
        getMessage({
          variables: {
            ConversationId: oldConversationRef.current,
            Limit: initialLoadCount
          }
        }).catch(err => { console.error(err) });
      }
    } else {
      getOldConversation({
        variables: queryVariables
      }
      )
    }
  }, []);

  useEffect(() => {
    if (refDetails?.[lastRead]?.current && !setPositionForConversation.current) {
      refDetails[lastRead].current.scrollIntoView({
        behavior: "auto"
      });

      hasPositioned.current = true;
      // set scroll based on the last message is received or send
      // added LastMesageIsReceived and hasUnreadMessage=false 
      if ((!lastMessageIsReceived.current) || (lastMessageIsReceived.current && !refHasUnredMessage.current)) {
        listInnerRef.current.scrollTo({
          top: listInnerRef.current.scrollHeight - listInnerRef.current.clientHeight,
          behavior: "auto"
        });
      }
      setScrollPosition(listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop);
      setPositionForConversation.current = true;
    }
  }); // No array so, triggers after all renders

  const groupList = (previewList: Message[]) =>
    previewList.reduce((groups, d) => {
      const date = getMessageDate(d.CreatedAt).toDateString();

      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(d);
      return groups;
    }, {});

  let listLabel = "";
  const groupArrays = (previewList: Message[]) => {
    let filteredMessageList = groupList(previewList);
    return Object.keys(filteredMessageList).map((date) => {
      return {
        date,
        messages: filteredMessageList[date]
      };
    });
  };

  let keyId;
  let unreadId: string = "";

  function sortParticipants() {
    return participants.sort((a, b) => (a.UserName > b.UserName ? 1 : -1));
  }

  function checkUnreadMessage(element: Message) {
    if ((!element.IsRead) && !element.IsSystemMessage && unreadId === "" && userName.toLowerCase() != element.AuthorId.toLowerCase()) {
      unreadId = element.CreatedAt_MessageId;
    }
    return unreadId;
  }

  function checkReceivedMessage(element: Message) {
    if (userName.toLowerCase() === element.AuthorId.toLowerCase()) {
      return false;
    }
    return true;
  }

  function setLoading(index, messageLength, listIndex, sortedListLength) {
    if (index == messageLength - 1 && listIndex == sortedListLength - 1) {
      setIsLoading(false);
    }
  }

  function calculateIsUnread(unreadId: string): boolean {
    return unreadId != "";
  }

  function getCurrentSystemMessage(messages: Message[]): Message {
    return messages && messages.length > 0 && messages.reduce((a, b) => a.CreatedAt > b.CreatedAt ? a : b, messages[0]);
  }
  // To filter system messages for the removed users (when has same time) to show releavant messages to the user.
  function handleSysMessagesToRemovedUsers(messages: Message[]): Message[] {
    let filteredMessages: Message[] = [];
    const removedSysMessages = messages.filter(item => item.IsSystemMessage === true && item.Content.includes("removed"));
    const addedSysMessages = messages.filter(item => item.IsSystemMessage === true && item.Content.includes("added") && item.Content.includes(userName));
    const currentUserSysMsgs = removedSysMessages.filter(item => item.Content.includes(userName));
    const latestRemoved = getCurrentSystemMessage(currentUserSysMsgs);
    const latestAdded = getCurrentSystemMessage(addedSysMessages);
    if (latestRemoved) {
      // Filter the removed system messages by CreatedAt and Content.
      // If removed user again added he should get all the messages.
      if (!latestAdded || latestAdded.CreatedAt < latestRemoved.CreatedAt)
        filteredMessages = messages.filter(item => item.CreatedAt == latestRemoved.CreatedAt && item.Content.includes("removed") && !item.Content.includes(userName));
    }
    const messagesToDeleteSet = new Set(filteredMessages);
    if (filteredMessages.length > 0) {
      messages = messages.filter((message) => {
        // return those elements not in the messagesToDeleteSet
        return !messagesToDeleteSet.has(message);
      });
    }

    return messages;
  }

  function generateNewArray(item) {
    return Array.from(new Set(item.messages.map(a => a.CreatedAt_MessageId)))
      .map(id => {
        return item.messages.find(a => a.CreatedAt_MessageId === id);
      });
  }

  function prepareMessagesToRender(messages: Message[]) {
    messages = handleSysMessagesToRemovedUsers(messages);
    isBadgeAdded.current = false;

    //find last sent message from messages
    if (messages.length > 0) {
      const sentMessageList = messages.filter(item => {
        if (!checkReceivedMessage(item) && !item.IsSystemMessage) {
          return true;
        }
      });
      lastSentMessageIdRef.current = sentMessageList[sentMessageList.length - 1]?.CreatedAt_MessageId;
    }
    let sortedList = groupArrays(messages);
    let listGroup = [];
    const objRefs = messages.reduce((acc, value) => {
      acc[value.CreatedAt_MessageId] = React.createRef();
      return acc;
    }, {});

    refs = objRefs;
    setRefDetails(refs);
    let isRecievedMessage: boolean;
    let items = [];
    let content = "";
    lastMessageIsReceived.current = checkReceivedMessage(
      sortedList[sortedList.length - 1].messages[sortedList[sortedList.length - 1].messages.length - 1]
    );
    let hasNewMessage = true;
    sortedList.forEach((item, listIndex) => {
      items = [];

      const newArray = generateNewArray(item);


      newArray.forEach((element, index) => {
        unreadId = checkUnreadMessage(element);

        ({ hasNewMessage } = createNewBadge(unreadId, hasNewMessage, items, element, refs));
        isRecievedMessage = checkReceivedMessage(element);
        if (element.IsSystemMessage) {
          content = createSystemMessageCard(element, content, userList, items);
        } else {
          items.push({
            children: (
              createCardElement({
                element: element,
                refs: refs,
                isRecievedMessage: isRecievedMessage,
                calculateIsUnread: calculateIsUnread,
                unreadId: unreadId,
                messageDisplayProps: props
              })
            )
          });
        }
        setLoading(index, item.messages.length, listIndex, sortedList)
        keyId = element.CreatedAt_MessageId;
      });

      const date = item.date;

      listLabel = checkLabel(date);

      listGroup.push(
        <div key={listLabel}>
          <ListGroup items={items} title={listLabel} id={"card_content"} />
          {listIndex != sortedList.length - 1 ? <div className={style.headerspace} /> : null}
        </div>
      );
    });

    if (showGroupSpinner == state.MODIFY) {
      items.push({
        children: (
          <div className={style.conversationSpinner}>
            <ProgressSpinner text="Group setup is in progress. It may take a few moments to update the group." />
          </div>
        )
      });
    }

    //next scroll position will be message from top of the scroll.
    setLastRead(unreadId);
    refHasUnredMessage.current = true;
    assignLastread(unreadId, refHasUnredMessage, setLastRead, keyId);
    setMessageList([...listGroup]);
  }

  let listItems = [];

  Array.prototype.push.apply(listItems, messageList);

  //set scroll position based on cache and unreadMessage
  useEffect(() => {
    // Filter conversation by Id and get scroll
    if (listInnerRef?.current) {
      //if nextScrollPosition is exist then load that position
      if (nextScrollPosition != undefined) {
        //Set new scrollpostion from the top = (newHight from  - oldScrollPostion) + current scroll position.
        const currentScrollHeight = listInnerRef.current.scrollHeight;
        const currentScrollTop = listInnerRef.current.scrollTop; // to set current scroll position
        setNextScrollPosition(currentScrollHeight);
        if (refHasNextScroll.current) {
          listInnerRef.current.scrollTo({
            top: currentScrollHeight - nextScrollPosition + currentScrollTop,
            behavior: "auto"
          });
        } else if (!refHasUnredMessage.current || refHasScrollatBottom.current) {
          listInnerRef.current.scrollTo({
            top: listInnerRef.current.scrollHeight - listInnerRef.current.clientHeight,
            behavior: "auto"
          });
        }
      } else if ((!lastMessageIsReceived.current) || (lastMessageIsReceived.current && !refHasUnredMessage.current) || refHasScrollatBottom.current) {
        // set scroll based on the last message is received or send
        // added LastMesageIsReceived and hasUnreadMessage=false 
        listInnerRef.current.scrollTo({
          top: listInnerRef.current.scrollHeight - listInnerRef.current.clientHeight,
          behavior: "auto"
        });
      }
    }
  }, [messageList]);


  useEffect(() => {
    if (conversationStoreDetails?.['conversationDetails']) {
      switch (conversationStoreDetails?.['conversationDetails'].Action) {
        case state.INSERT: {
          setShowGroupSpinner(state.INSERT);
          break;
        }
        case state.MODIFY: {
          setShowGroupSpinner(state.MODIFY);
          break;
        }
        case state.COMPLETED: {
          setShowGroupSpinner(null);
          getMessage({
            variables: {
              ConversationId: oldConversationRef.current,
              Limit: initialLoadCount
            }
          }).catch(err => {console.info(scrollPosition); console.error(err) });
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [conversationStoreDetails]);

  useEffect(() => {
    const messages = client.cache.readQuery({
      query: GET_MESSAGES_BY_CONVERSATION,
      variables: {
        ConversationId: oldConversationRef.current,
        Limit: initialLoadCount
      }
    });
    if (messages?.["getMessageV2"]) {
      if (messages["getMessageV2"]["Messages"].length > 0) {
        let listOfMessagescpy: Message[] = [...(messages["getMessageV2"]["Messages"] as Message[])];
        listOfMessagescpy.sort(function (a, b) {
          let dateA = a.CreatedAt;
          let dateB = b.CreatedAt;
          if (dateA > dateB) {
            return 1;
          }
          return dateA < dateB ? -1 : 0;
        });
        prepareMessagesToRender(listOfMessagescpy);
      }
      setNextToken(
        getMessages?.["getMessageV2"]["NextToken"] ? getMessages["getMessageV2"]["NextToken"] : ""
      );
    }
  }, [getMessages]);

  const groupWithName = props.isGroupConv && props.title;
  const conversationName = props.title ? props.title : "";
  function processOldConvData() {
    oldConversationId = OldConversationData["getOldConversationV3"].ConversationId;
    oldConversationRef.current = oldConversationId;
    recipientDisplayName.current = OldConversationData["getOldConversationV3"].ConversationName;

    if (
      (groupWithName && OldConversationData["getOldConversationV3"].ConversationName !== conversationName) ||
      props.isGroupConv !== OldConversationData["getOldConversationV3"].IsGroupConversation
    ) {
      recipientDisplayName.current = conversationName;
      oldConversationRef.current = "";
    }
    if (oldConversationRef.current != "") {
      getConversationByIdV2({
        variables: {
          ConversationId: oldConversationRef.current,
          UserName: props.userName
        }
      }).catch(err => { console.error(err) });

      getMessage({
        variables: {
          ConversationId: oldConversationRef.current,
          Limit: initialLoadCount
        }
      }).catch(err => { console.error(err) });

      if (props.refOldConversationId) {
        props.refOldConversationId.current = OldConversationData["getOldConversationV3"].ConversationId;
      }
    }
  }

  useEffect(() => {
    if (OldConversationData?.["getOldConversationV3"]) {
      if (OldConversationData["getOldConversationV3"].ConversationId) {
        processOldConvData();
      } else {
        setIsLoading(false);
      }
    }
  }, [OldConversationData]);

  const setErrorState = (errorMessage: string) => {
    setError(true);
  };


  const formElement = props.isRemoved ? style.formelementDeactivated : style.formelement;

  if (illegalAccess || error) {
    const errorContent = "Sorry, but we can't show your messages at the moment. Try again later.";

    return (
      <FormSection className={style.formsection}>
        <FormElement className={formElement}>
          <div className={style.errorCenterContainer}>
            <Stack className={commonStyle.stackTemplate}>
              <FeaturedIcon>
                <SystemProblem color="primary" size="x-large" title="SystemProblem" />
              </FeaturedIcon>
              <EmptyStatePanel header="Something went wrong"
                content={errorContent}
              />
              <div>
                <Button variant="filled" onClick={() => {
                  setError(false);
                  getgroupConversation({
                    variables: {
                      ConversationId: oldConversationRef.current
                    }
                  });
                  getConversationByIdV2({
                    variables: {
                      ConversationId: oldConversationRef.current,
                      UserName: props.userName
                    }
                  }).catch(err => { console.error(err) });

                  getMessage({
                    variables: {
                      ConversationId: oldConversationRef.current,
                      Limit: initialLoadCount
                    }
                  }).catch(err => { console.error(err) });
                }
                }>
                  <Refresh title="Edit" />
                  Try again
                </Button>
              </div>
            </Stack>
          </div>
        </FormElement>
      </FormSection>
    );
  }
  const checkFormelementStyle = props.isRemoved ? style.formsectionRemoved : style.formsection;
  if (checkLoadingDetails([isLoading, messagesLoading, loadingOldConversation, loadingConversation])) {
    props.setLoading(true);
    return (
      <FormSection className={checkFormelementStyle}>
        <FormElement className={formElement}>
          <div className={style.spinner}>
            <ProgressSpinner text="Loading messages..." />
          </div>
        </FormElement>
      </FormSection>
    );
  } else if (showGroupSpinner == state.INSERT || props.isSmallGroupCreating) {
    props.setLoading(true);
    return (
      <FormSection className={checkFormelementStyle}>
        <FormElement className={formElement}>
          <div className={style.conversationSpinner}>
            <ProgressSpinner text="Group setup is in progress. It may take a few moments to send your message." />
          </div>
        </FormElement>
      </FormSection>
    );
  } else {
    props.setLoading(false);
  }


  // fetch more message when reaching top of the scroll
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight } = listInnerRef.current;
      refHasScrollatBottom.current = false;
      setScrollPosition((prev) => {
        refHasScrollMovedAtBottom.current = false;
        if (prev && prev > (listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop))
          refHasScrollMovedAtBottom.current = true;
        return listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop;
      });


      if (scrollTop === 0 && nextToken.length) {
        //show loading on top of the chat history to indicat loading more messages.
        setMoreMessageLoading(true);
        fetchMoreMessages(oldConversationRef.current).catch(err => { console.error(err) });
        //setting the scroll height to get next scroll position
        setNextScrollPosition(scrollHeight);
        refHasNextScroll.current = true;
      } else {
        refHasNextScroll.current = false;
      }
      if (listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop === listInnerRef.current.clientHeight) {
        refHasScrollatBottom.current = true;
      }
      if ((listInnerRef.current.scrollHeight - listInnerRef.current.scrollTop === listInnerRef.current.clientHeight) && 
      refHasScrollMovedAtBottom.current)
        props.readMessageCallBack(oldConversationRef.current, userName.toLowerCase(), orgId, updateMessageCache);

      // this is temporary fix to not to freez scroll at top.
      SetScrollAtTop(scrollTop, nextToken, listInnerRef);
    }
  };


  const newFragment =
    oldConversationRef.current !== "" ? <div /> :
      <Stack className={commonStyle.stackTemplate}>
        <FeaturedIcon>
          <Forum color="var(--primary-dark)" size="x-large" title="Conversation" />
        </FeaturedIcon>
        <EmptyStatePanel header={initialChatContent} content={"Type your first message below."} />
        <div>
        </div>
      </Stack>


  const listGroup = props.isRemoved ? style.listgroupDeactivated : style.listgroup;

  const newMessageButtonClick = () => {
    if (refDetails?.[lastRead]?.current) {
      refDetails[lastRead].current.scrollIntoView({
        behavior: "instant",
        block: "center"
      });
      setNewMessageBanner(false);
    }
  }

  function createNewBadge(unreadId: string, hasNewMessage: boolean, items: any[], element: any, refs: any) {
    if (unreadId != "" && hasNewMessage && !isBadgeAdded.current) {
      isBadgeAdded.current = true;
      items.push({
        children: (
          <div className={style.newBadgeContainer} key={element.CreatedAt_MessageId} ref={refs[element.CreatedAt_MessageId]}>
            <div className={style.newMessageLine}></div>
            <div id="newBadge" ref={ref} className={style.badgeAlign}><Badge children=" New " /></div>
          </div>
        )
      });
      hasNewMessage = false;
    }
    return { hasNewMessage };
  }

  function SetScrollAtTop(scrollTop: number, nextToken: string, listInnerRef: React.MutableRefObject<HTMLDivElement>) {
    if (scrollTop === 0 && (!nextToken || nextToken.length === 0)) {
      listInnerRef.current.scrollTo({
        top: 1,
        behavior: "auto"
      });
    }
  }

  function assignLastread(unreadId: string, refHasUnredMessage: React.MutableRefObject<boolean>, setLastRead: React.Dispatch<React.SetStateAction<string>>, keyId: any) {
    if (unreadId === "") {
      refHasUnredMessage.current = false;
      setLastRead(keyId);
    }
  }

  interface ICardElementProps {
    element: any;
    refs: any;
    isRecievedMessage: boolean;
    calculateIsUnread: (unreadId: string) => boolean;
    unreadId: string;
    messageDisplayProps: IMessageDisplayComponentProps;
  }

  function createCardElement(props: ICardElementProps) {
    function cardComponentUtil() {
      return <CardComponent
        text={props.element.Content}
        time={getLocalTimeString(props.element.CreatedAt)}
        isReceived={props.isRecievedMessage}
        isUrgent={props.element.IsUrgent}
        isUnread={calculateIsUnread(unreadId)}
        isGroup={props.messageDisplayProps.isGroupConv}
        authorId={props.element.AuthorId}
        organisationId={props.messageDisplayProps.organisationId}
        isLastMessage={(lastSentMessageIdRef.current == props.element.CreatedAt_MessageId)}
        isRead={props.element.IsRead}
      ></CardComponent>;
    }

    return !isBadgeAdded.current ? <div key={props.element.CreatedAt_MessageId} ref={refs[props.element.CreatedAt_MessageId]}>
      {cardComponentUtil()}
    </div> :
      <div>
        {cardComponentUtil()}
      </div>;
  }

  function createSystemMessageCard(element: any, content: string, userList: React.MutableRefObject<any[]>, items: any[]) {
    if (element.Content != "") {
      content = FormatSystemMessage(element.IsSystemMessage, element.Content, userList.current);
      items.push({
        children: (<SystemMessage title={content} time={getLocalTimeString(element.CreatedAt)} />
        )
      });
    }
    return content;
  }

  return (
    <div className={style.containerDiv}>
      <div className={checkFormelementStyle} onScroll={onScroll}
        ref={listInnerRef} data-testid="message-scroll">
        <FormSection>
          {messageList.length > 0 ? (
            <FormElement className={formElement}>
              <div
                id="scrollId"
                data-testid="scrollId"
                className={listGroup}
              >
                {moreMessageLoading && (
                  <div className={style.loadMoreProgress}>
                    <ProgressSpinner small={true} text="Loading more messages..." />
                  </div>
                )}
                {messageList}
              </div>
            </FormElement>
          ) : (
            <FormElement className={style.newMessageFormElement}>
              <div className={style.newMessageContainer}>{newFragment}</div>
            </FormElement>
          )}
        </FormSection>
      </div>
      {newMessageBanner && <div className={style.containerBanner} >
        <div className={style.newMessageBanner}>
          <Button data-testid="message-banner" size="small" variant="filled" onClick={newMessageButtonClick}><ArrowDown title="" /> New messages</Button>
        </div>
      </div>}
    </div>
  );
};

export default React.memo(MessageDisplayComponent);

function checkLoadingDetails(isLoading: boolean[]) {
  return isLoading.some(x => x);
}
