import { V2UserConversation } from "../types/userConversation";
import ChatPanel from "./ChatPanel";
import { ISendMessageStateProps } from "./SendMessage";
import { User } from "../utils/user";
import { V2MessageInfo } from "../types/routeConversationDetails";
import React from "react";
import PreviewPanelConversationsLoading from "./PreviewPanelConversationsLoading";

export interface IContentScreen {
  previewStyleChoice: string;
  panelStyleChoice: string;
  isConversationSelected: boolean;
  refDisplayTitle: string;
  refConversationId: string;
  refIsGroup: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
  isRemoved: boolean;
  memoizedSetSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  setNoMessages: React.Dispatch<React.SetStateAction<boolean>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessageState: ISendMessageStateProps;
  selectedUserERN: string;
  user: User;
  messageInfo: V2MessageInfo;
  localConversation: V2UserConversation;
  refOldConversationId?: React.MutableRefObject<string>;
  setLeaveError: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  removedBy: string;
}

const RightPanelContent: React.FC<IContentScreen> = (props: IContentScreen) => {
  return (
    <>
      <div className={props.previewStyleChoice}>
        <PreviewPanelConversationsLoading
          memoizedSetSendMessageState={props.memoizedSetSendMessageState}
          setNoMessages={props.setNoMessages}
          setIsError={props.setIsError}
          refOldConversationId={props.refOldConversationId}
          refConversationId={props.refConversationId}
          selectedUserERN={props.selectedUserERN}
          localConversation={props.localConversation}
          isGroup={props.refIsGroup}
          setIsRemoved={props.setIsRemoved}
          isRemoved={props.isRemoved}
        />
      </div>
      <div className={props.panelStyleChoice}>
        <ChatPanel
          isConversationSelected={props.isConversationSelected}
          refOldConversationId={props.refOldConversationId}
          refDisplayTitle={props.refDisplayTitle}
          refConversationId={props.refConversationId}
          sendMessageState={props.sendMessageState}
          user={props.user}
          messageInfo={props.messageInfo}
          localConversation={props.localConversation}
          isGroup={props.refIsGroup}
          isRemoved={props.isRemoved}
          setIsRemoved={props.setIsRemoved}
          setLeaveError={props.setLeaveError}
          setDialogOpen={props.setDialogOpen}
          removedBy={props.removedBy}
        />
      </div>
    </>
  );
};

export default React.memo(RightPanelContent);
