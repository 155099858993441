import style from "../styles/MessageListItemComponent.module.scss";
import { ListItem } from "@emisgroup/ui-list";
import { Badge } from "@emisgroup/ui-badge";
import { getLocalTimeString, getMessageDate } from "../utils";
import { useContext, memo } from "react";
import { Participant } from "./ListItemComponent";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import Avatar from "../custom_components/UserAvatar/Avatar";
import Reply from "~icons/ic/baseline-reply";
import { ISendMessageStateProps } from "./SendMessage";

import { UserConversation } from "../types/userConversation";
import { useApolloClient } from "@apollo/client";
import { getLocalGroupConversation } from "../utils/MessageComponent";
import UserGroup from "~icons/ic/outline-groups";
import { UserAvatar } from "@emisgroup/ui-avatar";
import { CONVERSATION_STORE, MESSAGE_UNREAD_COUNT } from "../graphql/queries";
import { state } from "../types/GroupState";

export interface IMessageListItemComponentProps {
  index: number;
  preview: Partial<messageDetails>;
  setSendMessageState?: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  messageReadCallback: Function;
  selectedConversationId: string;
  selectedUserName: string;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}


export type messageDetails = {
  name: string;
  authorId: string;
  message: string;
  time: string;
  isRead: boolean;
  createdAtMessageId: string;
  conversationId: string;
  isReply: boolean;
  isUrgent: boolean;
  participants: Array<Participant>;
  userStatus: boolean;
  organisationId: string;
  unreadCount: number;
  isGroup: boolean;
};


const checkReceivedMessage = (userName: string, author: string) => {
  return userName === author;
}

const checkListLabel = (listLabel: string, time?: string) => {
  return listLabel === "Today" ? getLocalTimeString(time) : listLabel;
}
const MessageListItemComponent = (props: IMessageListItemComponentProps) => {
  const { preview, index } = props;
  let isGroupInProgress: boolean = false;
  //Apollo Client
  const apolloClient = useApolloClient();

  const appContext: IAppContextInterface = useContext(AppContext);
  let user = appContext.user;

  let localGroupConversation: UserConversation;
  localGroupConversation = getLocalGroupConversation(apolloClient.cache);

  const splitDisplayName = (displayName) => {
    const splitName = displayName.split(",");
    const familyName: string = splitName[0].trim();
    const givenName: string = splitName.length > 1 ? splitName[1].trim() : "";
    return [familyName, givenName];
  };

  function clearlocalCache() {
    if (localGroupConversation) {
      const normalizedId = apolloClient.cache.identify(localGroupConversation);
      return apolloClient.cache.evict({ id: normalizedId });
    }
  }

  const findRecipient = (messageDetails) => {
    if (messageDetails.participants) {
      return messageDetails.participants.length > 1 &&
        user.userName.toLowerCase() === messageDetails.participants[0].UserName.toLowerCase()
        ? messageDetails.participants[1].UserName
        : messageDetails.participants[0].UserName;
    } else {
      return "";
    }
  };

  const findSelectedUser = (messageDetails) => {
    if (messageDetails.participants && messageDetails.participants.length <= 2 && !props.selectedConversationId && !messageDetails.isGroup) {
      if (user.userName === props.selectedUserName && messageDetails.participants.length === 1) {
        return messageDetails.participants[0].UserName;
      } else {
        let filterName = messageDetails.participants.find(participant => participant.UserName === props.selectedUserName.toLowerCase() && participant.UserName != user.userName)
        if (filterName)
          return filterName.UserName;
      }
    }
    return undefined;
  }

  const toDispalyMessages = (messageDetails) => {
    if (!isRecievedMessage) {
      messageDetails.isRead = false;
    }
    clearlocalCache();
    const recipient = findRecipient(messageDetails);
    const avatarColor = setAvatarColor(messageDetails);
    props.setSendMessageState((prev) => {
      if (prev.conversationId != messageDetails.conversationId) {
        if (prev.conversationId) props.messageReadCallback(prev.conversationId);
        props.setIsRemoved(() => messageDetails.isRemoved);
        appContext.selectedConversationId.current = messageDetails.conversationId;
        return {
          title: messageDetails.name,
          conversationId: messageDetails.conversationId,
          recipients: [recipient],
          message: messageDetails.message,
          time: messageDetails.time,
          createdAtMessageId: messageDetails.createdAtMessageId,
          authorId: user.userName,
          avatarColor: avatarColor,
          isGroup: preview.isGroup,
          conversationName: "",
          isRemoved: messageDetails.isRemoved,
          removedOn: messageDetails.removedOn,
          removedBy: messageDetails.removedBy,
          hasBeenNamed: messageDetails.hasBeenNamed
        }
      } else {
        return prev;
      }
    });
  };

  let isRecievedMessage = checkReceivedMessage(user.userName.toLowerCase(), preview.authorId);
  if (!preview.name) {
    preview.name = "";
  }

  const [lastName, firstName] = splitDisplayName(preview.name);



  let listLabel = getListLabel(preview);

  const setReplyMessageStyle = (isRecMessage) => {
    return isRecMessage ? style.replyMessageWithIcon : style.replyMessage;
  };
  const setListMessageTextStyle = (isRecMessage) => {
    return isRecMessage ? style.listMessageTextWithIcon : style.listMessageText;
  };
  const setAvatarColor = (messagePreview) => {
    return messagePreview.participants ? findRecipient(messagePreview).toLowerCase() : "";
  };

  const ConversationIcon = preview.isGroup ? (
    <UserAvatar color="featured" size="medium" tooltip="">
      <UserGroup color="mono" size="medium" title="" />
    </UserAvatar>) : (
    <Avatar
      firstName={firstName}
      fullName={preview.name}
      lastName={lastName}
      isAvailable={preview.userStatus}
      color={setAvatarColor(preview)}
      isGroup={false}
      size={"medium"}
    />
  );

  const conversationStoreDetails = apolloClient.cache.readQuery({
    query: CONVERSATION_STORE,
    variables: {
      ConversationId: preview.conversationId
    }
  });


  if (conversationStoreDetails?.['conversationDetails']?.Action == state.INSERT) {
    isGroupInProgress = true;
  }

  let { hasUrgent, unreadCount } = unreadMessageDetails(apolloClient, preview);
  return (
    <div key={"" + index} role={"listItem_" + index} className=
      {getStyleDetails(props, preview, findSelectedUser)}>
      <ListItem divider={ true}  className={style.listItemWidth} data-testid={"listItem_" + index} key={"" + index} action={{
        callback: () => toDispalyMessages(preview),
        disableToggle: true       
      }}
      >
          <div className={style.avatar}>
            {ConversationIcon}
          </div>
          <div className={style.leftElement}>
            <span className={style.listMessageName}>{preview.name}</span>
            {isRecievedMessage && !isGroupInProgress ? (
              <div className={style.replyPosition}>
                <Reply title="Replied" size="medium" />
              </div>
            ) : null}
            {!isGroupInProgress && <div className={setReplyMessageStyle(isRecievedMessage)}>
              <span className={setListMessageTextStyle(isRecievedMessage)} dangerouslySetInnerHTML={{ __html: preview.message }}></span>
            </div>}
          </div>
          <div className={style.rightElements}>
            {!isRecievedMessage && !hasUrgent && unreadCount > 0 ? (
              <div className={style.badgeDisplay}>
                <div className={style.badgeDivHeight}>
                  <Badge children={unreadCount >= 100 ? "99+" : unreadCount} />
                </div>
              </div>
            ) : null}
            {!isRecievedMessage && hasUrgent && unreadCount > 0 ? (
              <div className={style.badgeDisplay}>
                {" "}
                <Badge children="Urgent" variant="danger" />
                {" "}
              </div>
            ) : null}
            {!isGroupInProgress &&
              <span className={unreadCount == 0 || isRecievedMessage ? style.readListTime : style.listTime}>
                {checkListLabel(listLabel, preview.time)}
              </span>}
          </div>
      </ListItem>
    </div>
  );
};

export default memo(MessageListItemComponent);

function getStyleDetails(props: IMessageListItemComponentProps, preview: Partial<messageDetails>, findSelectedUser: (messageDetails: any) => any): string {
  return props.selectedConversationId === preview.conversationId ||
    (!props.selectedConversationId && props.selectedUserName === findSelectedUser(preview))
    ? style.listItemSelected : style.listItem;
}

function unreadMessageDetails(apolloClient, preview: Partial<messageDetails>) {
  let cachedCountStore = apolloClient.cache.readQuery({
    query: MESSAGE_UNREAD_COUNT,
    variables: {
      ConversationId: preview.conversationId
    }
  });
  let unreadCount = cachedCountStore?.['conversationDetails'].UnreadCount ?? preview.unreadCount;
  let hasUrgent = cachedCountStore?.['conversationDetails'].HasUrgent ?? preview.isUrgent;
  return { hasUrgent, unreadCount };
}

function getListLabel(preview: Partial<messageDetails>) {
  let listLabel: string = "";
  const date = getMessageDate(preview.time).toDateString();
  listLabel = new Date(date)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric"
    })
    .split(" ")
    .join("-");

  if (date == new Date().toDateString()) {
    listLabel = "Today";
  } else if (date == new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()) {
    listLabel = "Yesterday";
  }
  return listLabel;
}
