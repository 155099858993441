import { useState, useEffect } from "react";
import { withTopbar } from "./hoc/withTopbar";
import style from "../styles/ContactList.module.scss";
import Contacts from "./Contacts";
import { useLocation, useNavigate } from "react-router-dom";
import { IUser } from "../utils/user";
import { MessageInfo } from "../types/routeConversationDetails";

export interface IContactListProps extends IUser {}

const ContactList: React.FC<IContactListProps> = (props: IContactListProps) => {
  const { userName, organisationId } = props;
  const { state } = useLocation();
  const [displayName, setDisplayName] = useState("");
  const [recipientId, setRecipientId] = useState("");
  const nav = useNavigate();

  const messageInfo: MessageInfo = {
    title: displayName,
    recipients: [recipientId],
     // Note: WHEN NEW API SCHEMA AVAILABLE/NEW RESOLVER AVAILABLE
     // CHANGES: USE UserERN instead userName.
    userName: state ? (state as IContactListProps).userName : userName,
    isGroup: false
  };
  useEffect(() => {
    if (displayName) {
      nav("/staff/landing-page/", {
        state: messageInfo
      });
    }
  });

  return (
    <div className={style.contacts}>
      {" "}
      <Contacts
        debug={false}
        organisationId={state ? (state as IContactListProps).organisationId : organisationId}
        displayName={displayName}
        setUserName={setDisplayName}
        recipientId={recipientId}
        setRecipientId={setRecipientId}
     //Note: WHEN NEW API SCHEMA AVAILABLE/NEW RESOLVER AVAILABLE
     // CHANGES: USE UserERN instead userName.
        userName={state ? (state as IContactListProps).userName : userName}
        isGroupMessage={false}
      ></Contacts>
    </div>
  );
};

export default withTopbar(ContactList, false, "Select a contact");
