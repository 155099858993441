/**
 * Utils class to manage MasterPage cache operations.
 */
import { GET_LOCAL_CONVERSATION_AND_MESSAGE, GET_MESSAGES_BY_CONVERSATIONID } from "../../../graphql/queries";
import { useApolloClient, ApolloClient, useLazyQuery } from "@apollo/client";
import * as queries from "../../../graphql/queries";
import { V2User } from "../../../types/user";
import { MessageReadStatusNotificationV2 } from "../../../types/messageReadStatus";
import { User as user } from "../../../utils/user";
import { FormatSystemMessageV2 } from "../../../utils/MessageComponent";
import { IConversationByUser } from "../../../types/userConversation";
import { GET_USERCONVERSATION_FRAGMENT } from "../../MultipleContact/schema";

export class CacheUtil {
  initialLoadCount: number;
  apolloClient: ApolloClient<Object>;

  constructor() {
    this.initialLoadCount = parseInt(process.env.INITIAL_LOAD_COUNT);
    this.apolloClient = useApolloClient();
  }

  compare(a, b) {
    return b.time - a.time;
  } 
  appendMessageInCache(message: any, user: user) {
    let cachedMessages = this.apolloClient.cache.readQuery({
      query: GET_MESSAGES_BY_CONVERSATIONID,
      variables: {
        ConversationId: message.ConversationId,
        Limit: this.initialLoadCount
      }
    });
    if (cachedMessages?.["getMessage"]) {
      let isExist = cachedMessages["getMessage"].Messages.find((x) => x.MessageId === message.MessageId);
      if (!isExist) {
        this.apolloClient.writeQuery({
          query: GET_MESSAGES_BY_CONVERSATIONID,
          variables: {
            ConversationId: message.ConversationId,
            Limit: this.initialLoadCount
          },
          data: {
            getMessage: {
              __typename: "MessagePages",
              id: cachedMessages["getMessage"].id,
              Messages: [...cachedMessages["getMessage"].Messages, { __typename: "Message", ...message }],
              NextToken: cachedMessages["getMessage"].NextToken
            }
          }
        });
      }
    }

    cachedMessages = this.apolloClient.cache.readQuery({
      query: queries.GET_LOCAL_CONVERSATION_AND_MESSAGE,
      variables: {
        Id: "all"
      }
    });
    if (cachedMessages?.["getLocalConversationAndMessage"]) {
      let filterList = [...cachedMessages["getLocalConversationAndMessage"]];
      const lastUpdatedOn= this.getLastUpdatedOn( message.ConversationId, user.userERN )
      let localMessages = filterList.map((x) => {
        if (x.conversationId === message.ConversationId) {
          return {
            ...x,
            message: message.IsSystemMessage ? FormatSystemMessageV2(true,message.Content, this.readUser(user.organisationId)) : message.Content,
            time: message.MessageCreatedAt,
            lastMessageTime: message.MessageCreatedAt,
            authorId: message.AuthorERN,
            isRead: this.getIsReadStatus(message,user, lastUpdatedOn),
            isReply: message.AuthorERN == user.userERN,
            isUrgent: message.IsUrgent
          };
        }
        return x;
      });
      let arrayForSort = localMessages;
      arrayForSort.sort(this.compare);
      filterList = arrayForSort;

      const NonUrgentMessageList = filterList.filter(
        (lists) => lists.isRead || user.userERN === lists.authorId || (!lists.isUrgent && !lists.isRead)
      );
      const urgentMessageList = filterList.filter(
        (element) => element.isUrgent && user.userERN !== element.authorId && !element.isRead
      );
      Array.prototype.push.apply(urgentMessageList, NonUrgentMessageList);
      filterList = urgentMessageList;

      this.apolloClient.writeQuery({
        query: queries.GET_LOCAL_CONVERSATION_AND_MESSAGE,
        variables: {
          Id: "all"
        },
        data: {
          getLocalConversationAndMessage: [...filterList]
        }
      });
    }
  }
  
  //#endregion

  //#region User
  //read user
  readUser(organisationId: string): V2User[] {
    const result = this.apolloClient.readQuery({
      query: queries.GET_NEW_USERS,
      variables: {
        OrganisationERN: organisationId
      }
    });
    if (result["getUser"]?.length > 0) return result["getUser"] as V2User[];
    return [];
  }
  getLastUpdatedOn(conversationId, userERN): string {
    const result : IConversationByUser = this.apolloClient.cache.readFragment({
      id: `ChatUserConversation:${conversationId}_${userERN}`,
      fragment: GET_USERCONVERSATION_FRAGMENT
    });  
    return result?.LastUpdatedOn;
  }
  getIsReadStatus(message,user,lastUpdatedOn):boolean{
    if (message.IsSystemMessage){
      if (message.AuthorERN == user.userERN)
        return true
      else
        return false
    }
    else{
      if((new Date(parseInt(message.MessageCreatedAt)) < new Date(parseInt(lastUpdatedOn))))
        return true
      else 
        return false
    }
  }
  //write user
  writeUser(organisationId: string, users: V2User[]) {
    this.apolloClient.writeQuery({
      query: queries.GET_NEW_USERS,
      variables: {
        OrganisationERN: organisationId
      },
      data: {
        getUser: [...users]
      }
    });
  }
  //#endregion
  newMessageCacheUpdates(conversationId: string, userERN: string, authorERN: string) {
    //with limit
    let receivedData;
    let sentData;
    const cacheLimit = this.apolloClient.cache.readQuery({
      query: GET_MESSAGES_BY_CONVERSATIONID,
      variables: {
        ConversationId: conversationId,
        Limit: this.initialLoadCount
      }
    });
    if (cacheLimit?.["getMessage"]) {
      const receivedMessageData = cacheLimit["getMessage"]["Messages"]?.filter((item) => item.AuthorERN != userERN);
      const sentMessageData = cacheLimit["getMessage"]["Messages"]?.filter((item) => item.AuthorERN == userERN);
      if (authorERN == userERN) {
        receivedData = receivedMessageData?.map((item) => ({ ...item, IsRead: true }));
        sentData = sentMessageData;
      } else {
        receivedData = receivedMessageData;
        sentData = sentMessageData?.map((item) => ({ ...item, IsRead: true }));
      }
      this.apolloClient.writeQuery({
        query: GET_MESSAGES_BY_CONVERSATIONID,
        variables: {
          ConversationId: conversationId,
          Limit: this.initialLoadCount
        },
        data: {
          getMessage: {
            __typename: "MessagePages",
            id: cacheLimit["getMessage"].id,
            Messages: [...receivedData, ...sentData],
            NextToken: cacheLimit["getMessage"].NextToken
          }
        }
      });
    }
  }
  newConvMsgCacheUpdates(conversationId: string, userERN: string, authorERN: string) {
    const cacheLimit = this.apolloClient.cache.readQuery({
      query: GET_LOCAL_CONVERSATION_AND_MESSAGE,
      variables: {
        Id: "all"
      }
    });
    if (cacheLimit?.["getLocalConversationAndMessage"]) {
      const messageData = cacheLimit["getLocalConversationAndMessage"]?.map((item) => {
        if (item.conversationId == conversationId && authorERN == userERN) {
          return { ...item, isRead: true, isUrgent: false };
        }
        return item;
      });
      this.apolloClient.writeQuery({
        query: GET_LOCAL_CONVERSATION_AND_MESSAGE,
        variables: {
          Id: "all"
        },
        data: {
          getLocalConversationAndMessage: [...messageData]
        }
      });
    }
  }
  //#region Update message status using MessageReadStatusNotificationV2
  updateMessageStatusInCache(readStatus: MessageReadStatusNotificationV2, user: user) {
    if (!readStatus.IsRead) return;
    this.newMessageCacheUpdates(readStatus.ConversationId, user.userERN, readStatus.AuthorERN); //update message cache with updateFragment
    this.newConvMsgCacheUpdates(readStatus.ConversationId, user.userERN, readStatus.AuthorERN); //update conversation and message cache with updateFragment
  }
  //#endregion
}
