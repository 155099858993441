import * as React from "react";
import commonStyle from "../styles/Common.module.scss";
import { UserConversation } from "../types/userConversation";
import { User } from "../utils/user";
import SendMessage, { ISendMessageStateProps } from "./SendMessage";
import { MessageInfo } from "../types/routeConversationDetails";
import Forum from "~icons/ic/twotone-forum";
import { FeaturedIcon } from "@emisgroup/ui-icon";
import EmptyStatePanel from "./EmptyStatePanel";
import { Stack } from "@emisgroup/ui-layouts";

export interface IChatPanelProps {
  isConversationSelected: boolean;
  refDisplayTitle: string;
  refConversationId: string;
  sendMessageState: ISendMessageStateProps;
  user: User;
  messageInfo: MessageInfo;
  localConversation: UserConversation;
  isGroup: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
  setLeaveError: React.Dispatch<React.SetStateAction<boolean>>;
  isRemoved: boolean;
  refOldConversationId?: React.MutableRefObject<string>;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  removedBy: string;
}

function isGroup(
  sendMessageState: ISendMessageStateProps,
  messageInfo: MessageInfo,
  localConversation: UserConversation,
  propsGroup: boolean
): boolean {
  if (localConversation) {
    return localConversation.IsGroupConversation;
  } else if (sendMessageState && Object.keys(sendMessageState).length > 1) {
    return sendMessageState.isGroup;
  } else if (messageInfo) {
    return messageInfo.isGroup;
  } else {
    return propsGroup;
  }
}


const ChatPanel: React.FC<IChatPanelProps> = (props: IChatPanelProps) => {

  //To find is group chat or not!
  let isGroupChat: boolean = isGroup(props.sendMessageState, props.messageInfo, props.localConversation, props.isGroup);

  let localConversation: MessageInfo = props.messageInfo;
  let hasBeenNamed: boolean = false;

  if (props.localConversation) {
    hasBeenNamed = props.localConversation.HasBeenNamed;
    localConversation = {
      isGroup: props.localConversation.IsGroupConversation ? props.localConversation.IsGroupConversation : false,
      recipients: props.localConversation.Participants.map((item) => item.UserName),
      title: props.localConversation.ConversationName,
      userName: props.localConversation.UserName
    };
  }

  return (
    <>
      {props.isConversationSelected ? (
        <>
          {/* This is for existing Conversation. it can be group or non-group */}
          {(props.refConversationId != undefined || props.sendMessageState.conversationId) && (
            <SendMessage
              {...props.user}
              {...props.messageInfo}
              {...props.sendMessageState}
              conversationId={
                props.refConversationId ? props.refConversationId : props.sendMessageState.conversationId
              }
              isGroupConv={isGroupChat}
              conversationName={props.refDisplayTitle}
              isRemoved={props.isRemoved}
              removedBy={props.removedBy}
              {...localConversation}
              refOldConversationId={props.refOldConversationId}
              setDialogOpen={props.setDialogOpen} setIsRemoved={props.setIsRemoved} setLeaveError={props.setLeaveError}
              isConversationSelected={props.isConversationSelected}
            />
          )}
          {/* This is for new Converation. it can be group or non-group  */}
          {localConversation && !props.refConversationId && (
            <SendMessage
              {...props.user}
              isGroupConv={isGroupChat}
              {...props.sendMessageState}
              {...localConversation}
              conversationName={props.refDisplayTitle}
              hasBeenNamed={hasBeenNamed}
              isRemoved={props.isRemoved}
              removedBy={props.removedBy}
              refOldConversationId={props.refOldConversationId}
              setDialogOpen={props.setDialogOpen} setIsRemoved={props.setIsRemoved} setLeaveError={props.setLeaveError}
              isConversationSelected={props.isConversationSelected}
            />
          )}
        </>
      ) : (
        <div className={commonStyle.dontShow}>
          <div className={commonStyle.nothingSelectedPanel}>
            <Stack className={commonStyle.stackTemplate}>
              <FeaturedIcon>
                <Forum color="var(--primary-dark)" size="x-large" title="Conversation" />
              </FeaturedIcon>
              <EmptyStatePanel header="Nothing selected" content="Select a conversation to view the messages." />
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatPanel;