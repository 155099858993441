import React from "react";
import style from "../styles/ListItemComponent.module.scss";
import MessageListItem from "./MessageListItem";
import { ISendMessageStateProps } from "./SendMessage";


export interface IListItemComponentProps {
  previewList?: Partial<messageDetails>[];
  setSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  messageReadCallback: Function;
  refConversationId: string;
  selectedUserName: string;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}

export type messageDetails = {
  name: string;
  authorId: string;
  message: string;
  time: string;
  isRead?: boolean;
  createdAtMessageId: string;
  conversationId: string;
  isReply: boolean;
  isUrgent: boolean;
  participants: Array<Participant>;
  userStatus: boolean;
  organisationId: string;
  unreadCount: number;
  isGroup: boolean;
  isRemoved: boolean;
  removedOn: string;
  removedBy: string;
  hasBeenNamed: boolean;
};

export type Participant = {
  OrganisationId: string;
  UserName: string;
};


const ListItemComponent = (props: IListItemComponentProps) => {
  const { previewList } = props;
  let selectedConversationId = "";
  props.setSendMessageState((prev) => {
    selectedConversationId = prev.conversationId
    return prev
  });
  if (!selectedConversationId) selectedConversationId = props.refConversationId
  return (
    
      <div className={style.listDiv}>
        {previewList.map((element, index, { length }) => {
          return (
            <div key={"ListItem-" + index} id={"listItem_content"}  >
              <MessageListItem
                index={index}
                preview={element}
                setSendMessageState={props.setSendMessageState}
                messageReadCallback={props.messageReadCallback}
                selectedConversationId={selectedConversationId}
                selectedUserName={props.selectedUserName}
                isGroup={props.isGroup}
                setIsRemoved={props.setIsRemoved}
              />
            </div>
          );
        })}

    </div>
  );
};

export default React.memo(ListItemComponent);