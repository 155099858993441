import * as React from "react";
import { IMessageDetails, V2UserConversation } from "../types/userConversation";
import ListItemComponent, { messageDetails } from "./ListItemComponent";
import { useState } from "react";
import style from "../styles/Common.module.scss";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { AppContext } from "../utils/ApplicationContext";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { ISendMessageStateProps } from "./SendMessage";

import { V2User } from "../types/user";
import { checkLastMessageTime, checkLastMessageTimeIfRemoved, getUserDisplayName } from "../utils";
import { useApolloClient } from "@apollo/client";
import { GET_LOCAL_CONVERSATION_AND_MESSAGE } from "../graphql/queries";
import { FormatSystemMessageV2 } from "../utils/MessageComponent";

export interface IMessagePreviewProps {
  userConversations: V2UserConversation[];
  messageThread: IMessageDetails[];
  users: React.MutableRefObject<V2User[]>;
  rerenderCount: number;
  setSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  messageReadCallback: Function;
  refConversationId: string;
  selectedUserERN: string;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}

let completeLoad = false;
let localHasMessages = false;

const MessagePreview: React.FunctionComponent<IMessagePreviewProps> = (props: IMessagePreviewProps) => {
  const [isMessageLoaded, setIsMessageLoaded] = useState(false);
  const [messageList, setMessageList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [reOrder, setReOrder] = useState(false);
  const client = useApolloClient();

  let messageRenderList: messageDetails[] = [];

  //get user context
  const appContext: IAppContextInterface = React.useContext(AppContext);
  let user = appContext.user;

  function compare(a, b) {
    return b.time - a.time;
  }

  function compareCreatedAt(a, b) {
    if (b.LastMessageTime === a.LastMessageTime) {
      return -1;
    }
    return b.LastMessageTime > a.LastMessageTime ? 1 : -1;
  }

  function getUserName(userERN) {
    if (props.users.current && props.users.current.length > 0) {
      return props.users.current.find((item) => item.UserERN == userERN);
    }
    return null;
  }

  const getConvDisplayName = (item) => {
    return user.userERN !== item.ParticipantList[0].split("_")[1] || item.ParticipantList.length === 1
      ? getUserName(item.ParticipantList[0].split("_")[1])
      : getUserName(item.ParticipantList[1].split("_")[1]);
  };

  function checkUrgetMessage(item, conversation) {
    if (item.LastUrgentMessageTime !== null && conversation.LastUpdatedOn !== null) {
      if (new Date(parseInt(item.LastUrgentMessageTime)) >= new Date(parseInt(conversation.LastUpdatedOn))) return true;
      else return false;
    } else {
      return false;
    }
  }
  const getParticipantName = (item) => {
    return !item.ParticipantList ? getUserName(item.AuthorId) : getConvDisplayName(item);
  };
  const getNameDetails = (name) => {
    return {
      displayName: getUserDisplayName(name),
      userStatus: name.UserStatus,
      organisationERN: name.OrganisationERN
    };
  };
  // Helper function to get last message, time, and removal status
  const getMessageDetails = (item) => {
    let [lastMessage, lastMessageTime] = checkLastMessageTime(item);
    let isRemoved = !!item.RemovedFromConversationOn;

    if (isRemoved) {
      [lastMessage, lastMessageTime] = checkLastMessageTimeIfRemoved(item);
    }
    return { lastMessage, lastMessageTime, isRemoved };
  };
  // Helper function to check if the current user is removed
  const isUserRemoved = (item) => {
    let removedParticipants = item.OldParticipantList.filter(
      (participant) => item.ParticipantList.indexOf(participant) < 0
    );
    return removedParticipants.includes(user.organisationId + "_" + user.userERN);
  };
  const getIsReadStatus =( item, conversation,lastMessageTime ): boolean => {
    if(item.IsSystemMessage)
    {
      if(item.LastMessageAuthorId == user.userERN)
        return true;
      else return false
    }
    else{
      if((new Date(parseInt(lastMessageTime)) < new Date(parseInt(conversation.LastUpdatedOn))))
        return true;
      else return false 
    }
  }
  const processUnrepliedMessages = (item, filteredConv) => {
    const checkExistingConversation = (obj) => obj.conversationId === item.ConversationId;

    const name = getParticipantName(item);
    let displayName = "",
      userStatus = "",
      organisationERN = "";
    if (name) {
      ({ displayName, userStatus, organisationERN } = getNameDetails(name));
    }
    if (!messageRenderList.some(checkExistingConversation)) {
      let { lastMessage, lastMessageTime, isRemoved } = getMessageDetails(item);
      if (item.RemovalStartedOn) {
        isRemoved = isUserRemoved(item);
      }

      const messageDetail: messageDetails = {
        message: FormatSystemMessageV2(item.IsSystemMessage, lastMessage, props.users.current),
        name: item.IsGroupConversation ? item.ConversationName : displayName,
        type_Id: item.Type_Id,
        authorId: item.LastMessageAuthorId,
        time: lastMessageTime,
        isRead: //getIsReadStatus(item,filteredConv,lastMessageTime),
          // item.IsSystemMessage? item.LastMessageAuthorId == user.userERN
          (new Date(parseInt(lastMessageTime)) < new Date(parseInt(filteredConv.LastUpdatedOn))),
        conversationId: item.ConversationId,
        lastMessageTime: lastMessageTime,
        isReply: item.LastMessageAuthorId == user.userERN,
        isUrgent: checkUrgetMessage(item, filteredConv),
        participantList: item.ParticipantList,
        userStatus: userStatus.toLowerCase() === "online",
        organisationId: organisationERN,
        isGroup: item.IsGroupConversation,
        isRemoved: isRemoved,
        removedOn: item.RemovedFromConversationOn,
        removedBy: item.RemovedBy,
        lastUpdatedOn: filteredConv.LastUpdatedOn,
        hasBeenNamed: item.HasBeenNamed
      };
      if (isRemoved && messageDetail.isGroup) {
        messageDetail.authorId = item.LastMessageAuthorIdWhenRemoved;
        messageDetail.name = item.ConversationNameWhenRemoved;
        // messageDetail.isRead = true;
        messageDetail.isReply = item.LastMessageAuthorIdWhenRemoved == user.userERN;
        messageDetail.participantList = item.ParticipantListWhenRemoved;
      }
      messageRenderList.push(messageDetail);
    }
  };

  function sortMessageListForRender(messageListForRender: messageDetails[]) {
    let arrayForSort = [...messageListForRender];
    arrayForSort.sort(compare);
    messageListForRender = arrayForSort;

    const NonUrgentMessageList = messageListForRender.filter(
      (lists) => lists.isRead || user.userERN === lists.authorId || (!lists.isUrgent && !lists.isRead)
    );
    const urgentMessageList = messageListForRender.filter(
      (element) => element.isUrgent && user.userERN !== element.authorId && !element.isRead
    );
    Array.prototype.push.apply(urgentMessageList, NonUrgentMessageList);
    messageListForRender = urgentMessageList;

    return messageListForRender;
  }

  const V2FormatSystemMessage = (isSystemMessage: boolean, content: string, users: V2User[]) => {
    if (isSystemMessage) {
      //content = <OrgId_UserName> removed
      let actualContent = content.substring(0, content.indexOf(" ")); // <OrgId_UserName>
      if (actualContent.indexOf("<") > -1) {
        let [orgERN, ...userERN] = actualContent.substring(1, actualContent.length - 1).split("_");

        if (orgERN && userERN) {
          let filteredUser = users.find((u) => u.UserERN == userERN.join("_"));
          if (filteredUser) {
            return content.replace(actualContent, getUserDisplayName(filteredUser));
          } else {
            return content.replace(actualContent, userERN.join("_"));
          }
        }
      }
      return content;
    } else return content;
  };

  function pushToMessageRenderList(messages: any[]) {
    props.userConversations.forEach((item) => {
      let filteredMessages = messages.find((i2) => i2.ConversationId === item.ConversationId);
      if (filteredMessages) processUnrepliedMessages({ ...item, ...filteredMessages }, item);
    });

    let messageList = sortMessageListForRender(messageRenderList);
    //create write query for messaedetails into cache GET_LOCAL_CONVERSATION_AND_MESSAGE
    client.cache.writeQuery({
      query: GET_LOCAL_CONVERSATION_AND_MESSAGE,
      variables: {
        Id: "all"
      },
      data: {
        getLocalConversationAndMessage: [...messageList]
      }
    });

    setMessageList(messageList);

    if (messageRenderList.length > 0) {
      setIsMessageLoaded(true);
    } else {
      setIsMessageLoaded(false);
    }
  }

  React.useEffect(() => {
    pushToMessageRenderList(props.messageThread);
  }, [props.messageThread]);

  React.useEffect(() => {
    localHasMessages = false;
    completeLoad = true;
    setIsLoading(false);
  }, [props.userConversations]);

  React.useEffect(() => {
    localHasMessages = isMessageLoaded;
  }, [isMessageLoaded]);

  React.useEffect(() => {
    if (reOrder === true) {
      let newList = messageList as messageDetails[];
      setMessageList(sortMessageListForRender(newList));
      setReOrder(false);
    }
  }, [reOrder]);

  return (
    <>
      {isLoading ? (
        <div className={style.spinner}>
          <ProgressSpinner text="Loading messages..." />
        </div>
      ) : (
        <div />
      )}
      {isMessageLoaded ? (
        <ListItemComponent
          key={props.rerenderCount}
          setSendMessageState={props.setSendMessageState}
          messageReadCallback={props.messageReadCallback}
          refConversationId={props.refConversationId}
          selectedUserERN={props.selectedUserERN}
          isGroup={props.isGroup}
          setIsRemoved={props.setIsRemoved}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default React.memo(MessagePreview);
