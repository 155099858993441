import { gql } from "@apollo/client";

export const GET_MESSAGES_BY_CONVERSATION = gql`
  query GetMessagesByConversation($ConversationId: ID!, $IsRead: Boolean, $Limit: Int, $NextToken: String) {
    getMessageV2(ConversationId: $ConversationId, IsRead: $IsRead, Limit: $Limit, NextToken: $NextToken) {
      id
      Messages {
        id
        AuthorId
        Content
        ConversationId
        IsRead
        IsReplied
        IsUrgent
        CreatedAt
        IsSystemMessage
        CreatedAt_MessageId
      }
      NextToken
    }
  }
`;

export const GET_LOCAL_MESSAGES = gql`
  query GetLocalMessages($Id: String) {
    getLocalMessages(Id: $Id) {
      id
      AuthorId
      Content
      ConversationId
      IsRead
      IsReplied
      IsUrgent
      CreatedAt
      IsSystemMessage
      CreatedAt_MessageId
    }
  }
`;

export const GET_CONVERSATION_BY_ID = gql`
  query GetConversationById($ConversationId: ID!, $UserName: String) {
    getConversationByIdV2(ConversationId: $ConversationId, UserName: $UserName) {
      ConversationId
      ConversationName
      DeletedOn
      IsDeleted
      LastUpdatedOn
      OrganisationId
      HasBeenNamed
      RemovedOn
      IsGroupConversation
      IsRemoved
      RemovedBy
      Participants {
        OrganisationId
        UserName
      }
      ParticipantList
      ParticipantHash
      UserName
      UnreadCount
      HasUrgent
      AddedToConversationOn
      IsConversationHistoryShared
    }
  }
`;

export const CONVERSATION_READ = gql`
  query conversationRead($UserName: String) {
    conversationRead(UserName: $UserName) {
      ConversationId
    }
  }
`;

//Conversation query
export const GET_USER_CONVERSATIONS = gql`
  query GetUserConversations($OrganisationId: ID!, $UserName: String!) {
    getConversationByUserV3(OrganisationId: $OrganisationId, UserName: $UserName) {
      ConversationId
      LastUpdatedOn
      ConversationName
      HasBeenNamed
      IsDeleted
      DeletedOn
      OrganisationId
      UnreadCount
      HasUrgent
      UserName
      IsGroupConversation
      IsRemoved
      RemovedBy
      RemovedOn
      ParticipantList
      ParticipantHash
      Participants {
        OrganisationId
        UserName
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUser($OrganisationId: ID!) {
    getUserV2(OrganisationId: $OrganisationId) {
      OrganisationId
      UserName
      DisplayName
      UserStatus
    }
  }
`;


export const GET_USER_STATUS = gql`
  query GetUserV2($OrganisationId: ID!) {
    getUserV2(OrganisationId: $OrganisationId) {
      OrganisationId
      UserName
      DisplayName
      UserStatus
    }
  }
`;

export const GET_OLDCONVERSATION = gql`
  query GetOldConversation(
    $OrganisationId: ID!
    $UserName: String!
    $ParticipantHash: String!
    $ConversationName: String
    $IsGroupConversation: Boolean
  ) {
    getOldConversationV3(
      OrganisationId: $OrganisationId
      UserName: $UserName
      ParticipantHash: $ParticipantHash
      ConversationName: $ConversationName
      IsGroupConversation: $IsGroupConversation
    ) {
      ConversationId
      ConversationName
      IsDeleted
      DeletedOn
      LastUpdatedOn
      IsGroupConversation
      UserName
      OrganisationId
      RemovedOn
      IsRemoved
      Participants {
        OrganisationId
        UserName
      }
      ParticipantList
      ParticipantHash
    }
  }
`;

export const GET_GROUP_CONVERSATION = gql`
  query getGroupConversation($ConversationId: ID!) {
    getGroupConversation(ConversationId: $ConversationId) {
      Author
      ConversationId
      ConversationName
      Action
    }
  }
`;

export const CONVERSATION_STORE = gql`
  query conversationStore($ConversationId: ID!) {
    conversationDetails(ConversationId: $ConversationId) {
      ConversationId
      Author
      Action
    }
  }
`;

export const MESSAGE_UNREAD_COUNT = gql`
  query countStore($ConversationId: ID!) {
    conversationDetails(ConversationId: $ConversationId) {
      ConversationId
      HasUrgent
      UnreadCount
    }
  }
`;


//#region V2 queries
export const GET_MESSAGES_BY_CONVERSATIONID = gql`
query GetMessagesByConversation($ConversationId: ID!, $Limit: Int!, $NextToken: String) {
  getMessage(ConversationId: $ConversationId, Limit: $Limit, NextToken: $NextToken) {
    id
    Messages {
      AuthorERN
      AuthorOrgERN
      Content
      ConversationId
      IsDeleted
      IsRead
      IsReplied
      IsEdited
      IsSystemMessage
      IsUrgent
      MessageCreatedAt
      MessageId
      Type_Id
      Type
    }
    NextToken
  }
}
`;

export const GET_LOCAL_V2MESSAGES = gql`
  query GetLocalMessages($Id: String) {
    getLocalMessages(Id: $Id) {      
      ConversationCreatedAt
      ConversationId
      ConversationName
      HasBeenNamed
      IsGroupConversation
      LastMessage
      LastMessageTime
      LastUrgentMessage
      LastUrgentMessageTime
      LastUpdatedOn
      LastMessageAuthorId
      ParticipantHash
      ParticipantList
      IsSystemMessage
      Type_Id
    }
  }
`;


export const V2CONVERSATION_READ = gql`
  query conversationRead($UserERN: String) {
    conversationRead(UserERN: $UserERN) {
      ConversationId
    }
  }
`;

export const GET_NEW_USERS = gql`
  query GetUser($OrganisationERN: String!) {
    getUser(OrganisationERN: $OrganisationERN) {
      OrganisationERN
      UserERN
      UserName
      DisplayName
      UserStatus
    }
  }
`;

export const GET_OLDCONVERSATION_V2 = gql`
  query GetOldConversation(
    $OrganisationERN: String!
    $UserERN: String!
    $ParticipantList: [String]!
    $ConversationName: String!
  ) {
    getOldConversation(
      OrganisationERN: $OrganisationERN
      UserERN: $UserERN
      ParticipantList: $ParticipantList
      ConversationName: $ConversationName
    ) {
      ConversationId
      Type_Id
      ConversationName
      HasBeenNamed
      OrganisationERN
      IsGroupConversation
      ParticipantHash
      ParticipantList
      Type
      ConversationCreatedAt
      LastUpdatedOn
    }
  }
`;

export const GET_CONVERSATION_INFO = gql`
  query getConversationByIds($ConversationIds: [String]!) {
    getConversationByIds(ConversationIds: $ConversationIds) {
      ConversationCreatedAt
      ConversationId
      ConversationName
      HasBeenNamed
      IsGroupConversation
      LastMessage
      LastMessageAuthorId
      LastMessageTime
      LastUpdatedOn
      LastUrgentMessage
      LastUrgentMessageTime
      IsSystemMessage
      OldParticipantList 
      ParticipantHash
      ParticipantList 
      RemovalStartedOn
      RemovedBy
      Type_Id
    }
  }
`;

export const GET_CONVERSATION_BY_USER = gql`
  query getConversationByUser($OrganisationERN: ID!, $UserERN: String!) {
    getConversationByUser(OrganisationERN: $OrganisationERN, UserERN: $UserERN) {
      AddedToConversationOn
      ConversationCreatedAt
      ConversationHistorySharedFrom
      ConversationId
      ConversationNameWhenRemoved
      DeletedConversationOn
      IsSystemMessageWhenRemoved
      LastMessageAuthorIdWhenRemoved
      LastMessageTimeWhenRemoved
      LastMessageWhenRemoved
      LastUpdatedOn
      LastUrgentMessageTimeWhenRemoved
      LastUrgentMessageWhenRemoved 
      OrganisationERN
      OrganisationERN_UserERN
      ParticipantListWhenRemoved
      RemovedBy
      RemovedFromConversationOn
      Type
      Type_Id
      UserERN
    }
  }
`;


export const GET_CONVERSATION_BY_USER_AND_CONVERSATIONID = gql`
  query getUserConversationById($ConversationId: String!, $OrganisationERN: String!, $UserERN: String!) {
    getUserConversationById(ConversationId: $ConversationId, OrganisationERN: $OrganisationERN, UserERN: $UserERN) {
      AddedToConversationOn
      ConversationCreatedAt
      ConversationHistorySharedFrom
      ConversationId
      ConversationNameWhenRemoved
      DeletedConversationOn
      LastUpdatedOn
      OrganisationERN
      OrganisationERN_UserERN
      ParticipantListWhenRemoved
      RemovedBy
      RemovedFromConversationOn
      Type
      Type_Id
      UserERN
      ConversationNameWhenRemoved
      ParticipantListWhenRemoved
      LastMessageWhenRemoved
      LastMessageTimeWhenRemoved
      LastUrgentMessageWhenRemoved
      LastUrgentMessageTimeWhenRemoved
      LastMessageAuthorIdWhenRemoved
      IsSystemMessageWhenRemoved
    }
  }
`;

export const GET_LOCAL_CONVERSATION_AND_MESSAGE = gql`
  query GetLocalConversationAndMessage($Id: String) {
    getLocalConversationAndMessage(Id: $Id) {   
        name
        authorId
        message
        time
        isRead
        lastMessageTime
        conversationId
        isReply
        isUrgent
        participantList
        userStatus
        organisationId
        isGroup
        isRemoved
        removedOn
        removedBy
        hasBeenNamed 
        type_Id
        lastUpdatedOn
      }    
  }
`;


//#endregion