import "../styles/LightboxCustom.scss";
import { Card } from "../custom_components/Card/Card";
import { Spinner } from '@emisgroup/ui-progress-indicator';
import { Stack } from "@emisgroup/ui-layouts";

export interface ILightBoxComponentProps {
  text: string;
  isGroupUpdated: boolean;
}
const LightBoxComponent = (props: ILightBoxComponentProps) => {
  return (
    props.isGroupUpdated ? (
      <div className='radix-overlay'>
        <div className='radix-dialog'>
          <Card>
            <Stack className="content-dialog">
              <div>
                <Spinner />
              </div>
              <div>
                {props.text}
              </div>
            </Stack>
          </Card>
        </div>
      </div>) : null
  );
}

export default LightBoxComponent;
