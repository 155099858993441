import { useState, useEffect } from "react";
import { withTopbar } from "./hoc/withTopbar";
import style from "../styles/ContactList.module.scss";
import Contacts from "./Contacts";
import { useLocation, useNavigate } from "react-router-dom";
import { IUser } from "../utils/user";
import { V2MessageInfo } from "../types/routeConversationDetails";

export interface IContactListProps extends IUser { }

const ContactView: React.FC<IContactListProps> = (props: IContactListProps) => {
  const { userERN, organisationId } = props;
  const { state } = useLocation();
  const [displayName, setDisplayName] = useState("");
  const [recipientId, setRecipientId] = useState("");
  const nav = useNavigate();
  let userErn: string = userERN;

  if (state) {
    userErn = (state as IContactListProps).userERN;
  }
  const messageInfo: V2MessageInfo = {
    title: displayName,
    recipients: [recipientId],
    userErn: userErn,
    isGroup: false
  };

  useEffect(() => {
    if (displayName) {
      nav("/staff/landing-page/", {
        state: messageInfo
      });
    }
  });

  return (
    <div className={style.contacts}>
      {" "}
      <Contacts
        debug={false}
        organisationId={state ? (state as IContactListProps).organisationId : organisationId}
        displayName={displayName}
        setUserName={setDisplayName}
        recipientId={recipientId}
        setRecipientId={setRecipientId}
        userERN={userERN}
        isGroupMessage={false}
      ></Contacts>
    </div>
  );
};

export default withTopbar(ContactView, false, "Select a contact");
