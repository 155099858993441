// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l03I9P9QAQ0GQW7SdrO2{position:absolute;bottom:0;left:var(--spacing-large);width:var(--spacing) !important;height:var(--spacing) !important}.ZRITew11Z70yzcEystJr{z-index:0;position:relative;max-width:40px;max-height:40px}`, "",{"version":3,"sources":["webpack://./src/styles/UserStatus.module.scss"],"names":[],"mappings":"AAEA,sBACI,iBAAA,CACA,QAAA,CACA,yBAAA,CACA,+BAAA,CACA,gCAAA,CAGJ,sBACI,SAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userStatus": `l03I9P9QAQ0GQW7SdrO2`,
	"divStyle": `ZRITew11Z70yzcEystJr`
};
export default ___CSS_LOADER_EXPORT___;
