import { gql } from "@apollo/client";

export const UPDATE_MESSAGE_READ_STATUS = gql`
  mutation MessageRead($ConversationId: ID!, $AuthorId: String!, $AuthorOrg: ID!, $IsRead: Boolean!) {
    batchUpdateV2(ConversationId: $ConversationId, AuthorId: $AuthorId, AuthorOrg: $AuthorOrg, IsRead: $IsRead) {
      ConversationId
      AuthorId
      AuthorOrg
      IsRead
    }
  }
`;

//#region mutation for V2Components
export const UPDATE_MESSAGE_READ_STATUS_V2 = gql`
  mutation MessageRead($ConversationId: ID!, $AuthorERN: String!, $AuthorOrgERN: ID!) {
    batchUpdate(ConversationId: $ConversationId, AuthorERN: $AuthorERN, AuthorOrgERN: $AuthorOrgERN) {
      ConversationId
      AuthorERN
      AuthorOrgERN
      ParticipantList
    }
  }
`;


export const SEND_MESSAGE = gql`
  mutation SendMessage($AuthorERN: String!, $Content: String!, $ConversationId: ID!, $DisplayName: String!, $AuthorOrgERN: ID, $IsUrgent: Boolean) {
  createMessage(AuthorERN: $AuthorERN, Content: $Content, ConversationId: $ConversationId, DisplayName: $DisplayName, AuthorOrgERN: $AuthorOrgERN, IsUrgent: $IsUrgent) {
    AuthorERN
    AuthorOrgERN
    Content
    DisplayName
    IsDeleted
    Type_Id
    IsEdited
    IsRead
    IsReplied
    IsSystemMessage
    IsUrgent
    MessageCreatedAt
    MessageId
    ConversationId
    ParticipantList
  }
}`;


export const CREATE_CONVERSATION = gql`
  mutation CreateConversations(
    $ConversationName: String
    $HasBeenNamed: Boolean!
    $IsGroupConversation: Boolean!
    $OrganisationERN: String!
    $UserERN: String!
    $ParticipantList: [String]!
    $Message: Message!
  ) {
    createConversations(
    ConversationName: $ConversationName
    HasBeenNamed: $HasBeenNamed
    IsGroupConversation: $IsGroupConversation
    OrganisationERN: $OrganisationERN
    UserERN: $UserERN
    ParticipantList: $ParticipantList
    Message: $Message
    ) {
      ConversationId
      ConversationName
      ConversationCreatedAt
      HasBeenNamed
      IsGroupConversation
      LastUpdatedOn
      OrganisationERN
      ParticipantHash
      ParticipantList
      Type
      Type_Id
    }
  }
`;



export const UPDATE_CONVERSATION = gql`
  mutation updateConversations(
    $ConversationId: ID!
    $Type_Id: String!
    $IsGroupConversation: Boolean!
    $ConversationName: String
    $HasBeenNamed: Boolean!
    $OrganisationERN: String!
    $ParticipantList: [String]!
    $LastUpdatedOn: String!
  ) {
    updateConversations(
    ConversationId: $ConversationId
    Type_Id: $Type_Id
    IsGroupConversation: $IsGroupConversation
    ConversationName: $ConversationName
    HasBeenNamed: $HasBeenNamed
    OrganisationERN: $OrganisationERN
    ParticipantList: $ParticipantList
    LastUpdatedOn: $LastUpdatedOn
    ) {
      ConversationId
      Type_Id
      ConversationName
      HasBeenNamed
      OrganisationERN
      IsGroupConversation
      ParticipantHash
      ParticipantList
      Type
      ConversationCreatedAt
      LastUpdatedOn
    }
  }
`;


export const LEAVE_CONVERSATION = gql`
  mutation leaveGroupConversations(
    $ConversationId: ID!
    $OrganisationERN: String!
    $UserERN: String!
  ) {
    leaveGroupConversations(
    ConversationId: $ConversationId
    OrganisationERN: $OrganisationERN
    UserERN: $UserERN
    ) {
    ConversationId
    OrganisationERN_UserERN
    Type_Id
    AddedToConversationOn
    ConversationHistorySharedFrom
    DeletedConversationOn
    OrganisationERN
    UserERN
    ConversationCreatedAt
    Type
    LastUpdatedOn
    ConversationQualifier
    RemovedBy
    RemovedFromConversationOn
    ConversationNameWhenRemoved
    ParticipantListWhenRemoved
    LastMessageWhenRemoved
    LastMessageTimeWhenRemoved
    LastUrgentMessageWhenRemoved
    LastUrgentMessageTimeWhenRemoved
    LastMessageAuthorIdWhenRemoved
    }
  }
`;


//#endregion