import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../utils/ApplicationContext";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { GET_CONVERSATION_BY_ID } from "../graphql/queries";
import { getConversationName } from "../utils/MessageComponent";

export interface IApproachPage { }

const ApproachPage: React.FC<IApproachPage> = (props: IApproachPage) => {
  let { conversationId } = useParams();
  const [isError, setIsError] = useState(false);
  const startedQueryRequest = useRef(false);

  // Set the conversation id passed in into the context and redirect (clears url ready for next notification click etc...)
  const appContext: IAppContextInterface = React.useContext(AppContext);
  const nav = useNavigate();

  const handleError = (err: ApolloError) => {
    console.error(`Error from approach page(getConversationByIdV2) : ${JSON.stringify(err.message)}`);
    Logger.error(err.message);
    setIsError(true);
  };

  const [getConversationByIdV2, { data: GetConversation, loading: conversationLoading }] = useLazyQuery(
    GET_CONVERSATION_BY_ID,
    {
      fetchPolicy: "cache-first",
      onError: handleError
    }
  );

  useEffect(() => {
    if (conversationId) {
      getConversationByIdV2({
        variables: {
          ConversationId: conversationId,
          UserName: appContext.user.userName
        }
      }).catch(err => { console.error(err) });
      startedQueryRequest.current = true;
    } else {
      nav("/staff/landing-page/"); // Redirect to Landing Page as conversationId not set.
    }
  }, []);

  function setConversation(oldConversation: any) {
    appContext.approachConversationName = getConversationName(oldConversation.ParticipantList, appContext.user.userName.toLowerCase(), oldConversation.ConversationName, appContext.userList);
    appContext.approachConversationId = conversationId;
    appContext.approachIsGroup = oldConversation.IsGroupConversation;
    appContext.approachRemovedOn = oldConversation.RemovedOn;
    appContext.approachIsRemoved = oldConversation.IsRemoved || false;
    appContext.approachRemovedBy = oldConversation.RemovedBy;
    appContext.hasBeenNamed = oldConversation.HasBeenNamed;
    if (appContext.selectedConversationId) appContext.selectedConversationId.current = conversationId;
    nav("/staff/landing-page/");
  }

  React.useEffect(() => {
    if (GetConversation != undefined) {
      if (GetConversation["getConversationByIdV2"].length > 0) {
        setConversation(GetConversation["getConversationByIdV2"][0]);
      } else {
        setIsError(true);
      }
    }
  }, [GetConversation]);

  if ((startedQueryRequest.current && !conversationLoading && GetConversation == undefined) || isError) {
    // Failed to identify conversation. Nav anyway in non-selected state.
    nav("/staff/landing-page/");
  }

  return <div />;
};

export default ApproachPage;
