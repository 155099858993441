import styles from "./Card.module.scss";
import type { PropsWithChildren } from "@emisgroup/ui-core";

export type CardProps = PropsWithChildren & {
  /**
   * Card height
   *
   * @default auto
   */
  height?: string;

  /**
   * Card width
   *
   * @default 100%
   */
  maxWidth?: string;
};

export const Card = ({
  children,
  className,
  "data-testid": dataTestId,
  height,
  id,
  maxWidth,
  ...props
}: CardProps) => {
  return (
    <div
      className={[styles.card, className].join(" ")}
      data-testid={dataTestId}
      id={id}
      {...props}
      style={{ height, maxWidth }}
    >
      {children}
    </div>
  );
};
