import { ConnectionState, CONNECTION_STATE_CHANGE } from "aws-amplify/api";
import { jwtDecode } from "jwt-decode";
import React from "react";
import { Hub } from "aws-amplify/utils";
import { Ijwt } from "./utils/user";

export function getMonthName(month: number): string {
  let month_names_short = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return month_names_short[month];
}

export function formatDate(dt) {
  if (dt) {
    return dt
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric"
      })
      .split(" ")
      .join("-");
  }
  return null;
}

export function getLocalTimeString(epochTime: string): string {
  let localTime = new Date(epochTime);
  localTime.setTime(+epochTime);
  return (
    ("0" + localTime.getHours()).slice(-2) + ":" + (localTime.getMinutes() < 10 ? "0" : "") + localTime.getMinutes()
  );
}

export function getMessageDate(receivedTime: string): Date {
  let localTime = new Date(0); // "0" Initiallises time to the Epoch.
  localTime.setTime(+receivedTime);
  return localTime;
}

export function splitDisplayName(displayName: string): Array<string> {
  if (!displayName) return null;
  const splitName = displayName.split(" ");
  const familyName: string = splitName[0];
  const givenName: string = splitName.length > 1 ? splitName[1] : "";
  return [familyName, givenName];
}

export function authTokenWithBearer(token: string): string {
  return `Bearer ${token}`;
}

export async function scheduler(calback: Function, time: number) {
  setInterval(() => {
    calback();
  }, time);
}

export function HubListner(callback: Function, previousSate: React.MutableRefObject<string>): any {
  //Hook for amplify subscription connection - Returns the cancel token
  return Hub.listen("api", (data: any) => {
    const { payload } = data;
    if (payload.event === CONNECTION_STATE_CHANGE) {
      const connectionState = payload.data.connectionState as ConnectionState;

      console.warn(`hubConnection status : ${JSON.stringify(connectionState)}`);
      // pending connection. client went offline. re-try while coming back to online.
      if (
        previousSate.current == ConnectionState.ConnectionDisrupted &&
        connectionState == ConnectionState.Connecting
      ) {
        if (typeof callback === "function") callback();
      }
      if (
        previousSate.current &&
        previousSate.current != ConnectionState.Disconnected &&
        connectionState == ConnectionState.Disconnected
      )
        callback();
      previousSate.current = connectionState;
    }
  });
}

export function getExpIn(token: string): number {
  const jwt: Ijwt = jwtDecode(token);
  const currentEpoch = Math.floor(Date.now() / 1000);
  const expIn = jwt.exp > currentEpoch ? (jwt.exp - currentEpoch) * 1000 : 5000;
  return expIn;
}

// Functin return user DisplayName as string.
// Required Paramter: user data.
export function getUserDisplayName(user: any): string {
  let displayName: string = "";
  if (user?.hasOwnProperty("DisplayName")) {
    displayName = user.DisplayName;
  }
  return displayName;
}

export const checkLastMessageTime = (item) => {
  // Check if the last message is urgent or not
  if (!item.LastUrgentMessageTime) {
    // check if item has lasturgentmessage
    return [item.LastMessage, item.LastMessageTime];
  } else if (!item.LastMessageTime) {
    return [item.LastUrgentMessage, item.LastUrgentMessageTime];
  } else if (new Date(parseInt(item.LastMessageTime)) > new Date(parseInt(item.LastUrgentMessageTime))) {
    return [item.LastMessage, item.LastMessageTime];
  } else {
    return [item.LastUrgentMessage, item.LastUrgentMessageTime];
  }
};

export const checkLastMessageTimeIfRemoved = (item) => {
  // Check if the last message is urgent or not
  if (!item.LastUrgentMessageTimeWhenRemoved) {
    // check if item has lasturgentmessage
    return [item.LastMessageWhenRemoved, item.LastMessageTimeWhenRemoved];
  } else if (!item.LastMessageTimeWhenRemoved) {
    return [item.LastUrgentMessageWhenRemoved, item.LastUrgentMessageTimeWhenRemoved];
  } else if (
    new Date(parseInt(item.LastMessageTimeWhenRemoved)) > new Date(parseInt(item.LastUrgentMessageTimeWhenRemoved))
  ) {
    return [item.LastMessageWhenRemoved, item.LastMessageTimeWhenRemoved];
  } else {
    return [item.LastUrgentMessageWhenRemoved, item.LastUrgentMessageTimeWhenRemoved];
  }
};
