// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jVXxTjgVGuexoNuemt5b{text-align:center;padding-top:var(--spacing-small);padding-left:var(--spacing-large);padding-right:var(--spacing-large)}.ZMr8nChTooWbZekzQzjo{font-size:var(--font-size-small);line-height:var(--line-height);font-weight:var(--font-weight);width:fit-content;display:inline-block;color:var(--text)}.fYn2abv5gqpayzpMnc82{font-size:var(--font-size-small);line-height:var(--line-height);font-weight:var(--font-weight);width:fit-content;display:inline-block;color:var(--text)}.pUsiYxykyE4S866DB6pU{display:inline-block;padding-left:var(--spacing-xsmall);padding-right:var(--spacing-small)}.MgpXb51PrKhobPC4u4X6{padding-left:var(--spacing-small);padding-right:var(--spacing-small);display:inline-block}.wxLa1vnrpHZzMFChEIR3{background:var(--neutral-20);width:var(--spacing-xsmall);height:var(--spacing-xsmall);border-radius:50%;display:inline-block;vertical-align:middle}`, "",{"version":3,"sources":["webpack://./src/styles/SystemMessage.module.scss"],"names":[],"mappings":"AACA,sBACI,iBAAA,CACA,gCAAA,CACA,iCAAA,CACA,kCAAA,CAIJ,sBACG,gCAAA,CACA,8BAAA,CACA,8BAAA,CACA,iBAAA,CACA,oBAAA,CACA,iBAAA,CAEH,sBACI,gCAAA,CACA,8BAAA,CACA,8BAAA,CACA,iBAAA,CACA,oBAAA,CACA,iBAAA,CAGJ,sBACI,oBAAA,CACA,kCAAA,CACA,kCAAA,CAGJ,sBACI,iCAAA,CACA,kCAAA,CACA,oBAAA,CAGJ,sBACI,4BAAA,CACA,2BAAA,CACA,4BAAA,CACA,iBAAA,CACA,oBAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `jVXxTjgVGuexoNuemt5b`,
	"systemTitle": `ZMr8nChTooWbZekzQzjo`,
	"systemTime": `fYn2abv5gqpayzpMnc82`,
	"leftContnet": `pUsiYxykyE4S866DB6pU`,
	"circlePadding": `MgpXb51PrKhobPC4u4X6`,
	"circle": `wxLa1vnrpHZzMFChEIR3`
};
export default ___CSS_LOADER_EXPORT___;
