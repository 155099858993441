// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CACxmwPq28_oiDTntdVy,.CACxmwPq28_oiDTntdVy .RccWv4j8wMCQTV5HdjZi{list-style:none;top:var(--spacing);margin:0;padding:0;background-color:var(--background)}.sxzZUqig5Z5ctpb62ESB{justify-content:center;color:var(--text);font-size:var(--font-size-small);height:var(--spacing-2xlarge);position:sticky;top:0px;padding-top:4px;padding-bottom:4px;z-index:5}.x1ccldntKxOIRKzzv_oh{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.uWTbW6NlUrQq80fTFYDw{border:1px solid var(--border-bright);display:flex;justify-content:center;align-items:center;background-color:var(--background-light);height:var(--spacing-large);border-radius:var(--border-radius);width:96px;font-family:var(--font-family);font-size:var(--font-size-small);font-weight:var(--font-weight);margin:auto;box-shadow:var(--shadow-40);padding-left:var(--spacing-small);padding-right:var(--spacing-small)}.m2J2UsuPQm8KuJt88W5w{padding-top:var(--spacing-2xlarge)}`, "",{"version":3,"sources":["webpack://./src/custom_components/ListGroup/ListGroup.module.scss"],"names":[],"mappings":"AAGA,kEAEE,eAAA,CACA,kBAAA,CACA,QAAA,CACA,SAAA,CACA,kCAAA,CAGF,sBACE,sBAAA,CACA,iBAAA,CACA,gCAAA,CACA,6BAAA,CACA,eAAA,CACA,OAAA,CACA,eAAA,CACA,kBAAA,CACA,SAAA,CAGF,sBACE,eAAA,CACA,sBAAA,CACA,kBAAA,CAGF,sBACE,qCAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACC,wCAAA,CACD,2BAAA,CACA,kCAAA,CACA,UAAA,CACA,8BAAA,CACA,gCAAA,CACA,8BAAA,CACA,WAAA,CACA,2BAAA,CACA,iCAAA,CACA,kCAAA,CAGF,sBACE,kCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listGroupWrapper": `CACxmwPq28_oiDTntdVy`,
	"listGroupListItem": `RccWv4j8wMCQTV5HdjZi`,
	"groupHeader": `sxzZUqig5Z5ctpb62ESB`,
	"groupHeaderText": `x1ccldntKxOIRKzzv_oh`,
	"datediv": `uWTbW6NlUrQq80fTFYDw`,
	"divheight": `m2J2UsuPQm8KuJt88W5w`
};
export default ___CSS_LOADER_EXPORT___;
