// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jTjUbYx9Kc7jh1mJRW3T{position:relative;width:100%;padding-bottom:var(--spacing)}.sWVOYfa7tZA2wlTY6ODk{position:relative;width:100%;padding-bottom:0px;color:var(--negative)}.Xo84lXx_fWydiH2PUjVV{padding-left:var(--spacing-small);display:flex;justify-content:space-between}.ZiKe4Apimlf7FloQOCg0{font-size:var(--font-size);color:var(--negative);margin-bottom:0px}.AQeG037kAGDhAUYMN00e{padding-left:var(--spacing);display:inline-block}.eKBH48EfoEVM8h9nM71g{padding-right:var(--spacing);float:right}.s1swdHUAW3Z_t6QMRVTN{margin:auto;max-width:1040px;background-color:var(--background);padding-bottom:var(--spacing);bottom:0px;width:100%;position:absolute}.f0dR9N5_h4gJOUGLwD8V{display:flex;justify-content:center;align-items:center;height:calc(100vh - (244px + 2*var(--acp-navapp-height, 48px) + var(--acp-navapp-height, 0px) + var(--footer-height, 0px)));background-color:var(--background)}.jYyL83OTBGC9MfCxQR3L{margin:0px !important}.Zbtm5xYxnyxH4f0OCbRr{line-height:var(--line-height);font-family:var(--font-family);font-weight:var(--font-weight-bold);font-size:var(--font-size-small);color:var(--text-light)}.Wtaw6ZbNvB5OOzcdd3yg{line-height:var(--line-height);padding-right:var(--spacing-small);font-family:var(--font-family);font-size:var(--font-size-small);color:var(--text-light)}`, "",{"version":3,"sources":["webpack://./src/styles/MessageSendingComponent.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,UAAA,CACA,6BAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CACA,kBAAA,CACA,qBAAA,CAGF,sBACE,iCAAA,CACA,YAAA,CACA,6BAAA,CAGF,sBACE,0BAAA,CACA,qBAAA,CACA,iBAAA,CAGF,sBACE,2BAAA,CACA,oBAAA,CAGF,sBACE,4BAAA,CACA,WAAA,CAGF,sBACE,WAAA,CACA,gBAAA,CACA,kCAAA,CACA,6BAAA,CACA,UAAA,CACA,UAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,2HAAA,CACA,kCAAA,CAGF,sBACE,qBAAA,CAGF,sBACA,8BAAA,CACA,8BAAA,CACA,mCAAA,CACA,gCAAA,CACA,uBAAA,CAGA,sBACE,8BAAA,CACA,kCAAA,CACA,8BAAA,CACA,gCAAA,CACA,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageElement": `jTjUbYx9Kc7jh1mJRW3T`,
	"errormessageElement": `sWVOYfa7tZA2wlTY6ODk`,
	"urgentSendButton": `Xo84lXx_fWydiH2PUjVV`,
	"errorpara": `ZiKe4Apimlf7FloQOCg0`,
	"checkboxPadding": `AQeG037kAGDhAUYMN00e`,
	"sendButton": `eKBH48EfoEVM8h9nM71g`,
	"messageContainer": `s1swdHUAW3Z_t6QMRVTN`,
	"centerContainer": `f0dR9N5_h4gJOUGLwD8V`,
	"errorBannerAlignment": `jYyL83OTBGC9MfCxQR3L`,
	"cntlEnterFormat": `Zbtm5xYxnyxH4f0OCbRr`,
	"textFormat": `Wtaw6ZbNvB5OOzcdd3yg`
};
export default ___CSS_LOADER_EXPORT___;
