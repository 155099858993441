import React, { FC } from "react";
import styles from "./ListGroup.module.scss";
import { IListGroupProps } from "./Props";

interface IListGroupViewProps extends IListGroupProps {
  /**
   * The React reference for the list header text
   */
  headerTextRef: React.RefObject<HTMLDivElement>;

  /**
   * The JSX versions of the child list litems
   */
  listItems: JSX.Element[];

  /**
   * Whether to show the title on hover
   */
  showTitle: boolean;
}

/**
 * Presentation layer of ListGroup component
 *
 * @param props Props sent to the view
 * @returns JSX.Element
 */
export const ListGroupView: FC<IListGroupViewProps> = (props: IListGroupViewProps): JSX.Element => {
  const {
    className,
    "data-testid": dataTestId,
    headerTextRef,
    id,
    listItems,
    showTitle,
    title,
  } = props;

  const titleOnHover = showTitle ? title : "";

  const getTitleBar = (): JSX.Element => {
    return (
      <div className={styles.groupHeader} title={titleOnHover}>
        <div className={styles.datediv} ref={headerTextRef}>
          {title}
        </div>
      </div>
    );
  };

  return (
    <div className={className} data-testid={dataTestId} id={id}>
      {title && getTitleBar()}
        <ul className={styles.listGroupWrapper}>{listItems}</ul>
    </div>
  );
};

export default ListGroupView;
