import * as React from "react";
import style from "../../styles/MultipleContact.module.scss";
import { Input } from "@emisgroup/ui-input";
import { Button } from "@emisgroup/ui-button";
import { Form, FormSection, FormElement } from "@emisgroup/ui-form";
import { ApolloError, useLazyQuery, useMutation, useApolloClient, useQuery, gql } from "@apollo/client";
import { CONVERSATION_FRAGMENT, GET_LOCAL_CONVERSATION } from "./schema";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { User } from "../../types/user";
import { Banner } from "@emisgroup/ui-banner";
import Warning from "~icons/ic/baseline-warning-amber";
import { Participant } from "../../types/messages";
import { GET_CONVERSATION_BY_ID, GET_USERS } from "../../graphql/queries";
import { getHashCode, getParticipants } from "../../utils/GetParticipants";
import { getUserDisplayName } from "../../utils";
import { removeTitle } from "../../utils/MessageComponent";



enum limit {
  BELOW_LIMIT,
  ABOVE_LIMIT
}

export enum SelectType {
  ADD,
  EDIT
}


interface IMemberFooterProps {
  conversationId: string;
  organisationId: string;
  userName: string;
  refIsCreated?: React.MutableRefObject<boolean>;
  type: SelectType;
  groupName?: string;
  oldParticipants?: Participant[];
  setIsnewMemberSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsgroupSaving?:React.Dispatch<React.SetStateAction<boolean>>;
}

//Move this to Mutations.ts
export const CREATE_CONVERSATION = gql`
  mutation CreateConversations(
    $ConversationId: ID
    $OrganisationId: ID!
    $HasBeenNamed: Boolean
    $UserName: String
    $Participants: [Participant!]!
    $ConversationName: String
    $IsGroupConversation: Boolean
    $IsConversationHistoryShared: Boolean
    ) {
    createConversationsV4(
      ConversationId: $ConversationId
      OrganisationId: $OrganisationId
      Participants: $Participants
      UserName: $UserName
      ConversationName: $ConversationName
      IsGroupConversation: $IsGroupConversation
      IsConversationHistoryShared: $IsConversationHistoryShared    
      HasBeenNamed : $HasBeenNamed 
    ) {
      ConversationId
      OrganisationId
      UserName
      Participants {
        OrganisationId
        UserName
      }
      ParticipantList
      ParticipantHash
      IsDeleted
      DeletedOn
      ConversationName
      LastUpdatedOn
      IsGroupConversation
      IsConversationHistoryShared
    }
  }
`;

const MemberFooter: React.FunctionComponent<IMemberFooterProps> = (props) => {
  const [groupName, setGroupName] = useState(props.groupName);
  const newParticipants = useRef([]);
  const apolloClient = useApolloClient();
  const [participantCount, setParticipantCount] = React.useState(0);
  const [createConversation] = useMutation(CREATE_CONVERSATION);
  const [disabled, setDisabled] = useState(false);
  const [errorState, setErrorState] = React.useState(false);
  const handleError = (err: ApolloError) => {
    console.error(`Error: ${JSON.stringify(err.message)}`);
  }
  useEffect(() => {
    if (props.groupName)
      setGroupName(props.groupName);
  }, [props.groupName]);

  const nav = useNavigate();
  const localConversation = useRef(null);
  const usersRef = React.useRef<User[]>();
  const participantMaxLimit = 80;

  //Get user details from cache
  const { data: users } = useQuery(GET_USERS, {
    variables: { OrganisationId: props.organisationId },
    onError: handleError,
    fetchPolicy: "cache-first"
  });

  //get user conversation from cache 
  const [getConversation,
    { data: queryConversation }
  ] = useLazyQuery(GET_LOCAL_CONVERSATION, {
    variables: {
      ConversationId: "local-conversation"
    },
    onError: handleError,
    fetchPolicy: "cache-only"
  });

  React.useEffect(() => {
    if (props.conversationId && users && users['getUserV2'].length > 0) {
      getConversation().catch(err => { console.error(err) });
    }
  }, [props.conversationId, users]);

  let conversation: any;
  React.useEffect(() => {
    if (queryConversation?.['getLocalConversation']) {
      conversation = queryConversation['getLocalConversation'];
      localConversation.current = conversation;
      newParticipants.current = getParticipants([], conversation.ParticipantList);
      usersRef.current = users['getUserV2'] as User[];
      setParticipantCount(conversation.ParticipantList.length - 1); // not including the current user
    }
  }, [queryConversation]);


  const handleCreateConv = async (localConversation: any) => {
    let localConv = localConversation;
    const participants = getParticipants([], localConv.ParticipantList);
    await createConversation({
      variables: {
        ConversationId: props.conversationId.valueOf(),
        OrganisationId: localConv.OrganisationId,
        UserName: localConv.UserName,
        ConversationName: localConv.HasBeenNamed ? localConv.ConversationName : "",
        Participants: participants,
        IsGroupConversation: localConv.IsGroupConversation,
        IsConversationHistoryShared: localConv.IsConversationHistoryShared,
        HasBeenNamed: localConv.HasBeenNamed
      },
      onError: handleError
    });
  }

  const IsValid = () => {
    if (participantCount > 0 && participantCount < participantMaxLimit) {
      return true;
    }
    else if (participantCount >= participantMaxLimit) {
      setErrorState(true);
    }

    return false;
  }

  const getParticipantCountErrorMessage = (count: number): limit => {
    if (count == 0) return limit.BELOW_LIMIT;
    return limit.ABOVE_LIMIT;
  }


  let groupMembers = "";
  let hasBeenNamed = false;

  function getUserNames(displayName: string) {
    return displayName && displayName.split(",").length > 1 ? displayName.split(",") : "";
  }


  const SortParticipants = (members) => {
    let memberList: string[] = [];
    let groupNameLength = 7;
    members.forEach((participant) => {
      const user = usersRef.current.find(
        (user) => user.UserName == participant.UserName && user.UserName != props.userName
      );
      const displayName = getUserDisplayName(user);
      if (displayName != "") {
        let firstName = getUserNames(user.DisplayName)[1]?.trim();
        memberList.push(removeTitle(firstName) || displayName);
      }
    });
    memberList.sort((a, b) => (a > b ? 1 : -1));

    groupMembers = memberList.slice(0, groupNameLength).join(", ");

    if ((memberList.length - 1) >= groupNameLength)
      groupMembers = groupMembers + " +" + ((memberList.length) - groupNameLength)
    return groupMembers;
  }

  function getDifference(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.UserName === object2.UserName;
      });
    });
  }

  function isGroupNameChanged(oldGroupName, newGroupName) {
    return oldGroupName != newGroupName;
  }

  function setUnnamedGroup() {
    if (!groupName) {
      hasBeenNamed = false;
      if (localConversation.current) {
        let members = [...localConversation.current.Participants];
        groupMembers = SortParticipants(members);
      };
    }
    else {
      hasBeenNamed = true;
    }
  }

  const CreateGroupClickHandler = async () => {

    if (!IsValid()) {
      setErrorState(true);
      return;
    }

    setUnnamedGroup();

    //Update local cache with groupname
    apolloClient.cache.updateFragment({
      id: `GroupConversationV2:${props.conversationId}_${props.userName}`,
      fragment: CONVERSATION_FRAGMENT,
    }, (data) => {
      let convName = groupName || groupMembers;
      let participantHash = getHashCode(data.ParticipantList);
      let result = { ...data, ParticipantHash: participantHash, HasBeenNamed: hasBeenNamed, IsRemoved: false, ConversationName: convName, RemovedBy: "" };

      localConversation.current = result;
      return result;
    });

    apolloClient.cache.writeQuery({
      query: GET_CONVERSATION_BY_ID,
      variables: {
        ConversationId: props.conversationId,
        UserName: props.userName
      },
      data: {
        getConversationByIdV2: [
          { ...localConversation.current, __typename: "ConversationV2" }
        ]
      }
    });

    await updateConversation(newParticipants, localConversation);
    //TO navigate to main page

  }

  async function updateConversation(newParticipants: React.MutableRefObject<any[]>, localConversation: React.MutableRefObject<any>) {
    if (props.type === SelectType.ADD) {
      if (props.refIsCreated)
        props.refIsCreated.current = true;
    } else {
      let updateParticipant = [
        ...getDifference(props.oldParticipants, newParticipants.current),
        ...getDifference(newParticipants.current, props.oldParticipants)
      ];

      if ((updateParticipant && updateParticipant.length > 0) || isGroupNameChanged(props.groupName, !localConversation.current.HasBeenNamed ? "" : localConversation.current.ConversationName)) {
        // To set true to show spinner on saving group.
        props.setIsgroupSaving(true);
        setDisabled(true);
        await handleCreateConv(localConversation.current);
        // To restore initial state value.
        props.setIsgroupSaving(false);
      }
    }
    if (props.type == SelectType.ADD)
      nav("/staff/");
    else
      nav("/staff/" + localConversation.current.ConversationId);
  }

  const memberTextProp = {
    participantCount: participantCount,
    errorState: errorState,
    isValid: IsValid,
    getParticipantCountErrorMessage: getParticipantCountErrorMessage,
    participantMaxLimit: participantMaxLimit

  }

  function checkIsNewMemberExist(): boolean {

    let OldMembers = props.oldParticipants.map((i) => i.UserName);
    let newMembers = newParticipants.current.map((i) => i.UserName);

    if (newMembers.length > 0) {
      let isExist = newMembers.every((element) => OldMembers.includes(element));
      props.setIsnewMemberSelected(!isExist);
      return isExist;
    }
    return true;
  }

  return (
    <React.Fragment>
      {props.type == SelectType.EDIT && !checkIsNewMemberExist() && <Banner color="warning">
        <Warning title="Warning" />
        <div>
          <div>
            <strong>
              History will be shared
            </strong><br />
            Conversation history will be shared with newly <br />
            added users.
          </div>
        </div>
      </Banner>}
      <Form>
        <MemberText {...memberTextProp} />
        <FormSection>
          <FormElement fieldId="groupname">
            <div className={style.groupNameInput}>{"Group name "}
              <div className={style.groupNameOptionaInput}>{"(optional)"}</div>
            </div>
            <Input className={style.groupNameInputField}
              name="groupname"
              value={groupName}
              type="text"
              onChange={(e) => {
                setGroupName(e.target.value)
              }}
              data-testid="groupname"
              maxLength={250}
            />
            <div className={style.formButtons}>
              <Button type="submit" disabled={disabled} variant="filled" onClick={() => { CreateGroupClickHandler().catch(err => { console.error(err) }) }} data-testid="creategroup">
                {props.type == SelectType.ADD ? "Create group" : "Save"}
              </Button>
            </div>
          </FormElement>
        </FormSection>
      </Form>
    </React.Fragment>
  );
};

const MemberText = (props) => {
  return (props.isValid() ?
    <FormSection>
      <FormElement>
        <h4 className={style.memberText} data-testid="membercount">
          {props.participantCount == 1 ? "1 member selected." : props.participantCount + " members selected."}
        </h4>
      </FormElement>
    </FormSection>
    :
    <FormSection>
      <FormElement>
        <h4 className={props.errorState ? style.memberTextError : style.memberText} data-testid="membercount">
          {props.getParticipantCountErrorMessage(props.participantCount) == limit.BELOW_LIMIT ?
            <span>Select at least 1 member.</span> : <><span>Group conversations have a limit of {props.participantMaxLimit} members.</span><br /><span>Remove some members.</span></>}
        </h4>
      </FormElement>
    </FormSection>
  );
}

export default MemberFooter;

