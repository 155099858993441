import { gql } from "@apollo/client";

export const CONVERSATION_FRAGMENT_V2 =
  gql`
          fragment conversations on GroupConversation {
            ConversationId
            ConversationName
            OrganisationERN
            UserERN
            IsRemoved
            ParticipantList 
            IsLocal @client
            IsGroupConversation
            LastUpdatedOn
            Type_Id
            HasBeenNamed      
        }
        `;

export const GET_LOCAL_CONVERSATION_V2 = gql`
  query GetLocalConversation($ConverastionId: String!) {
    getLocalConversation(ConversationId: $ConverastionId) {
      ConversationId
      ConversationName
      OrganisationERN
      UserERN
      IsRemoved
      ParticipantList 
      IsLocal @client
      IsGroupConversation
      LastUpdatedOn
      Type_Id
      HasBeenNamed      
    }
  }
`;
export const  GET_USERCONVERSATION_FRAGMENT = gql`
  fragment ConversationFields on ChatUserConversation{
      AddedToConversationOn
      ConversationCreatedAt
      ConversationHistorySharedFrom
      ConversationId
      ConversationNameWhenRemoved
      DeletedConversationOn
      IsSystemMessageWhenRemoved
      LastMessageAuthorIdWhenRemoved
      LastMessageTimeWhenRemoved
      LastMessageWhenRemoved
      LastUpdatedOn
      LastUrgentMessageTimeWhenRemoved
      LastUrgentMessageWhenRemoved 
      OrganisationERN
      OrganisationERN_UserERN
      ParticipantListWhenRemoved
      RemovedBy
      RemovedFromConversationOn
      Type
      Type_Id
      UserERN    
  }
`;




