import { ApolloError, useLazyQuery, useQuery } from "@apollo/client";
import * as React from "react";
import style from "../../styles/MultipleContact.module.scss";
import { GET_LOCAL_CONVERSATION_V2 } from "./schema";
import { UserConversation } from "../../types/userConversation";
import { V2User } from "../../types/user";
import Pills from "./Pills";
import { GET_NEW_USERS } from "../../graphql/queries";

interface IMemberSectionProps {
  conversationId: string;
  organisationId: string;
  userERN: string;
  isNewMemberSelected: boolean;
}

const MemberSection: React.FunctionComponent<IMemberSectionProps> = (props) => {
  const participantMaxLimit = 80;
  const [conversation, setConversation] = React.useState<UserConversation>(null);
  const usersRef = React.useRef<V2User[]>();
  const participantCountRef = React.useRef<number>(0);
  const handleError = (err: ApolloError) => {
    console.error(`Error  : ${JSON.stringify(err.message)}`);
  };

  const userType = "getUser";
  const { data: user } = useQuery(GET_NEW_USERS, {
    variables: { OrganisationERN: props.organisationId },
    onError: handleError,
    fetchPolicy: "cache-first"
  });

  //get user conversation from cache
  const [getConversation, { data: queryConversation }] = useLazyQuery(GET_LOCAL_CONVERSATION_V2, {
    variables: {
      ConversationId: "local-conversation"
    },
    onError: handleError,
    fetchPolicy: "cache-only"
  });

  React.useEffect(() => {
    if (props.conversationId && user && user[userType].length > 0) {
      getConversation().catch((err) => {
        console.error(err);
      });
    }
  }, [props.conversationId, user]);

  // This will run whenever then cache is updated.
  React.useEffect(() => {
    if (queryConversation?.["getLocalConversation"]) {
      usersRef.current = user[userType] as V2User[];
      let localConversation = queryConversation["getLocalConversation"] as UserConversation;
      setConversation(localConversation);
      participantCountRef.current = localConversation.ParticipantList.length;
    }
  }, [queryConversation]);

  const checkMemberSectionStyle = () => {
    if (props.isNewMemberSelected) {
      if (participantCountRef.current > participantMaxLimit) {
        return style.memberInfoSharedHistoryError;
      }
      return style.memberInfoSharedHistory;
    } else if (participantCountRef.current > participantMaxLimit) {
      return style.memberInfoColumnError;
    }
    return style.memberInfoColumn;
  };
  return (
    <div className={style.contactInfoColumn}>
      <h4 className={style.memberName}>{"Members"}</h4>
      <div className={checkMemberSectionStyle()}>
        <Pills
          conversation={conversation}
          users={usersRef.current}
          conversationId={props.conversationId}
          userERN={props.userERN}
        />
      </div>
    </div>
  );
};

export default MemberSection;
