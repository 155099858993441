import { gql } from "@apollo/client";

export const getUser = gql`
    fragment getUser on UserV3{
        OrganisationId
        UserName
        UserStatus
    }
`;

export const getConversationStore = gql`
    fragment getConversationStore on ConversationStore{
        ConversationId
        Author
        Action    
    }
`;

export const conversationFragment = gql`
    fragment conversationFragment on ConversationV2{
        ConversationId
        LastUpdatedOn
        ConversationName
        HasBeenNamed
        IsDeleted
        DeletedOn
        OrganisationId
        UserName
        IsGroupConversation
        ParticipantList
        ParticipantHash
        IsRemoved
        DeletedOn
        Participants {
            OrganisationId
            UserName
        }
    }`
    ;

export const messageFragment = gql`
    fragment messageFragment on MessageV2{
        IsRead
    }`;

    
export const convFragment = gql`
fragment convFragment on ConversationV2{
    UnreadCount
    HasUrgent
}`;