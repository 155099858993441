import { useApolloClient } from "@apollo/client";
import * as React from "react";
import style from "../../styles/MultipleContact.module.scss";
import { Pill } from "@emisgroup/ui-pill";
import { CONVERSATION_FRAGMENT_V2 } from "./schema";
import { UserConversation } from "../../types/userConversation";
import { V2User } from "../../types/user";
import { v4 as uuidv4 } from "uuid";
import { getUserDisplayName } from "../../utils";
import Clear from "~icons/ic/baseline-cancel";
interface IPills {
    conversation: UserConversation;
    conversationId: string;
    userERN: string;
    users: V2User[]
}



const Pills: React.FunctionComponent<IPills> = (props) => {
    const apolloClient = useApolloClient();
    const removeMember = (userERN: string) => {

        //remove participants
        apolloClient.cache.updateFragment({
            id: `GroupConversation:${props.conversationId}_${props.userERN}`,
            fragment: CONVERSATION_FRAGMENT_V2,
        }, (data) => {
            if (data) {
                const members = data.ParticipantList;
                const filterMembers = members.filter(item => item.split("_")[1] != userERN);   
                return { ...data, "ParticipantList": filterMembers };
            }
        });
    }

    if (props.conversation?.ParticipantList.length > 0) {
        const participants =  props.conversation.ParticipantList;
        const pills = participants.map(participant => {
            const user = props.users.find(user => user.UserERN == participant.split("_")[1]);
            return (
                <React.Fragment key={uuidv4()}>
                    {user && user.UserERN != props.userERN ?
                        <div key={uuidv4()} className={style.memberSectionPills}>
                            <Pill variant="added" data-testid="groupMember" onClick={() => removeMember(user.UserERN)}>{getUserDisplayName(user)}
                                <Clear color="white" size="small" title="close" />
                            </Pill>
                            <br />
                        </div>
                        : null}
                </React.Fragment>
            )
        });
        return <>{pills}</>
    }
    return <></>;
};

export default Pills;
