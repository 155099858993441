import { Route, Routes } from "react-router-dom";
import ContactList from "./ContactList";
import LandingPage from "./LandingPage";
import GroupMessage from "./GroupMessage";
import SelectMultipleContact from "./MultipleContact/SelectMultipleContact";
import EditMultipleContact from "./MultipleContact/EditMultipleContact";
import ApproachPage from "./ApproachPage";


import React, { useRef, useState } from "react";
import * as queries from "../graphql/queries";
import { authTokenWithBearer, HubListner } from "../utils";
import { Notification } from "./initialize/subscriptions/notification";
import { Message } from "./initialize/subscriptions/message";
import { User } from "./initialize/subscriptions/user";
import { useLazyQuery } from "@apollo/client";
import { ContextUtils } from "../utils/contextUtils";
import { User as UserClass } from "../utils/user";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";

export interface IBaseComponentProps {
  user: UserClass;
}

export const BaseComponent: React.FC<IBaseComponentProps> = (props: IBaseComponentProps) => {
  const notificationSub = new Notification();
  const messageSub = new Message();
  const userSub = new User();
  const subscriptionList = useRef<any[]>([]);
  const hubListner = useRef<any>(undefined);
  const [reloadSubscription, setReloadSubscription] = useState(undefined);

  const refRetryCount = useRef(0);

  const susbcriptionStauts = useRef<string>("");

  //get list of messages for existing conversation
  const [getConversation] = useLazyQuery(queries.GET_USER_CONVERSATIONS, {
    fetchPolicy: "network-only",
    variables: {
      OrganisationId: props.user.organisationId,
      UserName: props.user.userName
    }
  });

  const appContext: IAppContextInterface = React.useContext(AppContext);
  appContext.setRefreshSubscriptions = setReloadSubscription;

  //Amplify dispatch function to reconnect.
  async function subscribeAll() {
    try {
      let token = await ContextUtils.getAccessTokenFromContext();
      const jwt = authTokenWithBearer(token);

      //subscribe to notification.
      const subNotification = notificationSub.subscribe(jwt, getConversation);
      subscriptionList.current.push(subNotification);

      //subscribe to Messages
      const subMessage = messageSub.subscribe(jwt, getConversation);
      subscriptionList.current.push(subMessage);

      //subscribe to Users
      const subUser = userSub.subscribe(jwt);
      subscriptionList.current.push(subUser);
    } catch (error) {
      console.error(error);
    }
  }

  async function subscriptionRecreate() {
    unSubscribeAll();
    //re-try
    await subscribeAll();
  }

  function unSubscribeAll() {
    if (subscriptionList.current.length > 0) {
      for (const subscription of subscriptionList.current) {
        subscription.unsubscribe();
      }
      subscriptionList.current = [];
    }
  }

  function cancelAllHubListner() {
    hubListner.current();
  }

  //initial subcription
  React.useEffect(() => {
    //subscribe all
    subscribeAll();
    hubListner.current = HubListner(subscriptionRecreate, susbcriptionStauts);
    return () => {
      cancelAllHubListner();
      unSubscribeAll();
    };
  }, []);

  //Subscription reload
  React.useEffect(() => {
    if (reloadSubscription && refRetryCount.current <= 5) {
      subscriptionRecreate();
      hubListner.current = HubListner(subscriptionRecreate, susbcriptionStauts);
      refRetryCount.current++;
    }
  }, [reloadSubscription]);

  return (
    <Routes>
      <Route path="/staff">
        <Route index element={<ApproachPage />} />
        <Route path=":conversationId" element={<ApproachPage />} />
        <Route path="landing-page" element={<LandingPage />} />
        <Route path="contact-list" element={<ContactList />} />
        <Route path="contact-list/:conversationId" element={<ContactList />} />
        <Route path="group-message" element={<GroupMessage />} />
        <Route path="group-contact-list" element={<SelectMultipleContact />} />
        <Route path="group-contact-list/:conversationId" element={<EditMultipleContact />} />
      </Route>
    </Routes>
  );
};

export default BaseComponent;
