// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GwBcEKwCV7EUigV8RZVt{position:relative}.IyWMGtWHFkyVIVCf5Ueq{color:var(--text);background-color:var(--background);border-bottom:1px solid var(--border)}.I0XoMWPaVQCha_unvh7y{position:relative;padding:0;padding-left:0px}`, "",{"version":3,"sources":["webpack://./src/styles/ListItemComponent.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,kCAAA,CACA,qCAAA,CAGF,sBAEE,iBAAA,CACA,SAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listDiv": `GwBcEKwCV7EUigV8RZVt`,
	"horizontalLine": `IyWMGtWHFkyVIVCf5Ueq`,
	"messageDiv": `I0XoMWPaVQCha_unvh7y`
};
export default ___CSS_LOADER_EXPORT___;
