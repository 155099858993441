import { UserConversation } from "../types/userConversation";
import { Participant } from "../types/messages";
import { getParticipants } from "./GetParticipants";

export function leaveConversationFilter(localConversation: UserConversation, userName: string) {
   //Note: WHEN NEW API SCHEMA AVAILABLE/NEW RESOLVER getConversationByUser
     // CHANGES: USE OrgnisationERN, UserERN in ParticipantList.
  const participants=getParticipants(localConversation.Participants, localConversation.ParticipantList);
  // Filter out current user
  const newParticipants = participants.filter((item: Participant) => {
    if (item.UserName !== userName) {
      return item;
    }
  });

  let finalParticipants = newParticipants.map(item => {
    //Note: WHEN NEW API SCHEMA AVAILABLE/NEW RESOLVER getConversationByUser
     // CHANGES: Return OrgnisationERN, UserERN as object.
    return { UserName: item.UserName, OrganisationId: item.OrganisationId }
  });


  return finalParticipants;
}

export function leaveConversationFilterV2(participantsList: any, userERN: string) {
  // Filter out current user
  const newParticipants = participantsList.filter((item: string) => {
    if (item.split('_')[1] !== userERN) {
      return item;
    }
  });
  return newParticipants;
}