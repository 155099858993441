import { useLazyQuery, useQuery, useApolloClient, ApolloError } from "@apollo/client";
import * as React from "react";
import { ListItem } from "@emisgroup/ui-list";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import moduleStyle from "../styles/Common.module.scss";
import { useState, useRef, useEffect } from "react";
import Avatar from "../custom_components/UserAvatar/Avatar";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import { Checkbox } from "@emisgroup/ui-checkbox";
import { Participant } from "../types/messages";
import { CONVERSATION_FRAGMENT, GET_LOCAL_CONVERSATION } from "./MultipleContact/schema";
import { v4 as uuidv4 } from "uuid";
import EmptyStatePanel from "./EmptyStatePanel";
import { Stack } from "@emisgroup/ui-layouts";
import { Button } from "@emisgroup/ui-button";
import Refresh from "~icons/ic/refresh";
import { FeaturedIcon } from "@emisgroup/ui-icon";
import SystemProblem from "~icons/ic/twotone-sync-problem";
import { GET_USERS } from "../graphql/queries";
import { getParticipants, getParticipantList } from "../utils/GetParticipants";
import { getUserDisplayName } from "../utils";
import { Input } from "@emisgroup/ui-input";
import Search from "~icons/ic/outline-search";
import { useDebounce } from "use-debounce";
import noContactStyle from "../styles/NoContacts.module.scss";
import { IContactsProps } from "../models/IContactsProps";
import style from "../styles/Contacts.module.scss";
import noSearchStyle from "../styles/NoSearchContacts.module.scss";
import { useFlags } from "@emisgroup/acp-utility-feature-flags";

let _value: any;

export function get_latestData(): any {
  return _value;
}
export function set_contactData(v: any) {
  _value = v;
}

export enum SelectStatus {
  SELECT_ALL,
  DESELECT_ALL,
  INDETERMINATE
}

const GroupContacts: React.FC<IContactsProps> = (props: IContactsProps) => {

  //#endregion Read and update cache for select group member
  //get user conversation from cache 
  const [getConversation,
    { data: queryConversation }
  ] = useLazyQuery(GET_LOCAL_CONVERSATION, {
    variables: {
      ConversationId: "local-conversation"
    },
    onError: handleError,
    fetchPolicy: "cache-only"
  });

  const { loading, data, refetch, previousData } = useQuery(GET_USERS, {
    variables: { OrganisationId: checkOrgId() },
    onError: handleError,
    onCompleted: () => setErrorState(false),
    fetchPolicy: "cache-first"
  });

  console.clear();
  const participantMaxLimit = 80;
  const contactListRef = useRef<HTMLUListElement>(null);
  const [errorState, setErrorState] = useState(false);
  const [text, setText] = useState("");
  const [value] = useDebounce(text, 1000);
  const contactListScrollTopRef = useRef<number>(0);
  const contactLength = useRef(0);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { staffMessagingUiEnablesearchcontact } = useFlags();
  const [checked, setChecked] = useState(false);
  const previousGroupContactRef = useRef([]);

  Logger.debug("Creating Group Contacts component");
  const divRef = useRef([]);
  const [participantList, setParticipantList] = React.useState<Participant[]>([]);
  //Apollo Client 
  const apolloClient = useApolloClient();
  const onContactListScroll = () => {
    contactListScrollTopRef.current = contactListRef.current.scrollTop;
  }

  React.useLayoutEffect(
    () => {
      if (contactListScrollTopRef.current)
        contactListRef.current?.scrollTo(
          {
            top: contactListScrollTopRef.current
          }
        );
    }
  );


  const filterFunction = (userInput: string) => {
    return get_latestData()?.filter((x) => {
      if (!checked) {
        return x.UserName != props.userName && x.DisplayName.toLowerCase().includes(userInput);
      }
      if (userInput)
        return x.UserStatus == "online" && x.UserName != props.userName && x.DisplayName.toLowerCase().includes(userInput);
      return x.UserStatus == "online";
    });
  };



  useEffect(() => {
    if (contactListRef.current && previousGroupContactRef.current?.length == 0) {
      contactListRef.current.scrollTop = 0;
    }
    previousGroupContactRef.current = [];
  }, [filteredContacts]);

  function handleError(err: ApolloError) {
    console.error(`Error: ${JSON.stringify(err.message)}`);
    setErrorState(true);
  };

  function checkOrgId() {
    return props.organisationId ?? "";
  };



  useEffect(() => {
    previousGroupContactRef.current = previousData?.getUserV2;
  }, [previousData])

  useEffect(() => {
    setFilteredContacts(filterFunction(value.toLowerCase()));
  }, [data, value, checked]);



  React.useEffect(() => {
    if (queryConversation?.['getLocalConversation']) {
      const participantList = queryConversation['getLocalConversation'].ParticipantList as string[];
      const participants = getParticipants([], participantList);
      setParticipantList(participants);
    }
  }, [queryConversation]);

  React.useEffect(() => {
    if (props.conversationId) {
      getConversation().catch(err => { console.error(err) });
    }
  }, [props.conversationId])

  const checkCurrentUser = (conversationId: string, participant: Participant) => {
    if (participant && participant.UserName == props.userName) return false;
    if (!conversationId &&
      !queryConversation &&
      (queryConversation && !queryConversation['getLocalConversation'])
    ) return false;
  }

  // Add or remove participants from list
  function addRemoveMembers(remove: boolean, partcipantDetails: Participant[], participant: Participant) {
    let members = []
    if (remove) {
      //remove given Participant
      members = partcipantDetails.filter((item: Participant) => {
        if (item.UserName != participant.UserName) {
          return item;
        }
      });
    } else {
      //add given Participant

      members = [...partcipantDetails, {
        "__typename": "ConvParticipant",
        ...participant
      }];
    }
    return members;
  }

  function clearSearch() {
    setText("");
    inputRef.current.focus();
  }

  //Update covnersation cache.
  function updateParticipants(conversationId: string, selectAll: SelectStatus, participant: Participant) {

    checkCurrentUser(conversationId, participant);

    //Add or remove user 
    if (selectAll == SelectStatus.INDETERMINATE) {
      const data = apolloClient.cache.readFragment({
        id: `GroupConversationV2:${props.conversationId}_${props.userName}`,
        fragment: CONVERSATION_FRAGMENT
      });

      //participant already exists. then reove flag as true 
      const participants = getParticipants([], data?.['ParticipantList']);
      let remove: boolean = participants.some((item: Participant) => item.UserName == participant.UserName);

      let members = [];
      let participantList = [];
      if (participants) {
        members = addRemoveMembers(remove, participants, participant)
        participantList = getParticipantList(members);
      }

      //Update with 
      apolloClient.cache.updateFragment({
        id: `GroupConversationV2:${props.conversationId}_${props.userName}`,
        fragment: CONVERSATION_FRAGMENT,
      }, (data) => {
        let result = { ...data, "Participants": members, "ParticipantList": participantList };
        return result;
      });
      if (value)
        clearSearch();
    }
  }
  if (data) set_contactData(data.getUserV2.filter(x => x.DisplayName));

  if (loading) {
    return (
      <div className={style.spinner}>
        <ProgressSpinner text="Loading contacts..." />
      </div>
    );
  }

  const handleListItem = (e, data: any) => {
    e.preventDefault();
    if (data) {
      updateParticipants(props.conversationId, SelectStatus.INDETERMINATE, { UserName: data.UserName, OrganisationId: data.OrganisationId });
    }
    if (e.defaultPrevented) return
  };

  const memberSelected = (userName: string) => {
    if (participantList != null) {
      return participantList.some(item => item.UserName == userName);
    }
  }

  function getDisplayName(userDisplayName: string) {
    return userDisplayName && userDisplayName.split(",").length > 1 ? userDisplayName.split(",") : "";
  }

  function checkStatus(status: string) {
    return status ? status === "online" : false;
  }

  const createContactList = (contactDetails) => {
    contactLength.current = contactDetails.length;
    return contactDetails.map((value, index) => {
      const displayName = getUserDisplayName(value);
      const userFirstName = getDisplayName(displayName)[1]?.trim();
      const userLastName = getDisplayName(displayName)[0]?.trim();
      const userStatus = checkStatus(value.UserStatus);
      //Note: orgId may required. 
      return (
        props.userName == value.UserName ? null :
          < div key={uuidv4()}
            ref={(element) => {
              divRef.current[index] = element;
            }}
            id={value.UserName}
            data-testid={value.UserName}
            className={memberSelected(value.UserName) ? style.backgroundColorSelected : style.backgroundColorWhite}
            onClickCapture={(e) => {
              handleListItem(e, value)
            }}
          >
            <ListItem
              id={value.UserName}
              data-testid="listItem"
              className={style.listItem}
              action={{
                disableToggle: true
              }}
              key={value.UserName}
            >
              <span className={style.contactSpan}>
                {props.isGroupMessage ? (
                  <div className={style.contactCheckbox}>
                    <Checkbox
                      id={index}
                      data-testid={`contact-checkbox-${index}`}
                      className={style.checkboxPadding}
                      checked={memberSelected(value.UserName)}
                    />
                  </div>
                ) : null}
                <div className={style.avatarColumn}>
                  <Avatar
                    firstName={userFirstName}
                    fullName={getUserDisplayName(value)}
                    lastName={userLastName}
                    isAvailable={userStatus}
                    color={value.UserName.toLowerCase()}
                    isGroup={false}
                    size={"medium"}
                  />
                </div>
                <div>
                  <div className={style.contactDisplayName}>{getUserDisplayName(value)}</div>
                </div>
              </span>
            </ListItem>
          </div >
      );
    });
  };

  const sortContacts = (data: any) => {
    if (data) {
      return data
        .slice()
        .sort((a, b) => getUserDisplayName(a)?.localeCompare(getUserDisplayName(b)));
    }
  }

  const contactDetails = (contactDetails) => {
    if (errorState === false) {
      if (contactDetails != null && contactDetails.length > 0) {
        const contacts = sortContacts(contactDetails);
        return createContactList(contacts);
      } else if (contactDetails != null && contactDetails.length == 0) {
        return (
          <div key={uuidv4()} className={style.container}>
            <EmptyStatePanel header="No contacts" content="You do not have any contacts to send messages yet." />
          </div>
        );
      }
    }
  };

  const checkListStyle = () => {
    if (staffMessagingUiEnablesearchcontact)
      return searchMessageBackgroundStyle();
    else
      return messageBackgroundStyle();
  }

  const messageBackgroundStyle = () => {
    if (props.isNewMemberAdded) {
      if (participantList.length > participantMaxLimit) {
        return noSearchStyle.groupMessageBackgroundWithHistoryError;
      }
      return noSearchStyle.groupMessageBackgroundWithHistory;
    } else if (participantList.length > participantMaxLimit) {
      return noSearchStyle.groupMessageBackgroundError;
    }
    return noSearchStyle.groupMessageBackground;
  }

  const searchMessageBackgroundStyle = () => {
    if (props.isNewMemberAdded) {
      if (participantList.length > participantMaxLimit) {
        return style.groupMessageBackgroundWithHistoryError;
      }
      return style.groupMessageBackgroundWithHistory;
    } else if (participantList.length > participantMaxLimit) {
      return style.groupMessageBackgroundError;
    }
    return style.groupMessageBackground;
  }

  const filterOnlineUsers = (e) => {
    setChecked(e);
  }


  if (errorState) {
    return (
      <div className={moduleStyle.centerContainer}>
        <Stack className={moduleStyle.stackTemplate}>
          <FeaturedIcon>
            <SystemProblem color="primary" size="x-large" title="SystemProblem" />
          </FeaturedIcon>
          <EmptyStatePanel header="Something went wrong" content="Sorry, but we can't show your contacts at the moment. Try again later." />
          <div>
            <Button variant="filled" data-testid='retry-button' onClick={() => {
              refetch().catch(err => { console.error(err) });
            }
            }>
              <Refresh title="Edit" />
              Try again
            </Button>
          </div>
        </Stack>
      </div>
    );
  } else {
    return (
      <>
        {staffMessagingUiEnablesearchcontact && <div className={style.background}>
          <div className={style.groupInputMargin}>
            <Input ref={inputRef} data-testid="text-input" clearable={true} aria-label="search-contacts" onClear={() => {
              clearSearch();
            }} onChange={(e) => {
              setText(e.target.value);
            }}
              value={text} defaultValue="" autoFocus placeholder="Search for a contact by name">
              <Search title="" />
            </Input>
            <div className={style.onlineFormat}>
              <Checkbox className={style.checkBoxFormat} onChange={filterOnlineUsers} id="online-checkbox">
                <div>Online only</div>
              </Checkbox>
            </div>
          </div>
        </div>}
        <div key={uuidv4()} >
          {filteredContacts && value && filteredContacts.length == 0 ?
            <ul key={uuidv4()} className={checkListStyle()}>
              <div className={noContactStyle.noContactFormat}>No contacts found.</div></ul> :
            <ul key={uuidv4()} ref={contactListRef} data-testid={"scrollId"} onScroll={onContactListScroll}
              className={checkListStyle()}>{contactDetails(filteredContacts && filteredContacts.length > 0 ? filteredContacts : get_latestData())}</ul>
          }
        </div>
      </>
    );
  }
};

export default GroupContacts;