import style from "../styles/CardComponent.module.scss";
import { Card } from "../custom_components/Card/Card";
import Avatar from "../custom_components/UserAvatar/Avatar";
import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Badge } from "@emisgroup/ui-badge";
import { getUserDisplayName } from "../utils";
import { classNames } from "@emisgroup/ui-core";
import { GET_NEW_USERS } from "../graphql/queries";

export interface ICardComponentProps {
  text: string;
  time: string;
  isReceived?: boolean;
  isUrgent: boolean;
  isUnread: boolean;
  isGroup: boolean;
  authorERN: string;
  organisationId: string;
  isLastMessage: boolean;
  isRead: boolean;
}

function splitDisplayName(displayName: string, index: number) {
  return displayName?.split(",").length > 1 ? displayName.split(",")[index].trim() : "";
}

const CardComponent = (props: ICardComponentProps) => {
  const { text, time, isReceived, isUrgent, isUnread, isGroup, authorERN, isLastMessage, isRead } = props;
  const [displayName, setDisplayName] = useState("");
  let sentOrSeen: string = "";
  //isLastMessage is true and isReceived is false and isRead is false then set string  Sent
  if (isLastMessage) {
    sentOrSeen = (isRead && !isGroup)? "Seen" : "Sent";
  }

  //Get user details from cache
  const { data: user } = useQuery(GET_NEW_USERS, {
    variables: { OrganisationERN: props.organisationId },
    fetchPolicy: "cache-first"
  });

  useEffect(() => {
    if (user?.["getUser"] && user["getUser"].length > 0) {
      const userData = user["getUser"].find((u) => u.UserERN == props.authorERN);
      const name = getUserDisplayName(userData);
      setDisplayName(name);
    }
  }, [user]);

  if (isReceived) {
    return (
      <div
        className={classNames([
          {
            [style.sendDiv]: !isGroup,
            [style.sameSender]: isGroup
          }
        ])}
      >
        <div className={isUnread ? style.unreadDiv : style.readDiv}>
          <Card className={style.recievedMessage}>
            {isUrgent ? (
              <div className={style.urgentDiv}>
                <Badge children="Urgent" variant="danger" />
              </div>
            ) : null}
            {isGroup ? (
              <div className={style.cardAlign}>
                <div className={style.avatar}>
                  <Avatar
                    firstName={splitDisplayName(displayName, 1)}
                    fullName={displayName}
                    lastName={splitDisplayName(displayName, 0)}
                    color={authorERN}
                    isGroup={true}
                    size={"x-small"}
                  />
                </div>
                <div className={style.displayName}>{displayName}</div>
              </div>
            ) : null}
            <div className={style.messageLayout}>
              <div className={style.cardText} dangerouslySetInnerHTML={{ __html: text }}></div>
              <div className={style.timeStamp}>{time}</div>
            </div>
          </Card>
        </div>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <div className={style.receiveDiv}>
          <Card className={style.sentMessage}>
            {isUrgent ? (
              <div className={style.urgentDiv}>
                <Badge children="Urgent" variant="danger" />
              </div>
            ) : null}
            <div className={style.messageLayout}>
              <div className={style.cardText} dangerouslySetInnerHTML={{ __html: text }}></div>
              <div className={style.timeStamp}>{time}</div>
            </div>
          </Card>
        </div>
        {isLastMessage && <div className={style.sentSeenCard}>{sentOrSeen}</div>}
      </React.Fragment>
    );
  }
};

export default CardComponent;