// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@container (width < 768px){.gwu45qjsprZhDe6KVXbr{position:absolute;justify-content:center;align-items:center;background-color:var(--background);height:calc(100vh - var(--footer-height, 0px) - (1*var(--acp-navapp-height, 48px) + var(--acp-navapp-height, 0px)));width:100%}}@container (min-width: 768px){.gwu45qjsprZhDe6KVXbr{justify-content:center;align-items:center;background-color:var(--background);height:calc(100vh - var(--footer-height, 0px) - (2*var(--acp-navapp-height, 48px) + var(--acp-navapp-height, 0px)));width:100%;max-width:1040px}}`, "",{"version":3,"sources":["webpack://./src/styles/SendMessage.module.scss"],"names":[],"mappings":"AAEA,2BACE,sBACE,iBAAA,CACA,sBAAA,CACA,kBAAA,CACA,kCAAA,CACA,mHAAA,CACA,UAAA,CAAA,CAGJ,8BACE,sBACE,sBAAA,CACA,kBAAA,CACA,kCAAA,CACA,mHAAA,CACA,UAAA,CACA,gBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sendMessage": `gwu45qjsprZhDe6KVXbr`
};
export default ___CSS_LOADER_EXPORT___;
