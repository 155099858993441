// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PMSbeHl_lUKfpI7d7H8w{height:auto;max-width:100%;padding:var(--spacing-small)}`, "",{"version":3,"sources":["webpack://./src/custom_components/Card/Card.module.scss"],"names":[],"mappings":"AACA,sBACE,WAAA,CACA,cAAA,CACA,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `PMSbeHl_lUKfpI7d7H8w`
};
export default ___CSS_LOADER_EXPORT___;
