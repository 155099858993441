import React from "react";
import LaunchDarklyProvider from "./components/initialize/LaunchDarklyProvider";
import Layout from "./Layout";
const App = (): React.JSX.Element => {
  return (
    <LaunchDarklyProvider>
      <Layout />
    </LaunchDarklyProvider>
  );
};

export default App;
