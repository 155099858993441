/**
 * This class is to handle the CREATE_MESSAGE which we are receiving from notification subscription
 * and we receive only system messages from this subscription.
 */
import { User } from "../../../utils/user";
import { useApolloClient } from "@apollo/client";
import { generateClient } from "aws-amplify/api";
import * as subscriptions from "../../../graphql/subscriptions";
import { Message } from "../../../types/messages";
import { CacheUtil } from "../utils/Cache";
import { NotificationTypeV2 } from "../../../types/notification";
import * as fragments from "../../../graphql/fragments";
import { IAppContextInterface } from "../../../models/IAppContextInterface";
import { AppContext } from "../../../utils/ApplicationContext";
import React from "react";
import { MessageReadStatusNotificationV2 } from "../../../types/messageReadStatus";
import { IConversationByUser } from "../../../types/userConversation";

export class Notification {
  user: User;

  apolloClient = useApolloClient();
  cacheUtil: CacheUtil;

  constructor() {
    this.cacheUtil = new CacheUtil();
  }
  appContext: IAppContextInterface = React.useContext(AppContext);
  client = generateClient();
  subscribe(token: string, getConversationByUser: any, getConversation: any): any {
    this.user = new User(token);
    const filter = `${this.user.organisationId}_${this.user.userERN}`;
    const notificationReq = { Filter: filter };
    return this.client
      .graphql({
        query: subscriptions.subscribeToNotificationV5,
        variables: notificationReq,
        authToken: token.trim()
      })
      .subscribe({
        next: (value) => {
          if (!value?.data.subscribeToNotificationV5) return;
          //Handle system message subscription.
          if (value.data["subscribeToNotificationV5"].Type == NotificationTypeV2.CREATE_MESSAGE) {
            const message: Message[] = JSON.parse(value.data["subscribeToNotificationV5"].Data) as Message[];
            message.forEach((message) => this.cacheUtil.appendMessageInCache({...message, IsRead: false}, this.user));
            this.apolloClient.cache.updateFragment(
              {
                id: `ConversationStore:${message[0].ConversationId}`,
                fragment: fragments.getConversationStore
              },
              (data) => {
                if (data) return { ...data, Action: "COMPLETED" };
              }
            );
          }
          if (value.data["subscribeToNotificationV5"].Type === NotificationTypeV2.MESSAGE_READ_STATUS) {
            //MessageReadStatusNotification from the subscription
            const readStatus: MessageReadStatusNotificationV2 = JSON.parse(
              value.data["subscribeToNotificationV5"].Data
            ) as MessageReadStatusNotificationV2;
            this.cacheUtil.updateMessageStatusInCache(readStatus, this.user);
            getConversationByUser({
              variables: {
                OrganisationERN: this.user.organisationId,
                UserERN: this.user.userERN,
                ConversationId: readStatus.ConversationId
              }
            });
          }
          if (value.data["subscribeToNotificationV5"].Type === NotificationTypeV2.UPDATE_CONVERSATION) {
            const conversationNotification = JSON.parse(
              value.data["subscribeToNotificationV5"].Data
            ) as IConversationByUser;

            getConversation({
              variables: {
                ConversationIds: [conversationNotification.ConversationId]
              }
            });

            getConversationByUser({
              variables: {
                OrganisationERN: this.user.organisationId,
                UserERN: this.user.userERN,
                ConversationId: conversationNotification.ConversationId
              }
            });

            //read the conversation from cache and update the conversation.
          }
        },
        error: (error) => {
          this.appContext.setRefreshSubscriptions({});
          console.error("Notification subscription: " + error);
        }
      });
  }
}
