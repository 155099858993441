export enum NotificationType {
  CREATE_CONVERSATION,
  CREATE_USER,
  CREATE_MESSAGE,
  UPDATE_CONVERSATION,
  MESSAGE_READ_STATUS,
  USER_STATUS_UPDATE
}
export enum NotificationTypeV2 {
  CREATE_CONVERSATION = "CREATE_CONVERSATION",
  CREATE_USER = "CREATE_USER",
  CREATE_MESSAGE = "CREATE_MESSAGE",
  UPDATE_CONVERSATION = "UPDATE_CONVERSATION",
  MESSAGE_READ_STATUS = "MESSAGE_READ_STATUS",
  USER_STATUS_UPDATE = "USER_STATUS_UPDATE"
}

export interface Notification {
  Type: NotificationType;
  Data: string;
  ParticipantList: string[];
}

export interface ConversationNotification {
  ConversationId: string;
  OrganisationId: string;
  ParticipantList: string[];
}
