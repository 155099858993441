import { jwtDecode } from "jwt-decode";
import { decode } from 'js-base64';
import { ApolloError, gql, useMutation } from "@apollo/client";

export interface Ijwt {
  family_name: string;
  given_name: string;
  sub: string;
  role: string;
  email: string;
  userERN : string;
  orgErn: string;
  exp: number;
  iss: string;
  cdb: string;
}

interface Irole {
  OrganisationGuid: string;
  OdsCode: string;
  UserInRoleId: string;
  UserInRoleProfileId: string;
}

export const CREATE_USER_GQL = gql`
  mutation CreateUser(
    $UserName: String!
    $DisplayName: String!
    $JobType: String!
    $JobTypeId: ID!
    $OrganisationId: ID!
    $OrganisationName: String!
    $UserStatus: String
  ) {
    createUserV2(
      JobType: $JobType
      JobTypeId: $JobTypeId
      OrganisationId: $OrganisationId
      OrganisationName: $OrganisationName
      UserName: $UserName
      DisplayName: $DisplayName
      UserStatus: $UserStatus
    ) {
      JobType
      JobTypeId
      OrganisationId
      OrganisationName
      UserName
      DisplayName
      UserStatus
    }
  }
`;

export interface IUser {
  givenName: string;
  familyName: string;
  userName: string;
  userERN: string;
  displayName: string;
  organisationId: string;
  odsCode: string;
  cdb: string;
  jobTypeId?: string;
  userStatus: string;
}

export class User implements IUser {
  givenName: string;
  familyName: string;
  userName: string;
  userERN: string;
  displayName: string;
  organisationId: string;
  odsCode: string;
  cdb: string;
  jobType: string;
  orgName: string;
  jobTypeId: string;
  userStatus: string = "online";
  getSafeValue: any = (field) => {
    return field || undefined;
  }


  extractUserName = (jwt) => {
    return jwt.sub && this.getSafeValue(jwt.sub).includes('@') // Check it's an email address
      ? this.getSafeValue(jwt.sub) : this.getSafeValue(jwt.email);
  }

  extractDisplayName = (jwt) => {
    const displayName = this.givenName && this.familyName ? this.familyName.toUpperCase() + ", " + this.givenName.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()) : undefined;
    return jwt.title ? displayName + " (" + jwt.title + ")" : displayName; // Add title if known
  }


  // Handles B2C or Emis Web IDP style tokens
  updateFromJwt = (jwt) => {

    if (jwt) {
      this.givenName = jwt.givenName ? this.getSafeValue(jwt.givenName) : this.getSafeValue(jwt.given_name);
      this.familyName = jwt.familyName ? this.getSafeValue(jwt.familyName) : this.getSafeValue(jwt.family_name);
      this.userName = this.extractUserName(jwt).toLowerCase();
      this.userERN = this.getSafeValue(jwt.userERN);
      this.displayName = this.extractDisplayName(jwt);
      this.organisationId = jwt.orgERN ? this.getSafeValue(jwt.orgERN) : this.getSafeValue(jwt.orgErn);
      this.orgName = this.getSafeValue(jwt.orgName);
      this.cdb = this.getSafeValue(jwt.cdb);
      this.jobType = jwt.roleNames ? this.getSafeValue(jwt.roleNames[0]) : this.getSafeValue(jwt.roleName); // Uses first role in array if an array is passed
    }
  }

  updateFromRole = (role, jwt) => {
    if (role) {
      this.odsCode = this.getSafeValue(role.OdsCode);
      this.jobTypeId = this.getSafeValue(role.UserInRoleId);
    } else if (jwt) {
      this.jobTypeId = jwt.roleERNs ? this.getSafeValue(jwt.roleERNs[0]) : undefined // Uses first role ern in array if an array is passed
    }
  }

  updateUserInfo = (jwt, role) => {
    this.updateFromJwt(jwt);
    this.updateFromRole(role, jwt);
  }

  constructor(token: string) {
    try {
      const jwt: Ijwt = this.getDecodedAccessToken(token);
      const role: Irole = jwt?.role ? JSON.parse(decode(jwt.role)) : undefined;

      this.updateUserInfo(jwt, role);

    } catch (error) {
      console.log(error);
    }
  }

  public createUpdateUser(): boolean {
    const [createUser, { loading, error }] = useMutation(CREATE_USER_GQL);

    const handleError = (_err: ApolloError) => {
      return false;
    };

    if (error || (!this.userName && !loading)) {
      return false;
    }

    createUser({
      variables: {
        UserName: this.userName.toLowerCase(),
        DisplayName: this.displayName,
        JobTypeId: this.jobTypeId,
        JobType: this.jobType,
        OrganisationId: this.organisationId,
        OrganisationName: this.orgName,
        UserStatus: this.userStatus
      },
      onError: handleError
    });
    return true;
  }

  getDecodedAccessToken(token: string): Ijwt {
    try {
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  }
}
