import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, NormalizedCacheObject, defaultDataIdFromObject } from "@apollo/client";
import fetch from "cross-fetch";
import { setContext } from "@apollo/client/link/context";
import { ContextUtils } from "./contextUtils";

export class ApolloProviderClient {
  readonly client: ApolloClient<NormalizedCacheObject>;

  apiKey = localStorage.getItem("api_key") ? localStorage.getItem("api_key") : process.env.API_KEY
  api_header = {
    host: process.env.HOST,
    "x-api-key": this.apiKey,
    "Authorization": ""
  };

  graphqlPrinter = require("graphql/language/printer");

  createAppSyncGraphQLOperationAdapter = () => ({
    applyMiddleware: async (options, next) => {
      // AppSync expects GraphQL operation to be defined as a JSON-encoded object in a "data" property
      options.data = JSON.stringify({
        query: typeof options.query === "string" ? options.query : this.graphqlPrinter.print(options.query),
        variables: options.variables
      });

      // AppSync only permits authorized operations
      options.extensions = { authorization: this.api_header };

      // AppSync does not care about these properties
      delete options.operationName;
      delete options.variables;
      // Not deleting "query" property as SubscriptionClient validation requires it
      next();
    }
  });

  constructor(token: string, apiUrl: string) {

    const fullToken = token.includes("Bearer") ? token : `Bearer ${token}`;
    const apiKey = localStorage.getItem("api_key") ? localStorage.getItem("api_key") : process.env.API_KEY
    this.api_header = {
      host: process.env.HOST,
      "x-api-key": apiKey,
      "Authorization": fullToken
    };

    const { Laika } = require("@zendesk/laika/cjs/laika");
    const httpLink = new HttpLink({
      uri: apiUrl,
      headers: {
        "x-api-key": apiKey,
        "Content-Type": "application/graphql",
        "Access-Control-Allow-Headers": "*",
        "Authorization": fullToken
      },
      fetch: fetch
    });



    //#region Laika mock
    const laika = new Laika();
    //#endregion

    // This allows the mutation and query connection to be maintained on an ACP token refresh.
    const authLink = setContext(async (_, { headers }) => {
      const token = await ContextUtils.getAccessTokenFromContext();
      const fullToken = token.includes("Bearer") ? token : `Bearer ${token}`;
      return {
        headers: {
          ...headers,
          authorization: fullToken,
        },
      };
    });

    this.client = new ApolloClient({
      link: ApolloLink.from([
        authLink,
        laika.createLink(), // mock link
        httpLink
      ]),
      cache: new InMemoryCache({
        dataIdFromObject(responseObject) {
          switch (responseObject.__typename) {
            case `ChatUserConversation`:
              return `ChatUserConversation:${responseObject.ConversationId}_${responseObject.UserERN}`;
            case `ConversationDetails`:
              return `ConversationDetails:${responseObject.Type_Id}`;
            case `ConversationStore`:
              return `ConversationStore:${responseObject.ConversationId}`;
            case `CountStore`:
              return `CountStore:${responseObject.ConversationId}`;
            case 'GroupConversationV2':
              return `GroupConversationV2:${responseObject.ConversationId}_${responseObject.UserName}`;
            case 'GroupConversation':
              return `GroupConversation:${responseObject.ConversationId}_${responseObject.UserERN}`;
            case 'ConversationV2':
              return `ConversationV2:${responseObject.ConversationId}_${responseObject.UserName}`;
            case `UserV2`:
              return `UserV2:${responseObject.UserName}`;
            case `User`:
              return `User:${responseObject.UserERN}`;
            case 'MessageV2':
              return `MessageV2:${responseObject.CreatedAt_MessageId}`;
            default:
              return defaultDataIdFromObject(responseObject);
          }
        },
        typePolicies: {
          GroupConversationV2: {
            fields: {
              IsLocal: {
                read(IsLocal = false) {
                  return IsLocal;
                }
              }
            }
          },
          GroupConversation: {
            fields: {
              IsLocal: {
                read(IsLocal = false) {
                  return IsLocal;
                }
              }
            }
          }
        }
      }),
    });
  }

  getClient() {
    return this.client;
  }
}