import { useApolloClient } from "@apollo/client";
import * as React from "react";
import style from "../../styles/MultipleContact.module.scss";
import { Pill } from "@emisgroup/ui-pill";
import { CONVERSATION_FRAGMENT } from "./schema";
import { UserConversation } from "../../types/userConversation";
import { User } from "../../types/user";
import { v4 as uuidv4 } from "uuid";
import { getParticipantList, getParticipants } from "../../utils/GetParticipants";
import { getUserDisplayName } from "../../utils";
import Clear from "~icons/ic/baseline-cancel";
interface IPills {
    conversation: UserConversation;
    conversationId: string;
    userName: string;
    users: User[]
}



const Pills: React.FunctionComponent<IPills> = (props) => {
    const apolloClient = useApolloClient();
    const removeMember = (userName: string) => {

        //remove participants
        apolloClient.cache.updateFragment({
            id: `GroupConversationV2:${props.conversationId}_${props.userName}`,
            fragment: CONVERSATION_FRAGMENT,
        }, (data) => {
            if (data) {
                const members = getParticipants([], data.ParticipantList);
                const filterMembers = members.filter(item => item.UserName != userName);              
  //Note: WHEN NEW API SCHEMA AVAILABLE 
  // CHANGES: USE OrgnisationERN, UserERN in ParticipantList.
  //NOTE: You can use getNewParticipantList function
                const participantList = getParticipantList(filterMembers);
                return { ...data, "Participants": filterMembers, "ParticipantList": participantList };
            }
        });
    }

    if (props.conversation?.ParticipantList.length > 0) {
        const participants = getParticipants([], props.conversation.ParticipantList);
        const pills = participants.map(participant => {
            const user = props.users.find(user => user.UserName == participant.UserName);
            return (
                <React.Fragment key={uuidv4()}>
                    {user && user.UserName != props.userName ?
                        <div key={uuidv4()} className={style.memberSectionPills}>
                            <Pill variant="added" data-testid="groupMember" onClick={() => removeMember(user.UserName)}>{getUserDisplayName(user)}
                                <Clear color="white" size="small" title="close" />
                            </Pill>
                            <br />
                        </div>
                        : null}
                </React.Fragment>
            )
        });
        return <>{pills}</>
    }
    return <></>;
};

export default Pills;
