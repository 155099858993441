import * as React from "react";
import { IntlProvider as AppIntlProvider } from "@emisgroup/application-intl";
import enGBMessages from "./locales/en-GB/translation.json";
import { IChildrenProp } from "./Types";

export const applicationNamespace = "App";

export const resources = {
  "en-GB": {
    [applicationNamespace]: enGBMessages
  }
};

type Props = IChildrenProp;

/**
 * Responsible for rendering the IntlProvider component
 *
 * @param props child component to wrap
 * @returns wrapped component to handle internationalization.
 */
const IntlProvider: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return (
    <AppIntlProvider defaultNS={applicationNamespace} resources={resources}>
      {children}
    </AppIntlProvider>
  );
};

export { IntlProvider };
