import React, { FC, useEffect, useRef, useState } from "react";
import { ListItemView } from "./ListItem.view";
import { IListItemProps } from "./Props";

type Props = IListItemProps;

export const ListItem: FC<Props> = (props: Props): JSX.Element => {
  const { disableItemSelection, onToggle, selected, stickyLeadingContent } =
    props;

  const thisListItemRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [stickyOnLeft, setStickyOnLeft] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);

  /**
   * If the external "selected" prop is updated, make sure the internal
   * state matches.
   */
  useEffect(() => {
    setItemSelected(selected ?? false);
  }, [selected]);

  /**
   * This little snippet is responsible for making sure checkboxes and
   * radio buttons "stick" to the top of the list item when it reaches
   * the "large" size breaker (three lines of text).
   */
  useEffect(() => {
    if (stickyLeadingContent) {
      const elementHeight = thisListItemRef.current.offsetHeight;
      setStickyOnLeft(elementHeight >= 72);
    }
  }, [stickyLeadingContent, thisListItemRef]);

  const toggleSelectedState = () => {
    if (!disableItemSelection) {
      const newSelectState = !itemSelected;
      setItemSelected(newSelectState);

      if (onToggle) {
        onToggle.callback(newSelectState, onToggle.name);
      }
    }
  };

  return getToggleView(props, itemSelected, stickyOnLeft, thisListItemRef, toggleSelectedState);
};

const getToggleView = (
  props: Props,
  itemSelected,
  stickyOnLeft,
  thisListItemRef,
  toggleSelectedState
): JSX.Element => {
  const {
    bottomBarContent,
    children,
    className,
    "data-testid": dataTestId,
    disableItemSelection,
    hasDivider,
    id,
    leadingContent,
    trailingContent,
  } = props;

  const handleKeyEvent = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const keyCode = e.code;

    if (keyCode === "Space" || keyCode === "Enter") {
      e.preventDefault();
      toggleSelectedState();
    }
  };

  return (
    <ListItemView
      bottomBarContent={bottomBarContent}
      className={className}
      data-testid={dataTestId}
      disableItemSelection={disableItemSelection}
      handleKeyEvent={handleKeyEvent}
      hasDivider={hasDivider}
      id={id}
      itemSelected={itemSelected}
      key={id}
      leadingContent={leadingContent}
      reference={thisListItemRef}
      stickyOnLeft={stickyOnLeft}
      toggleSelectedState={toggleSelectedState}
      trailingContent={trailingContent}
      trailingContentSection={trailingContent?.content}
    >
      {children}
    </ListItemView>
  );
};

