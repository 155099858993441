import React, { useCallback, useEffect, useRef, useState } from "react";
import { User } from "../utils/user";
import { ISendMessageStateProps } from "./SendMessage";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import { ApolloError, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ProgressSpinner } from "@emisgroup/ui-progress-indicator";
import { Logger } from "@emisgroup/logging-sdk-typescript";
import { Message } from "../types/messages";
import PreviewPanelUserUpdates from "./PreviewPanelUserUpdates";
import { UserConversation } from "../types/userConversation";

//style
import commonStyle from "../styles/Common.module.scss";
import { UPDATE_MESSAGE_READ_STATUS } from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { MESSAGE_UNREAD_COUNT } from "../graphql/queries";

export interface IPreviewPanelMessagesLoadingProps {
  memoizedSetSendMessageState: React.Dispatch<React.SetStateAction<ISendMessageStateProps>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  refConversationId: string;
  selectedUserName: string;
  queryConversation: any;
  isGroup?: boolean;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
}

//#region Graphql schema

export const initialLoadCount = 25;

const PreviewPanelMessagesLoading: React.FC<IPreviewPanelMessagesLoadingProps> = (
  props: IPreviewPanelMessagesLoadingProps
) => {
  const loadedMessages = useRef<Message[]>([]);
  const updateCount = useRef(0);
  const hasLoaded = useRef(false);
  const [readTriggerCount, setReadTriggerCount] = useState(0);
  const [loadedCount, setLoadedCount] = useState(0);
  const client = useApolloClient();
  const [updateMessageReadStatus] = useMutation(UPDATE_MESSAGE_READ_STATUS);

  console.clear();
  const handleError = (_err: ApolloError) => {
    console.error(`Error  : ${JSON.stringify(_err.message)}`);
    props.setIsError(true);
    Logger.error("Error rendering PreviewPanelMessagesLoading");
    Logger.error(_err.message);
    return false;
  };

  const appContext: IAppContextInterface = React.useContext(AppContext);
  let user = new User(appContext.userToken);



  // Fetch messages in the first conversation
  const {
    data: messages,
    fetchMore,
    updateQuery: updateMessageCache
  } = useQuery(queries.GET_MESSAGES_BY_CONVERSATION, {
    variables: {
      ConversationId: props.queryConversation[0].ConversationId,
      Limit: initialLoadCount
    },
    onError: handleError,
    fetchPolicy: "cache-first"
  });

  const { data: allMessages } = useQuery(queries.GET_LOCAL_MESSAGES, {
    onError: handleError,
    variables: {
      Id: "all"
    },
    fetchPolicy: "cache-only"
  });


  useEffect(() => {
    if (allMessages?.getLocalMessages?.length > 0) {
      loadedMessages.current = allMessages.getLocalMessages;
      // think and try to stop this 
      setLoadedCount((prev) => {
        return prev + 1;
      });

    }
  }, [allMessages]);

  /** 
   * Append message into cache
   */
  const writeMessageCache = (messages: Message[]) => {

    let prevMessages: Message[] = [];
    //read cached messages
    const result = client.cache.readQuery({
      query: queries.GET_LOCAL_MESSAGES,
      variables: {
        Id: "all"
      }
    });

    if (result?.['getLocalMessages']) {
      prevMessages = result['getLocalMessages'] as Message[];
    }
    //write cached messaes
    client.cache.writeQuery({
      query: queries.GET_LOCAL_MESSAGES,
      variables: {
        Id: "all"
      },
      data: {
        getLocalMessages: [...prevMessages, ...messages]
      }
    });
  }

  const fetchMoreMessages = async (conv: UserConversation) => {
    const updateTotal = props.queryConversation.length - 1;
    await fetchMore({
      variables: {
        ConversationId: conv.ConversationId,
        Limit: initialLoadCount
      },
      updateQuery: (prev, { fetchMoreResult }) => {

        writeMessageCache(fetchMoreResult['getMessageV2'].Messages)

        updateCount.current++;
        if (updateCount.current == updateTotal) {
          setLoadedCount((prev) => {
            return prev + 1;
          });
        }
      }
    }).catch((error) => {
      props.setIsError(true)
    });
  };


  useEffect(() => {
    if (messages?.getMessageV2?.Messages && hasLoaded.current === false) {
      hasLoaded.current = true;

      loadedMessages.current = [...loadedMessages.current, ...messages["getMessageV2"].Messages];
      writeMessageCache(messages['getMessageV2'].Messages)
      if (props.queryConversation.length > 1) {
        props.queryConversation.slice(1).forEach(async (conv: UserConversation) => {
          await fetchMoreMessages(conv);
        });
      } else {
        // Trigger render when less than 2 conversations
        setLoadedCount((prev) => {
          return prev + 1;
        });
      }
    }
  }, [messages]);

  // Updates message cache for read messages
  function readStatusCacheUpdates(conversationId) {
    const cachedMessages = client.cache.readQuery({
      query: queries.GET_MESSAGES_BY_CONVERSATION,
      variables: {
        ConversationId: conversationId,
        Limit: initialLoadCount
      }
    });
    if ((cachedMessages as any)?.getMessageV2) {
      const messageData = cachedMessages["getMessageV2"].Messages.map((item) => {
        if (item.AuthorId !== user.userName) {
          return { ...item, IsRead: "true" };
        }
        return item;
      });
      client.writeQuery({
        query: queries.GET_MESSAGES_BY_CONVERSATION,
        variables: {
          ConversationId: conversationId,
          Limit: initialLoadCount
        },
        data: {
          getMessageV2: {
            __typename: "MessageV2Pages",
            id: cachedMessages["getMessageV2"].id,
            Messages: messageData,
            NextToken: cachedMessages["getMessageV2"].NextToken
          }
        }
      });
    }
  }

  // Get read status updates from message previews navigation
  const handleReadMessageUpdate = useCallback((conversationId) => {
    client.cache.writeQuery({
      query: MESSAGE_UNREAD_COUNT,
      variables: {
        ConversationId: conversationId
      },
      data: {
        conversationDetails: {
          __typename: "CountStore",
          id: `CountStore:${conversationId}`,
          ConversationId: conversationId,
          UnreadCount: 0,
          HasUrgent: false
        }
      }
    });
    updateMessageReadStatus({
      variables: {
        ConversationId: conversationId,
        AuthorId: user.userName,
        AuthorOrg: user.organisationId,
        IsRead: true
      },
      onError: handleError,
      update() {
        updateMessageCache((prev) => {
          let itemList: Message[];

          if (loadedMessages.current) {
            itemList = loadedMessages.current;
          }
          if (itemList) {
            let items = JSON.parse(JSON.stringify(itemList));

            items = sortItems(items, conversationId);
            itemList = items;
          }
          loadedMessages.current = itemList;

          readStatusCacheUpdates(conversationId);
        });

        setReadTriggerCount((prev) => {
          return prev + 1;
        });
      }
    }).catch(e => console.error('Update messge read status failed.' + e.message));
  }, []);

  function sortItems(items: any, conversationId: any) {
    items
      .filter((item) => item.IsRead === false && item.ConversationId === conversationId && item.AuthorId !== user.userName)
      .forEach((item) => {
        item.IsRead = "True";
      });

    return items
  }

  const { data: readConversation } = useQuery(queries.CONVERSATION_READ, {
    variables: {
      UserName: user.userName
    }
  });


  useEffect(() => {
    if (!readConversation?.conversationRead?.ConversationId) return
    let itemList: Message[];
    if (loadedMessages.current) {
      itemList = loadedMessages.current;
    }
    if (itemList) {
      let items = JSON.parse(JSON.stringify(itemList));
      items.forEach((item: Message) => {
        if (item.ConversationId === readConversation['conversationRead'].ConversationId && item.AuthorId !== user.userName) {
          item.IsRead = true;
        }
      });
      itemList = items;
    }

    client.cache.writeQuery(
      {
        query: queries.CONVERSATION_READ,
        variables: {
          UserName: appContext.user.userName
        },
        data: {
          conversationRead: {
            ConversationId: ""
          }
        }
      }
    )
    loadedMessages.current = itemList;

    //to rerender the screen.
    setReadTriggerCount((prev) => {
      return prev + 1;
    });
  }, [readConversation]);


  //#endregion
  if ((readTriggerCount > 0 || loadedCount > 0) && loadedMessages.current.length > 0) {
    return (
      <PreviewPanelUserUpdates
        key={"PreviewPanelUserUpdates"}
        queryConversation={props.queryConversation}
        memoizedSetSendMessageState={props.memoizedSetSendMessageState}
        messageReadCallback={handleReadMessageUpdate}
        setIsError={props.setIsError}
        refConversationId={props.refConversationId}
        selectedUserName={props.selectedUserName}
        loadedMessageList={loadedMessages.current}
        isGroup={props.isGroup}
        setIsRemoved={props.setIsRemoved}
      />
    );
  } else {
    return (
      <div className={commonStyle.leftPanel}>
        <div className={commonStyle.spinner}>
          <ProgressSpinner text="Loading messages..." />
        </div>
      </div>
    );
  }
};

export default PreviewPanelMessagesLoading;


