import React, { useState } from "react";
import { ITopbar } from "./hoc/withTopbar";
import style from "../styles/SendMessage.module.scss";
import MessageSendingComponent from "./MessageSendingComponent";
import { IUser } from "../utils/user";

import { Form } from "@emisgroup/ui-form";
import MessageDisplayComponent from "./MessageDisplayComponent";
import { CONVERSATION_READ, GET_MESSAGES_BY_CONVERSATION, MESSAGE_UNREAD_COUNT } from "../graphql/queries";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_MESSAGE_READ_STATUS } from "../graphql/mutations";
import { IAppContextInterface } from "../models/IAppContextInterface";
import { AppContext } from "../utils/ApplicationContext";
import commonStyle from "../styles/Common.module.scss";
import TitlePanel from "./TitlePanel";

export interface ISendMessageProps extends ISendMessageStateProps, ITopbar, IUser {
  refOldConversationId?: React.MutableRefObject<string>;
  setIsRemoved: React.Dispatch<React.SetStateAction<boolean>>;
  setLeaveError: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isConversationSelected: boolean;
  isGroupConv: boolean;
}

export interface ISendMessageStateProps {
  title: string;
  recipients: string[];
  message: string;
  time: string;
  conversationId: string;
  createdAtMessageId: string;
  authorId: string;
  avatarColor: string;
  isGroup: boolean;
  isRemoved: boolean;
  conversationName: string;
  hasBeenNamed?: boolean;
  removedOn: string;
  removedBy: string
}
export const initialLoadCount = 100;

const SendMessage: React.FC<ISendMessageProps> = (props: ISendMessageProps) => {
  const [isSmallGroupCreating, setIsSmallGroupCreating] = useState(false);
  const apolloClient = useApolloClient();
  const [updateMessageReadStatus] = useMutation(UPDATE_MESSAGE_READ_STATUS);
  const [isLoading, setIsLoading] = useState(false);

  const appContext: IAppContextInterface = React.useContext(AppContext);

  function newMessageCacheUpdates(conversationId: string) {
    //with limit
    const cacheLimit = apolloClient.cache.readQuery({
      query: GET_MESSAGES_BY_CONVERSATION,
      variables: {
        ConversationId: conversationId,
        Limit: initialLoadCount
      }
    });

    if (cacheLimit?.["getMessageV2"]) {

      const receivedMessageData = cacheLimit["getMessageV2"]["Messages"].filter(item => item.AuthorId != props.userName);
      const sentMessageData = cacheLimit["getMessageV2"]["Messages"].filter(item => item.AuthorId == props.userName);
      const messageData = receivedMessageData.map((item) => ({ ...item, IsRead: "true" }));
      apolloClient.writeQuery({
        query: GET_MESSAGES_BY_CONVERSATION,
        variables: {
          ConversationId: conversationId,
          Limit: initialLoadCount
        },
        data: {
          getMessageV2: {
            __typename: "MessageV2Pages",
            id: cacheLimit["getMessageV2"].id,
            Messages: [...messageData, ...sentMessageData],
            NextToken: cacheLimit["getMessageV2"].NextToken
          }
        }
      });
    }
  }

  //update unread count in cache
  function updateUnreadCountInCache(conversationId: string) {
    apolloClient.cache.writeQuery({
      query: MESSAGE_UNREAD_COUNT,
      variables: {
        ConversationId: conversationId
      },
      data: {
        conversationDetails: {
          __typename: "CountStore",
          id: `CountStore:${conversationId}`,
          ConversationId: conversationId,
          UnreadCount: 0,
          HasUrgent: false
        }
      }
    });
  }

  const updateMessageCacheDetails = ( prev) => {
    const receivedMessageData = prev["getMessageV2"]["Messages"].filter(item => item.AuthorId != props.userName);
    const messageData = receivedMessageData.map((item) => ({ ...item, IsRead: "true" }));
    return messageData;
  };

  function sentMessageDetails(prev: any) {
    return prev["getMessageV2"]["Messages"].filter(item => item.AuthorId == props.userName);
  }
  

  const handleReadMessageUpdate = (conversationId, authorId, authorOrg, updateMessageCache: Function) => {
    if (conversationId != "") {
      //update conversation cache set unreadcount as 0
      updateUnreadCountInCache(conversationId);

      const messages = apolloClient.cache.readQuery({
        query: GET_MESSAGES_BY_CONVERSATION,
        variables: {
          ConversationId: conversationId,
          Limit: initialLoadCount
        }
      });

      // Only update read statuses if we have any unread statuses
      if (messages?.["getMessageV2"]["Messages"].some((item) => !item.IsRead && item.AuthorId != props.userName)) {
        updateMessageReadStatus({
          variables: {
            ConversationId: conversationId,
            AuthorId: authorId,
            AuthorOrg: authorOrg,
            IsRead: true
          },
          update() {
            newMessageCacheUpdates(conversationId);
            apolloClient.cache.writeQuery(
              {
                query: CONVERSATION_READ,
                variables: {
                  UserName: appContext.user.userName
                },
                data: {
                  conversationRead: {
                    ConversationId: conversationId
                  }
                }
              }
            );
            updateMessageCache((prev) => {
              const sentMessageData = sentMessageDetails(prev);
              const messageData = updateMessageCacheDetails(prev);
              if (prev?.["getMessageV2"]) {
                return {
                  getMessageV2: {
                    __typename: "MessageV2Pages",
                    id: prev["getMessageV2"].id,
                    Messages: [...messageData, ...sentMessageData],
                    NextToken: prev["getMessageV2"].NextToken
                  }
                };
              }
            });
            //Note: update all possible getmesage query (with limit and without limit) so we dont't need subscription (subscriptions.ts->MessageReadStatus)
          }
        }).catch(err => { console.error(err) });
      }
    }
  };

  let titlePanelStyle = props.isRemoved ? commonStyle.rightPanelTopBarDeactivated : commonStyle.rightPanelTopBar;

  return (
    <>
      {props.isConversationSelected ? <div className={titlePanelStyle}>
        <TitlePanel displayTitle={props.conversationName} isGroupChat={props.isGroupConv} refOldConversationId={props.refOldConversationId} conversationId={props.conversationId} organisationId={props.organisationId} userName={props.userName} setDialogOpen={props.setDialogOpen} setIsRemoved={props.setIsRemoved} setLeaveError={props.setLeaveError} readMessageCallBack={handleReadMessageUpdate} />
      </div> : null}
      <div className={commonStyle.rightPanel}>
        <div className={style.sendMessage} key={props.conversationId}>
          <Form>
            <MessageDisplayComponent
              conversationId={props.conversationId ? props.conversationId : ""}
              authorId={props.authorId ? props.authorId : ""}
              isGroup={props.isGroupConv}
              hasBeenNamed={props.hasBeenNamed}
              isRemoved={props.isRemoved}
              refOldConversationId={props.refOldConversationId}
              removedOn={props.removedOn}
              isSmallGroupCreating={isSmallGroupCreating}
              readMessageCallBack={handleReadMessageUpdate}
              setLoading={setIsLoading}
              {...props}
            />
            <MessageSendingComponent
              conversationId={props.conversationId ? props.conversationId : ""}
              authorId={props.authorId ? props.authorId : ""}
              isGroupConv={props.isGroupConv}
              isRemoved={props.isRemoved}
              hasBeenNamed={props.hasBeenNamed}
              setIsSmallGroupCreating={setIsSmallGroupCreating}
              readMessageCallBack={handleReadMessageUpdate}
              isLoading={isLoading}
              {...props}
            />
          </Form>
        </div>
      </div>
    </>
  );
};

export default React.memo(SendMessage);

